import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';

const Dashboard = () => {
  const downloadFile = () => {
    // You can use the HTML5 'a' element to trigger a download
    const link = document.createElement('a');
    link.href = '/assets/images/atr.png'; // Set the file path or URL
    link.download = 'atr.png'; // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <Header label={<h5 className='py-0 my-2 text-primary font-style-italic'>WSP / ATR</h5>} />
      <main id="main" className="main">

        <div className='row'>
          <div className='col-lg-12'>
            <div className='card'>
              <div className='card-body'>
                <div className='content-block mt-2'>
                  <div className='h-4 card-content-headers mb-2'>
                    Welcome to the Skills Development Reporting
                  </div>
                  <p>
                    Learning and Development reporting is required not only from an internal management reporting perspective but is also required for
                    Skills Development as a key element of the B-BBEE scorecard and for skills levy reporting.
                  </p>
                  <p>
                    The Workplace Skills Plan (WSP) process focuses on compiling an organisation-wide skills plan that is submitted to SETA on an annual
                    basis.
                  </p>

                  <p>
                    The Annual Training Report (ATR) process enables the monitoring of intervention attendance on an ongoing basis. It ensures that the
                    relevant stakeholders are advised if attendance is sub-optimal so that this can be addressed.
                  </p>
                  <br />
                  <div className='col-lg-8 col-md-10 col-sm-8  mx-auto'>
                  <img className='fluid-image rounded' src='assets/images/atr.png' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
export default Dashboard;
