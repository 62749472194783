import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import HeadcountVSLearnersFilters from '../../components/Filters/HeadcountVSLearnersFilters';

const HeadcountVSHeadcount = () => {
  
  function downloadImage() {
    const imageUrl = '/files/Learners_Report.xlsx';
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'Learners_Report.xlsx';
    link.click();
  }


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Reports / Headcount vs. Learners"} />
      <main id="main" className="main">
        <div className='d-flex mt-3'>
          <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
          <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
        </div>
        <HeadcountVSLearnersFilters />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <div className=''>
                    <div className='text-primary font-size-15 font-weight-600'>Generate Reports:</div>
                    {["Comprehensive Headcount Report", "Learners Report only"].map((item, key) => (
                      <>
                        <div className='d-flex'>
                          <input type="radio" defaultChecked className='me-2' name='hsdghgds'/>
                          <p className='my-2'>{item}</p>
                        </div>
                      </>
                    ))}
                    <br />
                    
                    <div className='d-flex mt-3'>
                      <button onClick={downloadImage} type="button" class="btn btn-tertiary font-weight-600">Generate Report</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default HeadcountVSHeadcount
;
