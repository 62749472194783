import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import WSPATRFilters from '../../components/Filters/WSPATRFilters copy';

const WSPATRReport = () => {

  const downloadImage=(folder,url)=> {
    const imageUrl = '/files/'+folder+"/"+url;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = url;
    link.click();
  }
  const data = [
    {
      folder: "",
      name: "Generic Information",
      files: []
    },

    {
      folder: "WSP",
      name: "Workplace Skills Plan Report",
      files: [
        {
          link: "WSP_Current_Employment_Profile_LB.xlsx",
          name: "WSP_Current_Employment_Profile_LB",
        },
        {
          link: "WSP_Pivotal_Planned_Beneficiaries.xlsx",
          name: "WSP_Pivotal_Planned_Beneficiaries",
        },
        {
          link: "WSP_Planned_Beneficiaries.xlsx",
          name: "WSP_Planned_Beneficiaries",
        },
        {
          link: "WSP.zip",
          name: "Download All",
        },

      ]
    },

    {
      folder: "ATR",
      name: "Annual Training Report",
      files: [
        {
          link: "ATR_Hard_to_fill_vacancies.xlsx",
          name: "ATR_Hard_to_fill_vacancies",
        },
        {
          link: "ATR_Learning_Programmes.xlsx",
          name: "ATR_Learning_Programmes",
        },
        {
          link: "ATR_Pivotal_Trained_Beneficiaries.xlsx",
          name: "ATR_Pivotal_Trained_Beneficiaries",
        },
        {
          link: "ATR_Trained_Beneficiaries_Report.xlsx",
          name: "ATR_Trained_Beneficiaries_Report",
        },
        {
          link: "ATR.zip",
          name: "Download All",
        }
      ]
    }
  ]
  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Reports / WSP / ATR Report"} />
      <main id="main" className="main">
        <div className='d-flex mt-3'>
          <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
          <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
        </div>
        <WSPATRFilters />
        <br />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <div className=''>
                    <div className='text-primary font-size-15 font-weight-600'>Generate Reports:</div>
                    <br />
                    {["Generic Information", "Workplace Skills Plan Report", "Annual Training Report"] && data.map((item, key) => (
                      <>
                        <div className='row'>
                          <div className='col-lg-5'>
                            <div className="accordion mt-3">
                              <div class="accordion-item text-dark">
                                <h2 class="accordion-header text-dark " >
                                  <button class="accordion-button collapsed text-dark  py-3" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwo" + key} aria-expanded="false" aria-controls={"collapseTwo" + key}>
                                    {item.name}
                                  </button>
                                </h2>
                                <div id={"collapseTwo" + key} class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                  {item.files.map((element, index) => (
                                    <>
                                      <div className='d-flex mx-2 my-2 ms-4'>
                                        <div className='flex-grow-1'>{index + 1}. {element.name}</div>
                                        <button onClick={()=>{downloadImage(item.folder,element.link)}} className=' border px-3  rounded-1 text-light bg-primary'><i class="fa-solid fa-download"></i></button>
                                      </div>
                                    </>
                                  ))}
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default WSPATRReport;


