import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';
import IssueLogFilters from '../../components/Filters/IssueLogFilters';

const IssueLogs = () => {

  const navigate = useNavigate();
  const [data, setData] = useState(
    [
      {
        id: 1,
        issueCategory: "Performance",
        subject: "Update employee info",
        loggedBy: "",
        dateOpened: "2023-08-22 11:28:43",
        dateClosed: "",
        status: "Not Started",
        comment:""

      },

      {
        id: 2,
        issueCategory: "Termination",
        subject: "Remove employee - resigned",
        loggedBy: "Lerato Bopape",
        dateOpened: "2023-08-21 17:14:48",
        dateClosed: "2023-08-21 17:16:15",
        status: "Closed",
        comment:"Noted, and actioned."
      }
    ]
  )

  return (
    <>
      <Header icon={"fa-solid fa-info fa-xl mx-2"} label={"/Issue Logs"} />
      <main id="main" className="main">
        <section className="section ">
          <IssueLogFilters />
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex'>
                    <h6>
                      Issue Logs
                    </h6>
                    <button onClick={() => { navigate("/issue-logs/add") }} className='ms-auto btn  bg-primary btn-sm my-1 text-light'>Add</button>

                  </div>
                  <table class="table table-hover table-striped instance-table-header custom-table">
                    <thead>
                      <tr>
                        <th width="" scope="col" class="col_issue_sub_category" id="issue_sub_category">Issue Category</th>
                        <th width="" scope="col" class="col_subject" id="subject">Subject</th>
                        <th width="" scope="col" class="col_logged_by_name" id="logged_by_name">Logged By</th>
                        <th width="" scope="col" class="col_date_opened" id="date_opened">Date Opened</th>
                        <th width="" scope="col" class="col_date_closed" id="date_closed">Date Closed</th>
                        <th width="" scope="col" class="col_status" id="status">Status</th>
                        <th width="" scope="col" class="col_id">Action</th>
                        <th width="" scope="col" class="col_id">Attachments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, key) => (
                        <>
                          <tr>
                            <td class="issue_sub_category" id="issue_sub_category_0"><span class=" issue_sub_category" id="issue_sub_category-2">{item.issueCategory}</span></td>
                            <td class="subject" id="subject_0"><span class=" subject" id="subject-2">{item.subject}</span></td>
                            <td class="logged_by_name" id="logged_by_name_0"><span class=" logged_by_name" id="logged_by_name-2">{item.loggedBy}</span></td>
                            <td class="date_opened" id="date_opened_0"><span class=" date_opened" id="date_opened-2">{item.dateOpened}</span></td>
                            <td class="date_closed" id="date_closed_0"><span class=" date_closed" id="date_closed-2">{item.dateClosed}</span></td>
                            <td class="status" id="status_0"><span class=" status" id="status-2">{item.status}</span></td>
                            <td class="id" id="id_0">&nbsp;<button type="button" onClick={()=>navigate("/issue-logs/"+item.id)} id="2" name="id" class="btn-wide btn-shadow btn btn-primary btn-sm py-0 ext-light"><i> </i>Open</button></td>
                            <td class="id" id="id_0"></td>
                          </tr>
                        </>
                      ))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default IssueLogs;
