import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import WSPATRFilters from '../../components/Filters/AuditTrailFilters';

const AuditTrail = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"/Autdit Trail"} />
      <main id="main" className="main">
        <div className='d-flex mt-3'>
          <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
          <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>

        </div>
        <WSPATRFilters />
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
              <div class="search-bar mt-3 mx-3">
                    <form class="search-form d-flex align-items-center" method="POST" action="#">
                      <input type="text" name="query" placeholder="Search" className='search-input w-25' title="Enter search keyword" />
                      <button type="submit" title="Search"><i class="fa-solid fa-magnifying-glass bi-search"></i></button>
                    </form>
                  </div>
                <div className='card-body'>
                  
                  <div className=''>
                    <table class="table  custom-table">

                      <thead>
                        <tr>
                          <th scope="col" className=''>Employee</th>
                          <th scope="col">Changed By</th>
                          <th scope="col" className=''>Value Before</th>
                          <th scope="col" className=''>Value After</th>
                          <th scope="col" className=''> Field Name </th>
                          <th scope="col" className=''>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td >Joe Slovo</td>
                          <td>Maria Livingstone</td>
                          <td>R 43 000.23</td>
                          <td>R 57 000.02</td>
                          <td>Inseta Funding</td>
                          <td>2022 - 04 - 02 13:48:02</td>
                        </tr>

                        <tr>
                          <td >Anele Miriam Swanepoel</td>
                          <td>Maria Livingstone</td>
                          <td>Company Internal LMS</td>
                          <td>Ceed Learning</td>
                          <td>Training Provider</td>
                          <td>2022 - 04 - 02 14:32:09</td>
                        </tr>

                        <tr>
                          <td >Sam Smith</td>
                          <td>Debra Patta</td>
                          <td>Credit-Bearning</td>
                          <td>Non Credit-Bearing</td>
                          <td>Credit Bearing</td>
                          <td>2022 - 07 - 02 10:05:10</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default AuditTrail;
