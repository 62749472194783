

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DashboardLearnersFilters from '../../components/Filters/DashboardLearnersFilters';


const Learners = () => {

  const chartProps1 = {
    type: 'column',
    // width: 1000,
    // height: 400,
    title: '',
    categories: ["Apr ‘22", "May ‘22", "Jun ‘22", "Jul ‘22", "Aug ‘22", "Sep ‘22", "Oct ‘22", "Nov ‘22", "Dec ‘22", "Jan ‘22", "Feb ‘22", "Mar ‘22"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        //blue
        type: 'line',
        name: 'Client Services',
        data: [1300, 1450, 1500, 1550, 1600, 1600, 1500, 1500, 1400, 1600, 1400],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
        marker: {
          lineWidth: 2,
          lineColor: '#5BBDCB',
          fillColor: '#5BBDCB'
        }
      },
      //brown
      {
        type: 'line',
        name: 'Sales & Marketing',
        data: [900, 1210, 820, 930, 1050, 1160, 1270, 1380, 1280, 1180, 1090, 990],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#7A0200',
        marker: {
          lineWidth: 2,
          lineColor: '#7A0200',
          fillColor: '#7A0200'
        }
      },
      //green
      {
        type: 'line',
        name: 'Asset Management',
        data: [200, 810, 620, 430, 350, 260, 170, 180, 180, 280, 890, 790],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
        marker: {
          lineWidth: 2,
          lineColor: '#67904B',
          fillColor: '#67904B'
        }
      }
    ]
  };


  const chartProps2 = {
    type: 'column',
    title: '',
    categories: ["African", "Indian", "Coloured", "White"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Planned',
        data: [70, 50, 60, 20],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#547A91',
      },
      {
        name: 'Actual',
        data: [25, 15, 15, 15],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
      },
      {
        name: 'Variance',
        data: [45, 35, 45, 5],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#7A0200',
      }
    ]
  };

  const chartProps3 = {
    type: 'column',
    title: '',
    categories: ["Managers", "Professionals", "Technicians & <br/>Associate <br/>Professionals", "Clerical <br/> Support <br/> Workers","Service <br/>& Sales<br/> Workers","Machinery <br/>Operators &<br/> Assemblers","Elementary<br/> Occupations"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Planned',
        data: [25,50,40,50,0,0,8],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#547A91',
      },
      {
        name: 'Actual',
        data: [10,15,15,20,0,0,8],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
      },
      {
        name: 'Variance',
        data: [15,35,25,30,0,0,0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#7A0200',
      }
    ]
  };

  const chartProps4 = {
    type: 'column',
    title: '',
    categories: ["18-24","25-29","30-39","40-49","50-59","60+"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Planned',
        data: [50,50,40,0,0,0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#547A91',
      },
      {
        name: 'Actual',
        data: [10,15,15,20,0,0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
      },
      {
        name: 'Variance',
        data: [40,35,25,20,0,0],
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#7A0200',
      }
    ]
  };

  const chartProps5 = {
    type: 'column',
    title: '',
    categories: ["Female","Male"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        name: 'Planned',
        data: [60,20],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#547A91',
      },
      {
        name: 'Actual',
        data: [15,15],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#67904B',
      },
      {
        name: 'Variance',
        data: [45,5],
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        color: '#7A0200',
      }
    ]
  };


  const getOptions = (props) => ({
    chart: {
      type: props.type,
   
      backgroundColor: 'transparent',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });




  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Dashboards / Learners"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div className='d-flex mt-3'>
            <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
            <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
          </div>
          <DashboardLearnersFilters />
        </div>
        <section className="section mt-3">
          <div className='row'>

            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <div className="">
                    <h5 className="header-title">Cumulative number of Leaners</h5>
                  </div>
                  <div className={"d-flex justify-content-center"}>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                  <div>
                    <button className='btn btn-sm py-1 text-light bg-secondary'>
                      <i class="fa-solid fa-file-excel me-2"></i>
                      Export data Table
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                <div className="">
                  <h5 className="header-title">Learners by Race</h5>
                </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps2)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                <div className="">
                  <h5 className="header-title">Learners by  <span className='font-weight-600'>Occupational Group</span></h5>
                </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps3)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                <div className="">
                  <h5 className="header-title">Learners by Age</h5>
                </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps4)} />
                </div>
              </div>
            </div>

            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                <div className="">
                  <h5 className="header-title">Learners by Gender</h5>
                </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps5)} />
                </div>
              </div>
            </div>


            
          </div>
        </section>
      </main>
    </>
  )
}
export default Learners;

