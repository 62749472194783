const LearnersInformation = [
    {
        id: 1,
        Emp_Number: "5778894",
        Name: "Jonathan",
        Last_Name: "Wright",
        Position: "Manager: Ops & Admin",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 2,
        Emp_Number: "5890005",
        Name: "Lucas",
        Last_Name: "Wright",
        Position: "Team Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 3,
        Emp_Number: "6001116",
        Name: "Zoey",
        Last_Name: "Wright",
        Position: "Switchboard Operator",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 4,
        Emp_Number: "6112227",
        Name: "Natalie",
        Last_Name: "Cooper",
        Position: "Customer Services Officer",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 5,
        Emp_Number: "6223338",
        Name: "Avery",
        Last_Name: "Davis",
        Position: "Lead Spec: Test",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 6,
        Emp_Number: "6334449",
        Name: "Lily",
        Last_Name: "Davis",
        Position: "Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 7,
        Emp_Number: "6445560",
        Name: "Maya",
        Last_Name: "Davis",
        Position: "Manager: Ops & Admin",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 8,
        Emp_Number: "6556671",
        Name: "Christopher",
        Last_Name: "Evans",
        Position: "Team Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 9,
        Emp_Number: "6667782",
        Name: "Rachel",
        Last_Name: "Foster",
        Position: "Switchboard Operator",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 10,
        Emp_Number: "6778893",
        Name: "Adrian",
        Last_Name: "Garcia",
        Position: "Customer Services Officer",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 11,
        Emp_Number: "6890004",
        Name: "Evelyn",
        Last_Name: "Garcia",
        Position: "Lead Spec: Test",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 12,
        Emp_Number: "7001115",
        Name: "Kevin",
        Last_Name: "Garcia",
        Position: "Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 13,
        Emp_Number: "7112226",
        Name: "Anthony",
        Last_Name: "Scott",
        Position: "Manager: Ops & Admin",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 14,
        Emp_Number: "7223337",
        Name: "Caroline",
        Last_Name: "Scott",
        Position: "Team Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 15,
        Emp_Number: "7334448",
        Name: "Khloe",
        Last_Name: "McKee",
        Position: "Switchboard Operator",
        Line_Manager: "Joe Slovo",
    },
    {
        id: 16,
        Emp_Number: "7445559",
        Name: "Charlotte",
        Last_Name: "Gonzales",
        Position: "Customer Services Officer",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 17,
        Emp_Number: "7556670",
        Name: "Owen",
        Last_Name: "Johnson",
        Position: "Lead Spec: Test",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 18,
        Emp_Number: "7667781",
        Name: "Victoria",
        Last_Name: "Johnson",
        Position: "Manager",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 19,
        Emp_Number: "7778892",
        Name: "Leah",
        Last_Name: "Martin",
        Position: "Manager: Ops & Admin",
        Line_Manager: "Mickey Mouse",
    },
    {
        id: 20,
        Emp_Number: "7890003",
        Name: "Bjorn",
        Last_Name: "Holmes",
        Position: "Team Manager",
        Line_Manager: "Joe Slovo",
    },
];

export default LearnersInformation;
