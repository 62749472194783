// ofoOccupations.js
const OFOSpecialization =
    [
    "2021-111101 - Member of Mayoral Committee",
    "2021-111101 - Ward Committee Member",
    "2021-111101 - Councillor",
    "2021-111101 - Member of The Council of Provinces",
    "2021-111101 - Member of Executive Council (MEC)",
    "2021-111101 - Mayor",
    "2021-111101 - Speaker (Local or Provincial Government)",
    "2021-111101 - Community Board Member",
    "2021-111101 - Premier",
    "2021-111102 - Cabinet Minister",
    "2021-111102 - Government Minister",
    "2021-111102 - President",
    "2021-111102 - Deputy President",
    "2021-111102 - Chief Whip",
    "2021-111102 - State Secretary",
    "2021-111102 - Speaker (Parliament)",
    "2021-111102 - Member of Cabinet",
    "2021-111202 - Diplomatic Mission Administrator",
    "2021-111202 - Labour Inspectorate Manager",
    "2021-111203 - City Administrator",
    "2021-111203 - City Treasurer",
    "2021-111203 - Municipal Manager",
    "2021-111203 - Town Clerk",
    "2021-111203 - General Manager Local Authority",
    "2021-111203 - Water Services Provider Manager",
    "2021-111204 - Chief Operating Officer (Government Department)",
    "2021-111204 - Attaché",
    "2021-111204 - Commissioner",
    "2021-111204 - Secretary (Government Department)",
    "2021-111204 - Consular Officer",
    "2021-111204 - Plenipotentiary",
    "2021-111204 - Administrative Attaché",
    "2021-111204 - Trade Commissioner Attaché",
    "2021-111204 - Spokesperson",
    "2021-111204 - Chief of Staff",
    "2021-111204 - Registrar (Including Legal / Court etc. But Excluding Tertiary Institutions)",
    "2021-111204 - Chief Electoral Officer",
    "2021-111205 - Senior Metro Police Officer",
    "2021-111206 - Ombudsman",
    "2021-111206 - Public Protector",
    "2021-111207 - Consul General",
    "2021-111207 - Head of Mission",
    "2021-111207 - Ambassador",
    "2021-111207 - High Commissioner",
    "2021-111207 - Head of Department",
    "2021-111207 - Superintendent-general",
    "2021-111301 - Member of Traditional Council",
    "2021-111301 - Head of Village",
    "2021-111401 - Elected Full Time Party Official",
    "2021-111401 - President Trade Union",
    "2021-111401 -  Secretary General",
    "2021-111402 - Union Organiser",
    "2021-111402 - Shop Steward",
    "2021-111402 - Trade Union Official",
    "2021-111403 - Political Party Official",
    "2021-111403 - Political Party Organiser",
    "2021-112101 - Senior / Lead Independent Non-executive Director",
    "2021-112101 - Executive Director",
    "2021-112101 - Company Director",
    "2021-112101 - Independent Non-executive Director",
    "2021-112101 - Alternative Director",
    "2021-112101 - Managing Director (Enterprise / Organisation)",
    "2021-112101 - Chief Executive Officer (CEO)",
    "2021-112101 - Deemed (Shadow) Director",
    "2021-112101 - Non-executive Director",
    "2021-112101 - Managing Director",
    "2021-121101 - Budgeting Manager",
    "2021-121101 - Financial Controller",
    "2021-121101 - Account Systems Manager",
    "2021-121101 - Internal Revenue Controller",
    "2021-121101 - Municipal Finance Manager",
    "2021-121101 - Finance Director",
    "2021-121101 - Financial Administrator",
    "2021-121101 - Revenue Assessment Manager",
    "2021-121101 - Chief Financial Officer (CFO)",
    "2021-121101 - Foreign Exchange Manager",
    "2021-121101 - Chief Accountant",
    "2021-121101 - Financial Administration Manager",
    "2021-121102 - Remuneration Manager",
    "2021-121103 - Billing and Settlement Plan (BSP) Manager",
    "2021-121104 - Internal Audit Quality Assurer",
    "2021-121201 - Transition Manager",
    "2021-121201 - Employee Relations Manager",
    "2021-121202 - Education Training and Skills Development Manager",
    "2021-121202 - Training & Development Manager",
    "2021-121202 - Quality Training Manager",
    "2021-121202 - Assessments Manager",
    "2021-121202 - Learning and Development Manager",
    "2021-121202 - Human Resources Development Manager",
    "2021-121202 - Technical Training Manager",
    "2021-121202 - Training Manager",
    "2021-121203 - Corporate Benefits Controller",
    "2021-121203 - Benefits Manager",
    "2021-121203 - Remuneration and Benefits Manager",
    "2021-121203 - Employee Benefits Manager",
    "2021-121205 - Employee Wellness Consultant",
    "2021-121206 - Safety, Health and Environmental (SHE) Manager",
    "2021-121301 - Corporate Planning Manager",
    "2021-121301 - Planning & Development Manager",
    "2021-121301 - Strategic Planning Manager",
    "2021-121301 - Public Policy Manager",
    "2021-121301 - Policy Development Manager",
    "2021-121901 - Business Operations Manager",
    "2021-121902 - Administrative Services Manager",
    "2021-121902 - Business Services Manager",
    "2021-121903 - Resources Manager",
    "2021-121905 - Project Director",
    "2021-121908 - Quality Systems Coordinator",
    "2021-121908 - Agriculture & Forestry Quality Controller",
    "2021-121908 - ETQA Manager",
    "2021-121908 - Quality Assurance / Systems Auditor",
    "2021-121908 - Management System Auditor",
    "2021-121908 - Quality Control Manager",
    "2021-121908 - Quality Auditor",
    "2021-121908 - Quality Certification Manager",
    "2021-122101 - Business Support Manager",
    "2021-122101 - Business Development Manager",
    "2021-122101 - Key Account Manager",
    "2021-122101 - Insurance Sales and Marketing Manager",
    "2021-122102 - Sales Director",
    "2021-122102 - Sales Executive",
    "2021-122103 - Marketing Director",
    "2021-122103 - Market Research Manager",
    "2021-122103 - Marketing Manager",
    "2021-122104 - Interactive Direct Marketing Compliance Officer",
    "2021-122105 - Customer Care Manager / Representative",
    "2021-122105 - Client Services Advisor",
    "2021-122105 - Customer Services Engineer",
    "2021-122105 - Client Services Manager / Representative",
    "2021-122105 - Client Service Manager",
    "2021-122201 - Media Advisor / Manager",
    "2021-122201 - Advertising Manager",
    "2021-122201 - Media and Communications Manager",
    "2021-122201 - Public Relations Manager",
    "2021-122201 - Chief Communications Manager / Officer",
    "2021-122201 - Relationship Manager",
    "2021-122201 - Green Marketer",
    "2021-122201 - Corporate Relations Manager",
    "2021-122301 - Research Manager",
    "2021-122301 - Product Development Manager",
    "2021-122301 - Research Director",
    "2021-131101 - Mixed Crop Farm Manager",
    "2021-131101 - Dairy Farm Manager",
    "2021-131101 - Livestock Farm Manager",
    "2021-131101 - Arboriculture Farm Manager",
    "2021-131101 - Field Vegetable Farm Manager",
    "2021-131101 - Mixed Crop and Livestock Farm Manager",
    "2021-131101 - Agronomy Farm Manager",
    "2021-131101 - Sugar Farm Manager",
    "2021-131101 - Vegetable Farm Manager",
    "2021-131101 - Horticultural Farm Manager",
    "2021-131101 - Ornamental Horticultural Farm Manager",
    "2021-131102 - Production / Operations Manager (Forestry)",
    "2021-131102 - Forest Logistics Manager",
    "2021-131102 - Forest Manager",
    "2021-131102 - Timber Productions Manager",
    "2021-131102 - Plantations Manager",
    "2021-131102 - Harvest Manager (Forestry)",
    "2021-131103 - Forestry Production Foreman",
    "2021-131201 - Fresh Water Aquaculture Farm Manager",
    "2021-131201 - Aquaculture Hatchery Manager",
    "2021-131201 - Crocodile Farm Manager",
    "2021-131201 - Abalone Farm Manager",
    "2021-131201 - Mariculture Farmer / Farm Manager",
    "2021-132101 - Factory Manager",
    "2021-132102 - Works / Workshop Manager (Manufacturing)",
    "2021-132102 - Industrial Production Manager",
    "2021-132102 - Distillery Manager",
    "2021-132102 - Processing Unit Manager",
    "2021-132102 - Plant Superintendent",
    "2021-132102 - Plant Manager (Manufacturing)",
    "2021-132102 - Operations Manager (Production)",
    "2021-132102 - Planning Manager (Manufacturing)",
    "2021-132102 - Processing Manager",
    "2021-132104 - Engineering Maintenance Manager",
    "2021-132104 - Bankable Feasibility Study Manager",
    "2021-132104 - Mining Engineering Manager",
    "2021-132105 - Biofuels Production Manager",
    "2021-132105 - Landfill Gas Collection Manager",
    "2021-132105 - Hydroelectric Production Manager",
    "2021-132105 - Concentrated Solar Power (CSP) Plant Manager",
    "2021-132105 - Methane Collection Manager",
    "2021-132105 - Wind Energy Operations Manager",
    "2021-132105 - Geothermal Production Manager",
    "2021-132105 - Biomass Power Plant Manager",
    "2021-132107 - Reliability Engineer",
    "2021-132107 - Supplier Quality Assurance Manager",
    "2021-132107 - Quality Assurance Manager",
    "2021-132107 - Services Quality Manager",
    "2021-132111 - Quality Training Controller",
    "2021-132201 - Factory Manager (Mining)",
    "2021-132201 - Quarry Manager",
    "2021-132201 - Mine Deputy",
    "2021-132202 - Mining Exploration Manager",
    "2021-132202 - Resource Geologist",
    "2021-132202 - Chief Geologist (Mining)",
    "2021-132202 - Mine Design and Planning Manager",
    "2021-132202 - Planning Manager (Mining)",
    "2021-132202 - Chief Surveyor (Mining)",
    "2021-132202 - Mine Planning Manager",
    "2021-132202 - Technical Services Manager (Mining)",
    "2021-132202 - Chief Mine Planner",
    "2021-132202 - Exploration Manager",
    "2021-132202 - Grade Controller (Mining)",
    "2021-132202 - Chief of Mining Services",
    "2021-132202 - Geology Manager (Mining)",
    "2021-132301 - Construction Manager",
    "2021-132301 - Property Development Manager",
    "2021-132301 - Building and Construction Manager",
    "2021-132301 - Construction Site Manager",
    "2021-132301 - Construction Project Director",
    "2021-132302 - Professional Builder",
    "2021-132302 - Building Contractor",
    "2021-132302 - Building Site Manager / Agent",
    "2021-132401 - Procurement Manager",
    "2021-132401 - Freight Manager",
    "2021-132401 - Supply Lead Manager",
    "2021-132401 - Supply Chain Executive",
    "2021-132401 - Purchase Manager",
    "2021-132401 - Parts Manager",
    "2021-132401 - Storage and Distribution Manager",
    "2021-132401 - Acquisitions Manager",
    "2021-132401 - Goods Clearance Manager",
    "2021-132401 - Supply Chain Manager",
    "2021-132401 - Depot Manager",
    "2021-132401 - Freight Forwarding Customs Clearing Manager",
    "2021-132402 - Receiving & Dispatch Logistics Manager",
    "2021-132403 - Car Rental Agency Manager",
    "2021-132403 - Transport Company Manager",
    "2021-132403 - Cargo Manager",
    "2021-132404 - Coolstore / Packhouse / Stockroom / Storeroom Manager",
    "2021-132404 - Storage and Warehousing Manager",
    "2021-132406 - Railway Station Master",
    "2021-133101 - ICT / IT Manager",
    "2021-133101 - Chief Technology Officer",
    "2021-133101 - ICT / IT Director",
    "2021-133102 - ICT / IT / Computer Support Manager",
    "2021-133102 - ICT / IT / Computer Service Manager",
    "2021-133102 - ICT / IT / Computer Operations Manager",
    "2021-133102 - ICT Project Director",
    "2021-133102 - Hardware Development Manager",
    "2021-133102 - ICT / IT / Computer Marketing Executive",
    "2021-133103 - Data Operations Manager",
    "2021-133103 - Data Processing Manager",
    "2021-133104 - ICT Development Manager",
    "2021-133104 - Technical Solutions Manager",
    "2021-133104 - Technical Policy Manager",
    "2021-134101 - Early Childhood Development Centre Manager",
    "2021-134101 - Pre-school Principal",
    "2021-134101 - Child Care Coordinator",
    "2021-134101 - Child Care Centre Director",
    "2021-134101 - Day Care Centre Manager",
    "2021-134201 - Medical Manager",
    "2021-134201 - Director of Clinical Services",
    "2021-134201 - Public Health Administrator",
    "2021-134201 - Health Service Coordinator",
    "2021-134201 - Health Service Manager",
    "2021-134201 - Public Health Manager",
    "2021-134201 - Director of Medical Services",
    "2021-134202 - Hospital Matron",
    "2021-134202 - Director of Nursing",
    "2021-134204 - Director of Speech Pathology",
    "2021-134204 - Director Allied Health Services",
    "2021-134204 - Director of Physiotherapy Services",
    "2021-134204 - Director of Pharmacy",
    "2021-134205 - Sport Psychology Manager",
    "2021-134205 - Biokinetics Clinic Manager",
    "2021-134301 - Aged Care Manager",
    "2021-134301 - Retirement Village Manager",
    "2021-134401 - Social Services Project Manager",
    "2021-134401 - Social Services Centre Manager",
    "2021-134401 - Social Work Manager",
    "2021-134401 - Child and Youth Care Services Manager",
    "2021-134501 - Headmaster",
    "2021-134501 - Headmistress",
    "2021-134501 - Early Childhood Development School Principal",
    "2021-134501 - Deputy Principal",
    "2021-134501 - Independent School Principal",
    "2021-134502 - Further Education and Training College Programme Managers",
    "2021-134503 - Head of College Faculty",
    "2021-134503 - Campus Principal",
    "2021-134503 - Dean of Faculty",
    "2021-134504 - Schools Director",
    "2021-134504 - Provincial Education Manager",
    "2021-134505 - Deputy Vice-chancellor",
    "2021-134505 - Deputy Vice Chancellor : Research and Innovation",
    "2021-134505 - Vice-chancellor (Principal)",
    "2021-134602 - Credit Control Manager",
    "2021-134702 - Brigade Chief of Staff",
    "2021-134702 - Brigade Commander/General Officer Commanding (GOC)",
    "2021-134702 - War Ship Captain",
    "2021-134702 - SAAF Squadron Second In Command (SAAF only)",
    "2021-134702 - Battalion Second In Command",
    "2021-134702 - Unit Commander (Combat Units Only)",
    "2021-134702 - SAAF Squadron Commander (SAAF only)",
    "2021-134702 - War Ships Executive Officer / First Lieutenant",
    "2021-134702 - Unit Second In Command (Combat Units Only)",
    "2021-134702 - Division Chief of Staff",
    "2021-134702 - Regiment Second In Command",
    "2021-134702 - Battalion Commander",
    "2021-134702 - Division Commander/General Officer Commanding (GOC)",
    "2021-134702 - Regiment Commander",
    "2021-134703 - Master at Arms",
    "2021-134703 - Regimental Sergeant Major",
    "2021-134703 - Coxswain (Warship)",
    "2021-134901 - Environmental Education Manager",
    "2021-134901 - Environmental Health Manager",
    "2021-134901 - Species Protection Manager",
    "2021-134901 - Water Resource Management Practitioner",
    "2021-134901 - Sanitation Programme Manager",
    "2021-134901 - Water Resource Manager",
    "2021-134901 - Agriculture and Forestry Coordinator",
    "2021-134901 - Land Care Manager",
    "2021-134901 - Wild Life Protection Services Manager",
    "2021-134901 - Land and Water Manager",
    "2021-134901 - Contaminated Sites Manager",
    "2021-134901 - Centralised Permits and Cites Manager",
    "2021-134901 - Conservation Agriculture System Manager",
    "2021-134901 - Wild Life Management Services Manager",
    "2021-134901 - Pollution and Waste Manager",
    "2021-134901 - Conservation Science Manager",
    "2021-134903 - Owner Manager",
    "2021-134903 - Entrepreneurial Business Manager",
    "2021-134904 - Centre Manager",
    "2021-134906 - Architectural Practice Manager",
    "2021-134906 - Accounting Practice Manager",
    "2021-134906 - Real Estate Manager",
    "2021-134906 - Veterinary Practice Manager",
    "2021-134911 - Insurance Claims Manager",
    "2021-134912 - Fire Services Inspector",
    "2021-134912 - Chief Fire and Rescue Officer",
    "2021-134915 - Farm Produce Processing Manager",
    "2021-134915 - Pet Food Mill Manager",
    "2021-134915 - Abattoir Veterinarian",
    "2021-134915 - Seed Processing Plant Manager",
    "2021-134915 - Feed Mill Manager",
    "2021-134915 - Field Production Manager (Seed)",
    "2021-134915 - Mill Manager",
    "2021-134915 - Sugar Mill Manager",
    "2021-134915 - Food and Beverages Processing Manager",
    "2021-134915 - Abattoir Manager",
    "2021-134915 - Farm Produce Packhouse Manager",
    "2021-134915 - Fresh Produce Packhouse Manager",
    "2021-134916 - Feedlot Foreman",
    "2021-134916 - Abattoir Foreman",
    "2021-134916 - Fresh Produce Packhouse Foreman",
    "2021-134916 - Seed Processing Plant Foreman",
    "2021-134917 - Publishing Director",
    "2021-134917 - Publishing Manager",
    "2021-134917 - Print Manager",
    "2021-134917 - Publishing Production Manager",
    "2021-134917 - Commissioning Editor",
    "2021-141101 - Duty Manager (Hotel)",
    "2021-141101 - Boarding House Manager",
    "2021-141101 - Innkeeper",
    "2021-141101 - Backpackers Manager",
    "2021-141101 - Hotelier",
    "2021-141101 - Lodge Manager",
    "2021-141101 - Hostel Manager",
    "2021-141101 - Resort Manager",
    "2021-141102 - Bed and Breakfast Operator",
    "2021-141201 - Restaurateur",
    "2021-141201 - Canteen Manager",
    "2021-141201 - Restaurant Manager",
    "2021-141201 - Cafeteria Manager",
    "2021-141201 - Internet Cafe Manager",
    "2021-141201 - Caterer",
    "2021-141201 - Bistro Manager",
    "2021-141201 - Catering Manager",
    "2021-141201 - Food Services Manager",
    "2021-141201 - Mess Manager",
    "2021-141201 - Food and Beverage Manager",
    "2021-141202 - Nightclub Manager",
    "2021-141202 - Clubhouse Manager",
    "2021-141202 - Club Licensee",
    "2021-141203 - Food and Beverage Coordinator",
    "2021-141204 - Rooms Division Manager, Front Office Manager",
    "2021-141204 - Central Reservations Manager",
    "2021-142101 - Export Manager",
    "2021-142101 - Import Manager",
    "2021-142102 - Wholesale Merchant",
    "2021-142103 - Shop Owner",
    "2021-142103 - Retail Store Manager",
    "2021-142103 - Shop Manager",
    "2021-143101 - Racing Betting Branch Manager",
    "2021-143101 - Tab Manager",
    "2021-143102 - Slots Technical Manager",
    "2021-143102 - Race Track Operations Manager",
    "2021-143102 - Cashier Manager Gaming",
    "2021-143102 - Gaming Promotions Manager",
    "2021-143102 - Tables Manager",
    "2021-143102 - Slots Manager",
    "2021-143102 - Gaming Project Manager",
    "2021-143102 - Gaming Administration Manager",
    "2021-143104 - Art Director (Film, Television or Stage)",
    "2021-143104 - Arts Centre Manager",
    "2021-143104 - Artistic Director",
    "2021-143104 - Producer Performing Arts",
    "2021-143104 - Arts / Culture Administrator",
    "2021-143104 - Art Gallery Director",
    "2021-143104 - Production Manager Performing Arts",
    "2021-143105 - Sports Team Manager",
    "2021-143105 - Sport Courses, Clinics and Programmes Managers",
    "2021-143106 - Skateboard Park Manager",
    "2021-143106 - Video Games Arcade Manager",
    "2021-143106 - Video Games Centre Manager",
    "2021-143106 - Entertainment Centre Manager",
    "2021-143107 - Fitness Centre Administrator",
    "2021-143107 - Gym Manager",
    "2021-143107 - Health Club Manager",
    "2021-143107 - Sports Club Manager",
    "2021-143108 - Aquatic Centre Manager",
    "2021-143108 - Indoor Sports Centre Manager (Squash, Tennis, Ten Pin Bowling etc)",
    "2021-143108 - Sport Stadium Manager",
    "2021-143108 - Leisure Centre Manager",
    "2021-143108 - Riding School Manager",
    "2021-143108 - Gymnasium Manager",
    "2021-143108 - Golf Course Manager",
    "2021-143109 - Membership Relations Manager",
    "2021-143901 - Garden / Park Manager",
    "2021-143901 - Campus Administrator / Supervisor",
    "2021-143901 - Facilities Supervisor",
    "2021-143902 - Cleaning Contractor",
    "2021-143904 - Gaming Security Manager",
    "2021-143904 - Hospitality Security Manager",
    "2021-143904 - Security Risk Assessment Manager",
    "2021-143904 - Surveillance Investigations Manager",
    "2021-143904 - Surveillance Manager",
    "2021-143904 - Entrance Manager",
    "2021-143905 - Call / Contact Centre Quality Manager",
    "2021-143905 - Contact Centre Workforce Manager",
    "2021-143905 - Telesales Manager",
    "2021-143905 - Contact Centre Manager: Workforce Scheduler",
    "2021-143905 - Contact Centre Manager: Resource Planner",
    "2021-143905 - Call or Contact Centre Supervisor",
    "2021-143906 - Camping Site Manager",
    "2021-143906 - Holliday Park Manager",
    "2021-211101 - Nuclear Physicist",
    "2021-211101 - Medical Physicist",
    "2021-211101 - Biophysicist",
    "2021-211101 - Astrophysicist",
    "2021-211101 - Astronomer",
    "2021-211101 - Nanotechnology Engineering Technologists",
    "2021-211101 - Crystallographer",
    "2021-211201 - Atmospheric Scientist",
    "2021-211201 - Climatologist",
    "2021-211201 - Climate Scientist",
    "2021-211201 - Hydrometeorologist",
    "2021-211201 - Weather Forecaster",
    "2021-211301 - Laboratory Chemist",
    "2021-211301 - Analytical Chemist",
    "2021-211301 - Manufacturing Chemist",
    "2021-211301 - Industrial Chemist",
    "2021-211301 - Pharmacologist (Non-clinical)",
    "2021-211401 - Engineering Geologist",
    "2021-211401 - Mine Geologist",
    "2021-211401 - Exploration Geologist",
    "2021-211401 - Hydrogeologist",
    "2021-211401 - Field Geologist",
    "2021-211401 - Petrologist",
    "2021-211401 - Geological Oceanographer",
    "2021-211401 - Geochemist",
    "2021-211401 - Geological Auditor",
    "2021-211401 - Marine Geologist",
    "2021-211401 - Environmental Geologist",
    "2021-211401 - Structural Geologist",
    "2021-211401 - Geoscientist",
    "2021-211401 - Volcanologist",
    "2021-211401 - Geomorphologist",
    "2021-211401 - Palaeontologist",
    "2021-211401 - Geological Planner",
    "2021-211402 - Marine Scientist",
    "2021-211402 - Seismologist",
    "2021-211402 - Geophysical Scientist",
    "2021-211402 - Physical Oceanographer",
    "2021-211403 - Polymer Scientist",
    "2021-211403 - Fibre Technologist",
    "2021-211403 - Ceramics Scientist",
    "2021-211404 - Diamond Gemologist",
    "2021-212101 - Actuary Manager",
    "2021-212101 - Average Adjuster",
    "2021-212102 - Operations Research Analyst",
    "2021-212103 - Statistical Analyst",
    "2021-212103 - Reporting Analyst",
    "2021-212103 - Demographer",
    "2021-212103 - Geostatistician",
    "2021-212103 - Statistical Modeller",
    "2021-212103 - Biometrician",
    "2021-212103 - Population Analyst",
    "2021-212103 - Demographic Analyst",
    "2021-212103 - Epidemiologist",
    "2021-212103 - Statistical Methodologist",
    "2021-212103 - Statistical GIS Specialist",
    "2021-213102 - Life Scientist (General)",
    "2021-213102 - Phytotomist",
    "2021-213103 - Neuroanatomist",
    "2021-213103 - Sports Physiologist",
    "2021-213103 - Physiologist",
    "2021-213103 - Embryologist",
    "2021-213104 - Enzyme Chemist",
    "2021-213104 - Protein Chemist",
    "2021-213104 - Toxicologist",
    "2021-213104 - Biological Chemist",
    "2021-213104 - Toxic Advisor",
    "2021-213105 - Plant Molecular Biologist",
    "2021-213105 - Molecular Biologist",
    "2021-213105 - Cytotechnologist",
    "2021-213105 - Fruit Research Scientist",
    "2021-213105 - Bioprocess Engineer",
    "2021-213105 - Molecular Geneticist",
    "2021-213105 - Genomic Investigator",
    "2021-213105 - Crop Research Scientist",
    "2021-213105 - Cell Geneticist",
    "2021-213105 - DNA Sequencer",
    "2021-213105 - Bioinformatician",
    "2021-213105 - Biodiversity Researcher",
    "2021-213106 - Plant Taxonomist",
    "2021-213106 - Plant Environmental Biologist",
    "2021-213106 - Mycologist",
    "2021-213106 - Plant Morphologist",
    "2021-213107 - Fisheries Analyst",
    "2021-213107 - Fisheries Researcher",
    "2021-213107 - Aquatic Biologist",
    "2021-213107 - Fisheries Biologist",
    "2021-213107 - Fisheries Scientist",
    "2021-213108 - Clinical Evaluation Advisor",
    "2021-213108 - Bacteriologist",
    "2021-213108 - Virologist",
    "2021-213108 - Microbiology Researcher",
    "2021-213108 - Viral Serologist",
    "2021-213109 - Mammologist",
    "2021-213109 - Ornithologist",
    "2021-213109 - Animal Scientist",
    "2021-213109 - Osteologist",
    "2021-213109 - Entomologist",
    "2021-213109 - Animal Physiologist",
    "2021-213109 - Apicultural Scientist",
    "2021-213109 - Nematologist",
    "2021-213109 - Parasitologist",
    "2021-213110 - Clinical Biochemist",
    "2021-213110 - Microbiologist (Medical Research)",
    "2021-213110 - Pharmacologist (Clinical Research)",
    "2021-213110 - Transfusion Scientist",
    "2021-213110 - Public Health Scientist",
    "2021-213110 - Cytologist",
    "2021-213110 - Clinical Research Associate",
    "2021-213110 - Histologist",
    "2021-213110 - Medical Laboratory Scientist",
    "2021-213110 - Biomedical Technologist",
    "2021-213110 - Medical Researcher",
    "2021-213110 - Medical Scientific Officer",
    "2021-213110 - Medical Technologist",
    "2021-213110 - Immunologist (Medical Research)",
    "2021-213110 - Haematologist (Medical Research)",
    "2021-213201 - Agriculture Extension Officer",
    "2021-213201 - Farm Economic Techniques Advisor",
    "2021-213201 - Agriculture Field Officer",
    "2021-213201 - Landcare Officer",
    "2021-213201 - Horticulture Consultant / Advisor",
    "2021-213201 - Vegetable Consultant",
    "2021-213201 - Field Husbandry Consultant / Advisor",
    "2021-213201 - Animal Husbandry Consultant / Advisor",
    "2021-213201 - Agriculture Economic Advisor",
    "2021-213201 - Agriculture Advisor",
    "2021-213201 - Farm Consultant / Advisor",
    "2021-213201 - Floriculture Consultant / Advisor",
    "2021-213201 - Orchard Consultant",
    "2021-213201 - Pasture Consultant",
    "2021-213201 - Agriculture Mentor",
    "2021-213202 - Seed Production Horticulturist",
    "2021-213202 - Companion Animal Nutritionist",
    "2021-213202 - Plant Pathology Manager",
    "2021-213202 - Soil and Plant Scientists",
    "2021-213202 - Quarantine Scientist",
    "2021-213202 - Agronomist",
    "2021-213202 - Animal Nutritionist",
    "2021-213202 - Agricultural Immunologist",
    "2021-213202 - Agrostologist",
    "2021-213202 - Plant / Seed Breeder",
    "2021-213202 - Plant Production Scientist",
    "2021-213202 - Farming Scientist",
    "2021-213202 - Plant Physiologist",
    "2021-213202 - Seed Analyst",
    "2021-213202 - Seed Product Developer",
    "2021-213202 - Seed Production Agronomist",
    "2021-213202 - Pomologist",
    "2021-213202 - Plant Pathologist",
    "2021-213202 - Animal Husbandry Scientist",
    "2021-213202 - Plant Biologist / Ecologist / Toxicologist",
    "2021-213203 - Forestry Extension Officer",
    "2021-213203 - Forestry Fire Specialist",
    "2021-213203 - Wood Technologist",
    "2021-213203 - Forestry Consultant",
    "2021-213203 - Forestry Researcher",
    "2021-213203 - Forest Pathologist",
    "2021-213203 - Wood Scientist",
    "2021-213203 - Forest Ranger",
    "2021-213203 - Forestry Advisor",
    "2021-213203 - Silviculturist",
    "2021-213204 - Oenologist",
    "2021-213204 - Wine Blender",
    "2021-213205 - Milling Nutritionist",
    "2021-213205 - Protein Development Technologist",
    "2021-213205 - Food Safety Standards Manager",
    "2021-213205 - Food Sanitation and Safety Inspector",
    "2021-213205 - Food Quality Consultant",
    "2021-213205 - Food Technology Advisor",
    "2021-213205 - Food Technologist",
    "2021-213205 - Food Safety Standards Auditor",
    "2021-213205 - Food and Drink Technologist",
    "2021-213205 - Food Chemist / Engineer",
    "2021-213301 - Marine Ecologist",
    "2021-213301 - Animal Ecologist",
    "2021-213301 - Species Protection Officer",
    "2021-213301 - Fish and Games Officer",
    "2021-213301 - Fisheries Advisor",
    "2021-213301 - Forestry Conservationist",
    "2021-213301 - Conservation Officer",
    "2021-213301 - Ecological Researcher",
    "2021-213301 - Ecologist",
    "2021-213301 - Soil Conservationist",
    "2021-213301 - Conservancy Advisory Scientist",
    "2021-213301 - Water Conservation Scientist",
    "2021-213302 - Environmental Waste Officer",
    "2021-213302 - Environmental Auditor",
    "2021-213302 - Environmental Consultant",
    "2021-213302 - Environmental Research Scientist",
    "2021-213302 - Environmental Advisor",
    "2021-213302 - Climate Change Analyst",
    "2021-213302 - Environmentalist",
    "2021-213302 - Environmental Officer",
    "2021-213302 - Water Use Specialist",
    "2021-213304 - Soil Physicist",
    "2021-213304 - Soil Technologist",
    "2021-213304 - Pedologist",
    "2021-213304 - Scientific Soil Tester",
    "2021-213304 - Landcare Facilitator",
    "2021-213304 - Soil Advisor",
    "2021-213304 - Soil Fertility Expert",
    "2021-213304 - Land Degradation Analyst",
    "2021-213305 - Air Quality Technician",
    "2021-213306 - Hydrological Technical Officer",
    "2021-213306 - Waste Water Treatment Officer / Technician",
    "2021-213306 - Water Quality Technician",
    "2021-213306 - Hydrographical Technical Officer",
    "2021-213307 - Wildlife Conservationist",
    "2021-213307 - Wildlife Officer / Warden",
    "2021-213307 - Beach and Estuary Warden",
    "2021-213307 - Game / Park Warden",
    "2021-213307 - Fish Warden",
    "2021-214101 - Process Engineer",
    "2021-214101 - Safety Engineer",
    "2021-214101 - Process Design Engineer",
    "2021-214101 - Plant Engineer",
    "2021-214101 - Enterprise Resource Management Engineer",
    "2021-214101 - Health and Safety Engineer",
    "2021-214101 - Industrial Efficiency Engineer",
    "2021-214101 - Manufacturing Technology Engineer",
    "2021-214101 - Manufacturing Logistics Engineer",
    "2021-214101 - Operations Research Engineer",
    "2021-214101 - Supply Chain Management Engineer",
    "2021-214101 - Produce Process Engineer",
    "2021-214101 - Quality Management Engineer",
    "2021-214101 - Automation and Control Engineer",
    "2021-214101 - Value Engineering",
    "2021-214102 - Value Engineering Technologist",
    "2021-214102 - Process Technologist",
    "2021-214102 - Produce Process Technologist",
    "2021-214102 - Manufacturing Technologist",
    "2021-214102 - Operations Research Technologist",
    "2021-214102 - Supply Chain Technologist",
    "2021-214102 - Process Design Technologist",
    "2021-214102 - Quality Management Technologist",
    "2021-214102 - Safety Engineering Technologist",
    "2021-214102 - Fabrication Technologist",
    "2021-214102 - Manufacturing Logistics Technologist",
    "2021-214103 - Fabrication Engineer",
    "2021-214103 - Robotics and Production Automation Engineer",
    "2021-214104 - Textile Production Technologist",
    "2021-214104 - Industrial Machinery Technologist",
    "2021-214104 - Leather Production Technologist",
    "2021-214104 - Footwear Production Technologist",
    "2021-214104 - Clothing Production Technologist",
    "2021-214104 - Rubber Production Technologist",
    "2021-214104 - Automation and Control Technologist",
    "2021-214104 - Robotics and Production Automation Technologist",
    "2021-214201 - GIS and Landuse Management Engineer",
    "2021-214201 - Transportation and Urban Planning Engineer",
    "2021-214201 - Forest Engineer",
    "2021-214201 - Construction Engineer",
    "2021-214201 - Water and Wastewater  Engineer",
    "2021-214201 - Structural Engineer",
    "2021-214201 - Geotechnical Engineer",
    "2021-214201 - Biosystems Engineer",
    "2021-214201 - Hydraulics Engineer",
    "2021-214201 - Transport Engineer",
    "2021-214201 - Site Design Engineer",
    "2021-214202 - Geotechnics Technologist",
    "2021-214202 - Construction Technologist",
    "2021-214202 - Transportation and Urban Planner Technologist",
    "2021-214202 - Hydraulics Technologist",
    "2021-214202 - GIS and Landuse Technologist",
    "2021-214202 - Concrete Technologist",
    "2021-214202 - Water and Wastewater Technologist",
    "2021-214202 - Structural Technologist",
    "2021-214202 - Biosystems Technologist",
    "2021-214202 - Environmental Technologist",
    "2021-214301 - Water Resource Specialists",
    "2021-214301 - Air Pollution Control Engineer",
    "2021-214302 - Environmental Restoration Planner",
    "2021-214302 - Industrial Ecologist",
    "2021-214302 - Environmental Remediation Specialist",
    "2021-214401 - Machine Design and Development Engineer",
    "2021-214401 - Transportation Systems Engineer",
    "2021-214401 - Industrial Machinery Engineer",
    "2021-214401 - Automotive Engineer",
    "2021-214401 - Fuel Cell Engineer",
    "2021-214401 - Forensic Engineer",
    "2021-214401 - Pressurised Vessels Engineer",
    "2021-214401 - Piping Engineer",
    "2021-214401 - Fluid Mechanics Engineer",
    "2021-214401 - Structural Steel Engineer",
    "2021-214401 - Mechatronics Engineer",
    "2021-214401 - Heating and Ventilation Engineer",
    "2021-214401 - Maintenance Management Engineer",
    "2021-214401 - Rotational Plant Engineer",
    "2021-214401 - Air-conditioning, Heating and Ventilation (including fire) Engineer",
    "2021-214401 - Mechanical Engineer (Mines)",
    "2021-214401 - Thermodynamics Engineer",
    "2021-214401 - Diesel Engineer",
    "2021-214402 - Clinical Technologist",
    "2021-214402 - Rotational Plant Technologist",
    "2021-214402 - Thermodynamics Technologist",
    "2021-214402 - Pressurised Vessels Technologist",
    "2021-214402 - Fluid Mechanics Technologist",
    "2021-214402 - Air-conditioning, Heating and Ventilation (including fire) Technologist",
    "2021-214402 - Mechatronics Engineering Technologist",
    "2021-214402 - Transportation Systems Technologist",
    "2021-214402 - Maintenance Technologist",
    "2021-214402 - Forensic Technologist",
    "2021-214402 - Structural Steel Technologist",
    "2021-214402 - Piping Technologist",
    "2021-214402 - Automotive Technologist",
    "2021-214403 - Aircraft Electrical Engineer",
    "2021-214403 - Avionics Engineer",
    "2021-214403 - Aircraft Safety Engineer",
    "2021-214403 - Flight Test Engineer",
    "2021-214403 - Aircraft Mechanical Engineer",
    "2021-214403 - Flight Test Pilot",
    "2021-214403 - Aircraft Production Engineer",
    "2021-214403 - Aerospace Engineer",
    "2021-214403 - Aircraft Control Systems Engineer",
    "2021-214403 - Aircraft Designer",
    "2021-214403 - Avionics Systems Engineer",
    "2021-214403 - Aerodynamics Engineer",
    "2021-214403 - Propulsion Engineer",
    "2021-214403 - Aircraft Electronics Engineer",
    "2021-214404 - Aircraft Control Systems Engineering Technologist",
    "2021-214404 - Aerospace Engineering Technologist",
    "2021-214404 - Aircraft Mechanical Engineering Technologist",
    "2021-214404 - Aerodynamics Engineering Technologist",
    "2021-214404 - Avionics Systems Engineering Technologist",
    "2021-214404 - Aircraft Electrical Engineering Technologist",
    "2021-214404 - Propulsion Engineering Technologist",
    "2021-214404 - Avionics Engineering Technologist",
    "2021-214404 - Aeronautical Technologist",
    "2021-214404 - Aircraft Safety Engineering Technologist",
    "2021-214404 - Aircraft Electronics Engineering Technologist",
    "2021-214404 - Flight Test Engineering Technologist",
    "2021-214404 - Aircraft Production Engineering Technologist",
    "2021-214405 - Marine Designer",
    "2021-214405 - Marine Architect",
    "2021-214405 - Naval Architect",
    "2021-214407 - Instrument Engineer (Defence Industry)",
    "2021-214408 - Instrument Engineering Technologist (Defence Industry)",
    "2021-214501 - Biochemical Engineer",
    "2021-214501 - Fluid Dynamics Engineer",
    "2021-214501 - Glass and Ceramics Technologist",
    "2021-214501 - Cosmetics Engineer",
    "2021-214501 - Crude Oil, Coal and Petrochemicals Engineer",
    "2021-214501 - Bioengineer",
    "2021-214501 - Food Processing Engineer",
    "2021-214501 - Pharmaceutical Engineer",
    "2021-214501 - Hydrometallurgical Engineer",
    "2021-214501 - Catalytic Processing Engineer",
    "2021-214501 - Heat Transfer Engineer",
    "2021-214502 - Hydrometallurgical Technologist",
    "2021-214502 - Petrochemicals Technologist",
    "2021-214502 - Crude Oil Technologist",
    "2021-214502 - Cosmetics Technologist",
    "2021-214502 - Coal Technologist",
    "2021-214502 - Fuel Technologist",
    "2021-214502 - Plastics Technologist",
    "2021-214502 - Catalytic Processing Technologist",
    "2021-214502 - Biochemical Technologist",
    "2021-214502 - Pharmaceutical Technologist",
    "2021-214502 - Bioengineering Technologist",
    "2021-214502 - Food Processing Technologist",
    "2021-214502 - Refinery Process Engineer",
    "2021-214502 - Environmental (Water, Air, Soil) Technologist",
    "2021-214502 - Process Plants Design and Erection Technologist",
    "2021-214502 - Fluid Dynamics Technologist",
    "2021-214503 - Gas Examiner",
    "2021-214601 - Colliery Engineer",
    "2021-214601 - Underground Coal Mining Engineer",
    "2021-214601 - Rock Engineer",
    "2021-214601 - Open Cast Mining (including diamonds) Engineer",
    "2021-214601 - Metalliferous Mining Engineer",
    "2021-214601 - Surface Mining (including quarrying) Engineer",
    "2021-214602 - Open Cast Mining (including diamonds) Technologist",
    "2021-214602 - Colliery Technologist",
    "2021-214602 - Rock Technologist",
    "2021-214602 - Mine Ventilation Technologist",
    "2021-214602 - Underground Coal Mining Technologist",
    "2021-214602 - Marine Mining Technologist",
    "2021-214602 - Metalliferous Technologist",
    "2021-214602 - Surface Mining (including quarrying) Technologist",
    "2021-214603 - Corrosion Engineer",
    "2021-214603 - Flotation Metallurgical Engineer",
    "2021-214603 - Ore Processing Engineer",
    "2021-214603 - Extraction Engineer",
    "2021-214603 - Electrometallurgical Engineer",
    "2021-214603 - Physical Metallurgical Engineer",
    "2021-214603 - Welding Engineer",
    "2021-214603 - Extraction Metallurgical Engineer",
    "2021-214603 - Pyro Metallurgical Engineer",
    "2021-214603 - Alloy Design Engineer",
    "2021-214603 - Mining Process Engineer",
    "2021-214604 - Corrosion Technologist",
    "2021-214604 - Electrometallurgical Technologist",
    "2021-214604 - Extraction Technologist",
    "2021-214604 - Flotation Metallurgical Technologist",
    "2021-214604 - Physical Metallurgical Technologist",
    "2021-214604 - Extraction Metallurgical Technologist",
    "2021-214604 - Welding Technologist",
    "2021-214604 - Pyro Metallurgical Technologist",
    "2021-214604 - Alloy Design Technologist",
    "2021-214604 - Ore Processing Technologist",
    "2021-214605 - Assayer",
    "2021-214605 - Metals Scientist",
    "2021-214605 - Extractive Metallurgist",
    "2021-214605 - Metallographer",
    "2021-214605 - Industrial Metallurgist",
    "2021-214605 - Radiological Metallurgist",
    "2021-214605 - Physical Metallurgist",
    "2021-214607 - Oil Refinery Engineer",
    "2021-214607 - Petrophysical Engineer",
    "2021-214607 - Petroleum Engineering Technologist",
    "2021-214607 - Biofuels Engineer",
    "2021-214607 - Mud Engineer",
    "2021-214607 - Gas Engineer",
    "2021-214607 - Natural Gas Engineer",
    "2021-214607 - Biofuels/Biodiesel Technology and Product Development Managers",
    "2021-214901 - Clinical Engineer",
    "2021-214904 - Plumbing Estimator",
    "2021-214904 - Construction Economist",
    "2021-214904 - Building Economist",
    "2021-214904 - Electrical Specifications Writer",
    "2021-214905 - Natural Resources Engineer",
    "2021-214905 - Irrigation Engineer",
    "2021-214905 - Crop Production Mechanisation Engineer",
    "2021-214905 - Aqua Culture Engineer",
    "2021-214905 - Agricultural Product Processing Engineer",
    "2021-214905 - Agricultural Structures and Facilities Engineer",
    "2021-214906 - Natural Resources Engineering Technologist",
    "2021-214906 - Aqua Culture Engineering Technologist",
    "2021-214906 - Crop Production Mechanisation Engineering Technologist",
    "2021-214906 - Agricultural Structures and Facilities Engineering Technologist",
    "2021-214906 - Irrigation Engineering Technologist",
    "2021-214906 - Agricultural Product Processing Engineering Technologist",
    "2021-214907 - Materials and Non Destructive Testing Engineer",
    "2021-214907 - Material Sciences Engineer",
    "2021-214908 - Materials Technologist",
    "2021-214908 - Materials Sciences Technologist",
    "2021-214908 - Optical Engineer",
    "2021-214908 - Materials and Non Destructive Testing Technologist",
    "2021-215101 - Electric Power Generation Engineer",
    "2021-215101 - Electromechanical Engineer",
    "2021-215101 - Control Engineer",
    "2021-215101 - Illumination Engineer",
    "2021-215101 - Electrical Engineer (Mines)",
    "2021-215101 - Power Systems Engineer",
    "2021-215101 - Railway Signal Engineer",
    "2021-215101 - Electrical Design Engineer",
    "2021-215101 - Power Distribution Engineer",
    "2021-215101 - Power Transmission Engineer",
    "2021-215102 - Power Engineering Technologist",
    "2021-215102 - Electromechanical Engineering Technologist",
    "2021-215102 - Illumination Engineering Technologist",
    "2021-215102 - Control Engineering Technologist",
    "2021-215103 - Wind Energy Engineer",
    "2021-215103 - Hydro Energy Engineer",
    "2021-215103 - Bio-energy Engineer",
    "2021-215103 - Renewable Energy Engineer",
    "2021-215103 - Nuclear Energy Engineer",
    "2021-215103 - Solar Energy Engineer",
    "2021-215103 - Energy Services Engineer",
    "2021-215104 - Renewable Energy Technologist",
    "2021-215104 - Solar Energy Technologist",
    "2021-215104 - Nuclear Energy Technologist",
    "2021-215104 - Wind Energy Technologist",
    "2021-215104 - Bio-energy Technologist",
    "2021-215104 - Energy Sustainability Specialist",
    "2021-215104 - Hydro Energy Technologist",
    "2021-215104 - Energy Measurement and Verification Professional",
    "2021-215201 - Electronics Designer",
    "2021-215201 - Communications Engineer (Army)",
    "2021-215201 - Instrumentation Engineer",
    "2021-215201 - Television Engineer",
    "2021-215201 - Computer Engineer",
    "2021-215201 - Information Engineer",
    "2021-215202 - Computer Engineering Technologist",
    "2021-215202 - Clinical Engineering Technologist",
    "2021-215202 - Instrumentation Technologist",
    "2021-215202 - Information Technologist",
    "2021-215301 - DSP Designer",
    "2021-215301 - Radio Engineer",
    "2021-215301 - Signalling and Communications Engineer",
    "2021-215301 - Engineer Communications",
    "2021-215301 - Signal Systems Engineer",
    "2021-215301 - Fibre Optics Engineer",
    "2021-215301 - Satellite Transmission Engineer",
    "2021-215301 - Broadcast Engineer",
    "2021-215301 - Radio and Telecommunications Engineer",
    "2021-215301 - RF Designers (Tx and Rx)",
    "2021-215301 - Radar Engineer",
    "2021-215301 - Signal Processing Engineer",
    "2021-215303 - Communications Consultant",
    "2021-215303 - Telecommunications Specialist",
    "2021-215303 - Communications Specialist (ICT)",
    "2021-215303 - Telecommunications Consultant",
    "2021-215303 - Telecommunications Network Planner",
    "2021-216101 - Building Architect",
    "2021-216101 - Conservation Architect",
    "2021-216101 - Heritage Architect",
    "2021-216201 - Garden Designer",
    "2021-216201 - Landscape Designer",
    "2021-216201 - Landscape Planner",
    "2021-216301 - Clothing Designer",
    "2021-216301 - Costume Designer",
    "2021-216301 - Dress Designer",
    "2021-216301 - Knitwear Designer",
    "2021-216301 - Leisurewear Designer",
    "2021-216301 - Bag and Accessories Designer",
    "2021-216302 - Commercial Product Designer",
    "2021-216302 - Industrial Graphic Designer",
    "2021-216302 - Textile Designer",
    "2021-216302 - Textile Research and Development Practitioner",
    "2021-216302 - Furniture Designer",
    "2021-216302 - Wallpaper Designer",
    "2021-216302 - Weaving Pattern Card Preparer",
    "2021-216302 - Product Designer (excluding Engineering Products)",
    "2021-216302 - Carpet Designer / Copyist Planner",
    "2021-216302 - Ceramic Designer",
    "2021-216302 - Glass Designer",
    "2021-216303 - Custom Made Jewellery Designer",
    "2021-216303 - Jewellery Industrial Designer",
    "2021-216304 - Footwear Bottomstock Designer",
    "2021-216304 - Footwear Bottomstock Engineer",
    "2021-216401 - Town Planner",
    "2021-216401 - Geothermal Resource Officer",
    "2021-216401 - Land Development Planner",
    "2021-216401 - Natural Resource Management Consultant / Officer / Planner",
    "2021-216401 - Environmental Policy Planner",
    "2021-216401 - Land Planner",
    "2021-216401 - Land Use Planner",
    "2021-216401 - Community / Country / District Planner",
    "2021-216401 - Traffic and Transport Planner",
    "2021-216401 - Environmental Consent Planner",
    "2021-216402 - Transportation Planners",
    "2021-216402 - Logistics Analyst",
    "2021-216501 - Map Maker",
    "2021-216502 - Geodetic Surveyor",
    "2021-216502 - Topographer and Hydrographer",
    "2021-216502 - Geometrician",
    "2021-216502 - Land Surveyor",
    "2021-216502 - Aerial Photographic Interpreter",
    "2021-216502 - Cadastral Records Advisor",
    "2021-216502 - Aerial Surveyor",
    "2021-216502 - Surveying Technologist",
    "2021-216502 - Photogrammetrist",
    "2021-216502 - Photogrammetric Surveyor",
    "2021-216502 - Surveying and Mapping Specialist",
    "2021-216502 - Cadastral Survey Advisor",
    "2021-216502 - Hydrographic Surveyor",
    "2021-216502 - Cadastral Surveyor",
    "2021-216502 - Hydro Charting Advisor",
    "2021-216502 - Mine Surveyor",
    "2021-216502 - Hydrographic Survey Advisor",
    "2021-216502 - Geometric Engineer",
    "2021-216601 - Art Director",
    "2021-216601 - Display Artist",
    "2021-216601 - Exhibition / Display Designer",
    "2021-216601 - Calligrapher",
    "2021-216601 - Lettering Designer",
    "2021-216601 - Advertising Artist / Designer",
    "2021-216601 - Film and Video Graphics Designer",
    "2021-216601 - Graphic Artist",
    "2021-216601 - Commercial Artist",
    "2021-216601 - Lithographic Artist",
    "2021-216601 - Publication Designer",
    "2021-216602 - Animator",
    "2021-216602 - Technical Illustrator",
    "2021-216602 - Book Designer",
    "2021-216602 - Book Illstrator",
    "2021-216603 - Digital Media Designer",
    "2021-216603 - Instructional Designer",
    "2021-216603 - Interactive Media Designer",
    "2021-216603 - Multimedia Artist",
    "2021-221101 - Primary Health Care Physician",
    "2021-221101 - General Practitioner (GP)",
    "2021-221101 - Physician",
    "2021-221101 - Sports Medicine Practitioner",
    "2021-221101 - Doctor",
    "2021-221101 - Medical Practitioner",
    "2021-221101 - Sports Physician",
    "2021-221102 - Medical Intern",
    "2021-221201 - Medical Sedation Specialist",
    "2021-221201 - Obstetric Anaesthetist",
    "2021-221201 - Pain Management Specialist",
    "2021-221201 - Intensive Care Anaesthetist",
    "2021-221202 - Cardiac Imaging Fellow",
    "2021-221202 - Cardiothoracic Surgeon",
    "2021-221203 - Emergency Physician",
    "2021-221204 - Urogynaecologist",
    "2021-221204 - Child Birth Specialist",
    "2021-221204 - Gynaecological Oncologist",
    "2021-221204 - Infant Care Medical Specialist",
    "2021-221205 - Ophthalmic Optician",
    "2021-221205 - Eye Surgeon",
    "2021-221205 - Medical Eye Specialist",
    "2021-221206 - Paediatric Thoracic Physician",
    "2021-221206 - Neonatologist",
    "2021-221207 - Immunologist",
    "2021-221207 - Forensic Scientist",
    "2021-221207 - Clinical Cytopathologist",
    "2021-221207 - Forensic Biologist",
    "2021-221207 - Forensic Toxicologist",
    "2021-221208 - Child Psychiatrist",
    "2021-221208 - Geriatric Psychiatrist",
    "2021-221208 - Medical Psychotherapist",
    "2021-221208 - Adolescent Psychiatrist",
    "2021-221208 - Psychogeriatrician",
    "2021-221208 - Forensic Psychiatrist",
    "2021-221209 - Medical Imagining Specialist",
    "2021-221209 - Sonologist",
    "2021-221210 - Reproductive Endocrinologist",
    "2021-221210 - Palliative Medicine Specialist",
    "2021-221210 - Renal Medicine Specialist",
    "2021-221210 - Geriatrician",
    "2021-221210 - Intensive Care Medicine Specialist",
    "2021-221210 - Clinical Immunologist",
    "2021-221210 - Medical Specialist",
    "2021-221210 - Diabetologist",
    "2021-221210 - Thoracic Medicine Physician",
    "2021-221210 - Intensivist",
    "2021-221210 - Intensive Care Specialist",
    "2021-221210 - Pulmonary Specialist",
    "2021-221210 - Respiratory Medicine Physician",
    "2021-221210 - Clinical Geneticist",
    "2021-221210 - Clinical Oncologist",
    "2021-221210 - Haematologist",
    "2021-221210 - Musculoskeletal Physician",
    "2021-221210 - Thoracic Medicine Specialist",
    "2021-221210 - Infectious Diseases Specialist",
    "2021-221210 - Endocrinologist",
    "2021-221210 - Gastroenterologist",
    "2021-221210 - Nephrologist",
    "2021-221210 - Respiratory Physician",
    "2021-221210 - Cancer Specialist",
    "2021-221210 - Venereologist",
    "2021-221211 - Vascular Surgeon",
    "2021-221211 - Specialist In Rehabilitation Medicine",
    "2021-221211 - Ear, Nose and Throat Specialist",
    "2021-221211 - Preventive Medicine Specialist",
    "2021-221211 - Otologist",
    "2021-221211 - Sexual Health Physician",
    "2021-221211 - Head and Neck Surgeon",
    "2021-221211 - Industrial Medicine Specialist",
    "2021-221211 - Occupational Medicine Specialist",
    "2021-221211 - Rhinologist",
    "2021-221211 - Otolaryngologist",
    "2021-221211 - Cardiothoracic Surgeon",
    "2021-221211 - Laryngologist",
    "2021-222101 - Clinical Nurse Specialist",
    "2021-222101 - Orthopaedic Nurse Specialist",
    "2021-222101 - Ophthalmic Nurse Specialist",
    "2021-222101 - Nephrology Nurse Specialist",
    "2021-222101 - Midwife Specialist",
    "2021-222101 - Occupational Health Nurse Specialist",
    "2021-222101 - Adult Critical Nurse Specialist",
    "2021-222101 - Paediatric Nurse Specialist",
    "2021-222101 - Infection Control Nurse",
    "2021-222101 - Forensic Nurse Specialist",
    "2021-222102 - Gerontology Nurse",
    "2021-222102 - Registered Geriatric Nurse",
    "2021-222103 - Maternity Nurse",
    "2021-222103 - Neonatal Nurse",
    "2021-222103 - Plunket Nurse",
    "2021-222104 - Health Coordinator",
    "2021-222104 - Remote / Rural Area Nurse",
    "2021-222104 - Primary Health Care Nurse",
    "2021-222104 - School Nurse",
    "2021-222104 - Occupational Health Nurse",
    "2021-222104 - Clinic Nurse",
    "2021-222104 - Public Health Nurse",
    "2021-222104 - District Nurse",
    "2021-222104 - Nurse Clinician",
    "2021-222104 - Health Education and Promotion Nurse",
    "2021-222104 - Industrial / Factory Nurse",
    "2021-222105 - Intensive Care Nurse",
    "2021-222105 - Emergency / Trauma Nurse",
    "2021-222105 - Acute Care Nurse",
    "2021-222105 - Coronary Care Unit Nurse",
    "2021-222106 - Registered Mental Retardation Nurse",
    "2021-222107 - Orthopaedic Nurse",
    "2021-222107 - Rehabilitation Nurse",
    "2021-222107 - Palliative Care Nurse",
    "2021-222108 - General Nurse",
    "2021-222108 - Prison Nurse",
    "2021-222108 - Hospital Nurse",
    "2021-222108 - Nursing Prison Officer",
    "2021-222109 - Practice Nurse",
    "2021-222110 - Child and Adolescence Mental Health Nurse",
    "2021-222110 - Psychiatric Nurse",
    "2021-222110 - Drug and Alcohol Nurse",
    "2021-222110 - Psychopaedic Nurse",
    "2021-222110 - Psychiatric Rehabilitation Nurse",
    "2021-222110 - Psychogeriatric Care Nurse",
    "2021-222111 - Operating Room Nurse",
    "2021-222111 - Operating Theatre Nurse",
    "2021-222111 - Recovery Nurse",
    "2021-222111 - Anaesthetic Nurse",
    "2021-222112 - Oncology Nurse",
    "2021-222112 - Obstetric Nurse",
    "2021-222114 - Clinical Nurse Preceptor",
    "2021-222114 - Staff Development Nurse",
    "2021-222114 - Clinical Nurse Educator",
    "2021-222114 - Nurse Researcher",
    "2021-222116 - Nurse Unit Manager",
    "2021-222116 - Charge Nurse",
    "2021-222116 - Charge Sister",
    "2021-223104 - Natural Health Practitioner",
    "2021-223104 - Natural Health Therapist",
    "2021-223104 - Natural Therapist",
    "2021-223105 - Traditional Surgeon",
    "2021-223105 - Inyanga Herbalist",
    "2021-223105 - Traditional Birth Attendant",
    "2021-223105 - Sangoma Diviner",
    "2021-223106 - Unani Practitioner",
    "2021-223106 - Sangoma",
    "2021-223106 - Chinese Medicine Practitioner",
    "2021-223106 - Herbalist (Chinese)",
    "2021-223106 - Traditional African Health Practitioner",
    "2021-223106 - Oriental Medicine Practitioner",
    "2021-223106 - Chinese Herbal Medicine Practitioner",
    "2021-224101 - Advanced Care Paramedic",
    "2021-224101 - Feldscher",
    "2021-224101 - Primary Care Paramedic",
    "2021-224101 - Clinical Officer (Paramedical)",
    "2021-225101 - Veterinary Parasitologist",
    "2021-225101 - Veterinary Pathologist",
    "2021-225101 - Veterinary Surgeon",
    "2021-225101 - Animal Doctor",
    "2021-225101 - Veterinary Epidemiologist",
    "2021-225103 - Agricultural Veterinary Inspector",
    "2021-225103 - Animal Health Officer",
    "2021-225103 - SPCA Inspector",
    "2021-226101 - Dental / Oral Surgeon",
    "2021-226101 - Community Dentistry Specialist",
    "2021-226101 - Periodontist",
    "2021-226101 - Prosthodontist",
    "2021-226101 - Orthodontist",
    "2021-226101 - Endodontist",
    "2021-226101 - Oral Pathologist",
    "2021-226101 - Oral and Maxillofacial Surgeon",
    "2021-226101 - Stomatologist",
    "2021-226101 - Paedodontist",
    "2021-226102 - Community Dentistry Specialist",
    "2021-226102 - Dental Practitioner",
    "2021-226102 - Restorative Dentist",
    "2021-226102 - Oral and Maxiloficial Surgeon Periodontist",
    "2021-226102 - Oral Medicine Specialist",
    "2021-226201 - Hospital Chemist",
    "2021-226201 - Clinical Pharmacist",
    "2021-226201 - Health Service Pharmacist",
    "2021-226203 - Community Pharmacist",
    "2021-226203 - Pharmacist Assistant",
    "2021-226203 - Dispensing Chemist",
    "2021-226301 - Health Surveyor",
    "2021-226301 - Food Safety Officer",
    "2021-226301 - Food Safety Auditor",
    "2021-226301 - Waste Management Practitioner",
    "2021-226301 - Licensed Premises Inspector",
    "2021-226302 - Occupational Safety Practitioner / Officer",
    "2021-226302 - Health and Safety Officer / Coordinator / Professional",
    "2021-226302 - Chief Safety Officer Mining",
    "2021-226302 - Injury Prevention Consultant",
    "2021-226302 - Radiation Protection Expert",
    "2021-226302 - Occupational Hygienist",
    "2021-226302 - Risk and Safety Manager",
    "2021-226302 - Occupational Health and Safety Advisor",
    "2021-226302 - Road Safety Coordinator",
    "2021-226302 - Occupational Safety Advisor",
    "2021-226302 - Occupational Safety and Health (OSH) Advisor / Coordinator / Officer / Professional",
    "2021-226302 - Safety Coordinator",
    "2021-226401 - Cardiothoracic Physiotherapist",
    "2021-226401 - Occupational Health Physiotherapist",
    "2021-226401 - Neurological Physiotherapist",
    "2021-226401 - Paediatric Physiotherapist",
    "2021-226401 - Geriatric Physical Therapist",
    "2021-226401 - Physio",
    "2021-226401 - Continence and Women's Health Physiotherapist",
    "2021-226401 - Manipulation Therapist",
    "2021-226401 - Musculoskeletal Physiotherapist",
    "2021-226401 - Aquatic Physiotherapist",
    "2021-226401 - Gerentological Physiotherapist",
    "2021-226401 - Orthopaedic Physical Therapist",
    "2021-226401 - Sports Physiotherapist",
    "2021-226401 - Physical Therapist",
    "2021-226501 - Sports Nutritionist",
    "2021-226501 - Home Economist",
    "2021-226501 - Health Advisor (Nutrition)",
    "2021-226501 - Advisor Food Science / Technology",
    "2021-226501 - Dietetic Consultant",
    "2021-226601 - Hearing Therapist",
    "2021-226601 - Orthoepist",
    "2021-226602 - Speech Clinician",
    "2021-226602 - Speech Therapist",
    "2021-226602 - Language Therapist",
    "2021-226701 - Oculist",
    "2021-226901 - Music Therapist",
    "2021-226901 - Dance Therapist",
    "2021-226901 - Drama Therapist",
    "2021-226902 - Corrections Programme Coordinator / Facilitator",
    "2021-226902 - Work Rehabilitation Therapist",
    "2021-226902 - Ergotherapist",
    "2021-226903 - Chiropodist",
    "2021-226903 - Podiatric Surgeon",
    "2021-226904 - Recreational Therapist",
    "2021-226904 - Activities Coordinator",
    "2021-226904 - Activities Officer",
    "2021-226905 - Exercise Physiologist",
    "2021-226905 - Human Movement Specialist",
    "2021-226905 - Exercise Therapist",
    "2021-231101 - Lecturer",
    "2021-231101 - College of Education Lecturer",
    "2021-231101 - Distant Education Teacher/Correspondence School Teacher",
    "2021-231101 - Tertiary Educator",
    "2021-231101 - Associate Professor",
    "2021-231101 - Assessment Advisor And/Or Internal Moderator",
    "2021-231101 - Professor",
    "2021-231102 - University Demonstrator",
    "2021-232114 - Technical Drawing Teacher",
    "2021-232121 - Social Sciences Teacher",
    "2021-232130 - Community Education and Training Lecturer - Inactive",
    "2021-232131 - Adult Literacy Teacher",
    "2021-232132 - Adult Education Teacher",
    "2021-233102 - Teacher of The Deaf and Dumb",
    "2021-234101 - Foundation Phase School Teacher African Language (Grades R-3)",
    "2021-234101 - Primary Teacher-librarian",
    "2021-234101 - Foundation School Teacher Other Language (Grades R-3)",
    "2021-234201 - Pre-primary Education Associate Professional",
    "2021-234201 - Early Childcare Teacher",
    "2021-234201 - Early Intervention Teacher",
    "2021-234201 - Pre-school Director",
    "2021-234201 - Kindergarten Teacher",
    "2021-234201 - Playschool Teacher",
    "2021-234201 - Early Childhood Development Practitioner Other Language",
    "2021-234201 - Pre-school Coordinator",
    "2021-234201 - Nursery Teacher",
    "2021-234201 - Early Childhood Development Practitioner African Language",
    "2021-234202 - Kindergarten Assistant",
    "2021-235101 - Curriculum Coordinator",
    "2021-235101 - Education Officer",
    "2021-235101 - Academic Advisor",
    "2021-235101 - Education / Training Verifier",
    "2021-235101 - Pre-school Advisor",
    "2021-235101 - Teaching Aids Specialist",
    "2021-235101 - Curriculum Advisory Officer / Facilitator",
    "2021-235101 - Education / Training Methods Specialist",
    "2021-235101 - Academic Development Coordinator",
    "2021-235101 - Curriculum Specialist",
    "2021-235101 - Education / Training Consultant",
    "2021-235101 - Education and Training Quality Assurance (ETQA) Officer / Manager",
    "2021-235101 - Home-school Liaison Officer",
    "2021-235101 - Curriculum Development Audit Officer",
    "2021-235101 - Education / Training Specialist",
    "2021-235102 - Education Specialist",
    "2021-235102 - Moderators",
    "2021-235102 - Education Review Officer",
    "2021-235201 - Teacher of Gifted Students",
    "2021-235201 - Deaf and Hard of Hearing Special Needs Teacher",
    "2021-235201 - Augmentative and Alternative Communication Special Education Teacher",
    "2021-235201 - Education Therapist",
    "2021-235201 - Remedial Teacher",
    "2021-235201 - Visual Impairment Special Needs Teacher",
    "2021-235201 - Skills and Vocational Special Needs Teacher",
    "2021-235201 - Neurological and Neuro-Development Special Education Teacher",
    "2021-235201 - Rehabilitation Teacher",
    "2021-235201 - Behaviour Support Teacher",
    "2021-235201 - Chronic and Mental Health Special Needs Teacher",
    "2021-235201 - Behaviour Management Special Needs Teacher",
    "2021-235201 - Special Education Teacher (Mentally / Physically Handicapped)",
    "2021-235201 - Autism Special Education teacher",
    "2021-235201 - Cognitive and Impairment Special Needs Teacher",
    "2021-235201 - Physical Impairment Special Education Teacher",
    "2021-235201 - Learning Support Teacher",
    "2021-235205 - Abet Trainer",
    "2021-235205 - Teacher of Gifted Children",
    "2021-235206 - Education Worker Coordinator",
    "2021-235206 - Education Worker",
    "2021-235206 - Home School Liaison Officer",
    "2021-235401 - Flute / Guitar / Piano / Violin Teacher",
    "2021-235401 - Singing Teacher (Private Tuition)",
    "2021-235502 - Ballet Teacher",
    "2021-235503 - Elocution Teacher",
    "2021-235601 - Software Trainer",
    "2021-235601 - Computer Trainer",
    "2021-235601 - ICT Educator",
    "2021-241101 - Debtors Manager",
    "2021-241101 - Bank Accountant",
    "2021-241102 - Project Accountant",
    "2021-241102 - Budget Accountant",
    "2021-241102 - Cost Accountant",
    "2021-241103 - Tax Analyst",
    "2021-241103 - Tax Consultant",
    "2021-241103 - Tax Auditor",
    "2021-241104 - Audit and Assurance Manager",
    "2021-241104 - Forensic Auditor / Investigator",
    "2021-241104 - Project Auditor",
    "2021-241104 - Public Accountant",
    "2021-241104 - Auditor",
    "2021-241107 - Corporate Accountant",
    "2021-241107 - Business Group Accountant",
    "2021-241107 - Company Accountant",
    "2021-241108 - Investigating Accountant",
    "2021-241201 - Listed Securities Analyst",
    "2021-241201 - Investment Research Analyst",
    "2021-241202 - Hedge Fund Manager",
    "2021-241202 - Fund Manager",
    "2021-241202 - Discretionary Manager",
    "2021-241202 - Portfolio Manager",
    "2021-241203 - Fund Advisor",
    "2021-241203 - Securities Sales Trader",
    "2021-241203 - Portfolio Advisor",
    "2021-241203 - Financial Markets Investment Advisor",
    "2021-241301 - Account Management Advisor",
    "2021-241301 - Financial Consultant",
    "2021-241301 - Financial Advisor",
    "2021-241301 - Financial Business Analyst",
    "2021-241301 - Financial Agent",
    "2021-241301 - Financial Analyst",
    "2021-241301 - Financial Planner",
    "2021-241301 - Financial Planning Advisor / Manager",
    "2021-241301 - Insurance Manager",
    "2021-242101 - Business Turnaround Management Consultant",
    "2021-242101 - Business Consultant",
    "2021-242101 - Financial Systems Advisor",
    "2021-242101 - Small Business Consultant / Mentor",
    "2021-242101 - Strategic Developer / Facilitator",
    "2021-242101 - Corporate Planner",
    "2021-242101 - Commercial Analyst",
    "2021-242101 - Farm Management Consultant",
    "2021-242101 - Service Solutions Project Manager",
    "2021-242101 - Business Analyst",
    "2021-242101 - Technology Development Coordinator",
    "2021-242101 - Management Consulting Specialist",
    "2021-242101 - Ecommerce Programme Manager",
    "2021-242101 - Business Coach",
    "2021-242101 - Business Support Project Manager",
    "2021-242101 - Management Reporting Analyst",
    "2021-242101 - Operations Analyst",
    "2021-242101 - Resource Development Analyst",
    "2021-242101 - Purchase Advisor",
    "2021-242101 - Capital Expenditure Analyst",
    "2021-242101 - Superannuation Transitions Specialist",
    "2021-242102 - Organisation and Methods Official",
    "2021-242102 - Business / Community / Disability Liaison Officer",
    "2021-242102 - Land Information Change Manager",
    "2021-242102 - Change Management Facilitator",
    "2021-242102 - Organisational Performance Improvement Manager",
    "2021-242102 - Management Systems Consultant",
    "2021-242102 - Methods Engineer",
    "2021-242102 - Cost Modelling / Industry Analyst",
    "2021-242102 - Efficiency Engineer",
    "2021-242102 - Organisational Performance Manager / Practitioner",
    "2021-242102 - Equipment and Standards Advisor",
    "2021-242102 - Skills Auditor",
    "2021-242102 - Work Study Officer / Observer",
    "2021-242103 - Local Fisheries Development Officer",
    "2021-242201 - Military Intelligence Officer",
    "2021-242201 - Information Strategist",
    "2021-242201 - Vetting Specialist",
    "2021-242201 - Intelligence Coordinator Manager",
    "2021-242201 - Defence Intelligence Analyst",
    "2021-242201 - Criminal Intelligence Analyst",
    "2021-242202 - Strategy Analyst",
    "2021-242202 - Systems Advisory Officer Evaluations",
    "2021-242202 - Programme Evaluator",
    "2021-242202 - Intelligence Analyst",
    "2021-242202 - Special Projects Analyst",
    "2021-242202 - Research Consultant",
    "2021-242202 - Policy Advisor",
    "2021-242202 - Commerce Projects Advisor",
    "2021-242202 - Policy Consultant / Officer / Planner",
    "2021-242202 - Strategic Business Policy Analyst",
    "2021-242202 - Position Analyst",
    "2021-242202 - Research and Evaluation Analyst",
    "2021-242202 - Strategic Advisor",
    "2021-242202 - Risk / Planning / Review / Analyst",
    "2021-242202 - Trade Policy Advisor",
    "2021-242203 - Board Secretary",
    "2021-242204 - Treasurer",
    "2021-242204 - Financial Risk Manager",
    "2021-242207 - Compliance Officer (Financial Sector)",
    "2021-242207 - Financial Markets Compliance Officer",
    "2021-242208 - Organisational Risk Officer",
    "2021-242208 - Risk Compliance Manager",
    "2021-242208 - Organisational Enterprise Wide Risk Manager",
    "2021-242208 - Organisational Risk Adviser",
    "2021-242208 - Organisational Risk Coordinator",
    "2021-242208 - Organisational Development Manager / Practitioner",
    "2021-242211 - ICT Internal Auditor",
    "2021-242211 - Information Systems Auditor",
    "2021-242211 - Internal auditor",
    "2021-242211 - Audit Consultant",
    "2021-242212 - Foreign Relations Practitioner",
    "2021-242215 - Polygraph Examiner",
    "2021-242301 - Vocational Guide",
    "2021-242301 - Careers Advisor",
    "2021-242301 - Career Consultant",
    "2021-242302 - Workforce Planning Analyst",
    "2021-242302 - Skills Development Strategist",
    "2021-242302 - Human Resources Planner",
    "2021-242302 - Training Analyst",
    "2021-242302 - Enterprise Resource Planner",
    "2021-242303 - Hr Administrator",
    "2021-242303 - Human Resources Development Coordinator",
    "2021-242303 - Hr Coordinator",
    "2021-242303 - Business Unit Advisor",
    "2021-242303 - Remuneration and Benefits Specialist",
    "2021-242303 - Personnel Consultant",
    "2021-242303 - Hr Officer",
    "2021-242303 - Hr Analyst",
    "2021-242303 - Human Resource Consultant",
    "2021-242303 - Professional Development Advisor",
    "2021-242303 - Personnel Officer",
    "2021-242304 - Employment Relations Officer",
    "2021-242304 - Conflict Resolution Practitioner",
    "2021-242304 - Employee Relations Advisor",
    "2021-242304 - Employment Relations Coordinator",
    "2021-242304 - Industrial Relations Officer",
    "2021-242304 - Business Advisor Employee Relations",
    "2021-242304 - Industrial Relations Advisor",
    "2021-242304 - Industrial Relations Consultant",
    "2021-242307 - Recreation Advisor",
    "2021-242307 - Recreation Coordinator",
    "2021-242307 - Activities Officer (Recreation)",
    "2021-242401 - Training Coordinator",
    "2021-242401 - Training Advisor",
    "2021-242401 - Training and Development Practitioner",
    "2021-242401 - Learnership Manager",
    "2021-242401 - Training Officer",
    "2021-242401 - Training Consultant",
    "2021-242401 - Training Material Developer",
    "2021-242402 - Work at Heights Instructor",
    "2021-242402 - Maintenance Instructor",
    "2021-242402 - Business Leadership / Executive Coach",
    "2021-242402 - Product Trainer",
    "2021-242402 - Systems Trainer",
    "2021-242402 - Business Management Skills Trainer",
    "2021-242402 - Police Instructor",
    "2021-242402 - Mechanical Instructor",
    "2021-242402 - Welding Instructor",
    "2021-242402 - On-the-job Trainer",
    "2021-242402 - Metalwork Instructor",
    "2021-242402 - Business Skills Trainer",
    "2021-242402 - Operator Trainer",
    "2021-242403 - Assessor",
    "2021-243101 - Creative Director (Advertising)",
    "2021-243101 - Advertising Coordinator / Specialist",
    "2021-243101 - Advertising Account Executive",
    "2021-243101 - Advertising Account Manager",
    "2021-243101 - Advertising Agent Representative",
    "2021-243102 - Market Researcher",
    "2021-243102 - Market Access Counsellor",
    "2021-243102 - Marketing Analyst",
    "2021-243102 - Market Research Project Manager / Project Leader",
    "2021-243102 - Marketing Research Executive",
    "2021-243102 - Trade Marketing Analyst",
    "2021-243102 - Marketing Research Accounts Manager / Key Account Manager",
    "2021-243102 - Market Trends Consultant",
    "2021-243102 - Information and Publishing Communications Advisor",
    "2021-243103 - Business Strategy Manager",
    "2021-243103 - Sales Promotion Officer",
    "2021-243103 - Sales Coordinator",
    "2021-243103 - Sales Account Manager",
    "2021-243103 - Marketing Officer",
    "2021-243103 - Category Manager",
    "2021-243103 - Retail Advisor",
    "2021-243103 - Tourism Officer",
    "2021-243103 - Product / Programme Developer (Travel Services)",
    "2021-243103 - Business Forecaster",
    "2021-243103 - Marketing Professional",
    "2021-243103 - Marketing Consultant",
    "2021-243103 - Marketing Specialist",
    "2021-243103 - Interactive Direct Marketing Postal Planners",
    "2021-243103 - Brand Manager",
    "2021-243103 - Product Manager",
    "2021-243104 - Contact Centre Production / Productivity Analyst",
    "2021-243201 - Parliamentary Writer",
    "2021-243201 - Corporate Affairs Officer / Coordinator",
    "2021-243201 - Customer Relations Officer",
    "2021-243201 - Media Liaison Officer",
    "2021-243201 - Promotions Officer / Coordinator",
    "2021-243201 - Public Affairs Advisor / Officer",
    "2021-243201 - Internal / Employee Communication Coordinator",
    "2021-243201 - Public Relations (PR) Coordinator / Practitioner",
    "2021-243201 - Company Promoter",
    "2021-243201 - Speechwriter and Advisor",
    "2021-243201 - Water Liaison practitioner",
    "2021-243201 - Coordinator External / Internal Communication",
    "2021-243201 - Publicity Advisor / Officer / Coordinator",
    "2021-243201 - Communication Management Coordinator",
    "2021-243201 - Press Officer / Secretary",
    "2021-243202 - Fundraising Manager",
    "2021-243202 - Corporate Communication Strategist",
    "2021-243203 - Director Public Relations / Corporate Communications (Public Service)",
    "2021-243203 - Fundraising Director",
    "2021-243203 - Manager Public Affairs / Relations",
    "2021-243203 - Account Manager (Communications Consultancy)",
    "2021-243203 - Manager Communications Management",
    "2021-243203 - Account / Regional Director (Communications Consultancy)",
    "2021-243203 - Manager Corporate Communications",
    "2021-243203 - Media / Communication / Parliamentary / Liaison Practitioner (Government)",
    "2021-243203 - Manager External / Internal Communication)",
    "2021-243204 - Government, Protocol or Civic Event Organiser",
    "2021-243204 - Wedding Coordinator",
    "2021-243204 - Meeting Planner",
    "2021-243204 - Event Coordinator",
    "2021-243204 - Professional Conference Organiser",
    "2021-243204 - Event Manager Specialist",
    "2021-243204 - Social / Life Cycle Organiser",
    "2021-243204 - Concert Promoter / Organiser",
    "2021-243204 - Conference and Event Organiser / Coordinator",
    "2021-243204 - Event Director",
    "2021-243204 - Sports Event Organiser",
    "2021-243204 - Festival Coordinator",
    "2021-243301 - Technical Sales Consultant / Coordinator / Manager",
    "2021-243301 - Technical Service Advisor / Salesman",
    "2021-243301 - Service Delivery Analyst / Coordinator",
    "2021-243301 - Solar and Hot Water System Sales Representative",
    "2021-243301 - Engineering Salesman",
    "2021-243301 - Technical Representative / Salesman",
    "2021-243301 - Customer Services Engineer / Processor",
    "2021-243302 - Pharmaceutical Territory Manager",
    "2021-243302 - Medical Detailer",
    "2021-243302 - Medical Representative",
    "2021-243304 - Publishing Consultant",
    "2021-243304 - Printing Consultant",
    "2021-243304 - Publishers Representative",
    "2021-243403 - Computer Consultant",
    "2021-243403 - Computer Systems Consultant",
    "2021-243403 - Computer Software Support Consultant",
    "2021-251101 - ICT Business Systems Analyst",
    "2021-251101 - ICT Systems Strategist",
    "2021-251101 - Internet Consultant / Specialist",
    "2021-251101 - ICT Systems Coordinator",
    "2021-251101 - ICT Systems Architect",
    "2021-251101 - Capacity Planner Computing",
    "2021-251101 - ICT Systems Specialist",
    "2021-251101 - Systems Programmer",
    "2021-251101 - ICT System Designer",
    "2021-251101 - ICT Systems Contractor",
    "2021-251101 - ICT Systems Advisor",
    "2021-251101 - LAN / WAN Consultant / Specialist",
    "2021-251101 - Computer Analyst",
    "2021-251101 - ICT Systems Consultant",
    "2021-251201 - Software Engineer",
    "2021-251201 - Software Designer",
    "2021-251201 - ICT Risk Specialist",
    "2021-251201 - Software Architect",
    "2021-251201 - Information Architect Software",
    "2021-251202 - Software Configuration / Licensing Specialist",
    "2021-251202 - Computing (Development / Field) Engineer",
    "2021-251202 - Cross Enterprise Integrator",
    "2021-251202 - Database Designer",
    "2021-251202 - Architect ( Applications / Call Centre / Computing / Desktop / Ecommerce)",
    "2021-251202 - Education Systems Coordinator",
    "2021-251202 - Engineer (Applications / Content / IT / Software / Systems / WAN)",
    "2021-251202 - Designer (Hardware - Digital / Software)",
    "2021-251202 - Architect (Enterprise / Internet / IT / Network / Software / Unix / Web)",
    "2021-251203 - ICT Developer",
    "2021-251203 - ICT Programmer",
    "2021-251203 - Applications Developer",
    "2021-251301 - Multimedia Developer",
    "2021-251301 - Graphical Programmer",
    "2021-251301 - Digital Media Specialist",
    "2021-251301 - Multimedia Programmer",
    "2021-251301 - Animation Programmer",
    "2021-251301 - Computer Games Programmer",
    "2021-251302 - Web Programmer",
    "2021-251302 - Website Architect",
    "2021-251302 - Html Coder / Developer",
    "2021-251302 - Website Developer",
    "2021-251302 - Web Editor / Designer",
    "2021-251302 - Web Publishing Advisor",
    "2021-251302 - Internet Developer",
    "2021-251401 - Applications Integrator",
    "2021-251401 - Software and Applications Developer",
    "2021-251901 - Software tester",
    "2021-252101 - Data Administrator",
    "2021-252101 - Database Administrator",
    "2021-252101 - Database Analyst",
    "2021-252101 - Database Architect",
    "2021-252101 - Ecommerce Technical Architect",
    "2021-252201 - Content Product Manager",
    "2021-252201 - IT Information Systems Administrator",
    "2021-252201 - Network Administrator",
    "2021-252201 - Systems Administrator (Computers)",
    "2021-252201 - Network / Computer System Coordinator",
    "2021-252201 - Computerised Registry Manager",
    "2021-252201 - Advisor Systems Management",
    "2021-252201 - Ecommerce Project Manager",
    "2021-252201 - Systems Manager",
    "2021-252301 - Computer Network Engineer",
    "2021-252301 - Network Engineer",
    "2021-252301 - Computer Systems / Service Engineer",
    "2021-252301 - Network Support Engineer",
    "2021-252301 - Computer Systems Integrator",
    "2021-252301 - ICT Customer Support Officer",
    "2021-252301 - Systems Engineer",
    "2021-252301 - Systems Integrator",
    "2021-252301 - Communications Analyst (Computers)",
    "2021-252301 - Network Programmer / Analyst",
    "2021-252302 - Network Services Consultant (LAN / WAN)",
    "2021-252302 - Network Planner",
    "2021-252302 - LAN Engineer",
    "2021-252302 - Network Architect",
    "2021-252302 - Network Designer",
    "2021-252302 - LAN Analyst",
    "2021-252302 - Network Consultant",
    "2021-252302 - Network Strategist",
    "2021-252901 - Security Administrator",
    "2021-252901 - ICT Security Architect",
    "2021-252901 - Information Technology Security Manager",
    "2021-252901 - Internet Security Architect / Engineer / Consultant",
    "2021-252901 - Database Security Expert",
    "2021-261101 - Solicitor",
    "2021-261101 - Lawyer (Civil / Property / Competition / Contracts / Conveyancing / Criminal)",
    "2021-261101 - Prosecutor",
    "2021-261101 - Intellectual Property Lawyer / Practitioner",
    "2021-261101 - Marine Law Attorney",
    "2021-261101 - Environmental Law Attorney",
    "2021-261101 - Commercial Rights Manager",
    "2021-261101 - Lawyer (Employment/ Entertainment / Financial / Commercial / Immigration)",
    "2021-261101 - Maritime Law Attorney",
    "2021-261102 - Enforcement Analyst",
    "2021-261102 - Law Researcher",
    "2021-261102 - Enforcement Advisor",
    "2021-261102 - Legal Researcher",
    "2021-261104 - Trade Mark Advisor",
    "2021-261104 - Trade Marks Examiner Justice",
    "2021-261105 - Conciliation and Arbitration Commissioner",
    "2021-261105 - Administrative Appeals Tribunal Member",
    "2021-261105 - Land Valuation Tribunal Member",
    "2021-261201 - Commissioner Environment Court",
    "2021-261201 - Supreme Court Judge",
    "2021-261201 - Family Court Justice",
    "2021-261201 - District Court Judge",
    "2021-261201 - Chief Justice",
    "2021-261201 - President Court of Appeal",
    "2021-261201 - Referee Small Claims",
    "2021-261201 - High Court Justice",
    "2021-261201 - Principal Disputes Referee",
    "2021-261202 - Chamber Magistrate",
    "2021-261202 - Children's Court Magistrate",
    "2021-261202 - Victims Compensation Tribunal Magistrate",
    "2021-261202 - Industrial Court Magistrate",
    "2021-261202 - Licensing Court Magistrate",
    "2021-261901 - Intellectual Property Advisor",
    "2021-261901 - Negotiator",
    "2021-261901 - Facilitator",
    "2021-261901 - Arbitrator",
    "2021-261901 - Industrial Conciliator",
    "2021-261901 - Mediator",
    "2021-261901 - Rulings Analyst",
    "2021-261903 - Master of The High Court",
    "2021-261903 - Master",
    "2021-261903 - Court Master",
    "2021-262101 - Archives Analyst",
    "2021-262101 - Archives Technician",
    "2021-262101 - Preservation Manager",
    "2021-262101 - Books Technician",
    "2021-262101 - Document Restorer",
    "2021-262101 - Film Archivist",
    "2021-262101 - Parliamentary Archivist",
    "2021-262101 - Manuscripts Archivist",
    "2021-262101 - Legal Archivist",
    "2021-262101 - Archives Officer",
    "2021-262101 - Preservation Technician",
    "2021-262102 - Museum Registrar",
    "2021-262102 - Exhibitions Technician",
    "2021-262102 - Curatorial Services Manager",
    "2021-262103 - Art Conservator",
    "2021-262201 - Special Needs Librarian",
    "2021-262201 - Parliamentary Librarian",
    "2021-262201 - Coordinator Heritage General Collections Management",
    "2021-262201 - Legal Librarian",
    "2021-262201 - Bibliographer",
    "2021-262201 - Information Scientist / Officer",
    "2021-262201 - Collection Development Manager",
    "2021-262201 - Children's Librarian",
    "2021-262201 - Library Manager / Advisor",
    "2021-262201 - Audio-visual Librarian",
    "2021-262201 - Acquisitions Librarian / Advisor",
    "2021-262201 - Cataloguer",
    "2021-262201 - Reference Librarian / Advisor",
    "2021-262201 - Bibliographic Services Manager",
    "2021-262201 - Oral History Manager",
    "2021-262201 - Special Librarian",
    "2021-262201 - Multicultural Services Librarian",
    "2021-262201 - Corporate Librarian",
    "2021-262202 - Knowledge Management Officer",
    "2021-262202 - Biodiversity Information Management Specialist",
    "2021-262202 - Information System Consultant",
    "2021-262202 - Records Manager",
    "2021-262202 - Knowledge Manager",
    "2021-262202 - Freedom of Information Officer",
    "2021-262202 - Catalogue Administrator",
    "2021-262202 - Information Management Specialist",
    "2021-262202 - Records Administrator",
    "2021-263101 - Development Finance Project Analyst",
    "2021-263101 - Resource Economist",
    "2021-263101 - Health Economist",
    "2021-263101 - Taxation Economist",
    "2021-263101 - Price Economist",
    "2021-263101 - Economic Consultant",
    "2021-263101 - Economic Advisor",
    "2021-263101 - Economic Analyst",
    "2021-263101 - Micro-economist",
    "2021-263101 - Agricultural Economist",
    "2021-263101 - Economic Forecaster",
    "2021-263101 - Macro-economist",
    "2021-263101 - Mineral Economist",
    "2021-263101 - Small Business Economist",
    "2021-263101 - Environmental Economist",
    "2021-263101 - Research Economist",
    "2021-263101 - Industrial Economist",
    "2021-263101 - Financial Economist",
    "2021-263101 - Econometrician",
    "2021-263101 - Labour Market Economist",
    "2021-263101 - Merger Analyst",
    "2021-263201 - Cultural Affairs Advisor",
    "2021-263201 - Anthropologist (Governance)",
    "2021-263201 - Cultural Consultant",
    "2021-263201 - Cultural Development Officer",
    "2021-263201 - Ethnomusicologist",
    "2021-263201 - Cultural Development Facilitator",
    "2021-263201 - Cultural Development Advisor",
    "2021-263201 - Ethnologist",
    "2021-263201 - Cultural Officer",
    "2021-263205 - Analyst Criminal",
    "2021-263302 - Economic Historian",
    "2021-263302 - Cultural Historian",
    "2021-263302 - Geographical Historian",
    "2021-263302 - Art Historian",
    "2021-263302 - Historical Researcher",
    "2021-263304 - Political Science Researcher",
    "2021-263304 - Political Analyst",
    "2021-263401 - Health Psychologist",
    "2021-263401 - Forensic Psychologist",
    "2021-263401 - Neuropsychologist",
    "2021-263401 - Cognitive Behavioural Therapist",
    "2021-263401 - Clinical Neuropsychologist",
    "2021-263401 - Psychologist (Child / Comparative / Consultant / Criminal)",
    "2021-263403 - Industrial Psychologist",
    "2021-263403 - Occupational Psychologist",
    "2021-263404 - Behaviour Therapist",
    "2021-263404 - Hypnotherapist",
    "2021-263404 - Behaviour Management Consultant",
    "2021-263409 - Psychological Examiner",
    "2021-263409 - Psychometrist",
    "2021-263501 - Trauma Counsellor",
    "2021-263501 - Women's Welfare Organizer",
    "2021-263501 - Bereavement Counsellor",
    "2021-263501 - Genetic Counsellor",
    "2021-263501 - HIV / Aids Counsellor",
    "2021-263501 - Wellness Counsellor",
    "2021-263502 - Social Detoxification Worker",
    "2021-263502 - Gambling Counsellor",
    "2021-263502 - Drug and Alcohol Counsellor",
    "2021-263503 - Family Court Counsellor",
    "2021-263503 - Family Therapist",
    "2021-263503 - Family Mediator",
    "2021-263504 - Life Coach",
    "2021-263505 - School Counsellor",
    "2021-263506 - Probation Worker",
    "2021-263506 - Community Corrections Officer",
    "2021-263507 - School Social Worker",
    "2021-263507 - Clinical Social Worker",
    "2021-263507 - Adoptions Worker",
    "2021-263507 - Occupational Social Worker",
    "2021-263507 - Health Care Social Worker",
    "2021-263507 - Forensic Social Worker",
    "2021-263507 - Reintegration Worker",
    "2021-263508 - Child and Youth Counsellor",
    "2021-263510 - Employee Assistance Practitioner",
    "2021-263601 - Religious Worker",
    "2021-263601 - Rabbi",
    "2021-263601 - Religious Leader",
    "2021-263601 - Archbishop",
    "2021-263601 - Priest",
    "2021-263601 - Bishop",
    "2021-263601 - Imam",
    "2021-263601 - Salvation Army Officer",
    "2021-263601 - Theologian",
    "2021-263601 - Chaplain",
    "2021-264101 - Screenwriter",
    "2021-264101 - Playwright",
    "2021-264101 - Essayist",
    "2021-264101 - Dramatist",
    "2021-264101 - Poet",
    "2021-264101 - Script Writer",
    "2021-264101 - Novelist",
    "2021-264101 - Writer",
    "2021-264102 - Dramaturge",
    "2021-264201 - Publicity Writer",
    "2021-264201 - Copy Editor",
    "2021-264201 - Advertising Writer",
    "2021-264202 - Publications Editor",
    "2021-264202 - News Editor",
    "2021-264202 - Editorial Administrator",
    "2021-264202 - Features Editor",
    "2021-264202 - Editor",
    "2021-264202 - Programme Editor",
    "2021-264202 - Publications Advisor",
    "2021-264202 - Publications Officer",
    "2021-264202 - Magazine Designer",
    "2021-264202 - Subeditor",
    "2021-264202 - Associate Editor",
    "2021-264202 - Artwork Editor",
    "2021-264202 - Pictures Editor",
    "2021-264202 - Website / Blog Editor",
    "2021-264202 - Legal Editor",
    "2021-264202 - Technical Editor",
    "2021-264203 - Editorial Officer",
    "2021-264203 - Media Writer Reporter",
    "2021-264203 - Sports Writer",
    "2021-264203 - Newspaper Reporter",
    "2021-264203 - Columnist",
    "2021-264203 - Pressman",
    "2021-264203 - Press Reporter",
    "2021-264203 - Feature Writer",
    "2021-264203 - Leader Writer",
    "2021-264203 - Foreign Correspondent Newspaper",
    "2021-264203 - Editorial Assistant",
    "2021-264204 - News Commentator Broadcasting",
    "2021-264204 - Radio Reporter",
    "2021-264205 - Television Reporter",
    "2021-264205 - Television Editor",
    "2021-264206 - Film Assessor",
    "2021-264206 - Music Reviewer",
    "2021-264301 - Sign Language Interpreter",
    "2021-264301 - Court Interpreter",
    "2021-264301 - Philologist",
    "2021-264302 - Translator-revisor",
    "2021-264302 - Subtitler",
    "2021-265101 - Caricaturist",
    "2021-265101 - Air Brush Artist",
    "2021-265101 - Art Restorer Paintings",
    "2021-265101 - Muralist",
    "2021-265101 - Photo Colourer",
    "2021-265101 - Art Enameller",
    "2021-265101 - Mosaicist",
    "2021-265101 - Portrait Painter",
    "2021-265101 - Picture Restorer",
    "2021-265101 - Cartoonist",
    "2021-265101 - Painter (Artistic)",
    "2021-265201 - Music Arranger",
    "2021-265201 - Programme Selector Music",
    "2021-265201 - Lyric Writer",
    "2021-265201 - Orchestrator",
    "2021-265201 - Songwriter",
    "2021-265202 - Choral Director",
    "2021-265202 - Music Conductor",
    "2021-265202 - Band Leader",
    "2021-265202 - Orchestra Conductor",
    "2021-265203 - Drummer",
    "2021-265203 - Bandsman",
    "2021-265203 - Accompanist",
    "2021-265203 - Pianist / Violinist",
    "2021-265203 - Percussionist",
    "2021-265203 - Guitarist",
    "2021-265203 - Instrumentalist",
    "2021-265204 - Opera Singer",
    "2021-265204 - Street Musician",
    "2021-265204 - Ensemble Singer",
    "2021-265204 - Concert Singer",
    "2021-265204 - Chorister",
    "2021-265204 - Street Singer",
    "2021-265301 - Ballet Dancer",
    "2021-265301 - Contemporary or Modern Dancer",
    "2021-265301 - Striptease Artist",
    "2021-265401 - Location Director",
    "2021-265401 - Theatre Director",
    "2021-265401 - Studio Director",
    "2021-265401 - Motion Picture Director",
    "2021-265401 - Theatrical Director",
    "2021-265402 - Cinematographer",
    "2021-265403 - Post Production Editor",
    "2021-265403 - Film Editing Equipment Operator",
    "2021-265405 - Technical Producer",
    "2021-265406 - Recording Equipment Operator (Video)",
    "2021-265410 - Radio or Television Programmer",
    "2021-265412 - Broadcasting and Theatrical Production Manager",
    "2021-265412 - Radio Producer",
    "2021-265412 - CD Producer",
    "2021-265412 - Producer (Film and Television)",
    "2021-265412 - Executive Producer",
    "2021-265412 - Stage Producer",
    "2021-265412 - Musical Producer",
    "2021-265412 - Television Producer",
    "2021-265412 - Film Producer",
    "2021-265412 - Theatre Producer",
    "2021-265501 - Mime Artist",
    "2021-265501 - Performance Artist",
    "2021-265501 - Voice-over Artist",
    "2021-265501 - Actress",
    "2021-265601 - Radio Broadcaster",
    "2021-265601 - Radio Newscaster",
    "2021-265601 - Disc Jockey",
    "2021-265601 - Talk Show Host",
    "2021-265601 - Radio Newsreader",
    "2021-265601 - Radio Announcer",
    "2021-265601 - Radio Compeer",
    "2021-265601 - Commentator (Radio)",
    "2021-265602 - TV Broadcaster",
    "2021-265602 - TV Announcer",
    "2021-265602 - TV Commentator",
    "2021-265602 - TV Newsreader",
    "2021-265602 - TV Compeer",
    "2021-265602 - Newsroom Manager",
    "2021-265901 - Busker",
    "2021-265901 - Illusionist",
    "2021-265901 - Ventriloquist",
    "2021-265901 - Comedian",
    "2021-265901 - Aerialist",
    "2021-265901 - Acrobat",
    "2021-265901 - Magician",
    "2021-265901 - Clown",
    "2021-265901 - Circus Artist",
    "2021-265903 - Motivational Speaker",
    "2021-265904 - Community Artist",
    "2021-265904 - Community Arts Officer",
    "2021-265904 - Community Cultural Development Officer",
    "2021-311101 - Chemical Process Analyst",
    "2021-311101 - Chemical Laboratory Assistant",
    "2021-311101 - Chemical Laboratory Technician / Analyst",
    "2021-311101 - Chemical Technician (Food, Fuel, Gas, Paint, Liquefied Petroleum Gas, Leather)",
    "2021-311101 - Chemical Instrumentation Officer",
    "2021-311101 - Chemistry Technical Officer",
    "2021-311101 - Chemical Manufacturing Technician",
    "2021-311101 - Petroleum Refinery Laboratory Technician",
    "2021-311101 - Paint Maker",
    "2021-311101 - Biofuels Processing Technicians",
    "2021-311101 - Chemical Laboratory Analyst",
    "2021-311101 - Carbide Analyst",
    "2021-311101 - Petroleum Products Laboratory Technician",
    "2021-311102 - Seismology Technical Officer",
    "2021-311102 - Astronomy Technician",
    "2021-311102 - Physical Sciences Workshop Technician",
    "2021-311102 - Physics Technician",
    "2021-311102 - Geology Technician",
    "2021-311102 - Meteorology Technician",
    "2021-311102 - Product Quality Laboratory Technician",
    "2021-311102 - Physical Sciences Laboratory Technician",
    "2021-311102 - Physical Sciences Research Technician (University)",
    "2021-311104 - Radiation Protection Practitioner",
    "2021-311201 - Building Engineering Technician",
    "2021-311201 - Soils, Gravels and Crushed Stone Base Materials Tester",
    "2021-311201 - Concrete Tester",
    "2021-311201 - Mining and Quarrying Aggregates Tester",
    "2021-311201 - Bituminous Binders Tester",
    "2021-311201 - Civil Engineering Laboratory Technician",
    "2021-311201 - Work Site Engineering Technician",
    "2021-311201 - Construction Design Technician",
    "2021-311201 - Geotechnical Laboratory Technician",
    "2021-311201 - Civil Engineering Assistant",
    "2021-311201 - Civil Engineering Aggregates Tester",
    "2021-311201 - Civil Engineering Materials Tester",
    "2021-311201 - Asphalt Tester",
    "2021-311202 - Surveying Technician",
    "2021-311202 - Mapping Technician",
    "2021-311202 - Geomatics Technician",
    "2021-311202 - Hydrographic Technician",
    "2021-311202 - Aerial Survey Technician",
    "2021-311202 - Engineering Survey Drafting Technician",
    "2021-311203 - Town Planning Research Technician",
    "2021-311301 - Hydraulic Power Controller",
    "2021-311301 - Turbine Room Controller",
    "2021-311301 - Electrical Engineering Technical Officer",
    "2021-311301 - Electrical Instrument Technician",
    "2021-311301 - Electrical Engineering Laboratory Technician",
    "2021-311302 - Electrical Switchboard Controller",
    "2021-311302 - Power Distribution Technician",
    "2021-311302 - Power System Operator",
    "2021-311302 - Electric Power Load Dispatcher",
    "2021-311302 - Electric Power Plant Operator",
    "2021-311302 - Power Station Attendant",
    "2021-311302 - Distribution Control Operator",
    "2021-311302 - Power Distribution Plant Controller",
    "2021-311302 - Power Reticulation Utilities Officer",
    "2021-311302 - Power Transmission Technician",
    "2021-311303 - Energy Officer",
    "2021-311303 - Building Energy Auditor (Commercial)",
    "2021-311303 - Building Energy Auditor (Industrial)",
    "2021-311303 - Energy Efficiency Consultant",
    "2021-311303 - Building Energy Auditor (Residential)",
    "2021-311401 - Telemetry Technician",
    "2021-311401 - Computer-numeric Control (CNC) Technical Officer",
    "2021-311401 - Digital Controls Technical Officer",
    "2021-311401 - Flight Surveyor",
    "2021-311401 - Television and Radio Systems Technician",
    "2021-311401 - Electronic Engineering Associate",
    "2021-311401 - Clinical Engineering Technician",
    "2021-311401 - Medical Equipment Maintainer",
    "2021-311401 - Instrumentation Technician",
    "2021-311401 - Computer-numeric Control (CNC) Programmer",
    "2021-311501 - Mobile Plant Technician",
    "2021-311501 - Fire Protection Installer / Commissioner",
    "2021-311501 - Marine Engineering Technician",
    "2021-311501 - Pipe Testing Technician",
    "2021-311501 - Boiler Testing Technician",
    "2021-311501 - Tool and Die Design Technician",
    "2021-311501 - Agricultural Engineering Technician",
    "2021-311501 - Fuel Cell Technician",
    "2021-311501 - Automotive Engineering Technician",
    "2021-311501 - Naval Technician",
    "2021-311501 - Mechanical Laboratory Technician",
    "2021-311501 - Aeronautical Technician",
    "2021-311601 - Coal Technician",
    "2021-311601 - Cosmetics Technician",
    "2021-311601 - Biochemical Technician",
    "2021-311601 - Catalytic Processing Technician",
    "2021-311601 - Energy Technician",
    "2021-311601 - Crude Oil Technician",
    "2021-311701 - Mine Ventilation Officer",
    "2021-311701 - Strata Control Practitioner",
    "2021-311701 - Mine Ventilation Observer",
    "2021-311701 - Surface Mining Strata Control Practitioner",
    "2021-311701 - Strata Control Observer",
    "2021-311701 - Mining Surveyor",
    "2021-311701 - Underground Hardrock Strata Control Observer",
    "2021-311701 - Mine Ventilation Technician",
    "2021-311701 - Section Mine Planner",
    "2021-311701 - Ventilation Assistant",
    "2021-311701 - Mine Planner",
    "2021-311701 - Geographical Engineering Practitioner",
    "2021-311701 - Under ground Hardrock Strata Control Practitioner",
    "2021-311701 - Rock Engineering Officer",
    "2021-311701 - Mine Analyst",
    "2021-311701 - Mine Planning Technician",
    "2021-311701 - Coal Strata Control Practitioner",
    "2021-311701 - Rock Enginnering Technician",
    "2021-311701 - Environmental  Observer",
    "2021-311701 - Marine Mining Technician",
    "2021-311701 - Hardrock Mining Simpler",
    "2021-311701 - Mine Planning Practitioner",
    "2021-311702 - Dye Penetrant Testing Technician",
    "2021-311702 - Ultrasound Technician",
    "2021-311702 - Industrial Radiographer",
    "2021-311702 - Metallurgy Laboratory Technician",
    "2021-311702 - Non Destructive Testing Technician",
    "2021-311702 - Pressure Testing Technician",
    "2021-311702 - Magnetic Testing Technician",
    "2021-311702 - Heat Treatment Technician",
    "2021-311702 - Mineral Analyst / Spectroscopist",
    "2021-311702 - Mineral Spectroscopist",
    "2021-311704 - Surface Mining Geological Technician",
    "2021-311704 - Soil Mechanic Technician",
    "2021-311704 - Marine Mining Geological Technician",
    "2021-311704 - Earth Science Laboratory Technician",
    "2021-311704 - Geological Laboratory Technician",
    "2021-311704 - Geological Technician",
    "2021-311704 - Ore Reserve Technician",
    "2021-311704 - Geoscience Laboratory Technician",
    "2021-311704 - Remote Sensing Technicians",
    "2021-311704 - Mineral Resource Technician",
    "2021-311704 - Geotechnician",
    "2021-311704 - Underground Mining Geological Technician",
    "2021-311704 - Geochemical Laboratory Technician",
    "2021-311704 - Geological Technical Officer",
    "2021-311704 - Earth Science Technical Officer",
    "2021-311704 - Soil Science Technical Officer",
    "2021-311705 - Mine Ventilation Officer: DELETED",
    "2021-311801 - Electrical and Electronics Draughtsperson",
    "2021-311801 - Technical Draughting Officer",
    "2021-311801 - Substation Design Draughtsperson",
    "2021-311801 - Marine Draughtsperson",
    "2021-311801 - Electronic Engineering Draughtsperson",
    "2021-311801 - Shipbuilding Draughtsperson",
    "2021-311801 - Engineering Draughtsperson",
    "2021-311801 - Petroleum Draughtsperson",
    "2021-311801 - Civil Engineering Draughtsperson",
    "2021-311801 - Architectural and Civil Draughtsperson",
    "2021-311801 - Manufacturing Draughtsperson",
    "2021-311801 - Topographic Draughting Officer",
    "2021-311801 - Geological Draughting Person",
    "2021-311801 - Agricultural Engineering Draughtsperson",
    "2021-311801 - Mining Engineering Draughtsperson",
    "2021-311801 - Materials Engineering Draughtspersons",
    "2021-311801 - Mechanical Engineering Draughtsperson",
    "2021-311801 - Mining Detail Draughtsperson",
    "2021-311801 - Mechanical Draughtsperson",
    "2021-311801 - Metallurgical Engineering Draughtspersons",
    "2021-311801 - Architectural Draughtsperson",
    "2021-311801 - Electrical Engineering Draughtsperson",
    "2021-311801 - Chemical Engineering Draughtspersons",
    "2021-311801 - Aeronautical Draughtsperson",
    "2021-311801 - Design and Manufacturing Draughtsperson",
    "2021-311801 - Nuclear Draughtsperson",
    "2021-311801 - Mining Draughtsperson",
    "2021-311801 - Technical Draughtsperson",
    "2021-311801 - Relays Draughtsperson",
    "2021-311801 - Photographic Draughtsperson",
    "2021-311902 - Fire Inspector",
    "2021-311903 - Gas Chromatographic",
    "2021-311903 - Brewmaster / Master Brewer",
    "2021-311903 - Food Laboratory Technician",
    "2021-311903 - Sugar Laboratory Technician",
    "2021-311903 - Milling Laboratory Technician",
    "2021-311903 - Brewery Technician",
    "2021-311903 - Distillery Technician",
    "2021-311903 - Food Science Technician",
    "2021-311903 - Beverage Laboratory Technician",
    "2021-311903 - Fermentation Technician",
    "2021-311903 - Dairy Laboratory Technician",
    "2021-311904 - Rubber Manufacturing Technician",
    "2021-311904 - Wood and Paper Manufacturing Technician",
    "2021-311904 - Metal Manufacturing Technician",
    "2021-311904 - Computer Aided Jewellery Designer",
    "2021-311904 - Jewellery Technician",
    "2021-311904 - Glass, Clay and Stone Manufacturing Technician",
    "2021-311904 - Quantity Surveying Technician",
    "2021-311904 - Clothing, Textile, Footwear and Leather Manufacturing Technician",
    "2021-311904 - Plastics Manufacturing Technician",
    "2021-311904 - Cable Manufacturing Technician",
    "2021-311904 - Computer Aided Jewellery Manufacturer",
    "2021-311904 - Plastician",
    "2021-311904 - Plastics Component Manufacturing Technician",
    "2021-311904 - Rubber Component Manufacturing Technician",
    "2021-311904 - Master Goldsmith",
    "2021-311905 - Robotics and Production Automation Technician",
    "2021-311910 - Meat and Fish Product Analyst",
    "2021-311910 - Carbohydrate and Cereal based Product Analyst",
    "2021-311910 - Spices, Condiments and Culinary Product Analyst",
    "2021-311910 - Dairy Product Analyst",
    "2021-311910 - Oil and Fat based Product Analyst",
    "2021-311910 - Fruit and vegetable Product Analyst",
    "2021-311910 - Confectionery Product Analyst",
    "2021-311910 - Beverage Product Analyst",
    "2021-312101 - Shift Supervisor (Mining)",
    "2021-312101 - Mine Overseer (Production)",
    "2021-312101 - Mine Overseer (Projects)",
    "2021-312101 - Master Sinker",
    "2021-312101 - Mine Overseer (Planning)",
    "2021-312101 - Shift Overseer (Mining)",
    "2021-312101 - Quarry Foreman",
    "2021-312101 - Mine Environmental Control Supervisor",
    "2021-312101 - Pit Foreman",
    "2021-312101 - Mine Operations Foreman",
    "2021-312102 - Box Controller",
    "2021-312102 - Team Leader (Mining)",
    "2021-312102 - Shaft Bottom Attendant",
    "2021-312102 - Sinker",
    "2021-312102 - Rockbreaker",
    "2021-312102 - Hard Rock Miner",
    "2021-312102 - Coal Miner",
    "2021-312102 - Trammer",
    "2021-312102 - Shaft Timberman",
    "2021-312103 - Fitter Foreman",
    "2021-312103 - Planned Maintenace Foreman",
    "2021-312103 - Engineering Foreman",
    "2021-312103 - Mechanical Foreman",
    "2021-312103 - Electrical Foreman",
    "2021-312201 - Production Plant Supervisor",
    "2021-312201 - Shift Manager (Production)",
    "2021-312201 - Beneficiation Plant Foreman",
    "2021-312201 - Assembly Supervisor",
    "2021-312201 - Manufacturing Foreman",
    "2021-312202 - Mine Equipment Planner / Coordinator",
    "2021-312202 - Shutdown Coordinator / Planner",
    "2021-312202 - Marine Maintenance Planner",
    "2021-312202 - Engineering Planner",
    "2021-312202 - Maintenance Scheduler",
    "2021-312301 - Building Construction Supervisor",
    "2021-312301 - Clerk of Works",
    "2021-313102 - Geothermal Power Generation Technician",
    "2021-313102 - Fossil Power Plant Process Technician",
    "2021-313102 - Fossil Power Plant Process Operator",
    "2021-313102 - Methane/Landfill Gas Generation System Technicians",
    "2021-313102 - Bagase Boiler / Turbine Plant Controller",
    "2021-313102 - Biomass Plant Technicians",
    "2021-313103 - Hydro Power Plant Process Technician",
    "2021-313103 - Hydroelectric Plant Technicians",
    "2021-313103 - Hydro Power Plant Process Operator",
    "2021-313104 - Nuclear Equipment Operation Technicians",
    "2021-313104 - Nuclear Power Plant Process Operator",
    "2021-313104 - Nuclear Engineering Technician",
    "2021-313105 - Wind Turbine Service Technician",
    "2021-313105 - Wind Energy Technician",
    "2021-313105 - Wind Turbine Plant Process Technician",
    "2021-313106 - Solar Power Plant Operator",
    "2021-313106 - Concentrated Solar Power (CSP) Plant Technician",
    "2021-313201 - Industrial Water Plant Operator",
    "2021-313201 - Sewerage Plant Operator",
    "2021-313201 - Pumping-station Operator",
    "2021-313201 - Incinerator Operator",
    "2021-313201 - Water Treatment Plant Operator",
    "2021-313201 - Water Treatment Plant Technician",
    "2021-313201 - Liquid Waste Process Operator",
    "2021-313201 - Waste Water Plant Operator",
    "2021-313201 - Waterworks Plant Operator",
    "2021-313202 - Slurry Plant Operator",
    "2021-313203 - Water Works Management Practitioner",
    "2021-313203 - Industrial Water Process Controller",
    "2021-313301 - Pharmaceutical Production Controller",
    "2021-313301 - Acetylene Gas Plant Controller",
    "2021-313301 - Chemicals Distiller",
    "2021-313301 - Chemical Process Technician",
    "2021-313301 - Synthetic Fibre Control Room Operator",
    "2021-313301 - Chemical-still and Reactor Operator",
    "2021-313301 - Pharmaceutical Plant Controller",
    "2021-313301 - Chemicals Fermentation Controller",
    "2021-313301 - Pharmacy Products Machine Controller",
    "2021-313301 - Pilot Plant Controller",
    "2021-313401 - Oil and Gas Well Treatment Controller",
    "2021-313401 - Gas Compressor Turbine Controller",
    "2021-313401 - Petroleum and Gas Refining and Pumping Controller",
    "2021-313401 - Oil, Gas and Pipe Tester",
    "2021-313401 - Refinery Process Technician",
    "2021-313401 - Petroleum Blending Plant Controller",
    "2021-313401 - Petroleum Process Operator",
    "2021-313401 - Oil Dispatcher Pipelines",
    "2021-313401 - Oil Products Processor",
    "2021-313401 - Refinery Pipeline Controller",
    "2021-313401 - Industrial Gas Production Controller",
    "2021-313401 - Petroleum Terminal Plant Controller",
    "2021-313401 - Gas Plant Operator",
    "2021-313501 - Melting and Refinery Process Controller",
    "2021-313501 - Process Controller",
    "2021-313501 - Central Control Caster",
    "2021-313501 - Metal Processing Control Operator",
    "2021-313501 - Casting Process Controller",
    "2021-313501 - Blast Furnace Operator",
    "2021-313501 - Rolling Mill Control Operator",
    "2021-313501 - Metal Finishing Process Controller",
    "2021-313501 - Electrolysis Process Controller",
    "2021-313501 - Rolling Process Controller",
    "2021-313501 - material preparation",
    "2021-313901 - Automated Assembly Line Operator",
    "2021-313901 - Industrial Robot Controller",
    "2021-313901 - Packaging Manufacturing Process Control Technician",
    "2021-313901 - Furniture Manufacturing Process Control Technician",
    "2021-313901 - Printing Manufacturing Process Control Technician",
    "2021-313903 - Leather Manufacturing Process Control Technician",
    "2021-313903 - Footwear Manufacturing Process Control Technician",
    "2021-313903 - Textile Mechanician",
    "2021-313903 - Clothing Manufacturing Process Control Technician",
    "2021-313903 - Textile Manufacturing Process Control Technician",
    "2021-313903 - Footwear / Leather Quality Technician",
    "2021-313906 - Fruit Quality Manipulator (Smart Fresh)",
    "2021-313906 - De-greening Operations Controller",
    "2021-313906 - Vegetable Curing Process Controller",
    "2021-313907 - Food and Beverage Process Technician / Artisan",
    "2021-313907 - Brewhouse Operator",
    "2021-313909 - Mill Silo Operations Controller / Manager",
    "2021-313909 - Rice Miller",
    "2021-313909 - Stock Feed Miller",
    "2021-313909 - Spice Miller",
    "2021-313909 - Wheat Miller",
    "2021-313909 - Maize Miller",
    "2021-313909 - Oats Miller",
    "2021-313909 - Wet Grain Miller",
    "2021-313910 - Formulations Controller",
    "2021-313910 - Vegetable Juice Process Controller",
    "2021-313910 - Citrus Juice Process Controller",
    "2021-313910 - Deciduous Fruit Juice Process Controller",
    "2021-313911 - Grain Depot Controller",
    "2021-313912 - Mineral Beneficiation Process Controller - Smelting",
    "2021-313912 - Mineral Beneficiation Process Controller - Mineral Preparation",
    "2021-313912 - Burner",
    "2021-313912 - Mineral Beneficiation Process Controller - Calcining",
    "2021-313912 - Mineral Beneficiation Controller - Hydrometallurgy",
    "2021-313912 - Mineral Beneficiation Process Controller - Material Handling",
    "2021-313913 - Paper Pulp Refinery Operator",
    "2021-314101 - Herbarium Technician",
    "2021-314101 - Life Sciences Laboratory Technologist",
    "2021-314101 - Bacteriology Technician",
    "2021-314101 - Maxillofacial Technician",
    "2021-314101 - Microbiology Technician",
    "2021-314101 - Biomedical Technician",
    "2021-314101 - Marine Biology Technician",
    "2021-314101 - Biochemistry Technician",
    "2021-314101 - Zoology Technical Officer",
    "2021-314101 - Physiology Technologist",
    "2021-314101 - Pharmacology Technician",
    "2021-314101 - Serology Technician",
    "2021-314101 - Fisheries Technical Officer",
    "2021-314101 - Entomology Technician",
    "2021-314101 - Biological Technical Officer",
    "2021-314101 - Tissue Culture Technician",
    "2021-314101 - Botanical Technical Officer",
    "2021-314102 - Conservation Scientific Officer",
    "2021-314102 - Parks and Reserves Technical Officer",
    "2021-314102 - Naturalist",
    "2021-314102 - Environmental Technical Officer",
    "2021-314102 - Ecological Technical Officer",
    "2021-314102 - Water Pollution Control Officer",
    "2021-314201 - Field Production Officer",
    "2021-314201 - Dairy Technician",
    "2021-314201 - Animal Breeding Technician",
    "2021-314201 - Agriculture Technical Assistant",
    "2021-314201 - Agricultural Research Technician",
    "2021-314201 - Poultry Technical Officer",
    "2021-314201 - Irrigation Technician",
    "2021-314201 - Herd Tester",
    "2021-314201 - Agricultural Technical Officer",
    "2021-314201 - Agricultural Sample Collector",
    "2021-314201 - Agronomy Technician",
    "2021-314201 - Agricultural Laboratory Technician",
    "2021-314201 - Wool Testing Technician",
    "2021-314201 - Artificial Insemination Technical Officer",
    "2021-314201 - Field Crop Technical Officer",
    "2021-314201 - Seed Research Technician",
    "2021-314201 - Seed Production Technician",
    "2021-314201 - Horticultural Technical Officer",
    "2021-314201 - Agricultural Technical Advisor",
    "2021-314201 - Agriculture Technical Specialist",
    "2021-314201 - Agricultural Sampling Officer",
    "2021-314301 - Forester",
    "2021-314301 - Forest Conservation Technician",
    "2021-314302 - Forestry Technical Officer",
    "2021-314302 - Forestry Technical Assistant",
    "2021-314302 - Research Forester",
    "2021-314302 - Forestry Technical Specialist",
    "2021-314302 - Silviculture Technician",
    "2021-315101 - Electro Technical Officer",
    "2021-315201 - Jet Boat Driver / Operator",
    "2021-315201 - Hydrofoil Captain",
    "2021-315201 - Launch Master",
    "2021-315201 - Master Mariner",
    "2021-315201 - Charter Boat Operator",
    "2021-315201 - Ship's Pilot",
    "2021-315201 - Hovercraft Operator",
    "2021-315201 - Tug Master",
    "2021-315201 - Dredge Master",
    "2021-315201 - Harbour Pilot",
    "2021-315202 - Ships Mate / Officer",
    "2021-315202 - Deck Officer",
    "2021-315202 - Ship's Navigating Officer",
    "2021-315203 - Nautical Surveyor",
    "2021-315203 - Nautical Information Advisor",
    "2021-315203 - Marine Engineer Surveyor",
    "2021-315301 - Flight Navigator",
    "2021-315302 - Flight Engineer Inspector",
    "2021-315303 - Airline Captain",
    "2021-315303 - Aerial Spraying Contractor",
    "2021-315303 - Aerial Topdressing Contractor",
    "2021-315303 - Military Transport Pilot",
    "2021-315303 - Fighter Pilot",
    "2021-315303 - Aircraft Captain",
    "2021-315304 - Aeronautical Instructor",
    "2021-315304 - Ground School Instructor",
    "2021-315304 - Gliding Pilot Instructor",
    "2021-315304 - Aviation Instructor",
    "2021-315304 - Helicopter Pilot Instructor",
    "2021-315305 - Attack Helicopter Pilot",
    "2021-315305 - Chopper Pilot",
    "2021-315401 - Vessel Traffic Services",
    "2021-315401 - Aerodrome Controller",
    "2021-315401 - Air Traffic Service Operator",
    "2021-315501 - Aircraft Electronics Technical Officer",
    "2021-315501 - Air Defence Officer",
    "2021-321101 - X-Ray Operator",
    "2021-321101 - Radiographer",
    "2021-321101 - Medical Imaging Technologist",
    "2021-321101 - Medical / Diagnostic Radiation Technologist",
    "2021-321101 - Mammographer",
    "2021-321101 - X-Ray Darkroom Technician",
    "2021-321101 - Magnetic Resonance Technologist",
    "2021-321102 - Radiotherapist",
    "2021-321102 - Medical X-Ray Technician",
    "2021-321102 - Radiation Technologist / Therapist",
    "2021-321103 - Nuclear Radiographer",
    "2021-321103 - Radioisotope Technician / Technologist",
    "2021-321103 - Radiochemist",
    "2021-321104 - Cardiac Ultrasound Technician",
    "2021-321104 - Ultrasonographer",
    "2021-321106 - Cardiographer",
    "2021-321106 - Echocardiographer",
    "2021-321106 - Cardiology Technician",
    "2021-321106 - Cardiac Technologist",
    "2021-321106 - ECG Technician",
    "2021-321106 - Electrocardiograph Technician",
    "2021-321107 - Hospital Theatre Technician",
    "2021-321108 - Audiometric Technician",
    "2021-321108 - Audio Visual Technician",
    "2021-321110 - Neurosciences Research Technician",
    "2021-321118 - Orthotist Tradesman",
    "2021-321118 - Artificial Limb Maker",
    "2021-321118 - Artificial Limb Fitter Technician",
    "2021-321118 - Orthopaedic Appliance Maker / Repairer",
    "2021-321201 - Medical Research Officer",
    "2021-321201 - Medical Science Technician",
    "2021-321201 - Haematology Medical Technician",
    "2021-321201 - Immunology Medical Technician",
    "2021-321201 - Clinical Pathology Medical Technician",
    "2021-321201 - Histopathology Technician",
    "2021-321201 - Non Clinical Medical Technician",
    "2021-321201 - Microbiology Medical Technician",
    "2021-321201 - Medical Bloodbank Technician",
    "2021-321201 - Blood Transfusion Medical Technician",
    "2021-321201 - Haematology Technician",
    "2021-321201 - Phlebotomist",
    "2021-321201 - Cytotechnology Medical Technician",
    "2021-321201 - Pathology Technician",
    "2021-321201 - Medical Laboratory Technical Officer",
    "2021-321201 - Medical Laboratory Assistant",
    "2021-321201 - Clinical Microbiology Technician",
    "2021-321201 - Chemical Pathology Medical Technician",
    "2021-321201 - Bacterium Tuberculosis Medical Technician",
    "2021-321201 - Venepuncturist",
    "2021-321201 - Cytology Technician",
    "2021-321201 - Phlebotomy Medical Technician",
    "2021-321201 - Histopathological Medical Technician",
    "2021-321201 - Virology Medical Technician",
    "2021-321206 - Lung Function Medical Technologist",
    "2021-321206 - Parasitology Medical Technologist",
    "2021-321206 - Forensic Pathology Medical Technologist",
    "2021-321206 - Cytotechnology Medical Technologist",
    "2021-321206 - Haematology Medical Technologist",
    "2021-321206 - Blood Transfusion Medical Technologist",
    "2021-321206 - Cytogenetic Medical Technologist",
    "2021-321206 - Cardiology Medical Technologist",
    "2021-321206 - Microbiology Medical Technologist",
    "2021-321206 - Clinical Pathology Medical Technologist",
    "2021-321206 - Immunology Medical Technologist",
    "2021-321206 - Non Clinical Medical Technologist",
    "2021-321206 - Pharmacology Medical Technologist",
    "2021-321206 - Chemical Pathology Medical Technologist",
    "2021-321206 - Radio-isotope Medical Technologist",
    "2021-321206 - Histopathological Medical Technologist",
    "2021-321301 - Dispensary Technician",
    "2021-321301 - Pharmacist Technician",
    "2021-321301 - Pharmacy Technician - Inactive",
    "2021-321301 - Pharmacy Dispensing Technician",
    "2021-321301 - Pharmaceutical Assistant",
    "2021-321301 - Pharmaceutical Technician",
    "2021-321401 - Dental Prosthesis Maker Repairer",
    "2021-321401 - Clinical Dental Technician",
    "2021-321402 - Orthodontist Assistant",
    "2021-321402 - Dental Ceramicist",
    "2021-321402 - Orthodontic Technician",
    "2021-321402 - Dental Form Caster",
    "2021-321402 - Dental Mechanic Technician",
    "2021-321403 - Oral Health Therapist",
    "2021-321403 - Dental Health Advisor",
    "2021-321403 - Dental Attendant",
    "2021-321403 - Gum Therapist",
    "2021-321403 - Denturist",
    "2021-321403 - Dental Tutor Sister",
    "2021-321405 - Prosthetist",
    "2021-321405 - Orthotist",
    "2021-322101 - Nursing Assistant",
    "2021-322101 - Community Nurse",
    "2021-322101 - Psychiatric Assistant",
    "2021-322101 - Unregistered Nurse",
    "2021-322101 - Physiotherapy Nurse",
    "2021-322101 - Medical Assistant (Defence)",
    "2021-322102 - Lactation Consultant",
    "2021-322201 - Traditional Midwife",
    "2021-324101 - Artificial Inseminator",
    "2021-324101 - Veterinary Assistant",
    "2021-324101 - Animal Nurse",
    "2021-324101 - Veterinary Vaccinator",
    "2021-325101 - Dental Surgery Assistant",
    "2021-325101 - Dental Aide",
    "2021-325101 - Dental Nurse",
    "2021-325101 - Orthodontic Assistant",
    "2021-325101 - Dental Chairside Assistant",
    "2021-325102 - Oral Hygienist",
    "2021-325201 - Clinical Trial Data Manager",
    "2021-325201 - Medical Records Technician",
    "2021-325201 - Disease Registry Technician",
    "2021-325201 - Medical Records Analyst",
    "2021-325201 - Medical Records Clerk",
    "2021-325201 - Casemix Coordinator",
    "2021-325201 - Medical Records Administrator",
    "2021-325201 - Clinical Coder",
    "2021-325201 - Health Data Administrator",
    "2021-325201 - Health Information Clerk",
    "2021-325201 - Health Information Strategy Advisor",
    "2021-325301 - Aids Educator",
    "2021-325301 - Public Health Officer",
    "2021-325301 - Asthma Educator",
    "2021-325301 - Childbirth Educator",
    "2021-325301 - Diabetes Educator",
    "2021-325301 - Tuberculosis Educator",
    "2021-325301 - Health Education Officer",
    "2021-325301 - Health Educator",
    "2021-325301 - Community Health Worker",
    "2021-325401 - Spectacles Dispenser",
    "2021-325401 - Optical Dispenser",
    "2021-325501 - Shiatsu Therapist",
    "2021-325501 - Sports Medicine Masseur",
    "2021-325501 - Physical Rehabilitation Technician",
    "2021-325501 - Remedial Masseur",
    "2021-325501 - Physical Therapy Assistant",
    "2021-325501 - Physiotherapy Technician",
    "2021-325501 - Chinese (Tui-Na) Masseur",
    "2021-325501 - Acupressure Therapist",
    "2021-325601 - Ophthalmic Assistant",
    "2021-325601 - Clinical Assistant",
    "2021-325701 - Health Inspector",
    "2021-325701 - Sanitary Inspector",
    "2021-325701 - Sanitarian",
    "2021-325703 - Perishable Produce Export Assessor",
    "2021-325703 - Meat Safety and Quality Controller",
    "2021-325703 - Fruit and Vegetable Inspector",
    "2021-325703 - Produce Inspector",
    "2021-325703 - Dairy Product Inspector",
    "2021-325703 - Meat Inspector",
    "2021-325703 - Dairy Quality Assurance Officer",
    "2021-325705 - Works Inspector",
    "2021-325705 - Mine Risk Control Officer",
    "2021-325705 - Lift Inspector",
    "2021-325705 - Lighthouse Inspector",
    "2021-325705 - Lifting Machinery Inspector",
    "2021-325705 - Mine Radiation Officer",
    "2021-325705 - Medical Equipment Inspector",
    "2021-325705 - Safety Health Environment Quality Inspector",
    "2021-325705 - Non-destructive Testing (NDT) Inspector",
    "2021-325705 - Mine Safety Officer",
    "2021-325705 - Mine Air Quality Officer",
    "2021-325705 - Factory Inspector",
    "2021-325705 - Housing Inspector",
    "2021-325705 - Accident Prevention Consultant / Inspector",
    "2021-325705 - Bridge Inspector",
    "2021-325706 - Hazardous Devices Technician",
    "2021-325706 - Explosive Ordnance",
    "2021-325707 - Mines Inspector",
    "2021-325801 - Patient Transport Officer",
    "2021-325801 - Ambulance Man / Woman",
    "2021-325802 - Paramedic",
    "2021-325802 - Paramedic Aide",
    "2021-325902 - Spinal Health Specialist",
    "2021-325902 - Back or Spine Manipulation Specialist",
    "2021-331101 - Securities Broker",
    "2021-331101 - Fixed Interest Dealer",
    "2021-331101 - Money Market Dealer",
    "2021-331101 - Bond Dealer / Trader",
    "2021-331101 - Financial Market Dealer",
    "2021-331101 - Financial Marketer",
    "2021-331101 - Yield-X Trader",
    "2021-331101 - Money Marketer",
    "2021-331101 - Equity Dealer",
    "2021-331101 - Derivatives Dealer / Trader",
    "2021-331101 - Safex Trader",
    "2021-331101 - Foreign Exchange Dealer",
    "2021-331101 - Securities Trader",
    "2021-331105 - Strate Settlements Administrator",
    "2021-331201 - Brokerage Clerk",
    "2021-331201 - Credit Analyst / Control Officer",
    "2021-331201 - Credit Controller",
    "2021-331201 - Credit Clerk",
    "2021-331201 - Financial Accounting Plan Officer",
    "2021-331201 - Mortgage Clerk / Officer",
    "2021-331201 - Loans Consultant",
    "2021-331201 - Finance Systems Assistant",
    "2021-331201 - Loans Officer",
    "2021-331201 - Finance and Insurance Consultant",
    "2021-331201 - Budgeting and Reporting Officer",
    "2021-331201 - Finance Clerk / Officer",
    "2021-331201 - Lending Consultant",
    "2021-331201 - Insolvency Officer / Administrator",
    "2021-331201 - Treasury Officer",
    "2021-331301 - Community Scheme Administrator",
    "2021-331301 - Financial Administration Officer",
    "2021-331301 - Ledger Keeper",
    "2021-331401 - Data Quality Officer",
    "2021-331401 - Mathematical Assistant",
    "2021-331401 - Statistical Assistant",
    "2021-331401 - Actuarial Assistant",
    "2021-331401 - Decision Support Analyst",
    "2021-331501 - Commercial Valuer",
    "2021-331501 - Plant and Machinery Valuer",
    "2021-331501 - Property Valuer",
    "2021-331501 - Real Estate Valuer",
    "2021-331501 - Farm / Land Appraiser",
    "2021-331501 - Valuation Advisor / Analyst / Officer",
    "2021-331501 - Asset Protection Manager",
    "2021-331502 - Claims Manager",
    "2021-331502 - Insurance Case Manager",
    "2021-331502 - Insurance Claims Investigator",
    "2021-331503 - Insurance Loss Controller",
    "2021-331503 - Accident Claims Officer",
    "2021-331503 - Third Party Claim Assessor",
    "2021-331503 - Liability Claims Examiner",
    "2021-331503 - Examiner Pension Claims",
    "2021-331503 - Insurance Loss Assessor",
    "2021-331503 - Medical Claims Assessor",
    "2021-331503 - Insurance Claims Adjuster",
    "2021-331503 - Accident Compensation Assessor",
    "2021-331503 - Insurance Claims Assessor",
    "2021-331503 - Claims Assessor",
    "2021-331503 - Insurance Assessor",
    "2021-331504 - Insurance Risk Manager",
    "2021-332101 - Insurance Underwriter",
    "2021-332101 - Insurance Sales Consultant",
    "2021-332101 - Insurance Representative / Salesman / Advisor",
    "2021-332101 - Insurance Client Services Agent",
    "2021-332101 - Life Assurance Representative",
    "2021-332101 - Commercial Underwriter",
    "2021-332102 - Short Term Insurance Consultant",
    "2021-332201 - Canvasser",
    "2021-332201 - After-sales Service Adviser",
    "2021-332201 - Commercial Traveller",
    "2021-332202 - Solar Sales Representative",
    "2021-332202 - Heat Pump Sales Representative",
    "2021-332202 - Energy Efficiency Supplies Sales Representative",
    "2021-332203 - Sales Representative (Jewellery and Watches)",
    "2021-332203 - Sales Representative (Cosmetics and Skincare Products)",
    "2021-332203 - Sales Representative (Detergents)",
    "2021-332203 - Beauty Sales Consultant",
    "2021-332203 - Product Demonstrator",
    "2021-332205 - Manufacturers Sales Agent",
    "2021-332207 - Agricultural Chemicals Sales Representative",
    "2021-332207 - Veterinary Products Sales Representative",
    "2021-332207 - Crop Protection Product Sales Representative",
    "2021-332208 - Pharmacy Cosmetics Salesperson",
    "2021-332208 - Pharmacy Salesperson",
    "2021-332208 - Retail Dispensary / Pharmacy Assistant",
    "2021-332301 - Merchandise Planner",
    "2021-332301 - General / Company Buyer",
    "2021-332302 - Procurement Officer (Consumer Sensitive Consumables)",
    "2021-332302 - Purchasing Stores Officer",
    "2021-332302 - Purchasing Merchandiser",
    "2021-332302 - Procurement Clerk",
    "2021-332302 - Purchasing and Logistics Officer",
    "2021-332302 - Purchasing Agent / Assistant / Buyer / Clerk / Controller",
    "2021-332302 - Service and Purchase Advisor",
    "2021-332302 - Feed Ingredient Buyer",
    "2021-332302 - Procurement Administrator / Coordinator / Officer",
    "2021-332401 - Wool Broker / Buyer / Trader",
    "2021-332401 - Mineral Broker",
    "2021-332401 - Tobacco Trader / Valuator",
    "2021-332401 - Skin Buyer",
    "2021-332401 - Agricultural Produce Buyer",
    "2021-332401 - Media Buyer",
    "2021-332401 - Grain Buyer",
    "2021-332401 - Commodities Broker",
    "2021-332401 - Livestock Trader",
    "2021-332401 - Agricultural Procurer",
    "2021-332402 - Investment Banker",
    "2021-332402 - Home Loan Broker",
    "2021-332402 - Investment Broker",
    "2021-332402 - Mortgage Broker",
    "2021-332402 - Lease Broker",
    "2021-333101 - Cargo Agent",
    "2021-333101 - Clearing Agent",
    "2021-333101 - Forwarding Agent",
    "2021-333101 - Shipping Agent",
    "2021-333101 - Shipping Agent Coordinator",
    "2021-333101 - Commercial Support & Claims Coordinator (Cargo)",
    "2021-333201 - Trade Fair Manager",
    "2021-333201 - Destination Manager",
    "2021-333201 - Function Planner",
    "2021-333201 - Social/Life Cycle Coordinator",
    "2021-333201 - Wedding Planner",
    "2021-333201 - Exhibition Organiser",
    "2021-333201 - Exhibition Director",
    "2021-333201 - Marketing Campaign Organiser",
    "2021-333201 - Event Planner",
    "2021-333201 - Conference and Event Organizer",
    "2021-333201 - Functions Manager",
    "2021-333201 - Conference Planner",
    "2021-333301 - Casting Agent",
    "2021-333301 - Labour Recruitment Recruiter",
    "2021-333301 - Employment Placement Officer",
    "2021-333301 - Recruitment Officer",
    "2021-333301 - Recruitment Resourcer",
    "2021-333301 - Employment Agency Consultant",
    "2021-333301 - Employment Advisor",
    "2021-333301 - Employment Consultant",
    "2021-333301 - Recruitment Coordinator",
    "2021-333301 - Occupational Guidance Officer",
    "2021-333302 - Permanent Recruitment Officer",
    "2021-333303 - Temporary Recruitment Officer",
    "2021-333303 - Temporary Employment Services (TES) Operations Manager",
    "2021-333303 - Temporary Employment Services (TES) Site Supervisor",
    "2021-333303 - Labour Broker",
    "2021-333401 - Property Leasing Manager",
    "2021-333401 - Property Technical Officer (Ministry of Foreign Affairs and Trade)",
    "2021-333401 - Property Administrator",
    "2021-333401 - Property Business Support Manager",
    "2021-333401 - Building Manager",
    "2021-333401 - Body Corporate Manager",
    "2021-333401 - Property Clearance / Contracts / Development Manager",
    "2021-333401 - Property Manager (Services / Standards)",
    "2021-333402 - Rental Agent",
    "2021-333402 - Real Estate Consultant",
    "2021-333402 - Leasing Agent (Buildings)",
    "2021-333402 - Rental Property Consultant",
    "2021-333402 - Real Estate Referral Coordinator",
    "2021-333402 - Property Analyst / Consultant / Dealer / Developer / Planner",
    "2021-333402 - Leasing Consultant",
    "2021-333403 - Real Estate Salesperson",
    "2021-333403 - Residential Salesperson",
    "2021-333403 - Real Estate Sub-agent",
    "2021-333403 - Property Portfolio Officer",
    "2021-333403 - Commercial, Industrial or Agricultural Property Broker",
    "2021-333403 - Leasing and Letting Agent",
    "2021-333404 - Settlement Agent",
    "2021-333404 - Conveyancing Secretary",
    "2021-333405 - Real Estate Agency Licensee",
    "2021-333406 - Property Clerk",
    "2021-333902 - Literary Agent",
    "2021-333902 - Musical Performance Agent",
    "2021-333902 - Theatrical Agent",
    "2021-333902 - Model Agent",
    "2021-333902 - Sports Agent",
    "2021-333903 - Sales Representative (Printing)",
    "2021-333903 - Sales Representative (Advertising)",
    "2021-333903 - Business Services Officer",
    "2021-333903 - Advertising Sales Consultant",
    "2021-333903 - Print Cost Estimator",
    "2021-333903 - Sales Representative (Publishing)",
    "2021-333904 - Commercial Property Specialist",
    "2021-333904 - Commercial Property Broker",
    "2021-333904 - Real Estate Franchisor",
    "2021-333904 - Franchise Broker",
    "2021-333904 - Business Agent",
    "2021-333905 - Supply Chain Administrator",
    "2021-333906 - Livestock Agent / Buyer",
    "2021-333906 - Farm Stock Buyer",
    "2021-333908 - Marketing Support Coordinator",
    "2021-333908 - Communications Materials Developer",
    "2021-333909 - Ringman",
    "2021-334101 - Data Entry Supervisor",
    "2021-334101 - Filing Clerks Supervisor",
    "2021-334101 - Clerical Supervisor",
    "2021-334101 - Personnel Clerks Supervisor",
    "2021-334102 - Office Coordinator",
    "2021-334102 - Parliamentary Support Services Administrator",
    "2021-334201 - Judges Assistant",
    "2021-334201 - Legal Practice Manager",
    "2021-334201 - Judges Clerk",
    "2021-334302 - Correspondence Assistant",
    "2021-334302 - Administrative Secretary",
    "2021-334302 - Executive Assistant",
    "2021-334302 - Private Secretary",
    "2021-334401 - Health Practice Manager",
    "2021-334401 - Medical Office Administrative Assistant",
    "2021-334401 - Patient Care Secretary",
    "2021-334401 - Medical Stenographer",
    "2021-334401 - Hospital Ward Secretary",
    "2021-334401 - Medical Practice Manager",
    "2021-334401 - Medical Transcriptionist",
    "2021-334401 - Pathology Secretary",
    "2021-335101 - Valuation and Origin Industry Complaints Officers",
    "2021-335101 - Customs Liaison Officer / Prevention Officer",
    "2021-335101 - Customs Investigator",
    "2021-335101 - Customs Technical Unit Officer",
    "2021-335101 - Coastwatch Officer",
    "2021-335101 - Customs Advisory Officer / Administrator",
    "2021-335101 - Customs and Excise Officer",
    "2021-335101 - Customs Bond Officer / Dog Handler / Examiner / Operator",
    "2021-335101 - Customs Inspector",
    "2021-335101 - Customs Overseas Representative",
    "2021-335101 - Customs Ship Servicing Officer",
    "2021-335102 - Passport Checking Officer",
    "2021-335201 - Tax Investigator",
    "2021-335201 - Investigator Computer Tax Audit",
    "2021-335201 - Serious Fraud Investigator",
    "2021-335301 - Social Benefits Officer",
    "2021-335301 - Social Security Claims Officer",
    "2021-335301 - Pensions Officer",
    "2021-335401 - Driver Testing Counter Clerk",
    "2021-335401 - Driver Licensing Clerk / Officer",
    "2021-335401 - Driver Licensing Clerk Cashier",
    "2021-335402 - Export Agent / Clerk",
    "2021-335402 - Certification Clerk",
    "2021-335402 - Customs Agent / Clerk",
    "2021-335402 - Export Documentation Officer",
    "2021-335402 - Shipping Tally Clerk",
    "2021-335402 - Bond Clerk / Agent",
    "2021-335402 - Tally Clerk",
    "2021-335402 - Customs Broker",
    "2021-335402 - Wharf Tally Clerk",
    "2021-335501 - Inspector (SAPS)",
    "2021-335501 - Forensic Detective",
    "2021-335501 - Drugs Officer",
    "2021-335501 - Detective Intelligence",
    "2021-335501 - VIP Witness Protection Officer",
    "2021-335501 - Cartel Investigator",
    "2021-335501 - Plain Clothes Police Officer",
    "2021-335501 - Sergeant (SAPS)",
    "2021-335501 - Investigator",
    "2021-335501 - Detective Sergeant",
    "2021-335903 - Refugee Reception Manager",
    "2021-335903 - Refugee Reception Officer",
    "2021-335905 - Water Data collector",
    "2021-335905 - Water Inspector",
    "2021-335905 - Boring Inspector",
    "2021-335905 - Stream Control Officer",
    "2021-335911 - Quarantine and Agriculture Ports Officer",
    "2021-335911 - Quarantine Inspector",
    "2021-335911 - Microbiological Security Officer",
    "2021-335911 - Exotic Disease Investigator",
    "2021-335911 - Border Protection Officer",
    "2021-335912 - Fisheries Inspector",
    "2021-335912 - Fisheries Investigator",
    "2021-335913 - Building Site Inspector",
    "2021-335913 - Building Surveyor",
    "2021-335913 - Building Certifier",
    "2021-335913 - Construction Inspector",
    "2021-335914 - Locomotive Inspector",
    "2021-335915 - Transport Service Inspector",
    "2021-335915 - Railway Ticket Inspector",
    "2021-335915 - Bus Inspector",
    "2021-335915 - Tram Inspector",
    "2021-335915 - Railway Traffic Inspector",
    "2021-335916 - Gaming Compliance Manager",
    "2021-335916 - Casino Inspector and Gaming Control Officer",
    "2021-335916 - Gaming Board Investigator",
    "2021-335916 - Gaming License Administrator",
    "2021-341101 - Deeds Examiner",
    "2021-341101 - Conveyancing Compliance Officer",
    "2021-341101 - Conveyancing Clerk",
    "2021-341103 - Legal Assistant",
    "2021-341104 - Judge's Clerk",
    "2021-341104 - Court Clerk",
    "2021-341105 - Court Collections Officer",
    "2021-341105 - Sheriff's Officer",
    "2021-341106 - Court Escort",
    "2021-341106 - Court Usher",
    "2021-341106 - Court Attendant",
    "2021-341106 - Court Officer",
    "2021-341106 - Family / Local / High / Satellite Court Manager",
    "2021-341107 - Title Searcher",
    "2021-341107 - Litigation Support Officer",
    "2021-341107 - Inland Revenue Technical Advisor",
    "2021-341107 - Legal Annotator / Associate",
    "2021-341107 - Legal Clerk",
    "2021-341107 - Prosecutions Clerk / Support Officer",
    "2021-341108 - Trust Clerk",
    "2021-341108 - Estate Officer Public Trust",
    "2021-341109 - Commerce Investigator",
    "2021-341109 - Private Inquiry Agent",
    "2021-341109 - Commerce Investigating Officer",
    "2021-341109 - Private Detective",
    "2021-341110 - Revenue Fraud & Prosecutions Practitioner",
    "2021-341110 - Legal Analyst",
    "2021-341110 - Maritime Law State Law Advisor",
    "2021-341110 - Judges Associate",
    "2021-341110 - Environmental Law state Law advisor",
    "2021-341110 - Legal Coordinator",
    "2021-341110 - Legal Officer",
    "2021-341110 - Legal Advisor",
    "2021-341110 - Marine Law State Law Advisor",
    "2021-341110 - State Legal Advisor",
    "2021-341201 - Auxiliary Community Development Worker",
    "2021-341201 - Community Development Officer",
    "2021-341201 - Community Development Facilitator",
    "2021-341201 - Community Development Worker",
    "2021-341202 - Orthopaedic Case Coordinator",
    "2021-341202 - Serious Injury Case Manager",
    "2021-341203 - Community Service Worker",
    "2021-341203 - Crisis Intervention Worker",
    "2021-341203 - Family Service Worker",
    "2021-341203 - Life Skills Instructor",
    "2021-341203 - Welfare Support Worker",
    "2021-341203 - Women's Shelter Supervisor",
    "2021-341203 - Mental Health Worker",
    "2021-341301 - Lay Preacher",
    "2021-341301 - Nun",
    "2021-341301 - Faith Healer",
    "2021-341301 - Monk",
    "2021-342101 - Soccer Player",
    "2021-342101 - Rugby Player",
    "2021-342103 - Steeplechase Jockey",
    "2021-342202 - Linesperson",
    "2021-342202 - Referee",
    "2021-342203 - Racecourse Timekeeper",
    "2021-342203 - Handicapper (Racing)",
    "2021-342203 - Racecourse Official",
    "2021-342203 - Dog or Horse Racing Official",
    "2021-342203 - Stipendiary Steward",
    "2021-342203 - Racecourse Starter",
    "2021-342203 - Timekeeper",
    "2021-342203 - Racecourse Steward",
    "2021-342204 - Rhythmic Gymnastics Coach",
    "2021-342204 - Dressage Instructor",
    "2021-342204 - Scuba Instructor",
    "2021-342204 - Diving Coach",
    "2021-342204 - Gymnastics Coach or Instructor",
    "2021-342204 - Show Jumping Instructor",
    "2021-342204 - Cricket Coach",
    "2021-342204 - Football Coach",
    "2021-342204 - Snowboarding Instructor",
    "2021-342204 - Martial Arts Instructor",
    "2021-342204 - Diving Instructor (Open Water)",
    "2021-342204 - Callisthenics Instructor",
    "2021-342204 - Tennis Coach",
    "2021-342204 - Polo Coach",
    "2021-342204 - Swimming Coach or Instructor",
    "2021-342204 - Snorkelling Instructor",
    "2021-342204 - Skiing Instructor",
    "2021-342204 - Squash Coach",
    "2021-342204 - Equine Tutor / Riding Instructor",
    "2021-342204 - Surface Supply Breathing Apparatus (SSBA) Instructor",
    "2021-342204 - Athletic Coach",
    "2021-342204 - Water Polo Coach",
    "2021-342204 - Snowsport Instructor",
    "2021-342204 - Basketball Coach",
    "2021-342204 - Dive Master",
    "2021-342301 - Physical Activity Coordinator",
    "2021-342301 - Physical Training Instructor",
    "2021-342301 - Physical Fitness Trainer",
    "2021-342301 - Health and Fitness Instructor",
    "2021-342301 - Fitness Centre Worker",
    "2021-342301 - Aerobics Instructor",
    "2021-342301 - Gymnasium Attendant",
    "2021-342301 - Physical Education Officer",
    "2021-342301 - Personal Trainer",
    "2021-342301 - Gym Instructor / Trainer",
    "2021-342301 - Fitness Consultant / Trainer / Instructor",
    "2021-342301 - Aquarobics Instructor",
    "2021-342302 - Fishing Guide",
    "2021-342302 - Outdoor Adventure Instructor",
    "2021-342302 - Shark Swimming Guide",
    "2021-342302 - Dolphin Swimming Guide",
    "2021-342302 - Horse Trekking Guides",
    "2021-342302 - Sea Kayaking Guide",
    "2021-342302 - Adventure Tourism Operator",
    "2021-342302 - Diving Operator",
    "2021-342302 - Cycle Touring Guides",
    "2021-342302 - Mountain Guide",
    "2021-342302 - Outdoor Recreation Guide",
    "2021-342302 - Trekking Guide",
    "2021-342302 - Hiking Guide",
    "2021-342302 - Team Building Operator",
    "2021-342302 - Adventure Safari Guide",
    "2021-342302 - White-water Rafting Guide",
    "2021-342302 - Snorkelling Guide",
    "2021-342302 - Outdoor Adventure Leader",
    "2021-342302 - River Rafting Guide",
    "2021-342302 - Bungee Jump Master",
    "2021-342302 - Hunting Guide",
    "2021-342302 - Kayaking Guide",
    "2021-342302 - River Kayaking Guide",
    "2021-342302 - Horse Riding Instructor",
    "2021-343101 - Technical Photographer",
    "2021-343101 - Medical Photographer",
    "2021-343101 - Photo Finish / Post Production Photographer",
    "2021-343101 - Landscape Photographer",
    "2021-343101 - Documentary Photographer",
    "2021-343101 - Aerial Photographer",
    "2021-343101 - Fashion Photographer",
    "2021-343101 - Photographer and Image Recording Equipment Operator",
    "2021-343101 - Portrait Photographer",
    "2021-343101 - Sports Photographer",
    "2021-343101 - News Photographer",
    "2021-343101 - Photojournalist",
    "2021-343201 - Store Designer",
    "2021-343201 - Stage Scenery Designer",
    "2021-343201 - Commercial Interior Designer",
    "2021-343201 - Tenant Installation Specialist",
    "2021-343201 - Environmental Designer",
    "2021-343201 - Retail Interior Designer",
    "2021-343201 - Residential Interior Designer",
    "2021-343202 - Draper",
    "2021-343202 - Interior Consultant",
    "2021-343202 - Kitchen Design Consultant",
    "2021-343203 - Window Dresser",
    "2021-343203 - Display Decorator",
    "2021-343301 - Taxidermist",
    "2021-343401 - Commis Chef",
    "2021-343401 - Chef De Partie",
    "2021-343401 - Second Chef",
    "2021-343401 - Head Chef",
    "2021-343401 - Sous Chef",
    "2021-343401 - Pastry Chef",
    "2021-343401 - Demi Chef",
    "2021-343401 - Saucier",
    "2021-343401 - Executive Chef",
    "2021-343901 - Body Artist",
    "2021-343902 - Lighting Assistant",
    "2021-343902 - Lighting Electrician",
    "2021-343902 - Lighting Director",
    "2021-343902 - Lighting Operator",
    "2021-343902 - Lighting Technician",
    "2021-343903 - Floor Manager (Broadcasting)",
    "2021-343903 - Stage Technician",
    "2021-343903 - Stunt Coordinator",
    "2021-343903 - Theatre Technician",
    "2021-343905 - Stunt Artist",
    "2021-343905 - Stuntman",
    "2021-351101 - Computer Peripheral Equipment Operator",
    "2021-351101 - High-speed Computer Printer Operator",
    "2021-351201 - Programme Analyst Data Processing",
    "2021-351201 - Computer Engineering Assistant",
    "2021-351201 - Computer Programming Assistant",
    "2021-351201 - ICT Systems Analysis Assistant",
    "2021-351201 - Computer Help Desk Operator",
    "2021-351201 - Computer Database Assistant",
    "2021-351301 - Network Support Technician",
    "2021-351302 - GIS Assistant / Consultant / Officer /Operator / Specialist / Technician",
    "2021-351302 - Geographic Information Systems Coordinator",
    "2021-351302 - Technical Support Specialist",
    "2021-351302 - Remote Sensing Scientists and Technologists",
    "2021-351302 - Geographic Information Systems Specialist",
    "2021-351302 - Geographic Information Systems Analyst",
    "2021-351401 - Multimedia Technician",
    "2021-351401 - Webmaster",
    "2021-351401 - Website Administrator",
    "2021-351401 - Web Editor",
    "2021-351401 - Website Technician",
    "2021-352101 - Broadcasting Technician",
    "2021-352101 - Television Station Operator",
    "2021-352102 - Videographer",
    "2021-352102 - Video Tape Operator",
    "2021-352102 - Focus Puller (Film)",
    "2021-352103 - Audio Technician",
    "2021-352103 - Video and Sound Recorder",
    "2021-352103 - Audio Operator",
    "2021-352103 - Sound Effects Person",
    "2021-352103 - Re-recording Mixer",
    "2021-352103 - Dubbing Machine Operator",
    "2021-352103 - Dubbing Projectionist (Sound Mixing)",
    "2021-352103 - Sound Editor / Mixer / Recordist / Operator / Specialist",
    "2021-352104 - Television Broadcasting Sound Operator",
    "2021-352104 - Vision Controller",
    "2021-352104 - Vision Mixer",
    "2021-352104 - Television Control Room Technician",
    "2021-352105 - Radio Broadcasting Technician",
    "2021-352105 - Radio Control Room Technician",
    "2021-352106 - Production Runner (Radio and TV)",
    "2021-352201 - Engineering Technician (Telecommunications)",
    "2021-411101 - Operation Services / Support Officer",
    "2021-411101 - Clerical Assistant / Officer",
    "2021-411101 - Accident / Committee / Fingerprint Clerk",
    "2021-411101 - Social Security Administrator",
    "2021-411101 - Victims Court Assistant",
    "2021-411101 - Counter / Licensing / Veterinary / Warrants Clerk",
    "2021-411101 - Recording Clerk",
    "2021-411101 - Administration Clerk / Officer",
    "2021-411101 - Office / Field Assistant",
    "2021-411101 - Community Services Clerk",
    "2021-411101 - Administrative Assistant",
    "2021-411101 - Girl Friday",
    "2021-411101 - Buying Clerk",
    "2021-411101 - Advisory Officer Clerical",
    "2021-411101 - Survey Information Officer",
    "2021-411101 - Lab Administrator",
    "2021-411101 - Tender Evaluation Coordinator",
    "2021-411101 - Client Services Administrator",
    "2021-411101 - Planning Support Officer",
    "2021-411101 - Visa / Fingerprint Officer",
    "2021-412101 - Appointments Officer",
    "2021-412101 - Advisory Officer Cabinet Office",
    "2021-412101 - Aide De Camp",
    "2021-413101 - Verbatim Reporter",
    "2021-413101 - Typist",
    "2021-413101 - Computer Assisted Transcript Operator",
    "2021-413101 - Word Processing Clerk",
    "2021-413102 - Hansard Reporter",
    "2021-413102 - Stenocaptioner",
    "2021-413102 - Court Reporter / Taker",
    "2021-413102 - Braille Transcriber",
    "2021-413201 - Data Compiler / Enterer / Officer / Operator",
    "2021-413201 - Data Processing Operator",
    "2021-413201 - Electronic Data Processor (EDP)",
    "2021-413201 - Visual Display Unit Operator",
    "2021-413201 - Flexowriter Printer",
    "2021-413201 - Computer Clerk",
    "2021-413201 - Internal Affairs Computing Officer",
    "2021-413201 - Punched Card Machine Operator",
    "2021-413201 - Data Capturer",
    "2021-413201 - Data Specifier /Programmer / Cati Specialist",
    "2021-413201 - Data Control / Input Clerk",
    "2021-421101 - Teller",
    "2021-421101 - Settlements Clerk Banking",
    "2021-421101 - Bank Clerk / Officer",
    "2021-421102 - Administration and Superannuation Investment Officer",
    "2021-421102 - Personal Banking Consultant",
    "2021-421102 - Mortgage Banker",
    "2021-421102 - ATM Custodian",
    "2021-421102 - Bank Customer Services Clerk / Officer",
    "2021-421102 - Credit Support Officer",
    "2021-421103 - Foreign Exchange Officer / Teller",
    "2021-421105 - Post Office Clerk",
    "2021-421105 - Postal Frontline Teller",
    "2021-421105 - Postal Clerk",
    "2021-421201 - Bookmaking Clerk",
    "2021-421201 - Horse Racing Analyst",
    "2021-421202 - Croupier",
    "2021-421202 - Casino / Gaming Dealer",
    "2021-421202 - Casino Gaming Inspector",
    "2021-421202 - Gaming Table Operator",
    "2021-421202 - Casino Surveillance Operator",
    "2021-421202 - Gaming Inspector",
    "2021-421202 - Gaming Operator",
    "2021-421202 - Gaming Pit Boss",
    "2021-421203 - Tab Operator / Teller / Worker",
    "2021-421203 - Totalisator Agency Board Worker",
    "2021-421203 - Betting Operator",
    "2021-421203 - Tab Clerk",
    "2021-421204 - Penciller",
    "2021-421204 - Bagman / Bagwoman",
    "2021-421205 - Phonebet Operator",
    "2021-421207 - Lottery Seller Agent",
    "2021-421207 - Lotto Worker",
    "2021-421301 - Money Launderer / Lender",
    "2021-421301 - Micro Financier",
    "2021-421401 - District Collections Manager",
    "2021-421401 - Debt Recovery Officer",
    "2021-421401 - Collection Agent",
    "2021-421401 - Rate Rent Collector",
    "2021-421401 - Collection Officer",
    "2021-421401 - Mercantile Agent (AUS)",
    "2021-421401 - Payment Collector",
    "2021-421401 - Legal Recoveries Officer",
    "2021-421401 - Regional Manager Debt",
    "2021-421401 - Debt Management Officer",
    "2021-421401 - Repossessor",
    "2021-421401 - Repossession Agent",
    "2021-422101 - Tourist Advisor",
    "2021-422101 - Travel Reception Clerk",
    "2021-422102 - Booking Travel Clerk",
    "2021-422102 - Travel Reservation Clerk",
    "2021-422102 - Travel Consolidator",
    "2021-422102 - Travel Agent Representative",
    "2021-422102 - Travel Wholesaler",
    "2021-422102 - Inbound Tour Manager",
    "2021-422102 - Travel Arrangements Manager",
    "2021-422102 - International Travel Consultant",
    "2021-422102 - Travel Officer",
    "2021-422102 - Travel Agent",
    "2021-422102 - Domestic Travel Consultant",
    "2021-422102 - Travel Clerk",
    "2021-422201 - Inbound Contact Centre Team Leader",
    "2021-422201 - Inbound Call Centre Customer Service Representative",
    "2021-422202 - Collections Consultant (Contact Centre)",
    "2021-422202 - Call Centre Customer Service Representative (Outbound)",
    "2021-422202 - Outbound Contact Centre Team Leader",
    "2021-422202 - Call or Contact Centre Sales Agent / Consultant",
    "2021-422203 - Real Time Administrator",
    "2021-422203 - Bridge Controller",
    "2021-422203 - Real Time Analyst",
    "2021-422203 - Resource Controller",
    "2021-422203 - Real Time Monitor",
    "2021-422203 - Real Time Tracker",
    "2021-422204 - Contact Centre Workforce Scheduler",
    "2021-422204 - Contact Centre Workforce Management Planner",
    "2021-422204 - Contact Centre Shift Scheduler",
    "2021-422204 - Contact Centre Workforce Planner",
    "2021-422205 - Contact Centre Forecaster",
    "2021-422301 - Telecom Operator",
    "2021-422301 - Shift Telephonist",
    "2021-422301 - Phone Centre Operator",
    "2021-422301 - PABX Operator",
    "2021-422301 - Telephone Operator / Attendant",
    "2021-422501 - Client Support Officer",
    "2021-422501 - Enquiry Officer",
    "2021-422501 - Information Express Officer",
    "2021-422501 - Corporate / Front Office Receptionist",
    "2021-422501 - Public Foyer Attendant",
    "2021-422501 - Information Clerk / Officer / Advisor / Assistant",
    "2021-422501 - Public Counter Assistant / Clerk / Receptionist",
    "2021-422501 - Communications Assistant",
    "2021-422501 - Client Liaison Officer",
    "2021-422501 - Customer Centre Support Officer",
    "2021-422501 - Info Centre Administrator",
    "2021-422501 - Dealer Support Consultant",
    "2021-422501 - Complaints Clerk",
    "2021-422501 - Customer Services Clerk / Officer / Reception Officer",
    "2021-422501 - Helpline Advisor",
    "2021-422501 - Consumer Safety Advisor",
    "2021-422601 - Front Desk Support Officer",
    "2021-422601 - Tenancy Officer",
    "2021-422601 - Front Desk Coordinator",
    "2021-422602 - Patient Information Officer",
    "2021-422602 - Hospital Hostess",
    "2021-422701 - Telephone Researcher / Surveyor",
    "2021-422701 - Research / Survey / Poll Interviewer",
    "2021-422701 - Field Assistant / Coordinator",
    "2021-422701 - Market Research Interviewer",
    "2021-422701 - Clerical Field Officer",
    "2021-422701 - Interviewer",
    "2021-422701 - Enumerator",
    "2021-422701 - Field Worker",
    "2021-422701 - Market Research Reporter",
    "2021-422901 - Hospital Ward Clerk",
    "2021-422901 - Hospital Admitting Clerk",
    "2021-431101 - Assets Clerk / Coordinator",
    "2021-431101 - Accounting Clerk",
    "2021-431101 - Account Coordinator / Controller",
    "2021-431101 - Ledger Clerk",
    "2021-431101 - Settlement Clerk / Officer",
    "2021-431101 - Accounts Payable or Receivable Clerk",
    "2021-431101 - Audit Clerk",
    "2021-431101 - Billing Administrator / Representative / Officer",
    "2021-431101 - Debtors Clerk",
    "2021-431101 - Creditors Clerk",
    "2021-431102 - Pricing Clerk / Analyst / Estimator",
    "2021-431102 - Costing Estimator",
    "2021-431102 - Costing Clerk",
    "2021-431201 - Insurance Clerk / Officer",
    "2021-431201 - Insurance Underwriting Clerk",
    "2021-431201 - Superannuation Fund Clerk",
    "2021-431201 - Health Insurance Assessor",
    "2021-431201 - Insurance Brokering Clerk / Assistant",
    "2021-431202 - Securities Lending and Borrowing Officer",
    "2021-431202 - Script Lending Administrator",
    "2021-431202 - Share Scheme Administrator",
    "2021-431202 - Settlement Manager",
    "2021-431202 - Entitlement Officer",
    "2021-431202 - Settlement Officer",
    "2021-431202 - Client Take-on Officer",
    "2021-431202 - Accounts Opening and Maintenance Officer",
    "2021-431202 - Cash Management Officer",
    "2021-431202 - Corporate Action Officer",
    "2021-431203 - Census Mapping Monitor",
    "2021-431203 - Clerk Field Collections",
    "2021-431203 - Survey Statistician",
    "2021-431203 - Statistical Coordinator / Officer",
    "2021-431203 - Census Processing Manager",
    "2021-431203 - Statistical Field Worker",
    "2021-431203 - Data Match Officer",
    "2021-431203 - Actuarial Clerk",
    "2021-431203 - Census Clerk",
    "2021-431204 - Insurance Recovery Clerk",
    "2021-431204 - Insurance Claims Clerk",
    "2021-431204 - Claims Analyst",
    "2021-431204 - Insurance Claims Negotiator",
    "2021-431301 - Pay Clerk",
    "2021-431301 - Salaries Administrator",
    "2021-431301 - Payroll Coordinator",
    "2021-431301 - Salaries Clerk",
    "2021-431301 - Payroll Consultant",
    "2021-431301 - Wage Clerk",
    "2021-431301 - Salaries Officer",
    "2021-431301 - Payroll Assistant",
    "2021-432101 - Stores Coordinator",
    "2021-432101 - Exhibits Clerk / Officer",
    "2021-432101 - Stock Control Clerk",
    "2021-432101 - Packaging Material Stores Clerk-Perishable Produce",
    "2021-432101 - Stores Works Property Officer",
    "2021-432101 - Aisle Controller",
    "2021-432101 - Feed Additives Stores Person",
    "2021-432101 - Ships Provider",
    "2021-432101 - Storekeeping Officer",
    "2021-432101 - Lost and Found Property Clerk",
    "2021-432101 - Stores Controller",
    "2021-432101 - Stores Clerk / Officer",
    "2021-432101 - Stores Maintenance Clerk",
    "2021-432101 - Inventory Clerk / Controller / Administrator",
    "2021-432101 - Micro Nutrients Stores Persons",
    "2021-432101 - Purchasing and Inventory Officer",
    "2021-432101 - Supply Clerk / Assistant / Officer / Scheduler",
    "2021-432101 - Quartermaster Ship",
    "2021-432102 - Packed Fresh Produce Dispatch Clerk",
    "2021-432102 - Freight Clerk / Agent",
    "2021-432102 - Goods Receiving Clerk",
    "2021-432102 - Workshop Clerk",
    "2021-432102 - Carton and Label Controller",
    "2021-432102 - Distribution Officer",
    "2021-432102 - Shipping and Receiving Clerk",
    "2021-432102 - Inwards Goods Clerk",
    "2021-432102 - Receiving Clerk",
    "2021-432102 - Dispatch Clerk / Officer / Operator / Assistant / Worker",
    "2021-432103 - Mail Order Clerk",
    "2021-432103 - Customer Orders Clerk",
    "2021-432104 - Seed Dispatch Manager",
    "2021-432104 - Seed Warehouse Manager",
    "2021-432104 - Wool Storage Controller / Manager",
    "2021-432104 - Seed Receiving Manager",
    "2021-432104 - Ca / Ra Storage Manager / Controller (Perishable Produce)",
    "2021-432105 - Lamproom Gasperson",
    "2021-432105 - Lamproom Supervisor",
    "2021-432201 - Planning and Liaison Officer",
    "2021-432201 - Shift Controller",
    "2021-432201 - Production Leader",
    "2021-432201 - Printing Coordinator",
    "2021-432201 - Distribution Controller",
    "2021-432201 - Production Planning Officer",
    "2021-432201 - Seed Harvesting Leader",
    "2021-432201 - Production Recorder / Scheduler",
    "2021-432201 - Production Clerk",
    "2021-432201 - Delivery Clerk",
    "2021-432201 - Schedule Clerk",
    "2021-432201 - Material and Production Planning Clerk",
    "2021-432201 - Production Foreman",
    "2021-432201 - Parts Warehouse Clerk",
    "2021-432201 - Production Planner / Officer",
    "2021-432201 - Manufacturing Distributor",
    "2021-432201 - Fresh Produce Dispatch Controller",
    "2021-432201 - Quota Controller",
    "2021-432201 - Logistics Clerk / Assistant / Controller / Coordinator / Planner / Officer",
    "2021-432201 - Fresh Produce Receiving Coordinator",
    "2021-432301 - Railway Checker",
    "2021-432301 - Clerical Dispatcher (Transport Service)",
    "2021-432301 - Aircraft Load Controller",
    "2021-432301 - Clerical Controller (Transport Service)",
    "2021-432301 - Transport Coordinator / Dispatcher",
    "2021-432301 - Boat Dispatcher",
    "2021-432301 - Fleet Contracts Manager / Controller / Coordinator",
    "2021-432301 - Truck Dispatcher",
    "2021-432301 - Road Traffic Controller",
    "2021-432301 - Wharfinger",
    "2021-432301 - Fleet Maintainer / Supply Officer",
    "2021-432301 - Railway Traffic Dispatcher",
    "2021-441101 - Circulation Assistant",
    "2021-441101 - Repository Assistant",
    "2021-441101 - Records Coordinator / Officer",
    "2021-441101 - Heritage Collection Assistant",
    "2021-441101 - Indexing Assistant",
    "2021-441101 - Document Services Coordinator",
    "2021-441101 - Interloans Assistant",
    "2021-441101 - Library Clerk / Officer",
    "2021-441101 - Documentation Officer",
    "2021-441101 - Library Attendant",
    "2021-441101 - Reference Assistant / Officer",
    "2021-441101 - Library Shelver / Retrieval Assistant",
    "2021-441101 - Knowledge Online Coordinator",
    "2021-441101 - Archive Assistant",
    "2021-441101 - Indexer",
    "2021-441101 - Cataloguing Clerk / Officer",
    "2021-441101 - Executive Officer Library",
    "2021-441101 - Manuscripts Assistant",
    "2021-441201 - Rural Mail Contractor",
    "2021-441201 - Motorbike Courier",
    "2021-441201 - Parcel Contractor",
    "2021-441201 - Bicycle Courier",
    "2021-441202 - Mail Deliverer",
    "2021-441202 - Post Carrier",
    "2021-441202 - Mail Delivery Contractor",
    "2021-441202 - Postman / Postwoman",
    "2021-441202 - Messenger",
    "2021-441203 - Mail Centre Coordinator",
    "2021-441203 - Parcel Post Officer",
    "2021-441203 - Mail Officer",
    "2021-441203 - Post Office Mail Sorter",
    "2021-441203 - Mail Assistant",
    "2021-441203 - Postal Sorting Officer",
    "2021-441203 - Mail Processing Officer",
    "2021-441203 - Mail Dispatch Clerk",
    "2021-441203 - Mail Sorting Clerk",
    "2021-441203 - Mail Controller",
    "2021-441203 - Mailroom Assistant / Clerk",
    "2021-441203 - Mail Handler",
    "2021-441301 - Medical Record Clerk",
    "2021-441301 - Coding Data Processing Clerk",
    "2021-441302 - Permissions Clerk",
    "2021-441302 - Copyright Editor",
    "2021-441501 - File Management Officer",
    "2021-441501 - Directory Compiler",
    "2021-441501 - Information Centre Support Officer",
    "2021-441501 - Registry Office Keeper",
    "2021-441501 - Documentation Clerk / Officer",
    "2021-441501 - Clerical Retrieval Assistant",
    "2021-441501 - Index Clerk",
    "2021-441501 - Information and Records Manager / Coordinator",
    "2021-441501 - Information Services Assistant",
    "2021-441501 - Document Control Officer",
    "2021-441501 - Records Centre Advisor",
    "2021-441501 - Listing Clerk",
    "2021-441501 - Records Clerk / Coordinator",
    "2021-441502 - Office Machinist",
    "2021-441502 - Photocopier Operator / Photocopyist / Record Officer",
    "2021-441502 - Telex Operator",
    "2021-441502 - Shredder Operator / Document Shredder",
    "2021-441502 - Office Assistant Photocopying",
    "2021-441502 - Photographic Machine Operator",
    "2021-441502 - Copying Services Officer",
    "2021-441502 - Addressing Machine Operator",
    "2021-441502 - Duplicating Machinist",
    "2021-441502 - Xerox Operator",
    "2021-441601 - Overpayments Officer",
    "2021-441601 - Personnel Records Clerk",
    "2021-441601 - Human Resources Planning Clerk",
    "2021-441601 - Roster Clerk",
    "2021-441601 - Human Resources Records Clerk",
    "2021-441601 - Human Resources Systems Administrator",
    "2021-441601 - Recruiting / Recruitment Clerk",
    "2021-441601 - Inland Revenue Pay and Personnel Officer",
    "2021-441601 - Employment Office Clerk",
    "2021-441602 - Training Administrator",
    "2021-441603 - Remuneration and Benefits Clerk",
    "2021-441603 - Benefits Clerk",
    "2021-441603 - Benefits Administrator",
    "2021-441603 - Compensation and Benefits Administrator",
    "2021-441605 - Academic Faculty Officer",
    "2021-441605 - Academic Administration Clerk",
    "2021-441902 - Contracts Officer",
    "2021-441902 - Contract Coordinator",
    "2021-441903 - Strategic Support Officer",
    "2021-441903 - Course Coordinator",
    "2021-441903 - Project Programme Specialist",
    "2021-441903 - Project Coordinator",
    "2021-441903 - Assistant Secretary of The Cabinet",
    "2021-441903 - Support Officer",
    "2021-441903 - Project Controller",
    "2021-441903 - Project Corrections Operations Advisor / Coordinator",
    "2021-441903 - Administration Officer",
    "2021-441903 - Consents Officer / Clerk",
    "2021-441903 - Administrator",
    "2021-441903 - Programme / Project Coordinator",
    "2021-441903 - Project Advisor / Leader",
    "2021-441903 - Senior Officer",
    "2021-441903 - Project Planner",
    "2021-441905 - Communication Clerk / Assistant",
    "2021-511101 - Flight Purser",
    "2021-511101 - Airport Attendants",
    "2021-511101 - Cabin Steward / Attendant (Aircraft)",
    "2021-511101 - Ground Hostess",
    "2021-511101 - Crew Attendant",
    "2021-511101 - Air Hostess",
    "2021-511102 - Bus Escort",
    "2021-511102 - School Bus Warden",
    "2021-511102 - Travel Attendant Bus",
    "2021-511103 - Ships Purser",
    "2021-511103 - Ships Steward / Stewardess",
    "2021-511103 - Ships Cabin Attendant",
    "2021-511104 - Train Hostess",
    "2021-511104 - Rail Stewardess",
    "2021-511104 - Travel Attendant Train",
    "2021-511201 - Ticket Inspector (Public Transport)",
    "2021-511201 - Railway Sleeping Car Conductor",
    "2021-511201 - Cable Car Conductor",
    "2021-511201 - Bus / Train Ticket Inspector",
    "2021-511201 - Train Conductor",
    "2021-511201 - Tram Conductor",
    "2021-511301 - Museum or Gallery Attendant",
    "2021-511301 - Gallery Assistant",
    "2021-511301 - Museum Host and Tour Coordinator",
    "2021-511302 - Tour Controller",
    "2021-511302 - Regional Guide",
    "2021-511302 - Sightseeing Guide",
    "2021-511302 - Tour Director",
    "2021-511302 - Tour Coordinator",
    "2021-511302 - Inbound Tour Operator",
    "2021-511302 - Tour Leader",
    "2021-511302 - Tour Escort",
    "2021-513101 - Maitre D",
    "2021-513101 - Dining Room Attendant",
    "2021-513101 - Food and Beverage Attendant",
    "2021-513101 - Wine Steward",
    "2021-513101 - Night Club Hostess",
    "2021-513101 - Restaurant Hostess",
    "2021-513101 - Drink Waiter",
    "2021-513101 - Formal Service Waiter",
    "2021-513101 - Lounge Assistant",
    "2021-513101 - Sommelier",
    "2021-513101 - Silver Service Waiter",
    "2021-513101 - Wine Attendant",
    "2021-513102 - Cafeteria Assistant / Worker",
    "2021-513102 - Lunch Bar Assistant",
    "2021-513102 - Coffee Bar / Lounge / Shop Assistant",
    "2021-513102 - Catering Assistant",
    "2021-513102 - Fast Food Server",
    "2021-513102 - Canteen Attendant / Worker",
    "2021-513102 - Cafe Assistant",
    "2021-513102 - Fast Food Attendant",
    "2021-513102 - Cafe Counter Staff",
    "2021-513102 - Catering Counter Assistant",
    "2021-513102 - Food Counter Assistant",
    "2021-513102 - Snack Bar Attendant",
    "2021-513102 - Cafe Attendant",
    "2021-513201 - Barperson Waiter",
    "2021-513201 - Bar Steward",
    "2021-513201 - Bartender",
    "2021-513201 - Bar Operator",
    "2021-513201 - Club Steward",
    "2021-513201 - Bar Hostess / Maid",
    "2021-513202 - Coffee Maker",
    "2021-514101 - Afro Hairdresser",
    "2021-514101 - Hair Dressing Technician",
    "2021-514101 - Barber",
    "2021-514102 - Hairdresser (Braiding)",
    "2021-514102 - Hairdresser's Assistant",
    "2021-514102 - Hairdresser (Weaving)",
    "2021-514102 - Locktician",
    "2021-514102 - Dreadlock Specialist",
    "2021-514102 - Hair Colour Technician",
    "2021-514102 - Electrolysis Assistant",
    "2021-514102 - Salon Operator",
    "2021-514201 - Facial Therapist",
    "2021-514201 - Aesthetician",
    "2021-514201 - Cosmetologist",
    "2021-514201 - Medical Aesthetician",
    "2021-514201 - Skin Peeling and Dermabrasion",
    "2021-514201 - Make-up Artist",
    "2021-514201 - Permanent Make-up Artist",
    "2021-514201 - Beauty Therapist",
    "2021-514201 - Intense Pulsed Light (IPL / Laser) Skin Technician",
    "2021-514202 - Body Massage Therapist",
    "2021-514202 - Reflexologist",
    "2021-514202 - Sliming Therapist",
    "2021-514202 - Aroma Therapist",
    "2021-514202 - Intense Pulsed Light (IPL / Laser) Body Technician",
    "2021-514203 - Waxing Technician",
    "2021-514203 - Intense Pulsed Light (IPL / Laser) Hair Technician",
    "2021-514203 - Eyelash and Brow Technician",
    "2021-514203 - Electrologist",
    "2021-514204 - Manicurist",
    "2021-514204 - Pedicurist",
    "2021-514205 - Make Up Consultant",
    "2021-514205 - Stage Beautician",
    "2021-514206 - Slimming Consultant",
    "2021-514207 - Beauty Technologist",
    "2021-514207 - Health and Skin-care Therapist",
    "2021-514207 - Spa Therapist",
    "2021-514210 - Fashion Stylist",
    "2021-514210 - Shopping Consultant",
    "2021-514210 - Personal shopper",
    "2021-514210 - Image Retailer",
    "2021-514210 - Identity Coach",
    "2021-514210 - Corporate Stylist",
    "2021-514210 - Personal Stylist",
    "2021-515101 - Hotel Office Manager",
    "2021-515101 - Head / Front Office Porter (Hotel)",
    "2021-515101 - Accommodation Manager",
    "2021-515101 - Bar Manager",
    "2021-515101 - Front Office Manager (Hotel)",
    "2021-515101 - Head Housekeeper",
    "2021-515101 - Hotel Chief Steward",
    "2021-515101 - Hotel Concierge",
    "2021-515101 - Commissionaire Porter",
    "2021-515102 - Boarding School Hostel Matron",
    "2021-515102 - Dormitory Manager",
    "2021-515102 - Camp Hostel Matron",
    "2021-515102 - Prison Matron",
    "2021-515102 - Residential Institution Housekeeping Matron",
    "2021-515103 - Housekeeper (Not Private)",
    "2021-515103 - Linen Keeper",
    "2021-515104 - Cleaning Supervisor",
    "2021-515201 - Housekeeper (Private / Residential Service)",
    "2021-515201 - House Steward Private",
    "2021-515201 - Domestic Servant",
    "2021-515301 - Dormitory Warden",
    "2021-515301 - Building Superintendent",
    "2021-515301 - Maintenance Officer",
    "2021-515301 - Property Officer",
    "2021-515301 - Verger",
    "2021-515301 - Sexton",
    "2021-515301 - Hut Warden Conservation",
    "2021-515301 - Custodian",
    "2021-515301 - Camp Warden",
    "2021-515301 - Landlord / Landlady",
    "2021-515301 - Concierge (Building)",
    "2021-515301 - Janitor",
    "2021-515302 - Skating Rink Attendant",
    "2021-515302 - Swimming Pool Attendant",
    "2021-516101 - Palmist",
    "2021-516101 - Numerologist",
    "2021-516101 - Fortune Teller",
    "2021-516201 - Sex Worker",
    "2021-516201 - Prostitute",
    "2021-516201 - Valet",
    "2021-516201 - Companion",
    "2021-516301 - Funeral Practitioner",
    "2021-516301 - Undertaker",
    "2021-516401 - Animal Rescue Worker",
    "2021-516401 - Kennel Assistant",
    "2021-516401 - SPCA Worker",
    "2021-516401 - Shearer",
    "2021-516401 - Pet Groomer",
    "2021-516401 - Boarding Kennel Attendant",
    "2021-516401 - Horse Groomer",
    "2021-516401 - Pound Keeper",
    "2021-516402 - Dog Handler",
    "2021-516402 - Horse Trainer / Breaker",
    "2021-516403 - Aquarist",
    "2021-516403 - Zoo Attendant",
    "2021-516501 - Driver Education Teacher",
    "2021-516902 - Marriage Celebrant",
    "2021-521101 - Ice Cream Vendor",
    "2021-521101 - Market Stall Vendor",
    "2021-521201 - Street Food Vendor",
    "2021-521202 - Salesman Driver",
    "2021-521202 - Milk Vendor",
    "2021-521202 - Bread Contractor Vendor",
    "2021-522102 - Nail Salon Manager",
    "2021-522102 - Beauty Salon Manager",
    "2021-522102 - Hair Salon Manager",
    "2021-522201 - Sales Counter Manager",
    "2021-522201 - Sales Department Supervisor",
    "2021-522201 - Sales Promotions Officer",
    "2021-522201 - Checkout Supervisor",
    "2021-522301 - Supermarket Assistant / Worker",
    "2021-522301 - Retail Assistant",
    "2021-522301 - Swimming Pool Salesperson",
    "2021-522301 - Fast Food Assistant / Operator",
    "2021-522301 - Food Demonstrator",
    "2021-522301 - Pawnbroker",
    "2021-522301 - Clothing Sales Assistant",
    "2021-522301 - Foodmarket Worker",
    "2021-522301 - Counterhand",
    "2021-522301 - Shop Assistant",
    "2021-522301 - Perishable Sales Worker",
    "2021-522301 - Cosmetic Sales Assistant",
    "2021-522301 - Telephone Salesperson",
    "2021-522301 - Hardware Sales Assistant",
    "2021-522301 - Sales Person / Consultant",
    "2021-522302 - Motor Vehicle Salesperson",
    "2021-522302 - Tractor Salesperson",
    "2021-522302 - Licensed Motor Vehicle Dealer",
    "2021-522302 - Fleet Salesperson",
    "2021-522302 - Used Car Salesperson",
    "2021-522303 - Tyre Salesman",
    "2021-522303 - Agricultural Equipment Parts Salesperson",
    "2021-522303 - Parts Assistant Manager",
    "2021-522303 - Spare Parts Salesman",
    "2021-522303 - Sales Representative (Motor Vehicle Parts and Accessories)",
    "2021-522303 - Automotive Parts Interpreter",
    "2021-522303 - Workshop Sales Person",
    "2021-522303 - Tyre Sales Representative",
    "2021-522304 - Communications Sales Assistant",
    "2021-522304 - Mobile Phone Salesperson",
    "2021-522304 - IT Salesperson",
    "2021-522304 - Computer Retail Salesperson",
    "2021-523101 - Cash Register Operator",
    "2021-523101 - Service Station Console Operator",
    "2021-523101 - Grocery Checkout Operator",
    "2021-523102 - Driveway Cashier",
    "2021-523102 - Service Station Cashier",
    "2021-523102 - Cash Receipting Clerk",
    "2021-523102 - Toll Road Cashier",
    "2021-523102 - Cashier",
    "2021-523102 - Cash Accounting Clerk",
    "2021-523103 - Booking Clerk",
    "2021-523103 - Reservations Clerk",
    "2021-523103 - Airline Clerk",
    "2021-524101 - Modelling Instructor",
    "2021-524201 - Promotion Salesperson",
    "2021-524201 - Merchandiser",
    "2021-524301 - Door-to-door Fundraising Collector",
    "2021-524301 - Telefunder",
    "2021-524301 - Hawker",
    "2021-524301 - Fund Raiser",
    "2021-524301 - Party Plan Salesperson",
    "2021-524401 - Telemarketer",
    "2021-524401 - Customer Contact Centre Salesperson",
    "2021-524401 - Telephone Sales Person",
    "2021-524401 - Telemarketing Salesperson",
    "2021-524401 - Contact Centre Agent (Interactive and Direct Marketing)",
    "2021-524401 - Internet Salesperson",
    "2021-524501 - Driveway Attendant",
    "2021-524501 - Service Station Salesperson",
    "2021-524501 - Petroleum Transfer Technician",
    "2021-524501 - Forecourt Attendant",
    "2021-524501 - Petroleum Distribution Agent",
    "2021-524501 - Petrol Station Attendant",
    "2021-524601 - Salad Bar Attendant",
    "2021-524601 - Cafeteria Counter Attendant",
    "2021-524901 - Recycling Coordinators",
    "2021-524901 - Waste Recycler",
    "2021-524901 - Automotive Dismantler",
    "2021-524901 - Scrap Materials Buyer",
    "2021-524901 - Bottle Dealer",
    "2021-524901 - Vehicle Dismantler",
    "2021-524901 - Scrap Merchant / Metal Dealer",
    "2021-524902 - Video Library Assistant",
    "2021-524902 - Car Rental Sales Assistant",
    "2021-524902 - Rental Clerk",
    "2021-524902 - Rental Car Hirer",
    "2021-524902 - Industrial Hire Sales Assistant",
    "2021-524903 - Engineering Sales Clerk",
    "2021-524903 - Hire Coordinator",
    "2021-524903 - Lay-by Clerk",
    "2021-524903 - Internal Salesperson",
    "2021-531101 - Nursery School Attendant",
    "2021-531101 - Child Care Aide",
    "2021-531101 - Child Minder",
    "2021-531101 - Play Centre or Playground Supervisor",
    "2021-531101 - Crèche Attendant",
    "2021-531101 - Nursemaid",
    "2021-531101 - Children's Nursery Assistant / Attendant",
    "2021-531101 - Daymother",
    "2021-531102 - Family Day Carer",
    "2021-531103 - Governess",
    "2021-531103 - Baby Sitter",
    "2021-531103 - Au Pair",
    "2021-531104 - Playleader",
    "2021-531104 - Schoolchildren Attendant",
    "2021-531104 - After School Care Attendant",
    "2021-531105 - Residential Care Worker",
    "2021-531106 - Housemother",
    "2021-531106 - House Parent",
    "2021-531106 - Housemaster",
    "2021-531201 - Student Liaison Officer",
    "2021-531201 - Teachers' Assistant",
    "2021-531201 - School Services Officer",
    "2021-532202 - Home Support Worker (Aged or Disabled)",
    "2021-532202 - Personal Carer (Aged or Disabled)",
    "2021-532202 - Geriatric Care Giver",
    "2021-532202 - Carer For Aged and Persons With Disabilities",
    "2021-532202 - Personal Care Worker (Aged or Disabled)",
    "2021-532202 - Rest Home Worker",
    "2021-532902 - Hospital Porter",
    "2021-532902 - Wardsperson",
    "2021-532902 - Hospital Ward Assistant",
    "2021-532902 - Hospital Clothing Assistant",
    "2021-532902 - Hospital Attendant",
    "2021-532902 - Patient Services Assistant",
    "2021-532903 - Hospital Aide",
    "2021-532903 - Paramedical Aide",
    "2021-532903 - Assistant In Nursing",
    "2021-532903 - Nursing Attendant",
    "2021-532903 - Nurses' Aide",
    "2021-532904 - Care Giver",
    "2021-532904 - Night Attendant",
    "2021-532904 - Care Assistant",
    "2021-532904 - Doula",
    "2021-532904 - Health Assistant",
    "2021-532904 - Home Aide",
    "2021-532904 - Home-based Personal Care Worker",
    "2021-532904 - Home Nurse",
    "2021-532905 - Chiropractic Assistant",
    "2021-532905 - Diversional Therapist Assistant",
    "2021-532905 - Physiotherapist's Assistant",
    "2021-532905 - Occupational Therapist's Assistant",
    "2021-532905 - Osteopath's Assistant",
    "2021-532905 - Psychiatrist's Aide / Assistant",
    "2021-532905 - Podiatrist Assistant",
    "2021-532905 - Associate Professional (Health Therapy)",
    "2021-532905 - Audiologist Assistant",
    "2021-532906 - Holistic Pulsar",
    "2021-532906 - Therapeutic Reflexologist",
    "2021-532906 - Kinesiologist",
    "2021-532906 - Medical Herbalist",
    "2021-532906 - Herbalist (Western)",
    "2021-532906 - Iridologist",
    "2021-541101 - Forestry or Agricultural Fire Fighter",
    "2021-541101 - Fire Protection Officer Forest",
    "2021-541101 - Fire Salvage Officer",
    "2021-541101 - Fire Brigade Officer",
    "2021-541101 - Aircraft Fire Fighter",
    "2021-541101 - Rescue Fire Fighter",
    "2021-541101 - Fire Safety Officer",
    "2021-541101 - Fire Equipment Officer Forest",
    "2021-541101 - Fire Prevention Officer",
    "2021-541101 - Fire Engineer (Army)",
    "2021-541201 - Traffic Safety Coordinator / Officer",
    "2021-541201 - Traffic Coordinator",
    "2021-541201 - Police Patrol Officer",
    "2021-541201 - Constable City Traffic",
    "2021-541201 - Traffic Warden",
    "2021-541201 - Traffic Constable",
    "2021-541201 - Driver Testing Officer",
    "2021-541201 - Traffic Sergeant",
    "2021-541201 - Transportation Vehicle, Equipment and Systems Inspectors",
    "2021-541201 - Road Safety Officer",
    "2021-541201 - Traffic Enquiry Constable",
    "2021-541201 - Traffic Pointsman / Woman",
    "2021-541202 - Police Community Officer",
    "2021-541202 - Prosecutions Constable / Officer / Sergeant",
    "2021-541202 - Bomb Squad Officer",
    "2021-541202 - Constable (SAPS)",
    "2021-541202 - Mounted Police Officer",
    "2021-541202 - Neighbourhood Support Coordinator",
    "2021-541202 - Police Station Sergeant",
    "2021-541202 - Tactical Response Group Officer",
    "2021-541202 - Crime Scene Coordinator / Officer",
    "2021-541202 - Relief Sergeant",
    "2021-541202 - Gang Intelligence Officer",
    "2021-541202 - Incident Response Constable",
    "2021-541202 - Rural Sergeant",
    "2021-541202 - Motor Vehicle Crime Coordinator",
    "2021-541202 - Threat Assessment Officer",
    "2021-541202 - Police Dog Training Instructor",
    "2021-541202 - Crime Services Manager",
    "2021-541202 - Police Patrolman / Woman",
    "2021-541202 - Metro Police Officer",
    "2021-541202 - Police Surveillance Officer",
    "2021-541202 - Search and Rescue Officer",
    "2021-541202 - Services Desk Officer / Public Reception Constable",
    "2021-541202 - Police Station Control Manager",
    "2021-541202 - Law enforcement officer",
    "2021-541202 - Inquests Constable / Officer",
    "2021-541203 - Military Policeman",
    "2021-541301 - Gaoler",
    "2021-541301 - Correctional Officer",
    "2021-541301 - Custodial Officer",
    "2021-541301 - Correction Facility Worker",
    "2021-541301 - Correctional Centre Coordinator",
    "2021-541301 - Cell Block Attendant",
    "2021-541301 - Prison Guard",
    "2021-541301 - Prison Warden",
    "2021-541301 - Custodial Services Officer",
    "2021-541401 - Security Services Coordinator",
    "2021-541401 - Mobile Patrol Officer",
    "2021-541401 - Railway Patrol Officer",
    "2021-541401 - Security Guard",
    "2021-541401 - Coastguard",
    "2021-541401 - Patrolman",
    "2021-541401 - Guard",
    "2021-541401 - Security Safety and Emergency Services Manager",
    "2021-541401 - Beach Patrol Officer / Beach Guard",
    "2021-541401 - Watchman",
    "2021-541402 - Security Monitor",
    "2021-541402 - Answering Service Operator",
    "2021-541403 - Store Detective",
    "2021-541403 - End Controller",
    "2021-541403 - Shop Floor Walker",
    "2021-541404 - Body guard",
    "2021-541404 - Transit Protection driver",
    "2021-541501 - Counter-intelligence Operator",
    "2021-541501 - Intelligence Collector",
    "2021-541501 - Police Intelligence Operators",
    "2021-541501 - Defence Force Intelligence Operator (Functionary)",
    "2021-541501 - Intelligence Official",
    "2021-541501 - Vetting Officials",
    "2021-541501 - National Safety & Security Intelligence Operators",
    "2021-541501 - Intelligence Administration Clerks",
    "2021-541501 - Intelligence Non-commissioned Officers",
    "2021-541901 - Surf Patrolman",
    "2021-541902 - Industrial Paramedic",
    "2021-541902 - Mine Rescue Service Worker",
    "2021-541902 - Emergency Response Officer",
    "2021-541902 - Rope Rescue Technician",
    "2021-541905 - Bouncer",
    "2021-541906 - Security Advisor",
    "2021-542101 - Combat Operator (Surface Control Operator)",
    "2021-542101 - Combat Operator (Weapons Operator Mine Counter Measures)",
    "2021-542101 - Combat Operator (Clearance Diver)",
    "2021-542101 - Combat Operator (Telecommunication Operator Radio)",
    "2021-542101 - Combat Operator (Radar Operator Dry)",
    "2021-542101 - Combat Operator (Marine)",
    "2021-542101 - Combat Operator (Survey Recorder)",
    "2021-542101 - Combat Operator (Radar Operator Wet)",
    "2021-542101 - Combat Operator (Radar Operator Elint)",
    "2021-542101 - Combat Operator (Surface Weapons Operator)",
    "2021-542101 - Combat Operator (Telecommunication Operator Tactical)",
    "2021-542101 - Combat Operator (Protection Services)",
    "2021-542101 - Combat Operator (Telecommunication Operator)",
    "2021-542101 - Combat Operator (Engine Room Attendant)",
    "2021-542101 - Combat Operator (Mine Counter Measures Operator)",
    "2021-542101 - Combat Operator (Seaman)",
    "2021-542102 - Combat Officer (Marine)",
    "2021-542102 - Combat Officer (Subsurface Vessels)",
    "2021-542102 - Combat Officer (Surface Ships)",
    "2021-542202 - Branch Warrant Officer (Branch WO)",
    "2021-542202 - Group Warrant Officer (Gp WO)",
    "2021-542202 - Spec Forces Staff Officer Operations (SO Ops)",
    "2021-542202 - Spec Forces Warrant Officer/Sergeant Major",
    "2021-542202 - Spec Forces Specialist",
    "2021-542202 - Spec Forces Operations Officer (Ops Off)",
    "2021-542202 - Commando Warrant Officer (Comdo WO)",
    "2021-542202 - Spec Forces Course Warrant Officer (Course WO)",
    "2021-542203 - Team Leader (Tm Ldr)",
    "2021-542203 - Wing Second In Command (Wing 2iC)",
    "2021-542203 - Group Second In Command (Gp 2IC)",
    "2021-542203 - Group Commander (Gp Cmdr)",
    "2021-542203 - Branch Commander (Branch Cmdr)",
    "2021-542203 - Commando Officer Commanding (Comdo Co)",
    "2021-542203 - Wing Commander (Wing Cmdr)",
    "2021-542203 - Branch Second In Command (Branch 2iC)",
    "2021-542203 - Commando Second In Command (Comdo 2iC)",
    "2021-542301 - Infantry Officer",
    "2021-542302 - Artillery Officer",
    "2021-542303 - Air Defence Artillery Officer",
    "2021-542304 - Armour Officer",
    "2021-542305 - Engineer Officer",
    "2021-542306 - Signal Officer",
    "2021-611101 - Agronomy Farm Foreman",
    "2021-611101 - Field Crop Famer",
    "2021-611101 - Agronomy Production Supervisor",
    "2021-611102 - Field Vegetable Production Supervisor",
    "2021-611102 - Field Vegetable Farm Foreman",
    "2021-611201 - Arboriculture Production Supervisor",
    "2021-611201 - Arboriculture Farm Foreman",
    "2021-611202 - Horticultural Farm Foreman",
    "2021-611202 - Horticultural Production Supervisor",
    "2021-611301 - Ornamental Horticultural Farm Foreman",
    "2021-611301 - Ornamental Horticultural Production Supervisor",
    "2021-611302 - Landscaper",
    "2021-611302 - Parks Caretaker",
    "2021-611302 - Gardener",
    "2021-611302 - Turf Layer",
    "2021-611302 - Landscaping supervisor",
    "2021-611302 - Groundsman / Woman",
    "2021-611302 - Gardening Contractor",
    "2021-611302 - Agricultural Horticulture Worker",
    "2021-611303 - Grounds Maintainer",
    "2021-611303 - Turf Manager",
    "2021-611303 - Turf Curator",
    "2021-611303 - Turf Keeper",
    "2021-611303 - Golf Course Superintendent",
    "2021-611304 - Horticulturist",
    "2021-611304 - Nursery Manager",
    "2021-611304 - Seed Grower",
    "2021-611304 - Plant Propagator",
    "2021-611304 - Nurseryman",
    "2021-611304 - Nursery Grower",
    "2021-611306 - Arborist",
    "2021-611306 - Tree Doctor",
    "2021-611306 - Tree surgeon",
    "2021-611401 - Mixed Crop Production Supervisor",
    "2021-611401 - Mixed Crop Farm Foreman",
    "2021-612101 - Livestock Farm Foreman",
    "2021-612101 - Race Horse Transport Manager",
    "2021-612101 - Livestock Production Supervisor",
    "2021-612101 - Race Horse Stables Manager",
    "2021-612101 - Feedlot Manager",
    "2021-612101 - Pig Farmer",
    "2021-612102 - Dairy Farm Foreman",
    "2021-612102 - Dairy Production Supervisor",
    "2021-612201 - Poultry Farm Production Supervisor",
    "2021-612201 - Poultry Farm Foreman",
    "2021-612202 - Ratites Production Supervisor",
    "2021-612202 - Ostrich Farmer",
    "2021-612202 - Ratites Farm Foreman",
    "2021-612301 - Insect Production Supervisor",
    "2021-612301 - Insect Farm Foreman",
    "2021-612901 - Avian Farm Foreman",
    "2021-612901 - Avian Production Supervisor",
    "2021-612902 - Game Production Supervisor",
    "2021-612902 - Game Farm Foreman",
    "2021-613101 - Biodynamic Farmer/ Farm Foreman / Production Supervisor",
    "2021-613101 - Mixed Crop and Livestock Farm Foreman",
    "2021-613101 - Biodynamic Farmer",
    "2021-613101 - Mixed Livestock Farmer",
    "2021-613101 - Mixed Crop and Livestock Production Supervisor",
    "2021-613101 - Farmer and Grazier",
    "2021-621101 - Logger",
    "2021-621101 - Forestry Crosscutter",
    "2021-621101 - Lumberjack",
    "2021-621101 - Hardwood Feller",
    "2021-621101 - Chainsaw Operator",
    "2021-621101 - Tree Topper",
    "2021-621101 - Logging Contractor",
    "2021-621101 - Softwood Feller",
    "2021-622101 - Hatchery Foreman",
    "2021-622101 - Netting Foreman",
    "2021-622101 - Fish Production Foreman",
    "2021-622101 - Aquaculture Farm Foreman",
    "2021-622101 - Hatchery Manager (Fresh Water Fish)",
    "2021-622101 - Aquaculture Production Supervisor",
    "2021-622102 - Mariculture Production Supervisor",
    "2021-622102 - Mariculture Farm Foreman",
    "2021-622102 - Hatchery Manager (Marine Fish / Aquatic Stock)",
    "2021-622201 - Fishing Boat Skipper",
    "2021-622301 - Fishing Boat Captain",
    "2021-622301 - Fishing Launch Proprietor",
    "2021-622301 - Trawler Skipper",
    "2021-622401 - Game Tracker",
    "2021-622401 - Shooter",
    "2021-622401 - Trapper",
    "2021-631101 - Market Gardener",
    "2021-641101 - Civil Construction Worker",
    "2021-641201 - Bricklaying Stopper",
    "2021-641201 - Bricklayer (Refractory)",
    "2021-641201 - Retort Setter (Bricklaying)",
    "2021-641201 - Kiln Builder",
    "2021-641201 - Chimney Repairman",
    "2021-641201 - Chimney Builder",
    "2021-641201 - Arch Builder",
    "2021-641201 - Blocklayer",
    "2021-641201 - Bricklayer (Refractory And/Or Chemical)",
    "2021-641201 - Tuckpointer",
    "2021-641201 - Bricklayer and Plasterer",
    "2021-641201 - Bricklaying Contractor",
    "2021-641201 - Bricklaying Pointer",
    "2021-641301 - Headstone Maker",
    "2021-641301 - Construction Stonemason",
    "2021-641301 - Masonry Carver / Cutter / Setter Monumental",
    "2021-641301 - Monument Installer",
    "2021-641301 - Marble Mason",
    "2021-641301 - Masonry Fixer",
    "2021-641301 - Terrazzo Worker",
    "2021-641301 - Stone Masonry Setter",
    "2021-641301 - Monumental Mason / Stonemason",
    "2021-641401 - Concrete Worker",
    "2021-641401 - Grouting/Shotcreting Assistant",
    "2021-641403 - Civil Structures Constructor",
    "2021-641403 - Road Builder",
    "2021-641403 - Civil services contructor",
    "2021-641501 - Panel Erector",
    "2021-641501 - Building Maintenance Repairman",
    "2021-641501 - Woodworker",
    "2021-641501 - Wood Caravan Builder",
    "2021-641501 - Prefabricated Buildings Maker and Erector",
    "2021-641501 - Kitchen Maker and Installer",
    "2021-641501 - Kitchen Installer",
    "2021-641501 - Shopfitter",
    "2021-641502 - Carpenter Maintenance",
    "2021-641502 - Prop and Scenery Maker",
    "2021-641502 - Bridge Carpenter",
    "2021-641502 - Carpenter Contractor",
    "2021-641502 - Wharf Carpenter",
    "2021-641502 - Shutterhand",
    "2021-641502 - Formwork Carpenter",
    "2021-641502 - Construction Carpenter",
    "2021-641502 - Rough Carpenter",
    "2021-641502 - Mine Carpenter Rough Carpentry",
    "2021-641502 - Fixing Carpenter",
    "2021-641503 - Joinery Setter-out",
    "2021-641503 - Joinery Patternmaker",
    "2021-641503 - Door Joiner",
    "2021-641503 - Joinery Machinist",
    "2021-641503 - Ships Joiner",
    "2021-641503 - Wood Joiner",
    "2021-641902 - Construction Bracer",
    "2021-641902 - Scaffolding Inspector",
    "2021-641902 - Steeplejack",
    "2021-642101 - Roof Shingler",
    "2021-642101 - Roof Slater",
    "2021-642101 - Roofing Installer",
    "2021-642101 - Roofing Contractor",
    "2021-642101 - Covers Roof Using Natural Thatch",
    "2021-642101 - Metal Roofer",
    "2021-642101 - Roofer",
    "2021-642102 - Industrial Roof Plumber",
    "2021-642102 - Sheet Metal Patternmaker",
    "2021-642201 - Tiling Contractor",
    "2021-642201 - Tile Installer / Layer / Setter",
    "2021-642201 - Ceramic / Mosaic / Slate / Stone tiler",
    "2021-642202 - Carpet Installer",
    "2021-642202 - Flooring Contractor / Installer / Layer / Specialist",
    "2021-642202 - Parquetry Layer",
    "2021-642202 - Floor Sander",
    "2021-642202 - Carpet Layer",
    "2021-642202 - Floor Installer",
    "2021-642301 - Plasterboard Fixer / Stopper",
    "2021-642301 - Dry Wall Plasterer",
    "2021-642301 - Exterior Plasterer",
    "2021-642301 - Fibrous Plaster Wallboard Installer",
    "2021-642301 - Ceiling Fixer",
    "2021-642302 - Concrete / Cement Plasterer",
    "2021-642302 - Ornamental / Stucco Plasterer",
    "2021-642302 - Interior Plasterer",
    "2021-642302 - Plastering Contractor",
    "2021-642302 - Plasterer and tiler",
    "2021-642302 - Solid Plasterer",
    "2021-642401 - Insulating Contractor",
    "2021-642401 - Building Insulation Installer",
    "2021-642401 - Boiler and Pipe Insulation Worker",
    "2021-642401 - Insulation Installer",
    "2021-642401 - Acoustic Insulation Worker",
    "2021-642401 - Air-conditioning and Refrigeration Equipment Insulation Worker",
    "2021-642401 - Industrial Insulation Installer",
    "2021-642501 - Film Fitter",
    "2021-642501 - Glass Beveller",
    "2021-642501 - Mirror Silverer",
    "2021-642501 - Window Glass Fitter",
    "2021-642501 - Mirror Installer",
    "2021-642501 - Leadlight Glazier",
    "2021-642501 - Glass Cutter",
    "2021-642501 - Glazier Installer",
    "2021-642601 - Plumbing Contractor",
    "2021-642601 - Drain Technician",
    "2021-642601 - Sewer Pipe Layer",
    "2021-642601 - Maintenance Plumber",
    "2021-642601 - Septic Tank Installer",
    "2021-642601 - Water Plumber",
    "2021-642601 - Sanitary Plumber",
    "2021-642601 - Hot Water System Installer",
    "2021-642601 - Drainer / Drainlayer",
    "2021-642601 - Drainage Contractor",
    "2021-642602 - Solar Thermal Installer",
    "2021-642602 - Solar Photovoltaic Installer",
    "2021-642602 - Solateur",
    "2021-642603 - Gas Main and Line Fitter",
    "2021-642603 - Gas Plumber",
    "2021-642603 - Gasfitter",
    "2021-642605 - Drainage Inspector",
    "2021-642605 - Sanitary Plumbing and Water Supply Inspector",
    "2021-642605 - Gas Plumbing Inspector",
    "2021-642605 - Water Reticulation Practitioner",
    "2021-642607 - Metal Pipe Fitter",
    "2021-642701 - Air Conditioning and Refrigeration Technician",
    "2021-642701 - Air Conditioning Equipment Mechanic",
    "2021-642701 - Air Conditioning Technician",
    "2021-642701 - Mechanical Services and Air Conditioning Plumber",
    "2021-642702 - Cold Storage Maintenance Serviceman",
    "2021-642702 - Refrigeration Mechanician",
    "2021-642702 - Refrigeration Fitter",
    "2021-642702 - Electrical Mechanic",
    "2021-642702 - Refrigeration Mechanic (Industrial)",
    "2021-642702 - Air-conditioning / Cooling / Heating / Ventilation Systems Installer",
    "2021-642702 - Heating Control Mechanic",
    "2021-642702 - Refrigeration Mechanic (Commercial)",
    "2021-642702 - Air-conditioning / Cooling / Heating / Ventilation Mechanic",
    "2021-642702 - HVAC Control Technician / Fitter",
    "2021-642702 - Air-conditioning and Refrigeration Electrician",
    "2021-643101 - Structural Steel Painter",
    "2021-643101 - Painting Contractor",
    "2021-643101 - Paperhanger",
    "2021-643101 - Painter and Decorator",
    "2021-643201 - Metal Coater",
    "2021-643201 - Powder Coater",
    "2021-643201 - Spray Booth Operator",
    "2021-643201 - Rust Proofer",
    "2021-643202 - Spraypainter",
    "2021-643202 - Primer Sprayer",
    "2021-643202 - Aircraft Painter / Aircraft Spraypainter",
    "2021-643202 - Automotive / Car / Ship Spraypainter",
    "2021-643202 - Vehicle Spraypainter",
    "2021-643202 - Automotive / Boat / Car / Coach / Ship Painter",
    "2021-651101 - Foundry Moulder",
    "2021-651101 - Metal Casting Moulder",
    "2021-651101 - Metal Mould Maker",
    "2021-651101 - Metal Caster",
    "2021-651101 - Coremaker",
    "2021-651202 - Carbon Dioxide Welder",
    "2021-651202 - Welding Tradesperson",
    "2021-651202 - Solderer and Brazer",
    "2021-651202 - Plate Layer",
    "2021-651202 - Arc Welder",
    "2021-651202 - Plater-welder",
    "2021-651202 - Welding Inspector",
    "2021-651202 - Spot Welder Operator",
    "2021-651202 - Electric Arc Welder",
    "2021-651202 - Brazier",
    "2021-651202 - Aircraft Welder",
    "2021-651202 - Flame Cutter",
    "2021-651202 - Special Class Welder",
    "2021-651202 - Automotive Acetylene and Electrical Welder",
    "2021-651301 - Machine Riveter",
    "2021-651301 - Aircraft Body Maker",
    "2021-651301 - Aluminium / Stainless Steel Fabricator",
    "2021-651301 - Brassmith",
    "2021-651301 - Metal Spinner / Sheeter",
    "2021-651301 - Sheet Metal Marker / Installer",
    "2021-651301 - Tinsmith",
    "2021-651301 - Sheet Metal Plumber",
    "2021-651301 - Boilersmith",
    "2021-651301 - Coppersmith",
    "2021-651301 - Duct Erector",
    "2021-651302 - Boilermaker-welder",
    "2021-651302 - Plater-boilermaker",
    "2021-651302 - Boilermaker",
    "2021-651401 - Structural Steel Tradesperson",
    "2021-651401 - Brass Finisher",
    "2021-651401 - Metal Fabricator-welder",
    "2021-651401 - Template Maker",
    "2021-651402 - Signal Erector",
    "2021-651402 - Marker Structural Steel",
    "2021-651402 - Construction Steel Worker",
    "2021-651402 - Structural Metal Preparers",
    "2021-651403 - Reinforcing Iron Worker Construction",
    "2021-651403 - Steel Bender",
    "2021-651403 - Metal Plate Bender",
    "2021-651403 - Concrete Reinforcing Steel Placer",
    "2021-651404 - Plater",
    "2021-651404 - Metal Plater",
    "2021-651501 - Scaffolding Rigger",
    "2021-651501 - Construction Cable Layer / Puller",
    "2021-651501 - Forest / Logging Rigger",
    "2021-651501 - Winch Erector",
    "2021-651501 - Hoisting Equipment Rigger",
    "2021-651501 - Rigger Ropesman",
    "2021-651501 - Construction Rigger",
    "2021-651501 - Tower Rigger",
    "2021-651501 - Rigger (Metal Engineering)",
    "2021-651501 - Rigger Scaffolder",
    "2021-651501 - Ship Rigger",
    "2021-651501 - Theatrical Rigger",
    "2021-651502 - Ropesman",
    "2021-652101 - Tool Smith",
    "2021-652101 - Drop-hammer Worker",
    "2021-652101 - Metalsmith",
    "2021-652101 - Architectural Metal Worker",
    "2021-652101 - Hammer Smith",
    "2021-652101 - Spring Maker",
    "2021-652102 - Metal Temperer",
    "2021-652201 - Jigmaker (Metal)",
    "2021-652201 - Die Model Maker",
    "2021-652201 - Tool Jig and Die Maker",
    "2021-652201 - Jig Mounter",
    "2021-652201 - Die Caster",
    "2021-652201 - Press Toolmaker",
    "2021-652201 - Tool Die Maker",
    "2021-652201 - Die and Press Toolmaker",
    "2021-652201 - Roll, Tool and Template Maker",
    "2021-652201 - Tool and Jigmaker",
    "2021-652201 - Plastic Mould Maker",
    "2021-652201 - Die Cutter",
    "2021-652202 - Light Weapon Fitter",
    "2021-652202 - Light Armament Fitter",
    "2021-652203 - Safemaker",
    "2021-652204 - Engineering Patternmaker",
    "2021-652204 - Metal Foundry Template Maker",
    "2021-652204 - Foundry Patternmaker",
    "2021-652204 - Metal Profile Cutter",
    "2021-652205 - Plastic Die and Mouldmaker",
    "2021-652205 - Specialised Machine Builder",
    "2021-652205 - Gauge, Jig and Fixture Maker",
    "2021-652205 - Specialised Tooling Machinist",
    "2021-652205 - Casting and Die-casting Mouldmaker",
    "2021-652205 - Metal Forming Die Maker",
    "2021-652206 - Die Sinker and Engraver",
    "2021-652301 - Tooling Mechanician",
    "2021-652301 - Machine Tool Operator",
    "2021-652301 - Turner Machinist (Automotive)",
    "2021-652301 - Machine Tool Mechanician",
    "2021-652301 - Vertical Borer",
    "2021-652301 - Jig Borer",
    "2021-652301 - Fitter-machinist",
    "2021-652301 - Milling Machinist",
    "2021-652301 - Lathe Operator / Setter / Tradesperson",
    "2021-652301 - Machine Tool Setter",
    "2021-652301 - Turner (Metal)",
    "2021-652301 - Turner Machinist",
    "2021-652301 - Machinist and Fitter (Automotive)",
    "2021-652301 - Machine Setter",
    "2021-652301 - Automotive Machinist",
    "2021-652301 - Aircraft Machinist",
    "2021-652301 - CNC Machinist",
    "2021-652301 - Metal Machine Setter",
    "2021-652301 - Roll Turner",
    "2021-652301 - Machine Tool Fitter",
    "2021-652301 - Cabling technician",
    "2021-652302 - Printers Mechanic",
    "2021-652302 - Industrial Mechanician",
    "2021-652401 - Metal Finisher",
    "2021-652401 - Metal Cleaner",
    "2021-652401 - Metal Working Burnisher",
    "2021-652401 - Electropolisher",
    "2021-652402 - Knife Sharpener",
    "2021-652403 - Saw Setter",
    "2021-652403 - Saw Sharpener",
    "2021-652403 - Saw Doctor",
    "2021-652404 - Rough Grinder Metal Foundry",
    "2021-653101 - Air Conditioning Technician (Automotive)",
    "2021-653101 - Garage Mechanic",
    "2021-653101 - Auto Engineer / Mechanic",
    "2021-653101 - Automotive Mechanician",
    "2021-653101 - Automotive Fitter",
    "2021-653101 - Automotive Mechanic",
    "2021-653101 - Engine Management and Fuel Injection System Mechanic",
    "2021-653101 - Vehicle Mechanic",
    "2021-653101 - Service Mechanic",
    "2021-653101 - Patrol Officer Automobile Association",
    "2021-653101 - Mechanical Service Advisor (Vehicles)",
    "2021-653101 - Vehicle Radiator Mechanic",
    "2021-653101 - Automatic Transmission Mechanic",
    "2021-653101 - Fuel Injection Pump Mechanic",
    "2021-653101 - Motor Mechanic",
    "2021-653101 - Automotive Surveyor",
    "2021-653101 - Brake and Clutch Mechanic / Repairer / Fitter",
    "2021-653101 - Fuel Injection Mechanic",
    "2021-653103 - Motorcycle / Motorbike Technician",
    "2021-653103 - Motorcycle and Scooter Mechanic",
    "2021-653103 - Scooter Mechanic",
    "2021-653109 - Automotive Engine Fitter",
    "2021-653201 - Aircraft Service Technician",
    "2021-653201 - Aircraft Technician (Defence)",
    "2021-653201 - Aircraft Examiner / Inspector",
    "2021-653201 - Aircraft Engineering Serviceman / Tradesperson",
    "2021-653201 - Powerplant Mechanic (Aircraft)",
    "2021-653201 - Jet Engine Mechanic",
    "2021-653201 - Aircraft Mechanic",
    "2021-653201 - Aircraft Engine Fitter",
    "2021-653201 - Aircraft Maintenance Engineer (Engines)",
    "2021-653201 - Aircraft Simulator Mechanic",
    "2021-653201 - Aviation Maintenance Technician",
    "2021-653201 - Rocket Engine Component Mechanic",
    "2021-653201 - Pneudraulic Systems Mechanic (Aircraft)",
    "2021-653201 - Aircraft Engine Maintenance Technician",
    "2021-653201 - Helicopter Mechanic",
    "2021-653202 - Aircraft Structural Fitter",
    "2021-653202 - Airframe Fitter",
    "2021-653202 - Aircraft Structure Worker",
    "2021-653202 - Aircraft Composites and Structures Worker",
    "2021-653202 - Aircraft Trimmer",
    "2021-653202 - Aircraft Maintenance Engineer (Structural)",
    "2021-653202 - Aircraft Plastics and Fiberglas Worker",
    "2021-653202 - Aircraft Maintenance Technicians (Mechanical)",
    "2021-653202 - Aircraft Composite Structure Worker",
    "2021-653202 - Aircraft Restorer",
    "2021-653202 - Aircraft Structure, Surfaces, Rigging, and Systems Assemblers",
    "2021-653202 - Aircraft Composites Worker",
    "2021-653202 - Aircraft Sheetmetal Worker",
    "2021-653202 - Aircraft Maintenance Engineer (Airframes)",
    "2021-653202 - Aircraft Maintenance Engineer (Mechanical)",
    "2021-653202 - Aircraft Metal Worker",
    "2021-653202 - Airframe Mechanic",
    "2021-653202 - Aircraft Life Support Fitter",
    "2021-653301 - Stationary Motor Mechanic",
    "2021-653301 - Train Engine Repairer",
    "2021-653301 - Plant Maintenance Mechanic",
    "2021-653301 - Agricultural Machinery Mechanic",
    "2021-653301 - Mechanical Service Advisor (Equipment)",
    "2021-653301 - Construction Plant Mechanic",
    "2021-653301 - Ships Engine Room Mechanic",
    "2021-653301 - Apparel and Related Manufacturing Machine Mechanic",
    "2021-653301 - Mining Machinery Repairer",
    "2021-653301 - Leather Manufacturing Machine Mechanic",
    "2021-653301 - Footwear Manufacturing Machine Mechanic",
    "2021-653301 - Farm Machinery Repairer",
    "2021-653301 - Construction Machinery Mechanic",
    "2021-653301 - Stationary Engine Repairer",
    "2021-653301 - Marine Mechanic",
    "2021-653302 - Coin Machine Mechanic",
    "2021-653303 - Maintenance Fitter",
    "2021-653303 - Hydraulic Fitter",
    "2021-653303 - Mining Machinery Fitter",
    "2021-653303 - Mechanical Fitter (Machinery) Ships",
    "2021-653303 - Train Engine Fitter",
    "2021-653303 - Stationary Engine Fitter",
    "2021-653303 - Marine Pipe Fitter",
    "2021-653303 - Lubrication Equipment Mechanic",
    "2021-653303 - General Fitter",
    "2021-653303 - Marine Fitter",
    "2021-653303 - Machine Fitter",
    "2021-653303 - Machine Fitter (Including Hydraulics and Pneumatics)",
    "2021-653303 - Machine Fitter (Hydraulics)",
    "2021-653303 - Carriage and Wagon Fitter",
    "2021-653303 - Mechanical Fitter (Machinery) Submarines",
    "2021-653303 - Wagon Fitter",
    "2021-653305 - Garden Machinery Repairer",
    "2021-653305 - Outboard Motor Mechanic",
    "2021-653305 - Chainsaw Mechanic",
    "2021-653305 - Lawnmower Mechanic",
    "2021-653305 - Inboard / Outboard Engine Mechanic",
    "2021-653306 - Diesel Injector",
    "2021-653306 - Diesel Fuel Injection Mechanic",
    "2021-653306 - Diesel Fitter-mechanic",
    "2021-653306 - Diesel Fuel Injection Technician",
    "2021-653306 - Diesel Electrical Fitter",
    "2021-653306 - Field Service Technician (Diesel)",
    "2021-653306 - Truck Mechanic",
    "2021-653307 - Earthmoving Equipment Mechanic",
    "2021-653310 - Fluid Mechanic",
    "2021-653401 - Bicycle Technician",
    "2021-653401 - Bicycle Repairer",
    "2021-653402 - Perambulator Repairer",
    "2021-653402 - Wheelchair Repairer",
    "2021-661101 - Camera Repairer",
    "2021-661102 - Horologist",
    "2021-661201 - Musical Instrument Tuner",
    "2021-661201 - Piano Tuner",
    "2021-661301 - Silversmith",
    "2021-661301 - Automated Chain Maker",
    "2021-661301 - Jewellery Mass Production Operator",
    "2021-661301 - Surface Finisher",
    "2021-661302 - Jewellery Setter",
    "2021-661304 - Polished Diamond Sorter and Valuator",
    "2021-661304 - Rough Diamond Sorter and Evaluator",
    "2021-661501 - Glass Blower",
    "2021-661501 - Glaze Maker",
    "2021-661501 - Glassware Maker",
    "2021-661501 - Glass Finisher or Bender",
    "2021-661501 - Leadlighter",
    "2021-661501 - Glass Grinder",
    "2021-661501 - Glass Finisher",
    "2021-661502 - Slitter Optical Glass",
    "2021-661502 - Optical Glass Grinder",
    "2021-661502 - Contact Lens Technician",
    "2021-661502 - Optical Instrument Maker and Repairer",
    "2021-661502 - Optical Glass Cutter",
    "2021-661502 - Fine Optician",
    "2021-661502 - Optical Glass Polisher",
    "2021-661502 - Optical Laboratory Assistant",
    "2021-661502 - Optical Technician",
    "2021-661601 - Sign Manufacturer",
    "2021-661601 - Ticket Writer",
    "2021-661601 - Signcrafter",
    "2021-661601 - Signwriter and Painter",
    "2021-661601 - Sign Maker",
    "2021-661602 - Glass etcher",
    "2021-661602 - Hand Engraver",
    "2021-661602 - Etcher",
    "2021-661602 - Pantographer",
    "2021-661602 - Glass Embosser",
    "2021-661602 - Jewellery Hand Engraver",
    "2021-661702 - Carver (Bone / Stone / Wood)",
    "2021-661702 - Wood Carver",
    "2021-661801 - Handicraft Weaver",
    "2021-661801 - Carpets Handicraft Worker",
    "2021-661801 - Loom Threader",
    "2021-661801 - Handicraft Trims Maker",
    "2021-661801 - Handicraft Carpet Maker",
    "2021-661801 - Handicraft Footwear Maker Embroiderer",
    "2021-661801 - Textile Fibre Comber",
    "2021-661801 - Handicraft Maker Picture Framer and Frame Maker",
    "2021-661801 - Handicraft Velt Maker",
    "2021-661801 - Knitter",
    "2021-661801 - Textile Fibre Drawer",
    "2021-661801 - Thread and Yarn Spinner",
    "2021-661801 - Handicraft Braider, Crochet and Lacemaker",
    "2021-661801 - Handicraft Spinner",
    "2021-661801 - Leather Handicraft Worker",
    "2021-661801 - Textiles Handicraft Worker",
    "2021-661801 - Carpet Weaver",
    "2021-661801 - Textile Fibre Rover",
    "2021-661801 - Handicraft Knitter",
    "2021-661801 - Handicraft Sewer Leather",
    "2021-661801 - Tapestry Maker",
    "2021-661801 - Cloth Weaver",
    "2021-662101 - Electronic Pre-Press Technician (Screen)",
    "2021-662101 - Electronic Pre-Press Technician (Gravure)",
    "2021-662101 - Electronic Pre-Press Technician (Flexography)",
    "2021-662101 - Electronic Pre-Press Technician (Lithography)",
    "2021-662104 - Photo Lithographer",
    "2021-662104 - Electronic Compositor",
    "2021-662105 - Photo Gravure Cylinder Processor",
    "2021-662107 - Screen Printing Plate Mounter",
    "2021-662107 - Computer to Plate Operator",
    "2021-662107 - Screen Maker",
    "2021-662107 - Lithographic Plate Maker",
    "2021-662107 - Stereo Typer",
    "2021-662107 - Flexographic Plate Maker",
    "2021-662201 - Gravure Machine Minder",
    "2021-662201 - Continuous Stationery Machine Minder",
    "2021-662202 - Printer's Assistant",
    "2021-662202 - Small Offset Printing Machine Operator",
    "2021-662202 - Instant Printer Operator",
    "2021-662202 - Jobbing Printer",
    "2021-662203 - Silk Screen Printer",
    "2021-662203 - Screen Printer Technician",
    "2021-662203 - Screen Printing Press Operator",
    "2021-662203 - Plastic Packaging Screen Printer",
    "2021-662204 - Coldset Rotary Offset Lithography Technician",
    "2021-662204 - Monoblock Offset Machine Technician",
    "2021-662204 - Heatset Rotary Offset Lithography Technician",
    "2021-662204 - Continuous Stationery Printing Machine Technician",
    "2021-662206 - Business Forms Printer",
    "2021-662206 - Continuous Offset Machine Minder",
    "2021-662208 - Narrow-web Flexographic Printing Machine Minder",
    "2021-662208 - Label printing Machinist",
    "2021-662208 - Roll Label Machine Minder",
    "2021-662209 - Publication Gravure Printing Technician",
    "2021-662209 - Packaging Gravure Printing Technician",
    "2021-662212 - Letterpress Machine Minder",
    "2021-662212 - Letterpress Printing Machinist",
    "2021-662212 - Rotary Printing and Re-reeling Flexographic Machine Minder",
    "2021-662213 - Rotary Printing and Re-reeling Gravure Machine Minder",
    "2021-662215 - Stationery and Envelope Machine Adjuster",
    "2021-662215 - Ruling-Cutting",
    "2021-662215 - Envelope Machine Technician",
    "2021-662216 - Commercial High Volume Laser Printer",
    "2021-662216 - Commercial Ink Jet Printer",
    "2021-662216 - Wide Format Digital Printer",
    "2021-662216 - Digital Printer",
    "2021-662216 - Vinyl Printer",
    "2021-662216 - Variable Data Printer",
    "2021-662217 - Packaging Flexographic Printing Technician",
    "2021-662303 - Mechanised Book Binder",
    "2021-662303 - Mechanised Print Finisher",
    "2021-662303 - Mechanised Soft-cover Bookbinding Technician",
    "2021-662304 - Craft Book Binder",
    "2021-662306 - Guillotine Cutter",
    "2021-662307 - Folder Operator",
    "2021-662308 - Saddle Stitch Operator",
    "2021-662308 - Gang Stitch Operator",
    "2021-662309 - Perfect binding Machine Operator",
    "2021-662309 - Square Back Binding Machine Operator",
    "2021-662315 - Coater",
    "2021-662316 - Foiler",
    "2021-662317 - Roll Label Rewinder",
    "2021-671101 - Medium Voltage Switchgear Electrician",
    "2021-671101 - High Voltage Switchgear Electrician",
    "2021-671101 - Electrical Contractor",
    "2021-671101 - Construction Electrician",
    "2021-671101 - Winder Electrical Technician",
    "2021-671101 - Electrician (General)",
    "2021-671101 - Electrical Fitter",
    "2021-671101 - Electrical Wireman",
    "2021-671101 - Electrician (Engineering)",
    "2021-671102 - Electrical Inspector Construction",
    "2021-671202 - Machine Tool Millwright",
    "2021-671202 - Electromechanician",
    "2021-671202 - Millwright (Electromechanician)",
    "2021-671202 - Winder Technician",
    "2021-671202 - Ground Electromechanician",
    "2021-671203 - Mechatronics Trades Worker",
    "2021-671204 - Lift Fitter / Installer",
    "2021-671204 - Lift Service Technician",
    "2021-671204 - Elevator Fitter",
    "2021-671204 - Lift Electrician",
    "2021-671204 - Elevator Repair Person",
    "2021-671205 - Mechanical Fitter (Weapons) Submarines",
    "2021-671205 - Mechanical Fitter (Weapons) Ships",
    "2021-671205 - Battleship Weapon Fitter",
    "2021-671205 - Weapons Specialist",
    "2021-671205 - Electronic Fitter (Weapons) Submarines",
    "2021-671205 - Electronic Fitter (Weapons)",
    "2021-671205 - Armament Fitter",
    "2021-671205 - Electronic Fitter (Weapons) Ships",
    "2021-671205 - Combat Weapons Fitter",
    "2021-671206 - Domestic Appliance Mechanician",
    "2021-671207 - Heavy Coil Winder",
    "2021-671207 - Coil Winder",
    "2021-671207 - Motor Winder",
    "2021-671207 - Electrician Armature Winder",
    "2021-671208 - Auto Electrician",
    "2021-671208 - Locomotive Electrician",
    "2021-671208 - Aircraft Electrician",
    "2021-671208 - Vehicle Tracking Installer",
    "2021-671208 - Automotive Electrical Fitter",
    "2021-671208 - Autotronics Technician",
    "2021-671208 - Transport Electrician",
    "2021-671208 - Automotive Electrician",
    "2021-671208 - Ships Electrician",
    "2021-671208 - Vehicle Electrician",
    "2021-671301 - Electrical Line Worker",
    "2021-671301 - Electrical Inspector Lines",
    "2021-671301 - Electric Power Lines Faultsman / Serviceman",
    "2021-671301 - Electrical Line Mechanic (Transmission)",
    "2021-671301 - Signals and Electric Line Inspector",
    "2021-671301 - Electrical Line Mechanic (Distribution)",
    "2021-671301 - Lines Inspector",
    "2021-671301 - Linesman",
    "2021-671301 - Electric Cable Layer / Puller",
    "2021-671301 - Railway Traction Line Worker",
    "2021-671302 - Mains Layer and Jointer",
    "2021-671302 - Electric Cable Jointer",
    "2021-671302 - Technical Cable Jointer",
    "2021-672101 - Aircraft Maintenance Technicians (Avionics)",
    "2021-672101 - Aircraft Radio Mechanic",
    "2021-672101 - Radio Mechanician (Aircraft)",
    "2021-672101 - Aircraft Weaponselectromechanician",
    "2021-672101 - Aircraft Reconnaissance Electromechanician",
    "2021-672101 - Aircraft Maintenance Engineer (Instruments)",
    "2021-672101 - Aircraft Maintenance Engineer (Avionics)",
    "2021-672101 - Avionics Technician",
    "2021-672101 - Aircraft Electronician",
    "2021-672101 - Aircraft Maintenance Engineer (Electrical)",
    "2021-672101 - Aircraft Maintenance Engineer (Radio)",
    "2021-672102 - Radar Fitter and Repairer",
    "2021-672102 - Aircraft Mechanic (Radar)",
    "2021-672102 - Radar / Radiotrician (Aircraft)",
    "2021-672102 - Radar Technician",
    "2021-672102 - Radar Technician (Aircraft)",
    "2021-672102 - Electronic Fitter (Radio/Radar) Ships",
    "2021-672102 - Radartrician",
    "2021-672102 - Electronic Fitter (Radar)",
    "2021-672102 - Electronician (Radar)",
    "2021-672102 - Radar Mechanician (Aircraft)",
    "2021-672102 - Radartrician (Aircraft)",
    "2021-672103 - Photocopier Technician",
    "2021-672103 - Office Machinery Maintenance Tradesperson",
    "2021-672103 - Reprographic Machine Mechanic",
    "2021-672103 - Office Machinery Mechanic",
    "2021-672104 - Electronic Fitter (Machinery Ships)",
    "2021-672104 - Aerial Installer / Technician",
    "2021-672104 - Electronic Fitter",
    "2021-672104 - Electronic Fitter (Machinery Submarines)",
    "2021-672104 - Slot Machine Serviceman / Technician",
    "2021-672104 - Radio And television Mechanician",
    "2021-672104 - Radio and Television Fitter / Repairer",
    "2021-672104 - Domestic Radio Serviceman",
    "2021-672104 - Autoteller Mechanic",
    "2021-672104 - Domestic Radio and Television Mechanician",
    "2021-672104 - Electronic Fitter (Medical)",
    "2021-672104 - Security System Installer / Technician",
    "2021-672104 - Domestic Radio Mechanician",
    "2021-672104 - Electronic Equipment Assemblers",
    "2021-672104 - Electronic Adjuster / Fitter / Serviceman",
    "2021-672105 - Medical Instrument Repairer",
    "2021-672105 - Instrument Mechanician (Industrial Instrumentation & Process Control)",
    "2021-672105 - Scalemaker",
    "2021-672105 - Scientific Instrument Maker and Repairer",
    "2021-672105 - Instrument Maker",
    "2021-672105 - Analyser Mechanician",
    "2021-672105 - Electronic Instrument Trades Worker",
    "2021-672105 - Meteorological Instrument Maker",
    "2021-672105 - Electronic Repair Technician",
    "2021-672105 - Instrument Mechanician (Process Control Systems)",
    "2021-672105 - Surgical Instrument Maker / Repairer",
    "2021-672105 - Electronic Technician (Navy)",
    "2021-672105 - Communication Electronic Technician (Air Force)",
    "2021-672105 - Instrument Mechanician (Industrial)",
    "2021-672105 - Electronic Service Technician",
    "2021-672105 - Instrument Fitter",
    "2021-672105 - Scale Mechanic Weights and Measures",
    "2021-672105 - Industrial Instrument Maker / Repairer / Serviceman",
    "2021-672105 - Survey Instrument / Maker / Repairer / Manufacturer",
    "2021-672105 - Aircraft Instrument Mechanic",
    "2021-672105 - Instrument Mechanic",
    "2021-672105 - Instrument Mechanician (Process Control)",
    "2021-672105 - Instrument and Control Service Person",
    "2021-672107 - Electrical and Electronic Cathodic Protection Electrician",
    "2021-672107 - Railway Signal Electrician",
    "2021-672107 - Printers' Electrician",
    "2021-672107 - Electrician (Signals)",
    "2021-672107 - Electromechanical Mechanical Fitter (Medical)",
    "2021-672107 - Fire Alarm Technician",
    "2021-672107 - Electrician Rigger",
    "2021-672107 - Electrician (Power Electronics)",
    "2021-672107 - Fire Extinguisher System Technician",
    "2021-672108 - Radiotrician (Audio)",
    "2021-672108 - Electronic Fitter (Communication) Submarines",
    "2021-672108 - Radio (CMI) Radiotrician",
    "2021-672108 - Radiotrician (Communication)",
    "2021-672108 - Radio Communication Serviceman",
    "2021-672108 - Communication Radiotrician",
    "2021-672108 - Electronician (Communication)",
    "2021-672108 - Electronic Fitter (Radio)",
    "2021-672108 - Radio Communication Mechanician",
    "2021-672202 - Fibre Optics Jointer",
    "2021-672202 - Fibre Optic Cable Splicer",
    "2021-672203 - Computer Engineering Serviceperson",
    "2021-672203 - Computer Hardware Mechanic",
    "2021-672203 - Computer Equipment Fitter",
    "2021-672204 - Operator Bearer Systems (Army)",
    "2021-672204 - Telephone Linesperson and Installer",
    "2021-672204 - Telecommunications Line Serviceman",
    "2021-672204 - Electrician (Telecommunication)",
    "2021-672204 - Telecommunication Electrician",
    "2021-672204 - Telecommunications Line Installer",
    "2021-672204 - Telecom Transmission Serviceman",
    "2021-672205 - Telephone Installer",
    "2021-672205 - Communications Technician",
    "2021-672205 - Radio Communications Technician",
    "2021-672205 - Telecommunication Mechanician",
    "2021-672205 - Electronician (Communications)",
    "2021-672205 - Telephone Equipment Serviceman / Technician",
    "2021-672205 - Communications Electronician",
    "2021-672205 - Communications Installation Coordinator",
    "2021-672205 - Technical Communications Manager",
    "2021-672205 - Telecommunications Technician Quality Controller",
    "2021-672205 - Telephone Technician",
    "2021-672206 - Radio Operator",
    "2021-672206 - Radio Officer Ship",
    "2021-672206 - Communications Instrument Officer",
    "2021-672206 - Operator Specialist Communications (Army)",
    "2021-672206 - Communication Information Systems Sailor (Navy)",
    "2021-672206 - Telecommunication Equipment Operator",
    "2021-672206 - Radio Telephone (RT) Operator",
    "2021-672206 - Communication and Information Systems Controller (Air Force)",
    "2021-681101 - Sheep Slaughterer",
    "2021-681101 - Pork Slaughterer",
    "2021-681101 - Game Slaughterer",
    "2021-681101 - Cattle Slaughterer",
    "2021-681101 - Equine Slaughterer",
    "2021-681101 - Halaal Slaughter",
    "2021-681101 - Crocodile Slaughterer",
    "2021-681102 - Abattoir Process Worker",
    "2021-681102 - Meat Cutter / Trimmer",
    "2021-681103 - Biltong Maker",
    "2021-681103 - Sausage Maker",
    "2021-681103 - Processed Meat Maker",
    "2021-681103 - Smallgoods Maker",
    "2021-681103 - Sosati Maker",
    "2021-681104 - Fish Preserver",
    "2021-681104 - Fish Boner / Cutter",
    "2021-681104 - Mussel Marinator",
    "2021-681104 - Mussel Opener",
    "2021-681104 - Fish Dehydrator",
    "2021-681104 - Oyster Opener / Canner",
    "2021-681104 - Fish Filleter",
    "2021-681104 - Abalone Sheller",
    "2021-681105 - Poultry Spotter",
    "2021-681105 - Poultry Boner",
    "2021-681105 - Chicken Processor",
    "2021-681105 - Poultry Worker Abattoir Freezing Works",
    "2021-681105 - Poultry Processing Worker",
    "2021-681105 - Poultry Factory Worker",
    "2021-681201 - Dough Maker",
    "2021-681201 - Cake / Bread Baker",
    "2021-681202 - Cake Decorator",
    "2021-681202 - Biscuit Baker",
    "2021-681203 - Chocolatier",
    "2021-681301 - Cottage Cheese Maker",
    "2021-681301 - Milk Producer",
    "2021-681301 - Yoghurt Maker",
    "2021-681301 - Cheese Maker",
    "2021-681301 - Butter Maker",
    "2021-681401 - Chutney Maker",
    "2021-681401 - Dried Fruit Maker",
    "2021-681401 - Fruit / Vegetable Pickler",
    "2021-681505 - Picker Grader",
    "2021-681506 - Meat Grader",
    "2021-681506 - Meat Classifier",
    "2021-681506 - Livestock Product Assessor",
    "2021-681506 - Meat Examiner",
    "2021-681603 - Cigar Production Machine Operator",
    "2021-681603 - Cigarette Production Machine Operator",
    "2021-681603 - Tobacco Products Manufacturing Equipment Operator",
    "2021-681603 - Tobacco Dryer Operator",
    "2021-682101 - Log Chipping Machine Operator",
    "2021-682101 - Wood Treating Dipper",
    "2021-682101 - Timber Treatment Worker",
    "2021-682101 - Wood Seasoner",
    "2021-682101 - Dry Kiln Operator",
    "2021-682101 - Lumber Kiln Operator",
    "2021-682101 - Log Preparer",
    "2021-682101 - Debarker Operator",
    "2021-682101 - Timber Treatment Plant Operator",
    "2021-682101 - Bark Stripper",
    "2021-682102 - Veneer Dryer Tender",
    "2021-682102 - Veneer Machine Operator",
    "2021-682102 - Veneerer",
    "2021-682102 - Veneer Grader",
    "2021-682201 - Furniture Maker Wood",
    "2021-682201 - Wood Bender",
    "2021-682201 - Wheelwright",
    "2021-682201 - Antique Furniture Restorer",
    "2021-682201 - Antique Furniture Reproducer",
    "2021-682201 - Frame Maker (Including Chairmaking)",
    "2021-682201 - Coffin Maker",
    "2021-682201 - Cabinetmaker (Including Chairmaking and Framemaking)",
    "2021-682201 - Cabinetmaker (Including Veneering)",
    "2021-682201 - Chair and Couch Maker",
    "2021-682301 - Furniture Production Machine Operator",
    "2021-682301 - Furniture Polisher",
    "2021-682301 - Furniture Restorer",
    "2021-682301 - French Polisher",
    "2021-682302 - Frame Maker (Pictures)",
    "2021-682302 - Framer (Picture)",
    "2021-682303 - Panel Saw Operator",
    "2021-682303 - Tape Edge Machinist",
    "2021-682303 - Carving Machine Operator (Wood)",
    "2021-682303 - Woodworking Machine Set-up Operator",
    "2021-682303 - Edge Bander Operator",
    "2021-682303 - Jigmaker (Wood)",
    "2021-682303 - Copy Lathe Operator",
    "2021-682303 - Woodworking Machine Setter",
    "2021-682303 - Wood Products Machine Operator",
    "2021-682303 - Furniture Machinist",
    "2021-682303 - Automatic Profile Sander Operator",
    "2021-682303 - CNC Machinist (Wood)",
    "2021-682304 - Wood Lathe Turning Operator",
    "2021-682304 - Precision Woodworking Sawyer",
    "2021-682304 - Woodworking Lathe Operator",
    "2021-683101 - Garment Maker",
    "2021-683101 - Costume Maker",
    "2021-683101 - Wardrobe Coordinator",
    "2021-683101 - Seamstress",
    "2021-683101 - Wardrobe Assistant",
    "2021-683101 - Dressmaker",
    "2021-683101 - Tailors Trimmer",
    "2021-683102 - Fur Grader",
    "2021-683102 - Fur Matcher",
    "2021-683102 - Fur Tailor",
    "2021-683102 - Fur Cutter",
    "2021-683103 - Milliner",
    "2021-683103 - Hat Blocker",
    "2021-683103 - Hatter",
    "2021-683103 - Hat Finisher",
    "2021-683103 - Hat Trimmer",
    "2021-683201 - Clothing / Garment Cutter",
    "2021-683201 - Fabric Cutter",
    "2021-683201 - Computer Aided Garment, Footwear and General Goods Cutter",
    "2021-683201 - Textile Products Marker and Cutter",
    "2021-683201 - Garment Pattern Cutter",
    "2021-683201 - Leather Cutter",
    "2021-683202 - Clothing Pattern Maker",
    "2021-683202 - Footwear Last Maker",
    "2021-683202 - General Goods Pattern Maker",
    "2021-683202 - Footwear Pattern Maker",
    "2021-683301 - Awning Maker",
    "2021-683301 - Tarpaulin Maker",
    "2021-683301 - Blind Maker and Installer Not Venetian",
    "2021-683303 - Textile Produce Mender",
    "2021-683303 - Hand Embroiderer",
    "2021-683401 - Upholsterer and Sail Maker",
    "2021-683401 - Mattress Maker",
    "2021-683401 - Furniture Upholsterer",
    "2021-683503 - Hide / Skin Grader",
    "2021-683503 - Hide / Skin / Pelt Classer",
    "2021-683601 - Medical Grade Shoemaker",
    "2021-683601 - Shoe / Boot Repairer",
    "2021-683601 - Handlacer",
    "2021-683601 - Footwear Repairer",
    "2021-683601 - Cobbler",
    "2021-684101 - Abalone Diver",
    "2021-684101 - Fisheries Diver",
    "2021-684101 - Pearl Diver",
    "2021-684101 - Hyperbaric Welder Diver",
    "2021-684101 - Mining Diver",
    "2021-684101 - Underwater Worker",
    "2021-684101 - Scientific Diver",
    "2021-684101 - Offshore Diver",
    "2021-684101 - Underwater Salvage Worker",
    "2021-684101 - Clearance Diver",
    "2021-684101 - Onshore Diver",
    "2021-684101 - Saturation Diver",
    "2021-684201 - Seismograph Shooter",
    "2021-684201 - Blasting Assistant",
    "2021-684201 - Underground Hardrock Blaster",
    "2021-684201 - Surface Blaster",
    "2021-684201 - Mining/Quarrying Blaster",
    "2021-684201 - Underground Coal Blaster",
    "2021-684301 - Crop Quality Inspector",
    "2021-684301 - Seed Sampler",
    "2021-684301 - Grain Grader",
    "2021-684301 - Seed Lot Inspector",
    "2021-684401 - Chemical Sprayer",
    "2021-684401 - Seed Fumigator",
    "2021-684401 - Commodities Fumigator",
    "2021-684401 - Tea Fumigator",
    "2021-684401 - Marine Vessel Fumigator",
    "2021-684401 - Knapsack Spray Operator",
    "2021-684401 - Fumigator",
    "2021-684901 - Footwear Machine Mechanic / Fitter",
    "2021-684901 - Tufting Mechanician",
    "2021-684901 - Knitting Mechanician: Weft",
    "2021-684901 - Spinning Mechanician: Semi Worsted - Woollen System - Blending, Carding and Drawing",
    "2021-684901 - Sspinning Mechanician: Short Staple - Preparation - Drawframe and Speedframe",
    "2021-684901 - Spinning Mechanician: Woollen System - Ringframe, Dreft and Twisting",
    "2021-684901 - Weaving Mechanician - Shuttle Loom",
    "2021-684901 - Spinning Mechanician: Short Staple - Combing and Combing Preparation",
    "2021-684901 - Gripper Loom Mechanician",
    "2021-684901 - Spinning Mechanician: Semi Worsted - Preparation",
    "2021-684901 - Sewing Machine Mechanic",
    "2021-684901 - Spray Gun Mechanic (Leather)",
    "2021-684901 - Spinning Mechanician: Woollen System - Ringframe and Winding",
    "2021-684901 - Spinning Mechanician: Short Staple - Blowroom and Cards",
    "2021-684901 - Spinning Mechanician: Short Staple - Rotor Spinning",
    "2021-684901 - Spinning Mechanician: Short Staple - Winding",
    "2021-684901 - Knitting Mechanician: Warp",
    "2021-684901 - Weaving Mechanician - Narrow Loom",
    "2021-684901 - Weaving Preparation - Technical Controller",
    "2021-684901 - Spinning Mechanician: Long Staple",
    "2021-684901 - Spinning Mechanician: Semi Worsted - Finishing",
    "2021-684901 - Spinning Mechanician: Short Staple - Ringframe, Ringdoubling and 2 for 1 Uptwister",
    "2021-684901 - Spinning Mechanician: Semi Worsted - Winding and Twisting",
    "2021-684901 - Weaving Mechanician - Projectile Loom",
    "2021-684901 - Needle Punch Mechanician",
    "2021-684901 - Spinning Mechanician: Woollen System - Ringframe, Open End and Twisting",
    "2021-684901 - Weaving Mechanician - Rapier Loom",
    "2021-684901 - Technical Dyer - Finisher",
    "2021-684901 - Spinning Mechanician: Semi Worsted - Roving and Spinning",
    "2021-684901 - Spinning Mechanician: Short Staple - Open End",
    "2021-684901 - Weaving Mechanician - Airjet Loom",
    "2021-684901 - Technical Printer - Finisher",
    "2021-684904 - Panelbeater and Trimmer",
    "2021-684904 - Automotive Body Repairer",
    "2021-684904 - Automotive Trimmer",
    "2021-684904 - Auto Body Repairer",
    "2021-684904 - Rust Repairer",
    "2021-684904 - Vehicle Body Repairman",
    "2021-684904 - Vehicle Refinisher",
    "2021-684904 - Automotive Sheetmetal Worker",
    "2021-684904 - Chassis Straightener",
    "2021-684904 - Panel Beater and Spraypainter",
    "2021-684905 - Motor Body Builder",
    "2021-684905 - Bus Coach Builder",
    "2021-684905 - Metal Coach and Carriage Builder",
    "2021-684905 - Vehicle Builder",
    "2021-684905 - Auto Body Assembler",
    "2021-684905 - Truck Builder",
    "2021-684905 - Motor Vehicle Body Builder",
    "2021-684905 - Coach Builder",
    "2021-684905 - Vehicle Body Builder (Metal)",
    "2021-684905 - Vehicle Body Builder (Composite)",
    "2021-684905 - Metal Caravan Builder",
    "2021-684906 - Car Trimmer / Upholsterer",
    "2021-684906 - Auto Trimmer / Upholsterer",
    "2021-684906 - Automotive Component Upholsterer",
    "2021-684906 - Motor Trimmer / Upholsterer",
    "2021-684906 - Vehicle Trimmer / Upholsterer",
    "2021-684907 - Yacht Builder",
    "2021-684907 - Marine Plumber",
    "2021-684907 - Ship Plater",
    "2021-684907 - Sparmaker",
    "2021-684907 - Boat Assembler",
    "2021-684907 - Ship Builder",
    "2021-684907 - Boat Builder",
    "2021-684908 - Metal Liner-off",
    "2021-684908 - Loftsman (Marine)",
    "2021-684908 - Ship's Carpenter",
    "2021-684908 - Loftswoman (Marine)",
    "2021-684909 - Parachute Rigger",
    "2021-684909 - Aircraft Survival Equipment Fitter",
    "2021-684909 - Maritime Survival Equipment Fitter",
    "2021-684911 - Floral Artist",
    "2021-684914 - Weaving Machine Mechanic",
    "2021-684914 - Yarn Preparation Machine Mechanic",
    "2021-684914 - Knitting Machine Mechanic",
    "2021-684914 - Yarn Manufacturing Machine Mechanic",
    "2021-684914 - Braiding Machine Mechanic",
    "2021-684914 - Non-Woven Textile Manufacturing Machine Mechanic",
    "2021-711101 - Continuous Miner Operator",
    "2021-711101 - Crawler Pilot",
    "2021-711101 - Mining Plant Operator",
    "2021-711101 - Hydraulic Rock Breaker Operator (Mining)",
    "2021-711101 - Roof Bolter (Mining)",
    "2021-711101 - Shuttle Car Operator (Mining)",
    "2021-711101 - Coal Cutter",
    "2021-711201 - Plant Monitor",
    "2021-711201 - Mineral Plant Operator",
    "2021-711201 - Milling Plant Operator",
    "2021-711201 - Machine Operator (Stone Cutting or Processing)",
    "2021-711201 - Marble Cutter / Polisher / Processor / Sawyer / Splitter",
    "2021-711201 - Flotation Worker (Mineral Processing)",
    "2021-711201 - Coal Washer",
    "2021-711201 - Conveyor Belt Operator (Mining)",
    "2021-711201 - Lime Miller",
    "2021-711201 - Metallurgical Plant Operator",
    "2021-711201 - Cement Despatch Operator",
    "2021-711201 - Senior Process Operator",
    "2021-711201 - Crushing Plant Operator",
    "2021-711201 - Pumping Plant Operator",
    "2021-711201 - Screening Plant Operator",
    "2021-711201 - Smelting Plant Operator",
    "2021-711201 - Plant Operator",
    "2021-711201 - Recovery Plant Operator",
    "2021-711201 - Chemical Control Plant Operator",
    "2021-711201 - Leaching Plant Operator",
    "2021-711201 - Cement Grinding Mill Operator",
    "2021-711201 - Process Operator",
    "2021-711201 - Crusher Operator",
    "2021-711201 - Calcining Plant Operator",
    "2021-711201 - Separation Plant Operator",
    "2021-711201 - Cement Kiln Operator",
    "2021-711201 - Mineral Ore Treatment Plant Operator",
    "2021-711201 - Stone Turner Lathe",
    "2021-711201 - Converting Plant Operator",
    "2021-711201 - Electrometallurgical Plant Operator",
    "2021-711201 - Cement Crusher Operator",
    "2021-711201 - Stone Cutter / Polisher / Processor / Sawyer / Splitter",
    "2021-711201 - Emery Press Operator",
    "2021-711201 - Milling Machine Operator (Minerals)",
    "2021-711201 - Gold Precipitator",
    "2021-711201 - Material Handling Plant Operator",
    "2021-711201 - Conveying Plant Operator",
    "2021-711201 - Tailings Plant Operator",
    "2021-711201 - Flotation Plant Operator",
    "2021-711201 - Thickening Plant Operator",
    "2021-711201 - Mineral Beneficiation Plant Operator",
    "2021-711202 - Enameller (Jewellery)",
    "2021-711202 - Jewellery Metal Plater",
    "2021-711202 - Machine Engraver",
    "2021-711202 - Chain Manufacturing Operator (Jewellery Manufacturing)",
    "2021-711202 - Jewellery Electroformer",
    "2021-711202 - Wax Injector (Jewellery)",
    "2021-711202 - Jewellery Die Stamper",
    "2021-711203 - Diamond Marker",
    "2021-711203 - Sawyer",
    "2021-711203 - Brillianteer",
    "2021-711203 - Polishers Graders",
    "2021-711203 - Fancy Crossworker",
    "2021-711203 - Girdle Polishers",
    "2021-711203 - Bruter",
    "2021-711203 - Automatic Polishers",
    "2021-711203 - Crossworker",
    "2021-711203 - Diamond Cutting Setter",
    "2021-711203 - Top Polishers",
    "2021-711203 - Top half Polishers",
    "2021-711203 - Star Polishers",
    "2021-711203 - Final Diamond Processing Controller",
    "2021-711203 - Blocker",
    "2021-711203 - Top 8 side polishers",
    "2021-711203 - Table Polishers",
    "2021-711203 - Bottom Polishers",
    "2021-711203 - Scourer",
    "2021-711203 - Sawyer (laser)",
    "2021-711203 - Fancy Brillianteer",
    "2021-711203 - Diamond Boilers",
    "2021-711203 - Prep Polishers",
    "2021-711204 - Gem Polisher",
    "2021-711204 - Lapidary",
    "2021-711301 - Derrick Operator",
    "2021-711301 - Offshore Operations Derrickman",
    "2021-711301 - Jumbo Operator",
    "2021-711301 - Offshore Driller",
    "2021-711301 - Development Driller",
    "2021-711301 - Drill Rig Operator",
    "2021-711301 - Exploration Driller",
    "2021-711301 - Directional Driller",
    "2021-711301 - Power-tong Operator",
    "2021-711301 - Radial Drill Operator",
    "2021-711301 - Contract Driller",
    "2021-711301 - Derrickman",
    "2021-711301 - Marine Driller",
    "2021-711301 - Tourpusher",
    "2021-711301 - Raise Bore Operator",
    "2021-711301 - Scaler Operator",
    "2021-711301 - Drilling Plant Operator",
    "2021-711301 - Hydraulic Stope Rig Operator",
    "2021-711401 - Concrete Block Maker",
    "2021-711401 - Concrete Pump Operator",
    "2021-711401 - Artificial Stone Setter",
    "2021-711401 - Kiln Operator (Cement Production)",
    "2021-711401 - Stone Polishing Machine Operator",
    "2021-711401 - Concrete Precast Moulder",
    "2021-711401 - Artificial Stone Maker",
    "2021-711401 - Concrete Tile Machine Operator",
    "2021-711401 - Cast-stone Products Machine Operator",
    "2021-711401 - Concrete Pipe Machine Operator",
    "2021-711401 - Concrete Boom Operator",
    "2021-711401 - Cast-concrete Products Machine Operator",
    "2021-711403 - Plaster Caster",
    "2021-711404 - Central Concrete Mixing Plant Operator",
    "2021-711405 - Premix Concrete Batcher",
    "2021-712101 - Metal Heat Treating Operator",
    "2021-712101 - Metal Extrusion Plant Operator",
    "2021-712101 - Metal Drawer / Extruder",
    "2021-712101 - Steel Roller",
    "2021-712101 - Metal Production Plant Operator",
    "2021-712101 - Ladle Pourer",
    "2021-712102 - Chain Manufacturing Setter and Minder (Jewellery Manufacturing)",
    "2021-712201 - Metal Plating Machine Operator",
    "2021-712201 - Metal Finishing Machine Operator",
    "2021-712201 - Metal Coating Machine Operator",
    "2021-712201 - Galvaniser",
    "2021-712201 - Enameller",
    "2021-712201 - Hot Dip Plater",
    "2021-712201 - Anodiser",
    "2021-712201 - Electroformer",
    "2021-712201 - Aircraft Electroplater",
    "2021-713101 - Paint Tinter / Mixer",
    "2021-713101 - Chemical Production Sampler",
    "2021-713101 - Chemical Crusher / Grinder",
    "2021-713101 - Toiletry Products Machine Operators",
    "2021-713101 - Candle Production Machine Operator",
    "2021-713101 - Petroleum and Natural Gas Refining (Ethyl) Operator / Blender",
    "2021-713101 - Radioactive Waste Material Treatment Operator",
    "2021-713101 - Bullet / Ammunition / Explosives Maker",
    "2021-713101 - Nitrocellulose Maker",
    "2021-713101 - Pharmaceutical Products Machine Operator",
    "2021-713101 - Cleaning Materials Machine Operator",
    "2021-713101 - Chemical Extractor / Reactor / Still Operator",
    "2021-713101 - Distillation Operator",
    "2021-713101 - Battery Maker / Reconditioner",
    "2021-713101 - Explosives Mixer Operator",
    "2021-713101 - Abrasive Glass and Ceramics Mixer",
    "2021-713101 - Cosmetics Machine Operator",
    "2021-713101 - Explosive Products Machine Operator",
    "2021-713101 - Still / Petroleum and Natural Gas Refining Machine Operator",
    "2021-713101 - Tablet Making Machine Operator",
    "2021-713201 - Photo Lab Operator",
    "2021-713201 - Minilab Operator",
    "2021-713201 - Slide Developer",
    "2021-713201 - Printing/Photography (Colour) Machine Operator",
    "2021-713201 - Photograph Enlarger",
    "2021-713201 - Film Process Operator",
    "2021-713201 - Silver Recovery Operator",
    "2021-713201 - Photographic-film Machine Operator",
    "2021-713201 - Film/Colour Developer",
    "2021-713201 - Dark Room Attendant / Technician",
    "2021-713201 - Developing Machine Operator (Photography)",
    "2021-713201 - Copy Camera Operator",
    "2021-713201 - Digital Photographic Printer",
    "2021-713201 - Photographic Technician",
    "2021-713201 - Film Printer / Processor",
    "2021-713201 - Photograph Printer",
    "2021-713201 - Enlarging Machine Operator (Photography)",
    "2021-714101 - Tyre Builder",
    "2021-714101 - Rubber Belt Splicer",
    "2021-714101 - Tyre Retreader",
    "2021-714101 - Tyre Bead Creeler",
    "2021-714101 - Rubber Compounder",
    "2021-714101 - Steel and Fabric Calendering Machine Operator",
    "2021-714101 - Rubber Knitting and Reinforcing Machine Operator",
    "2021-714101 - Tyre Moulder",
    "2021-714101 - Rubber Extrusion Machine Operator",
    "2021-714101 - Latex Worker",
    "2021-714101 - Vulcaniser",
    "2021-714101 - Tyre Production Machine Operator",
    "2021-714101 - Rubber Moulding Machine Operator",
    "2021-714101 - Tyre Repairer",
    "2021-714101 - Tyre Finisher and Examiner",
    "2021-714101 - Rubber Millman",
    "2021-714101 - Rubber Products Machine Operator",
    "2021-714101 - Tyre Maker",
    "2021-714101 - Rubber Roller Grinder Operator",
    "2021-714101 - Rubber Milling Machine Operator",
    "2021-714101 - Rubber Moulder",
    "2021-714201 - Wire Drawer ( Plastics)",
    "2021-714201 - Optic Fibre Drawer",
    "2021-714201 - Insulation Extruder Operator",
    "2021-714202 - Shredder / Granulator Operator",
    "2021-714202 - Plastics Mixing Plant Operator",
    "2021-714202 - Powder Hand (Plastics)",
    "2021-714202 - Pelletising Extruder Operator",
    "2021-714203 - Acrylic Fabricator",
    "2021-714203 - Vinyl Welder and Fabricator",
    "2021-714203 - Plastic Pipe Fabricator",
    "2021-714204 - Blow Moulding Machine Operator",
    "2021-714204 - Extruding Machine Operator",
    "2021-714204 - Plastics Laminator",
    "2021-714204 - Lamination Machine Operator",
    "2021-714204 - Injection Moulding Machine Operator",
    "2021-714204 - Footwear Injection Moulding Operator",
    "2021-714204 - Slitting Setter",
    "2021-714205 - Fibreglass Hand Laminator",
    "2021-714205 - Resin Transfer Moulding Machine Operator",
    "2021-714205 - Fibreglass Gun-hand",
    "2021-714207 - Vacuum Forming Machine Operator",
    "2021-714208 - Plastics Mould Setter",
    "2021-714208 - Plastics Die Setter",
    "2021-714208 - Plastic Production Machine Setter",
    "2021-714208 - Plastics Die Fitter",
    "2021-714209 - Canoe Maker",
    "2021-714209 - Surfboard Maker",
    "2021-714209 - Canopy Builder",
    "2021-714209 - Kayak Maker",
    "2021-714301 - Machine Operator (Envelope and Paper Bag Production)",
    "2021-714301 - Paper Mache Moulder",
    "2021-714301 - Paper Bag Making Machinist",
    "2021-714301 - Embosser",
    "2021-714301 - Paper Cutting and Creasing  Machine Operator",
    "2021-714301 - Machine Operator (Cardboard Products)",
    "2021-714301 - Machine Operator (Paper Box Production)",
    "2021-714301 - Carton Making Machinist",
    "2021-715101 - Textile Blow Room and Carding Fibre Preparation Production Machine Operator",
    "2021-715101 - Textile Carding and Draw Frame Fibre Preparation Production Machine Operator",
    "2021-715101 - Textile Draw Frame and Speed Frame Fibre Preparation Production Machine Operator",
    "2021-715101 - Textile Silver Lap, Combing and Draw Frame Fibre Preparation Production Machine Operator",
    "2021-715205 - Tufting Machine Operator",
    "2021-715301 - Sewing Machine Attendant",
    "2021-715301 - Sewing Machinist",
    "2021-715302 - Textile / Leather / Material Cutting Machine Operator",
    "2021-715302 - Industrial Presser",
    "2021-715302 - Home Textile Production Machine Operator",
    "2021-715302 - Upholstery Machinist",
    "2021-715302 - Embroidery Machine Operator",
    "2021-715401 - Textile Printing Machine Operator",
    "2021-715401 - Textile Dyeing and Printing Preparation Machine Operator",
    "2021-715401 - Textile Wet Process Production finishing Machine Operator",
    "2021-715401 - Textile Wet Process Dyeing Machine Operator",
    "2021-715401 - Textile Wet Process Printing Machine Operator",
    "2021-715401 - Textile Wet Process Production Preparation Machine Operator",
    "2021-715401 - Textile Wet Process Finishing Machine Operator",
    "2021-715401 - Textile Dyeing Machine Operator / Textile Dyer",
    "2021-715401 - Fabric Bleaching Machine Operator",
    "2021-715401 - Textile Wet Process Coating Machine Operator",
    "2021-715501 - Beam House Operator",
    "2021-715501 - Fellmongering Machine Operator",
    "2021-715501 - Tanning Machine Operator",
    "2021-715501 - Staining / Leather Machine Operator",
    "2021-715501 - Pelt Preparations Operator",
    "2021-715501 - De-hairing/Hide Machine Operator",
    "2021-715501 - Leather Finishing Operator",
    "2021-715601 - Clicker",
    "2021-715602 - Toe Puff  Operator",
    "2021-715602 - Beading Operator",
    "2021-715602 - Shoe Folding Operator",
    "2021-715602 - Skiver",
    "2021-715701 - Folding Machine Operator (Laundry)",
    "2021-715701 - Washing Machine Operator",
    "2021-715701 - Launderer",
    "2021-715702 - Dry-cleaning Machine Operator",
    "2021-715703 - Textile Press Operator",
    "2021-715703 - Textile Press Operating Worker",
    "2021-715901 - Embroidery Machine Minder",
    "2021-716101 - Food Dehydration / Preserving Machine Operator",
    "2021-716101 - Food Freezing Machine Operator",
    "2021-716101 - Fruit Vegetable and Nut Processing Machine Operator",
    "2021-716101 - Food Preserving Machine Operator",
    "2021-716102 - Alcoholic Beverage Processor",
    "2021-716102 - Distiller",
    "2021-716103 - Aerated Water Cordial Machine Operator",
    "2021-716103 - Fruit Juice Processing Operator",
    "2021-716103 - Wine Making Machine Operator",
    "2021-716103 - Vinegar Processing Machine Operator",
    "2021-716103 - Cordial Syrup Making Machine Operator",
    "2021-716103 - Non Alcoholic Beverage Processing Operator",
    "2021-716104 - Milk Treatment Station Worker",
    "2021-716104 - Milk Pasteuriser",
    "2021-716104 - Cheese Factory Worker",
    "2021-716104 - Condensed Milk Maker Process Worker",
    "2021-716104 - Milk Processing Machine Operator",
    "2021-716104 - Pasteuriser Operator",
    "2021-716104 - Chocolate Production Machine Operator",
    "2021-716104 - Diary Process Controller",
    "2021-716104 - Milk Powder Maker",
    "2021-716104 - Milk Treater",
    "2021-716104 - Dairy Plant / Process Operator",
    "2021-716104 - Dairy Products Processor",
    "2021-716104 - Cooker Operator Dairy Products",
    "2021-716104 - Dairy Factory Worker",
    "2021-716105 - Plant Baker",
    "2021-716105 - Bread Production Machine Operator",
    "2021-716106 - Sugar Process Worker (Raw House)",
    "2021-716106 - Sugar Process Worker (Refinery)",
    "2021-716106 - Sugar Front End Worker",
    "2021-716107 - Coffee and Tea Blender",
    "2021-716107 - Coffee and Chicory Roaster and Grinder",
    "2021-716107 - Coffee / Tea Taster",
    "2021-716107 - Red Tea Maker",
    "2021-716108 - Seed Bagger",
    "2021-716108 - Seed Conditioner",
    "2021-716108 - Seed Grading Machine Operator",
    "2021-716108 - Seed Sheller",
    "2021-716109 - Mill hand / Mill Worker",
    "2021-716109 - Flourmill Kilnman",
    "2021-716109 - Grain Mill Worker",
    "2021-716109 - Lucerne Pellets Maker",
    "2021-716109 - Animal Feed Mill Worker",
    "2021-716109 - Grain Cleaner / Dryer / Crusher",
    "2021-716110 - Cigarette Maker Worker",
    "2021-716110 - Tobacco Product Maker Worker",
    "2021-716111 - Sausage Casing Maker",
    "2021-716111 - Meat Canning Process Worker",
    "2021-716111 - Meat Processor",
    "2021-716111 - Quality Controller of Meat Canning Process Workers",
    "2021-716111 - Meat Dehydrator / Preserver / Processor / Worker",
    "2021-716111 - Meat Smoker",
    "2021-716112 - Fish Processing Machine Operator",
    "2021-716112 - Fish Canning Process Worker",
    "2021-716118 - Soft Drinks Blending Process Operator",
    "2021-716118 - Meat Process Operator",
    "2021-716118 - Liquid Dairy Reception Process Operator",
    "2021-716118 - Chocolate and Sugar Confectionery Process Operator",
    "2021-716118 - Plant Baking Process Operator",
    "2021-716118 - Fruit and Vegetable Process Operator",
    "2021-716118 - Packaging Operator",
    "2021-716118 - Cereals Process Operator",
    "2021-716118 - Savoury Snack Process Operator",
    "2021-716118 - Seafood Process Operator",
    "2021-716118 - Biscuit Confectionery Process Operator",
    "2021-716118 - Starch Process Operator",
    "2021-716118 - Pasta Making Process Operator",
    "2021-717102 - Carton Making Machine Operator",
    "2021-717102 - Cardboard / Paperboard Machine Operator",
    "2021-717102 - Inverform Machine Operator",
    "2021-717102 - Paper (Bag / Box / Envelope) Maker",
    "2021-717102 - Paper Rewinder Operator",
    "2021-717102 - Bleach Plant Operator",
    "2021-717102 - Dryerman (Paper Mill)",
    "2021-717102 - Water Marking Operator",
    "2021-717102 - Paperboard Machine Operator",
    "2021-717102 - Paper Mill Machinist",
    "2021-717102 - Paper Folding Machine Operator",
    "2021-717102 - Fourdrinier Machine Operator",
    "2021-717102 - Station Marshaller",
    "2021-717102 - Paper Making Machine Operator",
    "2021-717102 - Supercalender Operator (Papermaking)",
    "2021-717201 - Sawyer (Wood)",
    "2021-717201 - Timber Stripper / Processor / Sorter / Grader",
    "2021-717201 - Beam Saw Operator",
    "2021-717201 - Cant Gang Sawyer",
    "2021-717201 - Saw Operator",
    "2021-717201 - Work Panel Production Worker",
    "2021-717201 - Ripsaw Operator",
    "2021-717201 - Timber Machinist",
    "2021-717201 - Veneer Production Machine Operator",
    "2021-717201 - Sawmill Process Worker",
    "2021-717201 - Docking Saw Operator",
    "2021-717201 - Sawmill Machine Operator",
    "2021-717201 - Band Saw Operator",
    "2021-717201 - Sawmill Moulder Operator",
    "2021-718101 - Porcelain Turner",
    "2021-718101 - Pottery / Porcelain Mould Maker",
    "2021-718101 - Kiln Operator (Pottery and Porcelain)",
    "2021-718101 - Brick Maker",
    "2021-718101 - Tile Maker",
    "2021-718101 - Clay Mixing Machine Operator",
    "2021-718101 - Slip Caster",
    "2021-718101 - Brick Extruder Operator",
    "2021-718101 - Brick and Tile Kiln Man",
    "2021-718101 - Kiln Operator (Brick and Tile)",
    "2021-718102 - Glass Toughening Operator",
    "2021-718102 - Lens Grinding Machine Operator",
    "2021-718102 - Glass Process Operator",
    "2021-718102 - Furnace Operator (Glass Production)",
    "2021-718102 - Glass Forming Operator",
    "2021-718102 - Glass Furnace Operator",
    "2021-718102 - Glass Melt Operator",
    "2021-718102 - Glass Blowing Machine Operator",
    "2021-718102 - Glass Laminating Operator",
    "2021-718201 - Motorman / Motorwoman (Fluids Drilling)",
    "2021-718201 - Air-conditioning / Refrigeration Plant Operator",
    "2021-718201 - Air Compressor Operator",
    "2021-718201 - Steam Engine Operator",
    "2021-718201 - Ship's Boiler Operator",
    "2021-718201 - Thermal Plant Operator",
    "2021-718201 - Marine Engine Driver",
    "2021-718201 - Boiler Tender",
    "2021-718201 - Steam Boilerman Fireman",
    "2021-718201 - Stoker",
    "2021-718302 - Wrapping Machine Operator",
    "2021-718303 - Can / Tin / Tube / Tray Filler",
    "2021-718303 - Sack or Bag Filler",
    "2021-718303 - Automated Filling Line Operator",
    "2021-718303 - Jar or Bottle Filler",
    "2021-718303 - Bottle Filler",
    "2021-718303 - Bottling Attendant",
    "2021-718303 - Sealing and Capping Machine Operator",
    "2021-718303 - Canning and Bottling Process Worker",
    "2021-718303 - Cannery Worker",
    "2021-718303 - Milk Bottler",
    "2021-718303 - Filling Machine Operator",
    "2021-718303 - Container Filler",
    "2021-718303 - Pressurised Container Filler",
    "2021-718303 - Canister Viewer",
    "2021-718303 - Aseptic Filling Line Operator",
    "2021-718304 - Bag Maker",
    "2021-718304 - Sack Maker",
    "2021-718304 - Cigarette Packaging Machine Setter",
    "2021-718304 - Packaging Machine Minder",
    "2021-718304 - Packaging Machine Operator",
    "2021-718304 - Metalised Packaging Paper Machine Setter",
    "2021-718304 - End Maker",
    "2021-718304 - Cigarette Manufacturing Machine Setter",
    "2021-718304 - Laminator",
    "2021-718304 - Corrugated Board Printing and Finishing Machine Technician",
    "2021-718304 - Can Maker",
    "2021-718304 - Corrugated Board Manufacturing Machine Minder",
    "2021-718304 - Carton Maker",
    "2021-718304 - Corrugated Board and Container Machine Minder",
    "2021-718905 - Hammer Hand",
    "2021-718905 - Laser Cutting Operator",
    "2021-718905 - Wire Drawer",
    "2021-718905 - Computer Numeric Control (CNC) Machine Operator",
    "2021-718905 - Press Pour Operator",
    "2021-718905 - Ball Sorter",
    "2021-718905 - Steel Worker",
    "2021-718905 - Wire Goods Maker",
    "2021-718905 - Alumina Refinery Operator",
    "2021-718905 - Brake Press Operator",
    "2021-718905 - Pneumatic Press Operator",
    "2021-718905 - Production Process Machine Operator and Assembler",
    "2021-718905 - Lead Worker",
    "2021-718905 - Guillotine Operator Metal",
    "2021-718905 - Sheet Metal Production / Process Worker",
    "2021-718905 - Turret Lathe Operator",
    "2021-718905 - Wire Product Machine Operator",
    "2021-718905 - Die Setter",
    "2021-718905 - Turret Punch Operator",
    "2021-718905 - Waste Thinner",
    "2021-718905 - Automated Machine Operator",
    "2021-718906 - Palletiser Operator",
    "2021-718906 - Tank Farm Operator (Petroleum)",
    "2021-718906 - Forestry Plant Operator",
    "2021-718906 - Conveyor Belt Operator",
    "2021-718906 - Cable Yard Operator",
    "2021-718906 - Bulk Fluids Handler",
    "2021-718907 - Licensed Weigher",
    "2021-718913 - Cinema / Film Projectionist",
    "2021-718913 - Cinetape Operator",
    "2021-718914 - Shot Blaster Castings",
    "2021-721101 - Turbine Assembler",
    "2021-721101 - Vehicle Assembler",
    "2021-721101 - Engine Assembler",
    "2021-721101 - Aircraft Assembler",
    "2021-721101 - Gearbox Assembler",
    "2021-721201 - Etcher (Printed Circuit Board)",
    "2021-721201 - Television Assembler",
    "2021-721201 - Armature Assembler",
    "2021-721201 - Telephone Assembler",
    "2021-721201 - Electronic Equipment Assembler",
    "2021-721201 - Electrical Equipment Assembler",
    "2021-721201 - Electromechanical Equipment Assembler",
    "2021-721901 - Knife Assembler",
    "2021-721901 - Bicycle Assembler",
    "2021-721901 - Furniture Finisher, Wood",
    "2021-721901 - Textile Products Assembler",
    "2021-721901 - Jewellery Treebuilder",
    "2021-721901 - Eyeglass Frame Assembler",
    "2021-721901 - Jewellery Assembler",
    "2021-721901 - Assembler, Wood Products",
    "2021-721901 - Box Builder",
    "2021-721901 - Pen and Pencil Assembler",
    "2021-721901 - Umbrella Maker",
    "2021-721901 - Wax Setter (Jewellery)",
    "2021-721901 - Leather Products Assembler",
    "2021-721901 - Pearl & Bead Stringer",
    "2021-721901 - Assembler, Metal Products (Except Mechanical)",
    "2021-721901 - Assembler, Plastic Products",
    "2021-721901 - Thermos Bottle Assembler",
    "2021-721901 - Assembler, Rubber Products",
    "2021-721901 - Plastic Toy Assembler",
    "2021-721901 - Paperboard Products Assembler",
    "2021-721901 - Sun-blinds Assembler",
    "2021-721901 - Door Assembler",
    "2021-721901 - Ammunition Assembler",
    "2021-721901 - Luggage Finisher",
    "2021-731101 - Locomotive Observer",
    "2021-731101 - Locomotive Driver",
    "2021-731101 - Rail Car Driver",
    "2021-731101 - Electric Train Driver",
    "2021-731101 - Steam Train Driver",
    "2021-731101 - Mine and Quarry Engine Driver",
    "2021-731101 - Fireperson",
    "2021-731101 - Railway Engine Driver",
    "2021-731201 - Railway Switching and Signalling Operator",
    "2021-731201 - Functional Yard Operator",
    "2021-731201 - Railway Traffic Assistant / Operator",
    "2021-731201 - Railway Shunter",
    "2021-731201 - Railway Yard Assistant",
    "2021-731202 - Railways Yard Foreman",
    "2021-732101 - Van Driver",
    "2021-732101 - Fast Food Delivery Driver",
    "2021-732101 - Taxi Truck Driver",
    "2021-732101 - Armoured Car Driver",
    "2021-732101 - Meals On Wheels Driver",
    "2021-732101 - Driver-messenger",
    "2021-732101 - Grocery Deliverer",
    "2021-732101 - Light Utility Vehicle (LUV) Driver",
    "2021-732102 - Motorcyclist",
    "2021-732102 - Motorcycle Dispatch Driver",
    "2021-732201 - Limousine Driver",
    "2021-732201 - Hire Car Driver",
    "2021-732201 - Shuttle Driver",
    "2021-732202 - Cab Driver",
    "2021-732202 - Taxi Operator",
    "2021-732203 - Security Driver",
    "2021-732203 - Fire Truck Driver",
    "2021-732203 - Ambulance Driver",
    "2021-732203 - Fire Van Driver",
    "2021-733101 - School Bus Driver",
    "2021-733101 - Minibus Driver",
    "2021-733102 - Tour Coach Operator",
    "2021-733201 - Lorry Driver",
    "2021-733201 - Road Train Driver",
    "2021-733201 - Dumper Truck Driver",
    "2021-733201 - Compactor Driver (Rubbish Collection)",
    "2021-733201 - Cement Mixer Driver",
    "2021-733201 - Logging Truck Driver",
    "2021-733201 - Freight Operator",
    "2021-733201 - Livestock Hauler",
    "2021-733201 - Tilt Tray Driver",
    "2021-733203 - Piano Removalist",
    "2021-733203 - Office Equipment Removalist",
    "2021-733204 - Petrol Tanker Driver",
    "2021-733204 - Water Tanker Driver",
    "2021-733204 - Milk Tanker Driver",
    "2021-733205 - Recovery Operator",
    "2021-733205 - Recovery Mechanic (Army)",
    "2021-733208 - Hydrator Operator",
    "2021-733209 - Road Marker",
    "2021-733210 - Bitumen Plant Operator",
    "2021-733210 - Road Making Machine Operator",
    "2021-733210 - Surface Laying Machine Operator",
    "2021-733210 - Spreading Machine Operator",
    "2021-733210 - Paving Machinery Operator",
    "2021-733210 - Tar Sealing Machine Operator",
    "2021-733210 - Bituminous Operator",
    "2021-733210 - Sprayer Tar / Bitumen",
    "2021-733210 - Asphalt Plant Operator",
    "2021-734101 - Agrichemical Spraying / Dusting Operator",
    "2021-734101 - Agricultural Mobile Equipment Operator",
    "2021-734101 - Agricultural Machine and Equipment Operator",
    "2021-734101 - Harvester Operator",
    "2021-734101 - Tractor Driver",
    "2021-734101 - Rotary Hoe Operator",
    "2021-734101 - Chemical Applicator",
    "2021-734101 - Farm Equipment / Machinery Operator",
    "2021-734101 - Cotton Picking Machine Operator",
    "2021-734102 - Forestry Loader Operator",
    "2021-734102 - Cable Yarder Operator",
    "2021-734102 - Tree Feller Operator",
    "2021-734102 - Feller Buncher Operator",
    "2021-734102 - Forestry Processor Operator",
    "2021-734102 - Forwarder Operator",
    "2021-734102 - Timber Carrier Driver",
    "2021-734102 - Skidder Operator",
    "2021-734201 - Construction Plant Operator (General)",
    "2021-734202 - Backhoe Driver",
    "2021-734203 - Bulldozer Driver",
    "2021-734203 - Scraper Operator (Earthmoving)",
    "2021-734203 - Road Scraper Driver Operator",
    "2021-734204 - Shovel Operator",
    "2021-734204 - Hydraulic Rock Breaker Operator",
    "2021-734204 - Excavator Driver",
    "2021-734204 - Trench Digging Machine Operator",
    "2021-734204 - Digging Machine Operator",
    "2021-734205 - Grader Driver",
    "2021-734206 - Load-haul-dump (LHD) Operator",
    "2021-734206 - Skid-steer-loader Operator",
    "2021-734206 - Front-end-loader Operator",
    "2021-734206 - Loader Driver",
    "2021-734206 - Front-end-loader Driver",
    "2021-734213 - Road Repairer",
    "2021-734213 - Road Roller Driver",
    "2021-734214 - Dump Truck Driver/ Operator",
    "2021-734214 - Haul Truck Operator",
    "2021-734214 - Underground Dump Truck Driver/ Operator",
    "2021-734301 - Crane Driver",
    "2021-734301 - Winding Engine Driver",
    "2021-734301 - Tower Crane Operator",
    "2021-734301 - Pile Driver",
    "2021-734301 - Braceperson",
    "2021-734301 - Elevated Work Platform Operator",
    "2021-734301 - Small Winder Operator",
    "2021-734301 - Small Winch Operator",
    "2021-734301 - Onsetter",
    "2021-734301 - Scraper Winch Operator",
    "2021-734301 - Cherry Picker Operator",
    "2021-734301 - Winch Operator",
    "2021-734301 - Chairlift Operator",
    "2021-734301 - Winding Engine Driver (Materials)",
    "2021-734301 - Portainer Operator",
    "2021-734303 - Dredger",
    "2021-734402 - Fork Truck Operator",
    "2021-734402 - Forklift Operator",
    "2021-734402 - Lumber / Timber Carrier Operator",
    "2021-734402 - Hoist Truck Operator",
    "2021-734402 - Reach Truck Operator",
    "2021-735101 - Ship Crew Member",
    "2021-735101 - Sailor",
    "2021-735101 - Able Seaman",
    "2021-735101 - Barge Hand",
    "2021-735101 - Ordinary Seaman",
    "2021-735101 - Tug Hand",
    "2021-735101 - Ferry Hand",
    "2021-735101 - Mariner",
    "2021-735101 - Launchman",
    "2021-735101 - Boatswain / Bosun",
    "2021-811101 - Char (Domestic)",
    "2021-811101 - Home / House Cleaner",
    "2021-811201 - Cleaner (Non-domestic)",
    "2021-811201 - Building Exterior / Interior Cleaner",
    "2021-811201 - Factory Cleaner / Sweeper",
    "2021-811201 - School Cleaner",
    "2021-811201 - Office Cleaner",
    "2021-811201 - Hotel / Motel Cleaner",
    "2021-811201 - Waterblaster",
    "2021-811201 - High Pressure Water Jetting Operators",
    "2021-811201 - Workshop Cleaner",
    "2021-811202 - Medium Risk Area Cleaner",
    "2021-811202 - Low Risk Area Cleaner",
    "2021-811202 - High Risk Area Cleaner",
    "2021-811202 - Ward Cleaner",
    "2021-811203 - Tea Services",
    "2021-811203 - Tea Lady",
    "2021-811203 - Tea Person",
    "2021-812101 - Starcher",
    "2021-812101 - Garment Cleaner",
    "2021-812101 - Linen Sorter / Maid",
    "2021-812101 - Laundry Maid",
    "2021-812104 - Steam Cleaner",
    "2021-812104 - Carpet Cleaning Contractor",
    "2021-812201 - Car Detailer",
    "2021-812201 - Aircraft Cleaner",
    "2021-812201 - Locomotive and Train Cleaner",
    "2021-812201 - Bus / Coach Cleaner",
    "2021-812201 - Car Wash Attendant",
    "2021-812201 - Car Polisher",
    "2021-812201 - Wash bay Attendant",
    "2021-812201 - Car Cleaner",
    "2021-812201 - Utility Vehicle Appearance Reconditioner",
    "2021-812201 - Aircraft Cabin / Interior Cleaner",
    "2021-812201 - Car Valet",
    "2021-812201 - Car Washer",
    "2021-812301 - Window Washer",
    "2021-812301 - Window Cleaning Contractor",
    "2021-812901 - Sanitation Worker",
    "2021-812903 - Restroom Attendant",
    "2021-821101 - Horticultural Farm Worker / Practitioner",
    "2021-821101 - Agronomy Farm Worker / Practitioner",
    "2021-821101 - Mixed Crop Worker",
    "2021-821101 - Crop Farm Assistant",
    "2021-821101 - Ornamental Horticultural Farm Worker",
    "2021-821101 - Crop Worker",
    "2021-821101 - Crop Farm Worker (Any Commodity)",
    "2021-821102 - Waterman",
    "2021-821102 - Farm Irrigator",
    "2021-821104 - Vegetable Picker",
    "2021-821104 - Hops or Tea Picker",
    "2021-821104 - Grape Picker",
    "2021-821104 - Mushroom Harvester / Picker",
    "2021-821104 - Flower Buncher or Picker",
    "2021-821104 - Fruit Picker",
    "2021-821104 - Haymaker",
    "2021-821104 - Picker Quality Controller",
    "2021-821104 - Nut Picker",
    "2021-821201 - Dairy Cattle Farm Worker",
    "2021-821201 - Sheep Farm Worker",
    "2021-821201 - Milking Equipment Operator",
    "2021-821201 - Stablehand",
    "2021-821201 - Beef Cattle Farm Worker",
    "2021-821201 - Feedlot Worker",
    "2021-821201 - Shepherd",
    "2021-821201 - Animal Attendant Farm",
    "2021-821201 - Livestock Drafter",
    "2021-821201 - Abattoir Livestock Attendant",
    "2021-821204 - Bird Sexer",
    "2021-821204 - Hatchery Assistant",
    "2021-821204 - Egg Collector",
    "2021-821205 - Shearing Shed Worker / Hand",
    "2021-821205 - Wool Baler",
    "2021-821205 - Wool Packer",
    "2021-821301 - General Farm Worker",
    "2021-821401 - Lawn Mower",
    "2021-821401 - Garden Services Worker",
    "2021-821402 - Greenhouse Worker Nursery",
    "2021-821402 - Compost Maker",
    "2021-821402 - Nursery Production Person",
    "2021-821402 - Nursery Hand (Horticulture)",
    "2021-821402 - Horticultural / Nursery Worker",
    "2021-821501 - Forestry Contractor",
    "2021-821501 - Skiddy Forest",
    "2021-821501 - Bush Clearing Contractor",
    "2021-821501 - Timber Getter",
    "2021-821501 - Forest Labourer / Hand / Pruner",
    "2021-821501 - Sniper Forest",
    "2021-821501 - Silviculture Forestry Worker",
    "2021-821501 - Fire Lookout",
    "2021-821501 - Firewood Cutter",
    "2021-821501 - Forestry Protection Officer",
    "2021-821501 - Quality Controller of Forestry Contractors",
    "2021-821501 - Tree Planter",
    "2021-821501 - Harvesting Forestry Worker",
    "2021-821502 - Log Hauler Bush",
    "2021-821502 - Log Assessor",
    "2021-821502 - Stump Grabber",
    "2021-821502 - Hauling Engine Contractor Logging",
    "2021-821502 - Sleeper Cutter",
    "2021-821502 - Topper Logging",
    "2021-821502 - Prop Cutter",
    "2021-821502 - Log Scaler",
    "2021-821502 - Bush Clearer Cutter",
    "2021-821502 - Log Raft Maker",
    "2021-821502 - Chokerman / Chokerwoman",
    "2021-821502 - Swampy",
    "2021-821502 - Woodman",
    "2021-821502 - Axeman Forest",
    "2021-821601 - Prawn Trawler Hand",
    "2021-821601 - Purse Seining Hand",
    "2021-821601 - Trawlerman",
    "2021-821601 - Fisherman",
    "2021-821601 - Greaser",
    "2021-821601 - Deep Sea Fisherman",
    "2021-821601 - Trawler Hand",
    "2021-821601 - Cray Fishing Hand",
    "2021-821601 - Fish Baiter",
    "2021-821601 - Fishing Boat Mate",
    "2021-821602 - Fresh Fish Hatchery Worker",
    "2021-821602 - Fresh Fish Farm / Hatchery Worker",
    "2021-831101 - Mining Surfaceman",
    "2021-831101 - Mine Sampler / Worker",
    "2021-831101 - Shaft Assistant",
    "2021-831101 - Onsetter Helper",
    "2021-831101 - Mine Stage Assistant",
    "2021-831101 - Roustabout",
    "2021-831101 - Roughneck",
    "2021-831101 - Pump Attendant",
    "2021-831101 - Quarry Hand / Worker",
    "2021-831101 - Mineral Ore Processing Labourer",
    "2021-831101 - Air Quality Monitor",
    "2021-831101 - Pit Crew Support Worker",
    "2021-831101 - Coal Hewer",
    "2021-831101 - Radiation Protection Monitor",
    "2021-831101 - Shaft Timberman Assistant",
    "2021-831101 - Stoping Worker",
    "2021-831101 - Gravel Worker",
    "2021-831101 - Conveyor Belt Attendant",
    "2021-831101 - Sand Pit Worker",
    "2021-831101 - Mining Production Assistant",
    "2021-831101 - Mine Survey Recorder",
    "2021-831101 - Acclimatisation Chamber Operator/ Heat Tolerance Screening (HTS) Attendant",
    "2021-831101 - Sorter (Mining)",
    "2021-831101 - Shaft Sinking Assistant",
    "2021-831101 - Battery Bay Attendant/ Battery and Lamp Repairer",
    "2021-831102 - Roustabout (Oil and Gas)",
    "2021-831102 - Well Caser / Treatment Offsider",
    "2021-831102 - Driller's Offsider",
    "2021-831102 - Derrick Hand / Worker",
    "2021-831102 - Well Treatment Offsider",
    "2021-831102 - Oil Rig Floorman",
    "2021-831102 - Oil and Gas Caser / Cementer / Derrickman",
    "2021-831102 - Drill Rig Assistant",
    "2021-831103 - DMS Helper",
    "2021-831103 - Mineral Wash Plant Attendant",
    "2021-831301 - Demolition Contractor / Labourer",
    "2021-831301 - Maintenance Person / Coordinator",
    "2021-831301 - Carpenter's Assistant",
    "2021-831301 - Artisan Aide Building Trade",
    "2021-831301 - Bricklayer's Assistant",
    "2021-831301 - Kitchen Assembler",
    "2021-831301 - Joinery Worker",
    "2021-831301 - Tilerhand",
    "2021-831301 - Road Surfaceman",
    "2021-831301 - Pipe Layer",
    "2021-831301 - Road Construction / Maintenance Labourer",
    "2021-831302 - Hydro Contract Worker",
    "2021-831303 - Grave Digger",
    "2021-831303 - Ditch Digging Worker",
    "2021-831305 - Concrete Processing Worker",
    "2021-831305 - Cement Finisher Worker",
    "2021-831305 - Concrete Finisher",
    "2021-831305 - Concrete Layer / Placer",
    "2021-831306 - Kerb Setter",
    "2021-831306 - Road Building Construction Worker",
    "2021-831306 - Road Worker",
    "2021-831307 - Tracklayer",
    "2021-831307 - Track Inspector",
    "2021-831307 - Railway Ganger",
    "2021-831307 - Railway Fettler",
    "2021-831307 - Railway Trackman",
    "2021-831308 - Dogman / Dogwoman",
    "2021-831308 - Slinger",
    "2021-831309 - Heating Ventilation Air Conditioning Lagger",
    "2021-831309 - Pipe Lagger",
    "2021-831310 - Exploration Sampler's Assistant",
    "2021-831310 - Survey Helper",
    "2021-831310 - Geological Survey Field Assistant",
    "2021-831310 - Seismic Survey Assistant",
    "2021-831310 - Sampler/ MRM Sampler/ MRT Grade Controller",
    "2021-831310 - Survey Recorder",
    "2021-831310 - Geotechnical Assistant",
    "2021-831310 - Mineral Resource Assistant",
    "2021-831311 - Fencing Contractor",
    "2021-831311 - Fence Erector",
    "2021-832101 - Chocolate Packer",
    "2021-832102 - Shrinkwrap Operator",
    "2021-832102 - Meat Preparer and Packer Freezing Works",
    "2021-832901 - Rigger Assistant",
    "2021-832901 - Rigger's Aide",
    "2021-832901 - Machinist Assistant",
    "2021-832901 - Wrought Iron Worker",
    "2021-832901 - Metal Forger's Assistant",
    "2021-832901 - Fitter's Assistant",
    "2021-832901 - Machinist Aide",
    "2021-832901 - Boilermaker Aide",
    "2021-832901 - Fitter Operative",
    "2021-832901 - Boilermaker Operative",
    "2021-832901 - Metal Moulder's Assistant",
    "2021-832901 - Fettler",
    "2021-832901 - Millwright Assistant",
    "2021-832901 - Foundry Worker",
    "2021-832901 - Millwright Aide",
    "2021-832901 - Blacksmith's Striker",
    "2021-832901 - Fitter Aide",
    "2021-832901 - Fitter Assistant",
    "2021-832901 - Boilermaker Assistant",
    "2021-832902 - Fibreglass Laminating Process Worker",
    "2021-832902 - Fibreglass Pattern Maker",
    "2021-832902 - Fibreglass Lay Up Hand",
    "2021-832902 - Plastic Toy Maker",
    "2021-832902 - Poly Coiler",
    "2021-832902 - Formica Worker",
    "2021-832902 - Rubber Factory Worker",
    "2021-832902 - Plastics Trimmer",
    "2021-832902 - Rubber Process Worker",
    "2021-832902 - Plastics Packer",
    "2021-832902 - Plastics Mixing Plant Worker",
    "2021-832902 - Plastics and Composite Shot Peeners",
    "2021-832902 - Latex Foam Hand",
    "2021-832902 - Plastics Process Worker",
    "2021-832902 - Cellular Plastics Cutter Hand",
    "2021-832902 - Plastics Finisher",
    "2021-832903 - Timber Filleter",
    "2021-832903 - Wood Finisher",
    "2021-832903 - Wood and Wood Products Factory Worker",
    "2021-832903 - Wallboard Worker",
    "2021-832903 - Timber Mill Worker",
    "2021-832903 - Timber Stacker Hand",
    "2021-832903 - Timber Process Worker",
    "2021-832903 - Strawboard Maker",
    "2021-832903 - Hardboard Factory Hand",
    "2021-832903 - Fibreboard Maker",
    "2021-832903 - Furniture Manufacturing Labourer",
    "2021-832903 - Match Making Machine Operator",
    "2021-832903 - Timber Worker",
    "2021-832903 - Timber Yard Worker",
    "2021-832903 - Panel Production Worker",
    "2021-832903 - Sawmill or Timber Yard Worker",
    "2021-832903 - Particleboard Factory Hand",
    "2021-832903 - Process Worker Furniture / Wooden Toys",
    "2021-832903 - Furniture Maker's Assistant",
    "2021-832903 - Sawmill Bullock Horseman",
    "2021-832903 - Joinery Factory Hand",
    "2021-832903 - Sawmill Yardman",
    "2021-832903 - Quality Controller of Sawmill Labourers",
    "2021-832903 - Pulp, Paper Making and Paper Products Labourer",
    "2021-832903 - Chipboard Block Setter",
    "2021-832903 - Wood Processing Worker",
    "2021-832903 - Tailer-out",
    "2021-832903 - Paper and Pulp Mill Worker (General)",
    "2021-832903 - Gang Nail Process Worker",
    "2021-832903 - Plywood Factory Hand",
    "2021-832904 - Beer Brewer",
    "2021-832904 - Bread Room Hand",
    "2021-832904 - Valve Controller",
    "2021-832904 - Perishable Produce Packhouse Worker",
    "2021-832904 - Baking Factory Worker",
    "2021-832904 - Line Attendant",
    "2021-832904 - Brewery Worker",
    "2021-832904 - Beer Production Worker",
    "2021-832904 - Grain Dryer Attendant",
    "2021-832904 - Winery Worker",
    "2021-832904 - Biscuit Factory Hand",
    "2021-832904 - Silo Worker",
    "2021-832904 - Winery Cellar Hand",
    "2021-832904 - Brewer",
    "2021-832905 - Leather Processing Factory Worker",
    "2021-832905 - Flesher Hand",
    "2021-832905 - Soles Channeller / Fitter / Grader / Preparer",
    "2021-832905 - Toggler",
    "2021-832905 - Tannery Hand / Assistant / Worker",
    "2021-832905 - Upper Burnisher / Cementer / Fitter / Folder",
    "2021-832905 - Fellmongery Hand",
    "2021-832905 - Footwear Machine Attendant",
    "2021-832905 - Footwear General Worker",
    "2021-832905 - Footwear Production Chargehand",
    "2021-832905 - Footwear Bench Worker",
    "2021-832905 - Footwear Table Hand",
    "2021-832905 - Footwear Skiver / Rounder / Sorter / Laster",
    "2021-832906 - Glass Making Process Worker",
    "2021-832906 - Glass Fibre Process Worker",
    "2021-832906 - Glass Processor / Worker",
    "2021-832906 - Factory Hand Fibreglass Moulding",
    "2021-832906 - Glass Fibre Maker",
    "2021-832906 - Glass Mould Cleaner",
    "2021-832907 - Munitions Factory Hand",
    "2021-832907 - Gas Plant Labourer",
    "2021-832907 - Grease Press Hand",
    "2021-832907 - Utilities Operator",
    "2021-832907 - Paint Factory Hand",
    "2021-832907 - Chemical Tester (Physical/ Routine)",
    "2021-832907 - Charcoal Burner",
    "2021-832908 - Clay Processing Labourer",
    "2021-832908 - Brick Handler",
    "2021-832908 - Clay Mould Plant Operator",
    "2021-832908 - Ceramics Worker",
    "2021-832908 - Clay Mixer",
    "2021-832908 - Kiln Labourer",
    "2021-832908 - Porcelain Factory Worker",
    "2021-832908 - Carousel Minder",
    "2021-832908 - Pottery and Porcelain Modeller",
    "2021-832908 - Pottery Process Worker",
    "2021-832908 - Pottery and Porcelain Cutter",
    "2021-832908 - Brick and Tile Drawer and Setter",
    "2021-832909 - Bundler",
    "2021-832909 - Textile Machine Attendant",
    "2021-832909 - Tying Machine Operator Woollen Mill",
    "2021-832909 - Counting and Packaging Clothing Process Worker",
    "2021-832909 - Counting and Packaging Textile Process Worker",
    "2021-832909 - Cloth Burler",
    "2021-832909 - Clothing and Textile Factory Worker",
    "2021-832909 - Canvas Upholstery Cutter",
    "2021-832909 - Textile Production Chargehand",
    "2021-832909 - Clothing or Textile Production Chargehand",
    "2021-832909 - Thread and Yarn Piecer",
    "2021-832909 - Textiles General Worker",
    "2021-832909 - Textiles Table Hand",
    "2021-832909 - Crochet Worker",
    "2021-832910 - Motor Vehicle Parts and Accessories Fitter (General)",
    "2021-832910 - Vehicle Window Tinter",
    "2021-832910 - Radiator Repairer",
    "2021-832910 - Exhaust and Muffler Fitter",
    "2021-832910 - Windscreen Fitter",
    "2021-832910 - Tyre Serviceman / Serviceperson",
    "2021-832910 - Car Alarm Installer",
    "2021-832910 - Tyre Fitter and Aligner",
    "2021-832910 - Autoglazier",
    "2021-832910 - Radiator Fitter",
    "2021-832910 - Wheel Alignment Attendant",
    "2021-833301 - Freight Loader",
    "2021-833301 - Yard Cleanup Man",
    "2021-833301 - Rail and Road Transport Vehicle Loader",
    "2021-833301 - Yardman",
    "2021-833301 - Checker and Loader Transport Vehicle",
    "2021-833301 - Yard Attendant",
    "2021-833302 - Furniture Removalist hand",
    "2021-833303 - Harbour Board Worker",
    "2021-833303 - Wharf Labourer",
    "2021-833303 - Stevedore",
    "2021-833303 - Boat and Ship Cargo Loader",
    "2021-833303 - Docker Wharf",
    "2021-833303 - Longshoreman",
    "2021-833303 - Cargo Handler / Worker",
    "2021-833304 - Aircraft Handler",
    "2021-833304 - Aircraft Loader",
    "2021-833304 - Aircraft Baggage Handler",
    "2021-833401 - Stacker Textiles",
    "2021-833401 - Shelf Stacker",
    "2021-833401 - Warehouse Worker / Shelver",
    "2021-833401 - Storeperson Packer / Shelver",
    "2021-833402 - Order Picker / Assembler",
    "2021-833402 - Supply Operator (Army)",
    "2021-833402 - Manufacturing Storeperson",
    "2021-833402 - Chiller Hand",
    "2021-833402 - Warehouse Assistant",
    "2021-833402 - Stores Assistant",
    "2021-841101 - Spasa Shop Cook",
    "2021-841101 - Fast Food Assistant",
    "2021-841101 - Takeaway Cook",
    "2021-841101 - Pizza Maker",
    "2021-841101 - Chips Maker",
    "2021-841101 - Chicken / Fish Fryer",
    "2021-841101 - Grill Cook",
    "2021-841101 - Short Order Cook",
    "2021-841101 - Burger Maker",
    "2021-841201 - Sandwich / Salad Preparer",
    "2021-841201 - Pantry Attendant",
    "2021-841201 - Kitchen Steward",
    "2021-841201 - Kitchen Assistant (Non-domestic)",
    "2021-841201 - Dishwasher",
    "2021-841202 - Butcher's Assistant",
    "2021-841202 - Pastry Cook's Assistant",
    "2021-841202 - Cook's Assistant",
    "2021-841202 - Bakery Assistant",
    "2021-841202 - Chef's Assistant",
    "2021-841202 - Confectioner's Assistant",
    "2021-851102 - Circular / Pamphlet Deliverer",
    "2021-861101 - Garbage Depot Hand",
    "2021-861101 - Refuse Collector",
    "2021-861101 - Waste Removalist",
    "2021-861101 - Reclamation Worker",
    "2021-861101 - Garden Rubbish Collector",
    "2021-861101 - Rubbish Collection Contractor",
    "2021-861101 - Garbage Collector",
    "2021-861301 - Park / Gardens Cleaner",
    "2021-861301 - Road Sweeper",
    "2021-862101 - Bar Back",
    "2021-862101 - Bar Useful",
    "2021-862101 - Glassie",
    "2021-862102 - Baggage Checker",
    "2021-862102 - Hotel Bell Boy",
    "2021-862102 - Lift Attendant",
    "2021-862102 - Night Porter",
    "2021-862102 - Doorperson",
    "2021-862102 - Baggage Handler",
    "2021-862103 - Uniform Room Attendant",
    "2021-862201 - Garage door and Motor Installer",
    "2021-862201 - Shower Screen Installer",
    "2021-862201 - Curtain Fitter",
    "2021-862201 - Awning Installer",
    "2021-862201 - Security Door Installer",
    "2021-862201 - Blinds Installer",
    "2021-862201 - Home Renovator",
    "2021-862201 - Carport Erector",
    "2021-862202 - Hotel Useful",
    "2021-862202 - Hotel Yardperson",
    "2021-862202 - Handy Man",
    "2021-862202 - Handy Person",
    "2021-862301 - Parking Meter Reader",
    "2021-862301 - Electric Meter Reader",
    "2021-862301 - Water Meter Reader",
    "2021-862302 - Gambling Machine Attendant",
    "2021-862302 - Vending Machine Refiller",
    "2021-862302 - Poker Machine Attendant",
    "2021-862302 - Coin Collector",
    "2021-862913 - Event Support Assistant",
    "2021-862913 - Event Volunteer",
    "2021-862918 - Electrical Operative",
    "2021-862918 - Instrumentation Aide",
    "2021-862918 - Electrician Assistant",
    "2021-862918 - Auto Electrician's Assistant",
    "2021-862918 - Air conditioning and Refrigeration Mechanic Assistant",
    "2021-862918 - Instrumentation Assistant",
    "2021-862918 - Electrical Helper",
    "2021-862918 - Electronic Equipment Mechanician Aide",
    "2021-862918 - Electrician Aide",
    "2021-862918 - Auto Electrician Aide",
    "2021-862918 - Air conditioning and Refrigeration Mechanic Aide",
    "2021-862918 - Electronic Equipment Mechanician Assistant",
    "2021-862918 - Artisan Aide Electrical",
    "2021-862918 - Instrument Artisan Assistant",
    "2021-862918 - Measurement, Control and Instrumentation (MC&I) Aide",
    "2021-862918 - Measurement, Control and Instrumentation (MC&I) Assistant",
    "2021-862919 - Lube Attendant",
    "2021-862919 - Grease Room Attendant",
    "2021-862919 - Repair Shop Assistant",
    "2021-862919 - Stripper / Assembler",
    "2021-862919 - Lubrication Attendant",
    "2021-862919 - Mechanic Aide",
    "2021-862919 - Mechanic Assistant",
    "2021-862920 - Railway Washout Man",
    "2021-862920 - Railway Guard / Porter",
    "2021-862920 - Railways Yard Master",
    "2021-862920 - Railways Traffic Foreman",
    "2021-862920 - Railway Surfaceman",
    "2021-862926 - Usher",
    "2021-862926 - Turnstile Attendant",
    "2021-862926 - Gatekeeper",
    "2021-862926 - Ticket Taker",
    "2021-862926 - Entertainment Usher",
    "2021-862926 - Venue Attendant",
    "2021-862926 - Ticket Collector Railway",
    "2021-862926 - Cinema Attendant",
];

export default OFOSpecialization;
