// ofoOccupations.js
const OFOOccupations = [
    "2021-111101 - Local or Provincial Government Legislator",
    "2021-111102 - Parliamentarian",
    "2021-111201 - Defence Force Senior Officer",
    "2021-111202 - General Manager Public Service",
    "2021-111203 - Local Authority Manager",
    "2021-111204 - Senior Government Official",
    "2021-111205 - Senior Police Officer",
    "2021-111206 - Ombudsperson",
    "2021-111207 - Senior Government Manager",
    "2021-111301 - Traditional Leader",
    "2021-111401 - Elected Official",
    "2021-111402 - Trade Union Representative",
    "2021-111403 - Political Party Representative",
    "2021-112101 - Director (Enterprise / Organisation)",
    "2021-121101 - Finance Manager",
    "2021-121102 - Payroll Manager",
    "2021-121103 - Credit Manager",
    "2021-121104 - Internal Audit Manager",
    "2021-121201 - Human Resource Manager",
    "2021-121202 - Business Training Manager",
    "2021-121203 - Compensation and Benefits Manager",
    "2021-121204 - Recruitment Manager",
    "2021-121205 - Employee Wellness Manager",
    "2021-121206 - Health and Safety Manager",
    "2021-121207 - Personnel Manager",
    "2021-121301 - Policy and Planning Manager",
    "2021-121901 - Corporate General Manager",
    "2021-121902 - Corporate Services Manager",
    "2021-121903 - Physical Asset Manager",
    "2021-121904 - Contract Manager",
    "2021-121905 - Programme or Project Manager",
    "2021-121906 - Franchise Manager",
    "2021-121908 - Quality Systems Manager",
    "2021-121909 - Sustainability Manager",
    "2021-121910 - Water Asset Manager",
    "2021-122101 - Sales and Marketing Manager",
    "2021-122102 - Sales Manager",
    "2021-122103 - Director of Marketing",
    "2021-122104 - Interactive and Direct Marketing Strategist",
    "2021-122105 - Customer Service Manager",
    "2021-122201 - Advertising and Public Relations Manager",
    "2021-122301 - Research and Development Manager",
    "2021-131101 - Agricultural Farm Manager",
    "2021-131102 - Forestry Operations Manager",
    "2021-131103 - Forestry Operations Supervisor",
    "2021-131201 - Aquaculture Farm Manager",
    "2021-132101 - Manufacturer",
    "2021-132102 - Manufacturing Operations Manager",
    "2021-132104 - Engineering Manager",
    "2021-132105 - Power Generation Operations Manager",
    "2021-132106 - Manufacturing Quality Manager",
    "2021-132107 - Quality Manager",
    "2021-132108 - Metrologist",
    "2021-132109 - Quality Systems Auditor",
    "2021-132201 - Mining Manager",
    "2021-132202 - Mineral Resources Manager",
    "2021-132203 - Rock Engineering Manager",
    "2021-132301 - Construction Project Manager",
    "2021-132302 - Project Builder",
    "2021-132401 - Supply and Distribution Manager",
    "2021-132402 - Logistics Manager",
    "2021-132403 - Road Transport Manager",
    "2021-132404 - Warehouse Manager",
    "2021-132405 - Fleet Manager",
    "2021-132406 - Railway Station Manager",
    "2021-132407 - Airport or Harbour Manager",
    "2021-132408 - Grain Depot Manager",
    "2021-132409 - Fuel Manager",
    "2021-132410 - Maritime Search and Rescue Mission Coordinator",
    "2021-133101 - Chief Information Officer",
    "2021-133102 - ICT Project Manager",
    "2021-133103 - Data Management Manager",
    "2021-133104 - Application Development Manager",
    "2021-133105 - Information Technology Manager",
    "2021-133106 - Information Systems Director",
    "2021-134101 - Child Care Centre Manager",
    "2021-134201 - Medical Superintendent",
    "2021-134202 - Nursing Clinical Director",
    "2021-134203 - Primary Health Care Manager",
    "2021-134204 - Secondary Health Services Manager",
    "2021-134205 - Health Service Specialised Clinic Manager",
    "2021-134206 - Sport Science Manager",
    "2021-134207 - Community Health Manager",
    "2021-134301 - Special Care Accommodation Manager",
    "2021-134401 - Social Services Manager",
    "2021-134402 - Community Development Manager",
    "2021-134403 - Child and Youth Care Manager",
    "2021-134501 - School Principal",
    "2021-134502 - College Principal",
    "2021-134503 - Faculty Head",
    "2021-134504 - District Education Manager",
    "2021-134505 - Educational Rector",
    "2021-134506 - Educational Registrar",
    "2021-134507 - Departmental Head",
    "2021-134601 - Bank Manager",
    "2021-134602 - Credit Bureau Manager",
    "2021-134603 - Financial Markets Business Manager",
    "2021-134701 - Military Manager",
    "2021-134702 - Military Commander",
    "2021-134703 - Military Warrant Officer",
    "2021-134901 - Environmental Manager",
    "2021-134902 - Laboratory Manager",
    "2021-134903 - Small Business Manager",
    "2021-134904 - Office Manager",
    "2021-134905 - Judicial Court Manager",
    "2021-134906 - Practice Manager",
    "2021-134907 - Archives Manager",
    "2021-134908 - Library Manager",
    "2021-134909 - Museum Manager",
    "2021-134911 - Insurance Policy Administration Manager",
    "2021-134912 - Commissioned Fire and Rescue Officer",
    "2021-134913 - Commissioned Police Officer",
    "2021-134914 - Correctional Services Manager",
    "2021-134915 - Non Manufacturing Operations Manager",
    "2021-134916 - Non Manufacturing Operations Foreman",
    "2021-134917 - Publisher",
    "2021-134918 - Water production and Supply Manager",
    "2021-134919 - Traffic and Law Enforcement Manager",
    "2021-134920 - Community Correctional Manager",
    "2021-134921 - Disaster Management Manager",
    "2021-141101 - Hotel or Motel Manager",
    "2021-141102 - Guest House Manager",
    "2021-141103 - Reception Manager",
    "2021-141201 - Café (Licensed) or Restaurant Manager",
    "2021-141202 - Licensed Club Manager",
    "2021-141203 - Catering Production Manager",
    "2021-141204 - Reservations Manager",
    "2021-142101 - Importer or Exporter",
    "2021-142102 - Wholesaler",
    "2021-142103 - Retail General Manager",
    "2021-142104 - Post Office Manager",
    "2021-143101 - Betting Agency Manager",
    "2021-143102 - Gaming Manager",
    "2021-143103 - Cinema or Theatre Manager",
    "2021-143104 - Arts / Culture Manager",
    "2021-143105 - Sports Administrator",
    "2021-143106 - Amusement Centre Manager",
    "2021-143107 - Fitness Centre Manager",
    "2021-143108 - Facility Centre Manager",
    "2021-143109 - Club Membership Manager",
    "2021-143901 - Facilities Manager",
    "2021-143902 - Cleaning Services Manager",
    "2021-143903 - Boarding Kennel or Cattery Manager",
    "2021-143904 - Security Services Manager",
    "2021-143905 - Call or Contact Centre Manager",
    "2021-143906 - Caravan Park and Camping Ground Manager",
    "2021-143907 - Dockmaster",
    "2021-143908 - Travel Accommodation Inspector",
    "2021-143909 - Travel Agency Manager",
    "2021-211101 - Physicist",
    "2021-211201 - Meteorologist",
    "2021-211205 - Climate Change Scientist",
    "2021-211301 - Chemist",
    "2021-211302 - Manufacturing Research Chemist",
    "2021-211401 - Geologist",
    "2021-211402 - Geophysicist",
    "2021-211403 - Materials Scientist",
    "2021-211404 - Gemologist",
    "2021-211405 - Mineralogist",
    "2021-211406 - Hydrologist",
    "2021-211407 - Oceanographer",
    "2021-212101 - Actuary",
    "2021-212102 - Mathematician",
    "2021-212103 - Statistician",
    "2021-213101 - Animal Behaviourist",
    "2021-213102 - General Biologist",
    "2021-213103 - Anatomist or Physiologist",
    "2021-213104 - Biochemist",
    "2021-213105 - Biotechnologist",
    "2021-213106 - Botanist",
    "2021-213107 - Marine Biologist",
    "2021-213108 - Microbiologist",
    "2021-213109 - Zoologist",
    "2021-213110 - Medical Scientist",
    "2021-213111 - Pharmaceutical Physician",
    "2021-213201 - Agriculture Consultant",
    "2021-213202 - Agricultural Scientist",
    "2021-213203 - Forest Scientist",
    "2021-213204 - Wine Maker",
    "2021-213205 - Food and Beverage Scientist",
    "2021-213301 - Conservation Scientist",
    "2021-213302 - Environmental Scientist",
    "2021-213304 - Earth and Soil Scientist",
    "2021-213305 - Air Quality Analyst",
    "2021-213306 - Water Quality Analyst",
    "2021-213307 - Park Ranger",
    "2021-213308 - Brownfield Redevelopment Specialist",
    "2021-213309 - Toxicologist",
    "2021-213310 - Biodiversity Planner",
    "2021-214101 - Industrial Engineer",
    "2021-214102 - Industrial Engineering Technologist",
    "2021-214103 - Production Engineer",
    "2021-214104 - Production Engineering Technologist",
    "2021-214105 - Energy Efficiency Manager",
    "2021-214201 - Civil Engineer",
    "2021-214202 - Civil Engineering Technologist",
    "2021-214301 - Environmental Engineer",
    "2021-214302 - Environmental Impact and Restoration Analyst",
    "2021-214401 - Mechanical Engineer",
    "2021-214402 - Mechanical Engineering Technologist",
    "2021-214403 - Aeronautical Engineer",
    "2021-214404 - Aeronautical Engineering Technologist",
    "2021-214405 - Naval Architect",
    "2021-214406 - Marine Engineering Technologist",
    "2021-214407 - Defence Industry Armament Engineer",
    "2021-214408 - Defence Industry Armament Engineering Technologist",
    "2021-214501 - Chemical Engineer",
    "2021-214502 - Chemical Engineering Technologist",
    "2021-214503 - Explosives and Dangerous Goods Inspector",
    "2021-214601 - Mining Engineer",
    "2021-214602 - Mining Engineering Technologist",
    "2021-214603 - Metallurgical Engineer",
    "2021-214604 - Metallurgical Engineering Technologist",
    "2021-214605 - Metallurgist",
    "2021-214607 - Petroleum Engineer",
    "2021-214901 - Biomedical Engineer",
    "2021-214902 - Explosive Ordnance Engineer",
    "2021-214903 - Marine Salvage Engineer",
    "2021-214904 - Quantity Surveyor",
    "2021-214905 - Agricultural Engineer",
    "2021-214906 - Agricultural Engineering Technologist",
    "2021-214907 - Materials Engineer",
    "2021-214908 - Materials Engineering Technologist",
    "2021-214909 - Microsystems Engineers",
    "2021-215101 - Electrical Engineer",
    "2021-215102 - Electrical Engineering Technologist",
    "2021-215103 - Energy Engineer",
    "2021-215104 - Energy Engineering Technologist",
    "2021-215201 - Electronics Engineer",
    "2021-215202 - Electronics Engineering Technologist",
    "2021-215301 - Telecommunications Engineer",
    "2021-215302 - Telecommunications Engineering Technologist",
    "2021-215303 - Telecommunications Network Engineer",
    "2021-215304 - Telecommunications Field Engineer",
    "2021-216101 - Architect",
    "2021-216201 - Landscape Architect",
    "2021-216301 - Fashion Designer",
    "2021-216302 - Industrial Designer",
    "2021-216303 - Jewellery Designer",
    "2021-216304 - Footwear Designer",
    "2021-216401 - Urban and Regional Planner",
    "2021-216402 - Transport Analyst",
    "2021-216403 - Marine Spatial Planner",
    "2021-216501 - Cartographer",
    "2021-216502 - Surveyor",
    "2021-216601 - Digital Artist",
    "2021-216602 - Illustrator",
    "2021-216603 - Multimedia Designer",
    "2021-216604 - Web Designer",
    "2021-221101 - General Medical Practitioner",
    "2021-221102 - Resident Medical Officer",
    "2021-221103 - Public Health Physician",
    "2021-221201 - Anaesthetist",
    "2021-221202 - Cardiologist",
    "2021-221203 - Emergency Medicine Specialist",
    "2021-221204 - Obstetrician and Gynaecologist",
    "2021-221205 - Ophthalmologist",
    "2021-221206 - Paediatrician",
    "2021-221207 - Pathologist",
    "2021-221208 - Psychiatrist",
    "2021-221209 - Radiologist",
    "2021-221210 - General Medicine Specialist Physician",
    "2021-221211 - Surgeon",
    "2021-221212 - Forensic Pathologist",
    "2021-221213 - Radiation Oncologist",
    "2021-221214 - Nuclear Medicine Specialist",
    "2021-221215 - Family Physician",
    "2021-221216 - Neurologist",
    "2021-221217 - Clinical Pharmacologist",
    "2021-221218 - Medical Geneticist",
    "2021-221219 - Plastic and Reconstructive Surgeon",
    "2021-221220 - Urologist",
    "2021-221221 - Public Health Medicine Specialist",
    "2021-221222 - Public Health Occupational Medicine Specialist",
    "2021-221223 - Paediatric Surgeon",
    "2021-221224 - Orthopaedic Surgeon",
    "2021-221225 - Neurosurgeon",
    "2021-221226 - Otorhinolaryngologist",
    "2021-221227 - Dermatologist",
    "2021-222101 - Clinical Nurse Practitioner",
    "2021-222102 - Aged Care Registered Nurse",
    "2021-222103 - Registered Nurse (Child and Family Health)",
    "2021-222104 - Registered Nurse (Community Health)",
    "2021-222105 - Registered Nurse (Critical Care and Emergency)",
    "2021-222106 - Registered Nurse (Developmental Disability)",
    "2021-222107 - Registered Nurse (Disability and Rehabilitation)",
    "2021-222108 - Registered Nurse (Medical)",
    "2021-222109 - Registered Nurse (Medical Practice)",
    "2021-222110 - Registered Nurse (Mental Health)",
    "2021-222111 - Registered Nurse (Operating theatre)",
    "2021-222112 - Registered Nurse (Surgical)",
    "2021-222113 - Paediatrics Nurse",
    "2021-222114 - Nurse Educator",
    "2021-222115 - Nurse Researcher",
    "2021-222116 - Nurse Manager",
    "2021-222117 - Midwife",
    "2021-223101 - Acupuncturist",
    "2021-223102 - Ayurvedic Practitioner",
    "2021-223103 - Homoeopath",
    "2021-223104 - Naturopath",
    "2021-223105 - Traditional African Medicine Practitioner",
    "2021-223106 - Traditional Chinese Medicine Practitioner",
    "2021-223107 - Phytotherapist",
    "2021-224101 - Paramedical Practitioner",
    "2021-224102 - Sports Scientist",
    "2021-225101 - Veterinarian",
    "2021-225102 - Veterinary Public Health Professional / Practitioner",
    "2021-225103 - Livestock Inspector",
    "2021-226101 - Dental Specialist",
    "2021-226102 - Dentist",
    "2021-226201 - Hospital Pharmacist",
    "2021-226202 - Industrial Pharmacist",
    "2021-226203 - Retail Pharmacist",
    "2021-226204 - Authorised Pharmacist Prescriber",
    "2021-226301 - Environmental Health Officer",
    "2021-226302 - Safety, Health, Environment and Quality (SHE&Q) Practitioner",
    "2021-226303 - Ergonomist",
    "2021-226304 - Food Inspector",
    "2021-226401 - Physiotherapist",
    "2021-226501 - Dietitian",
    "2021-226502 - Nutritionist",
    "2021-226601 - Audiologist",
    "2021-226602 - Speech Pathologist",
    "2021-226603 - Speech Therapist and Audiologist",
    "2021-226604 - Hearing Aid Acousticians",
    "2021-226701 - Optometrist",
    "2021-226702 - Orthoptist",
    "2021-226901 - Arts Therapist",
    "2021-226902 - Occupational Therapist",
    "2021-226903 - Podiatrist",
    "2021-226904 - Diversional Therapist",
    "2021-226905 - Biokineticist",
    "2021-226906 - Rheumatologist",
    "2021-231101 - University Lecturer",
    "2021-231102 - University Tutor",
    "2021-232130 - TVET Educator",
    "2021-232131 - Adult Education Teacher",
    "2021-232132 - College Lecturer",
    "2021-233107 - FET Phase School Teacher (Grades 10-12)",
    "2021-233108 - Senior Phase School Teacher (Grades 7 - 9)",
    "2021-234101 - Foundation Phase School Teacher",
    "2021-234102 - Intermediate Phase School Teacher (Grades 4-6)",
    "2021-234201 - Early Childhood Development Practitioner",
    "2021-235101 - Education or Training Advisor",
    "2021-235102 - Education or Training Reviewer",
    "2021-235201 - Special Needs Teacher",
    "2021-235401 - Private Tuition Music Teacher",
    "2021-235501 - Private Tuition Art Teacher",
    "2021-235502 - Private Tuition Dance Teacher",
    "2021-235503 - Private Tuition Drama Teacher",
    "2021-235601 - ICT Trainer",
    "2021-235901 - Private Tuition Dressmaking Teacher",
    "2021-235902 - Private Tuition Handicrafts Teacher",
    "2021-235903 - School Laboratory Technician",
    "2021-235904 - Examination Supervisor",
    "2021-241101 - General Accountant",
    "2021-241102 - Management Accountant",
    "2021-241103 - Tax Professional",
    "2021-241104 - External Auditor",
    "2021-241106 - Accountant in Practice",
    "2021-241107 - Financial Accountant",
    "2021-241108 - Forensic Accountant",
    "2021-241201 - Investment Analyst",
    "2021-241202 - Investment Manager",
    "2021-241203 - Investment Advisor",
    "2021-241204 - Financial Markets Practitioner",
    "2021-241205 - Professional Principal Executive Officer",
    "2021-241301 - Financial Investment Advisor",
    "2021-242101 - Management Consultant",
    "2021-242102 - Organisation and Methods Analyst",
    "2021-242103 - Business Development Officer",
    "2021-242104 - AML Surveillance Analyst",
    "2021-242201 - Intelligence Officer",
    "2021-242202 - Policy Analyst",
    "2021-242203 - Company Secretary",
    "2021-242204 - Corporate Treasurer",
    "2021-242207 - Compliance Officer",
    "2021-242208 - Organisational Risk Manager",
    "2021-242209 - Accounting Officer",
    "2021-242210 - Business Administrator",
    "2021-242211 - Internal Auditor",
    "2021-242212 - Diplomat",
    "2021-242213 - Regulatory Affairs Officer",
    "2021-242214 - Intellectual Property Special Advisor",
    "2021-242215 - Fraud Examiner",
    "2021-242301 - Career Development Practitioner",
    "2021-242302 - Skills Development Practitioner",
    "2021-242303 - Human Resource Advisor",
    "2021-242304 - Industrial Relations Advisor",
    "2021-242305 - Occupational Analyst",
    "2021-242306 - Labour Market Analyst",
    "2021-242307 - Recreation Officer",
    "2021-242401 - Training and Development Professional",
    "2021-242402 - Occupational Instructor",
    "2021-242403 - Assessment Practitioner",
    "2021-242404 - Student Support Service Officer",
    "2021-243101 - Advertising Specialist",
    "2021-243102 - Market Research Analyst",
    "2021-243103 - Marketing Practitioner",
    "2021-243104 - Market Campaign Analyst",
    "2021-243201 - Communication Coordinator",
    "2021-243202 - Communication Strategist",
    "2021-243203 - Corporate Communication Manager",
    "2021-243204 - Event Producer",
    "2021-243301 - Industrial Products Sales Representative",
    "2021-243302 - Medical and Pharmaceutical Products Sales Representative",
    "2021-243303 - Educational Products and Services Sales Representative",
    "2021-243304 - Printing and Publishing Equipment Sales Representative",
    "2021-243401 - ICT Account Manager",
    "2021-243402 - ICT Business Development Manager",
    "2021-243403 - ICT Sales Representative",
    "2021-251101 - ICT Systems Analyst",
    "2021-251102 - Data Scientist",
    "2021-251201 - Software Developer",
    "2021-251202 - Programmer Analyst",
    "2021-251203 - Developer Programmer",
    "2021-251301 - Multimedia Specialist",
    "2021-251302 - Web Developer",
    "2021-251401 - Applications Programmer",
    "2021-251901 - Computers Quality Assurance Analyst",
    "2021-252101 - Database Designer and Administrator",
    "2021-252201 - Systems Administrator",
    "2021-252301 - Computer Network and Systems Engineer",
    "2021-252302 - Network Analyst",
    "2021-252303 - `Transmission Engineer",
    "2021-252901 - ICT Security Specialist",
    "2021-252902 - Technical ICT Support Services Manager",
    "2021-261101 - Attorney",
    "2021-261102 - Administrative Lawyer",
    "2021-261103 - Patent Attorney",
    "2021-261104 - Trade Mark Attorney",
    "2021-261105 - Tribunal Member",
    "2021-261106 - Advocate",
    "2021-261107 - Legal Manager",
    "2021-261201 - Judge",
    "2021-261202 - Magistrate",
    "2021-261901 - Adjudicator",
    "2021-261902 - Legislation Facilitator",
    "2021-261903 - Master of The Court",
    "2021-261904 - Family Court Registrar",
    "2021-261905 - Notary",
    "2021-262101 - Archivist",
    "2021-262102 - Gallery or Museum Curator",
    "2021-262103 - Conservator",
    "2021-262201 - Librarian",
    "2021-262202 - Information Services Manager",
    "2021-263101 - Economist",
    "2021-263102 - Economic Reasearch Manager",
    "2021-263201 - Anthropologist",
    "2021-263202 - Archaeologist",
    "2021-263203 - Geographer",
    "2021-263204 - Sociologist",
    "2021-263205 - Criminologist",
    "2021-263206 - Heritage Consultant",
    "2021-263301 - Genealogist",
    "2021-263302 - Historian",
    "2021-263303 - Prehistorian",
    "2021-263304 - Political Scientist",
    "2021-263401 - Clinical Psychologist",
    "2021-263402 - Educational Psychologist",
    "2021-263403 - Organisational Psychologist",
    "2021-263404 - Psychotherapist",
    "2021-263405 - Research Psychologist",
    "2021-263406 - Sport Psychologist",
    "2021-263407 - Counselling Psychologist",
    "2021-263408 - Community Psychologist",
    "2021-263409 - Psychometrician",
    "2021-263501 - Social Counselling Worker",
    "2021-263502 - Addictions Counsellor",
    "2021-263503 - Marriage and Family Counsellor",
    "2021-263504 - Rehabilitation Counsellor",
    "2021-263505 - Student Counsellor",
    "2021-263506 - Probation Social Worker",
    "2021-263507 - Adoption Social Worker",
    "2021-263508 - Child and Youth Care Worker",
    "2021-263509 - Parole Board Member",
    "2021-263510 - Employee Wellness Practitioner",
    "2021-263511 - Career Councillor",
    "2021-263512 - Community Development Practitioner",
    "2021-263601 - Minister of Religion",
    "2021-264101 - Author",
    "2021-264102 - Book or Script Editor",
    "2021-264103 - Technical Writer",
    "2021-264201 - Copywriter",
    "2021-264202 -  Editor",
    "2021-264203 - Journalist",
    "2021-264204 - Radio Journalist",
    "2021-264205 - Content Presenter",
    "2021-264206 - Critic",
    "2021-264301 - Interpreter",
    "2021-264302 - Translator",
    "2021-264303 - Linguist",
    "2021-265101 - Painter (Visual Arts)",
    "2021-265102 - Potter or Ceramic",
    "2021-265103 - Sculptor",
    "2021-265104 - Ephemeral Artist",
    "2021-265201 - Composer",
    "2021-265202 - Music Director",
    "2021-265203 - Musician (Instrumental)",
    "2021-265204 - Singer",
    "2021-265205 - Music Researcher",
    "2021-265206 - Music Copyist",
    "2021-265301 - Dancer or Choreographer",
    "2021-265401 - Director (Film, Television, Radio or Stage)",
    "2021-265402 - Director of Photography",
    "2021-265403 - Film and Video Editor",
    "2021-265404 - Program Director (Television or Radio)",
    "2021-265405 - Technical Director",
    "2021-265406 - Video Producer",
    "2021-265407 - Police Video Unit Manager and Producer",
    "2021-265408 - Casting Director",
    "2021-265409 - Film and Television Production Manager",
    "2021-265410 - Radio or Television Programme Organiser",
    "2021-265411 - Location Manager (Film or Television)",
    "2021-265412 - Media Producer",
    "2021-265501 - Actor",
    "2021-265601 - Radio Presenter",
    "2021-265602 - Television Presenter",
    "2021-265901 - Entertainer or Variety Artist",
    "2021-265902 - Hypnotist",
    "2021-265903 - Public Speaker",
    "2021-265904 - Community Arts Worker",
    "2021-311101 - Chemistry Technician",
    "2021-311102 - Physical Science Technician",
    "2021-311103 - Fragrance Evaluators/ flavourists",
    "2021-311104 - Radiation Control/ Nuclear Monitoring Technician",
    "2021-311201 - Civil Engineering Technician",
    "2021-311202 - Surveying or Cartographic Technician",
    "2021-311203 - Town Planning Technician",
    "2021-311217 - Water Control Officer",
    "2021-311301 - Electrical Engineering Technician",
    "2021-311302 - Electric Substation Operations Manager",
    "2021-311303 - Energy Efficiency Technician",
    "2021-311401 - Electronic Engineering Technician",
    "2021-311501 - Mechanical Engineering Technician",
    "2021-311502 - Pressure Equipment  Inspector",
    "2021-311503 - Aeronautical Engineering Technician",
    "2021-311601 - Chemical Engineering Technician",
    "2021-311701 - Mining Technician",
    "2021-311702 - Metallurgical or Materials Technician",
    "2021-311703 - Non-Destructive Testing Technician  (NDTT)",
    "2021-311704 - Geophysical Technician",
    "2021-311801 - Draughtsperson",
    "2021-311901 - Forensic Technician (Biology, Toxicology)",
    "2021-311902 - Fire Investigator",
    "2021-311903 - Food and Beverage Technician",
    "2021-311904 - Manufacturing Technician",
    "2021-311905 - Industrial Engineering Technician",
    "2021-311906 - Environmental Engineering Technician",
    "2021-311910 - Food Laboratory Analyst",
    "2021-312101 - Mining Production Supervisor",
    "2021-312102 - Miner",
    "2021-312103 - Engineering Supervisor",
    "2021-312201 - Production / Operations Supervisor (Manufacturing)",
    "2021-312202 - Maintenance Planner",
    "2021-312301 - Building Associate",
    "2021-313102 - Fossil Power Plant Process Controller",
    "2021-313103 - Hydro Power Plant Process Controller",
    "2021-313104 - Nuclear Power Plant Process Controller",
    "2021-313105 - Wind Turbine Power Plant Process Controller",
    "2021-313106 - Concentrated Solar Power (CSP) Plant Process Controller",
    "2021-313107 - Geothermal Technicians",
    "2021-313108 - Weatherisation Installers and Technicians",
    "2021-313109 - Solar Photovoltaic Service Technician",
    "2021-313110 - Wind Turbine Service Technician",
    "2021-313201 - Water Plant Operator",
    "2021-313202 - Waste Materials Plant Operator",
    "2021-313203 - Water Process Controller",
    "2021-313301 - Chemical Plant Controller",
    "2021-313401 - Gas or Petroleum Controller",
    "2021-313501 - Metal Manufacturing Process Control Technician",
    "2021-313901 - Integrated Manufacturing Line Process Control Technician",
    "2021-313902 - Wood Processing Control Technician",
    "2021-313903 - Clothing, Textile and Footwear Manufacturing Process Control Technician",
    "2021-313905 - Cotton Ginning Process Controller",
    "2021-313906 - Fresh Produce Packing Controller",
    "2021-313907 - Food and Beverage Manufacturing Process Controller",
    "2021-313908 - Sugar Process Controller",
    "2021-313909 - Miller",
    "2021-313910 - Juice Extraction Process Controller",
    "2021-313911 - Grain Handling Controller",
    "2021-313912 - Mineral Beneficiation Process Controller",
    "2021-313913 - Chemical Waste Controller",
    "2021-313916 - Manufacturing Production Technicians",
    "2021-313917 - Pulp and Paper Manufacturing Process Control Technician",
    "2021-314101 - Life Science Technician",
    "2021-314102 - Environmental Science Technician",
    "2021-314201 - Agricultural Technician",
    "2021-314301 - Forestry Technician",
    "2021-314302 - Forestry Research Technician",
    "2021-315101 - Marine Engineer",
    "2021-315201 - Ship's Master",
    "2021-315202 - Ship's Officer",
    "2021-315203 - Ship's Surveyor",
    "2021-315204 - Marine Certification & Surveillance Manager",
    "2021-315205 - Boat Driver / Coxswain",
    "2021-315206 - Aids to Navigation Manager",
    "2021-315207 - Aids to Navigation Technician",
    "2021-315301 - Aircraft Navigator",
    "2021-315302 - Flight Engineer",
    "2021-315303 - Aeroplane Pilot",
    "2021-315304 - Flying Instructor",
    "2021-315305 - Helicopter Pilot",
    "2021-315306 - Balloonist",
    "2021-315401 - Traffic Controller",
    "2021-315501 - Airborne Electronics Analyst",
    "2021-315502 - Airworthiness Surveyor",
    "2021-321101 - Medical Diagnostic Radiographer",
    "2021-321102 - Medical Radiation Therapist",
    "2021-321103 - Nuclear Medicine Technologist",
    "2021-321104 - Sonographer",
    "2021-321105 - Anaesthetic Technician",
    "2021-321106 - Cardiac Technician",
    "2021-321107 - Operating Theatre Technician",
    "2021-321108 - Audiometrist",
    "2021-321109 - Dialysis Technician",
    "2021-321110 - Neurophysiological Technician",
    "2021-321111 - Renal Technician",
    "2021-321112 - Intensive Care Technician",
    "2021-321113 - Orthopaedic Technician",
    "2021-321114 - Health Technical Support Officer",
    "2021-321115 - Medical Electronic Equipment Operator",
    "2021-321116 - Electroencephalographic Technician",
    "2021-321117 - Radiation Laboratory Technician",
    "2021-321118 - Orthotist or Prosthetist",
    "2021-321119 - Pulmonology Clinical Technologist",
    "2021-321120 - Reproductive Biology Clinical Technologist",
    "2021-321121 - Cardiothoracic Perfusion Clinical Technologist",
    "2021-321122 - Occupational Therapy Technician",
    "2021-321123 - Orientation and Mobility Practitioner",
    "2021-321201 - Medical Technician",
    "2021-321206 - Medical Technologist",
    "2021-321301 - Pharmacy Technician",
    "2021-321401 - Clinical Dental Technician",
    "2021-321402 - Dental Technician",
    "2021-321403 - Dental Therapist",
    "2021-321405 - Orthotic and Prosthetic Technician",
    "2021-321406 - Dental Technologist",
    "2021-322101 - Enrolled Nurse",
    "2021-322102 - Mother Craft Nurse",
    "2021-322201 - Assistant Midwife",
    "2021-323101 - Indigenous Heath Worker (Inyanga)",
    "2021-323102 - Ancillary Health Care Worker",
    "2021-323105 - Therapeutic Aromatherapist",
    "2021-323106 - Therapeutic Reflexologist",
    "2021-324101 - Veterinary Nurse",
    "2021-324102 - Veterinary Technician",
    "2021-324103 - Veterinary Technologist",
    "2021-325101 - Dental Assistant",
    "2021-325102 - Oral Hygienist",
    "2021-325201 - Health Information Manager",
    "2021-325301 - Health Promotion Practitioner",
    "2021-325401 - Dispensing Optician",
    "2021-325501 - Massage Therapist",
    "2021-325502 - Hydrotherapist",
    "2021-325503 - Electrotherapist",
    "2021-325504 - Physiotherapy Technician",
    "2021-325601 - Clinical Associate",
    "2021-325701 - Environmental and Occupational Health Inspector",
    "2021-325702 - Marine Safety Officer",
    "2021-325703 - Agricultural / Horticultural Produce Inspector",
    "2021-325704 - Aquaculture Produce Analyst",
    "2021-325705 - Safety Inspector",
    "2021-325706 - Ammunition Technician",
    "2021-325707 - Mine Health and Safety Inspector",
    "2021-325708 - Magazine Master",
    "2021-325801 - Ambulance Officer",
    "2021-325802 - Intensive Care Ambulance Paramedic / Ambulance Paramedic",
    "2021-325901 - Chiropractor",
    "2021-325902 - Osteopath",
    "2021-331101 - Securities Dealer",
    "2021-331105 - Asset Swap Administrator",
    "2021-331106 - Financial Markets Settlement Officer",
    "2021-331201 - Credit or Loans Officer",
    "2021-331204 - False Worker",
    "2021-331205 - Business Banker",
    "2021-331301 - Bookkeeper",
    "2021-331302 - Accounting Technician",
    "2021-331303 - Tax Technician",
    "2021-331401 - Statistical and Mathematical Assistant",
    "2021-331501 - Valuer",
    "2021-331502 - Insurance Investigator",
    "2021-331503 - Insurance Loss Adjuster",
    "2021-331504 - Insurance Risk Surveyor",
    "2021-331505 - Vehicle Damage Quantifier",
    "2021-332101 - Insurance Agent",
    "2021-332102 - Insurance Broker",
    "2021-332201 - Commercial Sales Representative",
    "2021-332202 - Sales Representative (Building and Plumbing Supplies)",
    "2021-332203 - Sales Representative (Personal and Household Goods)",
    "2021-332204 - Commercial Services Sales Agent",
    "2021-332205 - Manufacturers Representative",
    "2021-332206 - Sales Representative (Photographic Equipment and Supplies)",
    "2021-332207 - Chemical Sales Representative",
    "2021-332208 - Pharmacy Sales Assistant",
    "2021-332301 - Retail Buyer",
    "2021-332302 - Purchasing Officer",
    "2021-332401 - Commodities Trader",
    "2021-332402 - Finance Broker",
    "2021-333101 - Clearing and Forwarding Agent",
    "2021-333201 - Events Manager",
    "2021-333301 - Recruitment Officer",
    "2021-333302 - Labour Recruitment Consultant: Permanent Employment Agency (PEA)",
    "2021-333303 - Labour Recruitment Consultant: Temporary Employment Services (TES)",
    "2021-333401 - Property Manager",
    "2021-333402 - Real Estate Agent",
    "2021-333404 - Real Estate Sales Settlement Administrator",
    "2021-333405 - Real Estate Agency Principal",
    "2021-333406 - Property Lease Administrator",
    "2021-333901 - Auctioneer",
    "2021-333902 - Special Services Contracting Agent",
    "2021-333903 - Sales Representative (Business Services)",
    "2021-333904 - Business Broker",
    "2021-333905 - Supply Chain Practitioner",
    "2021-333906 - Stock and Station Agent",
    "2021-333907 - Property Portfolio and Asset Manager",
    "2021-333908 - Marketing Coordinator",
    "2021-333909 - Bid Assistant",
    "2021-333910 - Business Support Coordinator",
    "2021-333911 - Physical Asset Practitioner",
    "2021-334101 - Office Supervisor",
    "2021-334102 - Office Administrator",
    "2021-334103 - Call Centre Team Leader",
    "2021-334201 - Legal Secretary",
    "2021-334301 - Secretary Bargaining Council",
    "2021-334302 - Personal Assistant",
    "2021-334401 - Medical Secretary",
    "2021-335101 - Customs Officer",
    "2021-335102 - Immigration Officer",
    "2021-335201 - Taxation Inspector",
    "2021-335301 - Social Security Assessor",
    "2021-335401 - Driving license examiner",
    "2021-335402 - Import-export Administrator",
    "2021-335403 - Passport Officer (Issuing)",
    "2021-335404 - Motor Vehicle Examiner",
    "2021-335501 - Detective",
    "2021-335502 - Crash Investigator",
    "2021-335901 - Labour Inspector",
    "2021-335902 - Wage Inspector",
    "2021-335903 - Refugee Status Determination Officer",
    "2021-335904 - Pest Management Officer",
    "2021-335905 - Water Allocation Officer",
    "2021-335906 - Environmental Practices Inspector",
    "2021-335907 - Weights and Measures Inspector",
    "2021-335908 - Censorship Inspector",
    "2021-335909 - Price Inspector",
    "2021-335910 - Trade Mark Examiner",
    "2021-335911 - Quarantine Officer",
    "2021-335912 - Fisheries Officer",
    "2021-335913 - Building Inspector",
    "2021-335914 - Train Examiner",
    "2021-335915 - Transport Operations Inspector",
    "2021-335916 - Gaming Operations Compliance Officer",
    "2021-341101 - Conveyancer",
    "2021-341102 - Legal Executive",
    "2021-341103 - Paralegal",
    "2021-341104 - Clerk of Court",
    "2021-341105 - Court Bailiff",
    "2021-341106 - Court Orderly / Court Registry Officer",
    "2021-341107 - Law Clerk",
    "2021-341108 - Trust Officer",
    "2021-341109 - Private Investigator",
    "2021-341110 - Associate Legal Professional",
    "2021-341111 - Debt Counsellor",
    "2021-341112 - Labour Dispute Enforcement Agent",
    "2021-341201 - Auxiliary Community Development Practitioner",
    "2021-341202 - Disabilities Services Officer",
    "2021-341203 - Social Auxiliary Worker",
    "2021-341204 - Auxiliary Child and Youth Care Worker",
    "2021-341301 - Religious Associate Professional",
    "2021-342101 - Footballer",
    "2021-342102 - Golfer",
    "2021-342103 - Jockey",
    "2021-342104 - Cricketer",
    "2021-342105 - Athlete",
    "2021-342106 - Boxer",
    "2021-342107 - Cyclist",
    "2021-342108 - Racing Driver",
    "2021-342109 - Surfer",
    "2021-342110 - Swimmer",
    "2021-342111 - Tennis Player",
    "2021-342112 - Wrestler",
    "2021-342113 - Yachtsman",
    "2021-342114 - Other Sportsperson",
    "2021-342201 - Sports Development Officer",
    "2021-342202 - Sports Umpire",
    "2021-342203 - Sports Official",
    "2021-342204 - Sports Coach or Instructor",
    "2021-342301 - Fitness Instructor",
    "2021-342302 - Outdoor Adventure Guide",
    "2021-342303 - Caving Guide",
    "2021-343101 - Photographer",
    "2021-343102 - Air Observer",
    "2021-343201 - Interior Designer",
    "2021-343202 - Interior Decorator",
    "2021-343203 - Visual Merchandiser",
    "2021-343204 - Set Designer",
    "2021-343301 - Gallery or Museum Technician",
    "2021-343302 - Library Technician",
    "2021-343401 - Chef",
    "2021-343901 - Tattoo Artist",
    "2021-343902 - Light Technician",
    "2021-343903 - Stage Manager",
    "2021-343904 - Theatrical Dresser",
    "2021-343905 - Stunt Person",
    "2021-343906 - Special Effects Person",
    "2021-343907 - Continuity Person",
    "2021-343908 - Film Technician",
    "2021-343909 - Microphone Boom Operator",
    "2021-343910 - Performing Arts Road Manager",
    "2021-351101 - Computer Operator",
    "2021-351201 - ICT Communications Assistant",
    "2021-351301 - Computer Network Technician",
    "2021-351302 - Geographic Information Systems Technicians",
    "2021-351303 - Marine GIS Technician",
    "2021-351401 - Web Technician",
    "2021-352101 - Broadcast Transmitter Operator",
    "2021-352102 - Camera Operator (Film, Television or Video)",
    "2021-352103 - Sound Technician",
    "2021-352104 - Television Equipment Operator",
    "2021-352105 - Radio Station Operator",
    "2021-352106 - Production Assistant (Film, Television or Radio)",
    "2021-352201 - Telecommunications Technical Officer or Technologist",
    "2021-411101 - General Clerk",
    "2021-411102 - Back Office Process Consultant",
    "2021-411103 - Parole Board Clerk",
    "2021-412101 - Secretary (General)",
    "2021-413101 - Word Processing Operator",
    "2021-413102 - Machine Shorthand Reporter",
    "2021-413201 - Data Entry Operator",
    "2021-421101 - Bank Teller",
    "2021-421102 - Bank Worker",
    "2021-421103 - Currency Exchange Officer",
    "2021-421104 - Banknote Processor",
    "2021-421105 - Postal Frontline Service Worker",
    "2021-421201 - Bookmaker",
    "2021-421202 - Gaming Worker",
    "2021-421203 - Betting Agency Counter Clerk",
    "2021-421204 - Bookmaker's Clerk",
    "2021-421205 - Telephone Betting Clerk",
    "2021-421206 - Bingo Caller",
    "2021-421207 - Lotto Operator",
    "2021-421301 - Pawnbrokers and Money-lenders",
    "2021-421401 - Debt Collector",
    "2021-422101 - Tourist Information Officer",
    "2021-422102 - Travel Consultant",
    "2021-422201 - Inbound Contact Centre Consultant",
    "2021-422202 - Outbound Contact Centre Consultant",
    "2021-422203 - Contact Centre Real Time Advisor",
    "2021-422204 - Contact Centre Resource Planner",
    "2021-422205 - Contact Centre Forecast Analyst",
    "2021-422206 - Call or Contact Centre Agent",
    "2021-422301 - Switchboard Operator",
    "2021-422401 - Hotel or Motel Receptionist",
    "2021-422501 - Enquiry Clerk",
    "2021-422601 - Receptionist (General)",
    "2021-422602 - Medical Receptionist",
    "2021-422701 - Survey Interviewer",
    "2021-422901 - Admissions Clerk",
    "2021-422902 - Ship's Purser",
    "2021-431101 - Accounts Clerk",
    "2021-431102 - Cost Clerk",
    "2021-431103 - Taxation Clerk",
    "2021-431201 - Insurance Administrator",
    "2021-431202 - Securities Services Administrative Officer",
    "2021-431203 - Statistical Clerk",
    "2021-431204 - Insurance Claims Administrator",
    "2021-431301 - Payroll Clerk",
    "2021-432101 - Stock Clerk / Officer",
    "2021-432102 - Dispatching and Receiving Clerk / Officer",
    "2021-432103 - Order Clerk / Officer",
    "2021-432104 - Warehouse Administrator / Clerk",
    "2021-432105 - Lampman",
    "2021-432201 - Production Coordinator",
    "2021-432301 - Transport Clerk",
    "2021-441101 - Library Assistant",
    "2021-441201 - Courier",
    "2021-441202 - Postal Delivery Officer",
    "2021-441203 - Mail Clerk",
    "2021-441301 - Coding Clerk",
    "2021-441302 - Proof Reader",
    "2021-441401 - Scribe",
    "2021-441501 - Filing or Registry Clerk",
    "2021-441502 - Office Machine Operator",
    "2021-441601 - Human Resources Clerk",
    "2021-441602 - Skills Development Administrator",
    "2021-441603 - Compensation and Benefits Clerk",
    "2021-441604 - Labour Relations Case Administrator",
    "2021-441605 - Academic Administrative Officer",
    "2021-441901 - Classified Advertising Clerk",
    "2021-441902 - Contract Administrator",
    "2021-441903 - Program or Project Administrators",
    "2021-441905 - Account Clerk (Public Relations / Communication)",
    "2021-511101 - Flight Attendant",
    "2021-511102 - Bus Hostess",
    "2021-511103 - Marine Steward",
    "2021-511104 - Railway Steward",
    "2021-511201 - Transport Conductor",
    "2021-511301 - Gallery or Museum Guide",
    "2021-511302 - Tour Guide",
    "2021-512101 - Cook",
    "2021-513101 - Waiter",
    "2021-513102 - Cafe Worker",
    "2021-513201 - Bar Attendant",
    "2021-513202 - Barista",
    "2021-514101 - Hairdresser",
    "2021-514102 - Hair or Beauty Salon Assistant",
    "2021-514201 - Skin Care Therapist",
    "2021-514202 - Slimming Therapist",
    "2021-514203 - Hair Removal Technician",
    "2021-514204 - Nail Technician",
    "2021-514205 - Make Up Artist",
    "2021-514206 - Weight Loss Consultant",
    "2021-514207 - Somatologist",
    "2021-514208 - Spa Therapist",
    "2021-514209 - Aesthetic Laser Therapist",
    "2021-514210 - Image consultant",
    "2021-515101 - Hotel Service Manager",
    "2021-515102 - Housekeeping Service Manager",
    "2021-515103 - Commercial Housekeeper",
    "2021-515104 - Cleaning Practitioner",
    "2021-515201 - Domestic Housekeeper",
    "2021-515202 - Butler",
    "2021-515301 - Caretaker",
    "2021-515302 - Amusement, Fitness or Sport Centre Attendant",
    "2021-516101 - Astrologer",
    "2021-516201 - Escort",
    "2021-516301 - Funeral Director",
    "2021-516303 - Embalmer",
    "2021-516305 - Mortuary Technician / Assistant",
    "2021-516306 - Crematorium Operator",
    "2021-516401 - Animal Attendant / Groomer",
    "2021-516402 - Animal Trainer",
    "2021-516403 - Zookeeper",
    "2021-516404 - Dog Walker",
    "2021-516501 - Driving Instructor",
    "2021-516901 - Refuge Worker",
    "2021-516902 - Civil Celebrant",
    "2021-521101 - Street Market Vendor",
    "2021-521201 - Street Food Sales Person",
    "2021-521202 - Cash Van Salesperson",
    "2021-522101 - Antique Dealer",
    "2021-522102 - Salon Manager",
    "2021-522201 - Retail Supervisor",
    "2021-522301 - Sales Assistant (General)",
    "2021-522302 - Motorised Vehicle or Caravan Salesperson",
    "2021-522303 - Automotive Parts Salesperson",
    "2021-522304 - ICT Sales Assistant",
    "2021-523101 - Checkout Operator",
    "2021-523102 - Office Cashier",
    "2021-523103 - Ticket Seller",
    "2021-524101 - Model",
    "2021-524102 - Event Stylist",
    "2021-524201 - Sales Demonstrator",
    "2021-524301 - Door-to-door Salesperson",
    "2021-524401 - Call Centre Salesperson",
    "2021-524501 - Service Station Attendant",
    "2021-524601 - Food Service Counter Attendant",
    "2021-524901 - Materials Recycler",
    "2021-524902 - Rental Salesperson",
    "2021-524903 - Sales Clerk / Officer",
    "2021-524904 - Energy Broker",
    "2021-531101 - Child Care Worker",
    "2021-531102 - Family Day Care Worker",
    "2021-531103 - Nanny",
    "2021-531104 - Out of School Hours Care Worker",
    "2021-531105 - Child or Youth Residential Care Assistant",
    "2021-531106 - Hostel Parent",
    "2021-531201 - Teachers' Aide",
    "2021-531202 - Pre-School Aide",
    "2021-531203 - Integration Aide",
    "2021-532201 - Residential Care Officer",
    "2021-532202 - Aged or Disabled Carer",
    "2021-532203 - Community Health Worker",
    "2021-532901 - First Aid Attendant",
    "2021-532902 - Hospital Orderly",
    "2021-532903 - Nursing Support Worker",
    "2021-532904 - Personal Care Assistant",
    "2021-532905 - Therapy Aide",
    "2021-532906 - Natural Remedy Consultant",
    "2021-541101 - Fire Fighter",
    "2021-541102 - Hazardous Materials Removal Workers",
    "2021-541201 - Traffic Officer",
    "2021-541202 -  Non - commissioned Police Official",
    "2021-541203 - Military Police Official",
    "2021-541301 - Prison Officer",
    "2021-541401 - Security Officer",
    "2021-541402 - Alarm, Security or Surveillance Monitor",
    "2021-541403 - Retail Loss Prevention Officer",
    "2021-541404 - Close protector",
    "2021-541501 - Intelligence Operator",
    "2021-541901 - Lifeguard",
    "2021-541902 - Emergency Service and Rescue Official",
    "2021-541903 - Parking Inspector",
    "2021-541904 - Armoured Car Escort",
    "2021-541905 - Crowd Controller",
    "2021-541906 - Security Consultant",
    "2021-541907 - Disaster Management Officer",
    "2021-542101 - Naval Combat Operator",
    "2021-542102 - Naval Combat Officer",
    "2021-542201 - Special Forces Manager",
    "2021-542202 - Special Forces Officer",
    "2021-542203 - Special Forces Operator",
    "2021-542301 - Infantry Soldier",
    "2021-542302 - Artillery Soldier",
    "2021-542303 - Air Defence Artillery Soldier",
    "2021-542304 - Armour Soldier",
    "2021-542305 - Engineer Soldier",
    "2021-542306 - Signal Soldier",
    "2021-542401 - Combat Medical Support Operator",
    "2021-542501 - Air Operations Officer",
    "2021-611101 - Agronomy Farmer",
    "2021-611102 - Field Vegetable Farmer",
    "2021-611201 - Arboriculture Farmer",
    "2021-611202 - Horticultural Farmer",
    "2021-611301 - Ornamental Horticultural Farmer",
    "2021-611302 - Landscape Contractor",
    "2021-611303 - Groundskeeper",
    "2021-611304 - Nurseryperson",
    "2021-611306 - Arboriculturist",
    "2021-611401 - Mixed Crop Farm Production Manager / Foreman",
    "2021-612101 - Livestock Farmer",
    "2021-612102 - Dairy Farmer",
    "2021-612201 - Poultry Farmer",
    "2021-612202 - Ratites Farmer",
    "2021-612301 - Insect Farmer",
    "2021-612901 - Avian Farmer",
    "2021-612902 - Game Farmer",
    "2021-613101 - Mixed Crop and Livestock Farmer",
    "2021-621101 - Tree Feller",
    "2021-621102 - Forest and Conservation Workers",
    "2021-622101 - Aquaculture Farmer",
    "2021-622102 - Mariculture Farmer",
    "2021-622201 - Skipper (Fishing Boat)",
    "2021-622301 - Master Fisher",
    "2021-622401 - Hunter",
    "2021-631101 - Subsistence Crop Farmer",
    "2021-631201 - Subsistence Livestock Farmer",
    "2021-631301 - Subsistence Mixed Crop and Livestock Farmers",
    "2021-641101 - House Builder",
    "2021-641201 - Bricklayer",
    "2021-641301 - Stonemason",
    "2021-641302 - Granite Cutter",
    "2021-641303 - Refractory Mason",
    "2021-641401 - Concreter",
    "2021-641402 - Fibre-cement Moulder",
    "2021-641403 - Civil Engineering Constructor",
    "2021-641501 - Carpenter and Joiner",
    "2021-641502 - Carpenter",
    "2021-641503 - Joiner",
    "2021-641901 - Demolition Technician",
    "2021-641902 - Scaffolder",
    "2021-642101 - Roof Tiler",
    "2021-642102 - Roof Plumber",
    "2021-642103 - Roof Thatcher",
    "2021-642201 - Wall and Floor Tiler",
    "2021-642202 - Floor Finisher",
    "2021-642301 - Fibrous Plasterer",
    "2021-642302 - Plasterer",
    "2021-642401 - Insulation Installer",
    "2021-642501 - Glazier",
    "2021-642601 - Plumber",
    "2021-642602 - Solar Installer",
    "2021-642603 - Gas Practitioner",
    "2021-642604 - Fire Services Plumber",
    "2021-642605 - Plumbing Inspector",
    "2021-642606 - Heat Pump Installer",
    "2021-642607 - Pipe Fitter",
    "2021-642701 - Air-conditioning and Refrigeration Mechanic",
    "2021-642702 - Refrigeration Mechanic",
    "2021-643101 - Painter",
    "2021-643201 - Industrial Spraypainter",
    "2021-643202 - Vehicle Painter",
    "2021-643302 - Chimney Cleaner",
    "2021-651101 - Moulder",
    "2021-651201 - Pressure Welder",
    "2021-651202 - Welder",
    "2021-651203 - Fitter-welder",
    "2021-651204 - Gas Cutter",
    "2021-651301 - Sheet Metal Worker",
    "2021-651302 - Boiler Maker",
    "2021-651401 - Metal Fabricator",
    "2021-651402 - Structural Steel Erector",
    "2021-651403 - Steel Fixer",
    "2021-651404 - Structural Plater",
    "2021-651501 - Rigger",
    "2021-651502 - Cable and Rope Splicer",
    "2021-652101 - Blacksmith",
    "2021-652102 - Forging Press Worker",
    "2021-652201 - Toolmaker",
    "2021-652202 - Gunsmith",
    "2021-652203 - Locksmith",
    "2021-652204 - Patternmaker",
    "2021-652205 - Master Toolmaker",
    "2021-652206 - Die Sinker",
    "2021-652301 - Metal Machinist",
    "2021-652302 - Fitter and Turner",
    "2021-652401 - Metal Polisher",
    "2021-652402 - Tool Grinder and Sharpener",
    "2021-652403 - Saw Maker and Repairer",
    "2021-652404 - Grinder",
    "2021-653101 - Automotive Motor Mechanic",
    "2021-653103 - Motorcycle Mechanic",
    "2021-653109 - Automotive Engine Mechanic",
    "2021-653201 - Aircraft Maintenance Mechanic",
    "2021-653202 - Aircraft Structures Worker",
    "2021-653301 - Industrial Machinery Mechanic",
    "2021-653302 - Mechanical Equipment Repairer",
    "2021-653303 - Mechanical Fitter",
    "2021-653304 - Diesel Fitter",
    "2021-653305 - Small Engine Mechanic",
    "2021-653306 - Diesel Mechanic",
    "2021-653307 - Heavy Equipment Mechanic",
    "2021-653308 - Tractor Mechanic",
    "2021-653309 - Forklift Mechanic",
    "2021-653310 - Lubrication Equipment Mechanic",
    "2021-653401 - Bicycle Mechanic",
    "2021-653402 - Non-motorised Transport Equipment Repairer",
    "2021-661101 - Precision Instrument Maker and Repairer",
    "2021-661102 - Watch and Clock Maker and Repairer",
    "2021-661103 - Scale Fitter",
    "2021-661201 - Musical Instrument Maker or Repairer",
    "2021-661301 - Goldsmith",
    "2021-661302 - Diamond and Gemstone Setter",
    "2021-661303 - Jewellery Evaluator",
    "2021-661304 - Diamond Sorter and Evaluator",
    "2021-661401 - Potter or Ceramic Artist",
    "2021-661501 - Glass Maker",
    "2021-661502 - Optical Mechanic",
    "2021-661601 - Signwriter",
    "2021-661602 - Engraver",
    "2021-661701 - Basket, Cane and Wicker Worker",
    "2021-661702 - Carver",
    "2021-661703 - Cane Furniture Maker",
    "2021-661801 - Textile, Leather and Related Materials Handicraft Workers",
    "2021-661901 - Metal Toymaker",
    "2021-662101 - Electronic Pre-press",
    "2021-662104 - Electronic Originator",
    "2021-662105 - Gravure Cylinder Preparation Technician",
    "2021-662106 - Process Engraver",
    "2021-662107 - Printing Plate Maker",
    "2021-662201 - Printing Machinist",
    "2021-662202 - Small Offset Lithography Operator",
    "2021-662203 - Screen Printer",
    "2021-662204 - Paper Sheetfed Offset Lithography Technician",
    "2021-662205 - Metal Sheetfed Offset Lithography Technician",
    "2021-662206 - Continuous Stationery Printing Machine Technician",
    "2021-662207 - Monoblock Offset Machine Technician",
    "2021-662208 - Roll Label Machine Technician",
    "2021-662209 - Gravure Printing Technician",
    "2021-662210 - Heatset Rotary Offset Lithography Technician",
    "2021-662211 - Coldset Rotary Offset Lithographic Printing Technician (Coldset)",
    "2021-662212 - Rotary Printing and Re-reeling Flexographic Machine Technician",
    "2021-662213 - Rotary Printing and Re-reeling Gravure Machine Technician",
    "2021-662215 - Stationery Machine Technician",
    "2021-662216 - Commercial Digital Printer",
    "2021-662301 - Mechanized soft-cover bookbinding technician",
    "2021-662303 - Mechanised Bookbinding Technician",
    "2021-662304 - Craft Bookbinding Technician",
    "2021-662305 - Mechanised Hard-cover Bookbinding Technician",
    "2021-662307 - Folding Machine Operator (Paper Products)",
    "2021-662308 - Saddle Stitch Machine Operator",
    "2021-662309 - Adhesive Binding Machine Operator",
    "2021-662310 - Book Sewing Machine Operator",
    "2021-662311 - Gathering Machine Operator",
    "2021-662312 - Commercial Mailing Machine Operator",
    "2021-662313 - Newspaper and Magazine Mailroom Machine Operator",
    "2021-662314 - Envelope Manufacturing Machine Operator",
    "2021-662315 - Coating Machine Operator",
    "2021-662316 - Foiling Machine Operator",
    "2021-662317 - Roll Label Rewind Machine Operator",
    "2021-671101 - Electrician",
    "2021-671102 - Electrical Installation Inspector",
    "2021-671202 - Millwright",
    "2021-671203 - Mechatronics Technician",
    "2021-671204 - Lift Mechanic",
    "2021-671205 - Weapon Systems Mechanic",
    "2021-671206 - Electrical Equipment Mechanic",
    "2021-671207 - Armature Winder",
    "2021-671208 - Transportation Electrician",
    "2021-671301 - Electrical Line Mechanic",
    "2021-671302 - Cable Jointer",
    "2021-672101 - Avionics Mechanician",
    "2021-672102 - Radar Mechanic",
    "2021-672103 - Business Machine Mechanic",
    "2021-672104 - Electronic Equipment Mechanician",
    "2021-672105 - Instrument Mechanician",
    "2021-672107 - Special Class Electrician",
    "2021-672108 - Radiotrician",
    "2021-672201 - Data and Telecommunications Cabler",
    "2021-672202 - Telecommunications Cable Jointer",
    "2021-672203 - Computer Engineering Mechanic / Service Person",
    "2021-672204 - Telecommunications Line Mechanic",
    "2021-672205 - Telecommunications Technician",
    "2021-672206 - Communications Operator",
    "2021-681101 - Slaughterer",
    "2021-681102 - Red Meat De-boner",
    "2021-681103 - Butcher",
    "2021-681104 - Fishmonger",
    "2021-681105 - Poultry Slaughterer",
    "2021-681201 - Confectionary Baker",
    "2021-681202 - Pastry Cook",
    "2021-681203 - Confectionery Maker",
    "2021-681301 - Dairyman",
    "2021-681401 - Fruit or Vegetable Preserver",
    "2021-681402 - Oil Expeller",
    "2021-681403 - Jam Maker",
    "2021-681501 - Cheese Grader / Tester",
    "2021-681502 - Food Taster / Grader",
    "2021-681503 - Tea Taster / Grader",
    "2021-681504 - Wine Taster / Grader",
    "2021-681505 - Fruit and Vegetable Grader / Classer",
    "2021-681506 - Livestock Product Analyst",
    "2021-681601 - Cigar Maker",
    "2021-681602 - Green Tobacco Storage Controller / Manager",
    "2021-681603 - Tobacco Processing Machine Operator",
    "2021-682101 - Wood Preparer and Treater",
    "2021-682102 - Plywood and Veneer Maker and Repairer",
    "2021-682201 - Cabinet Maker",
    "2021-682203 - Wood Model Maker",
    "2021-682301 - Furniture Finisher",
    "2021-682302 - Picture Framer",
    "2021-682303 - Wood Machinist",
    "2021-682304 - Wood Turner",
    "2021-682305 - Cooper",
    "2021-683101 - Tailor",
    "2021-683102 - Furrier",
    "2021-683103 - Hat Maker",
    "2021-683104 - Wig Maker",
    "2021-683201 - Clothing, Home Textiles and General Goods Cutter",
    "2021-683202 - Apparel and related pattern maker",
    "2021-683301 - Canvas Goods Maker",
    "2021-683302 - Sail Maker",
    "2021-683303 - Textile Produce Mender and Embroiderer",
    "2021-683401 - Upholsterer",
    "2021-683402 - Bed Maker",
    "2021-683501 - Fellmonger",
    "2021-683502 - Tanner",
    "2021-683503 - Pelt Grader",
    "2021-683601 - Shoemaker",
    "2021-684101 - Diver",
    "2021-684201 -  Mining Blaster",
    "2021-684202 - Blaster",
    "2021-684301 - Crop Produce Analyst",
    "2021-684302 - Tobacco Grader",
    "2021-684303 - Cotton Grader",
    "2021-684304 - Wool Classer / Grader",
    "2021-684305 - Quality Controller (Manufacturing)",
    "2021-684401 - Pest or Weed Controller",
    "2021-684901 - Textile, Clothing, Footwear and Leather Processing Machine Mechanic",
    "2021-684902 - Farrier",
    "2021-684904 - Panelbeater",
    "2021-684905 - Vehicle Body Builder",
    "2021-684906 - Vehicle Trimmer",
    "2021-684907 - Boatbuilder and Repairer",
    "2021-684908 - Shipwright",
    "2021-684909 - Survival Equipment Fitter",
    "2021-684910 - Ammunition Fitter",
    "2021-684911 - Florist",
    "2021-684912 - Photographer's Assistant",
    "2021-684913 - Melter",
    "2021-684914 - Textile Machine Mechanic",
    "2021-711101 - Mining Operator",
    "2021-711102 - Shotcreter",
    "2021-711201 - Mineral Processing Plant Operator",
    "2021-711202 - Jewellery Processing and Finishing Machine Operator",
    "2021-711203 - Diamond Cutter and Polisher",
    "2021-711204 - Gemstone Cutter",
    "2021-711205 - Gemstone Machine Operator",
    "2021-711301 - Driller",
    "2021-711302 - Rock Drill Operator",
    "2021-711401 - Concrete Products Machine Operator",
    "2021-711402 - Glass, Clay and Stone Manufacturing Machine Setter and Minder",
    "2021-711403 - Plaster Machine Operator",
    "2021-711404 - Cement Production Plant Operator",
    "2021-711405 - Concrete Batching Plant Operator",
    "2021-711406 - Industrial Diamond Polishing Machine Operator",
    "2021-712101 - Metal Processing Plant Operator",
    "2021-712102 - Metal Manufacturing Machine Setter and Minder",
    "2021-712103 - Abrasive Wheel Maker",
    "2021-712104 - Brake Lining Maker",
    "2021-712201 - Electroplater",
    "2021-713101 - Chemical Production Machine Operator",
    "2021-713201 - Photographic Developer and Printer",
    "2021-714101 - Rubber Production Machine Operator",
    "2021-714102 - Rubber Manufacturing Machine Setter and Minder",
    "2021-714201 - Plastic Cablemaking Machine Operator",
    "2021-714202 - Plastic Compounding and Reclamation Machine Operator",
    "2021-714203 - Plastics Fabricator or Welder",
    "2021-714204 - Plastics Production Machine Operator (General)",
    "2021-714205 - Reinforced Plastic and Composite Production Worker",
    "2021-714206 - Rotational Moulding Operator (Plastics)",
    "2021-714207 - Thermoforming Machine Operator",
    "2021-714208 - Plastics Manufacturing Machine Minder",
    "2021-714209 - Reinforced Plastics and Composite Trades Worker",
    "2021-714301 - Paper Products Machine Operator",
    "2021-715101 - Fibre Preparation Production Machine Operator",
    "2021-715102 - Yarn Production Machine Operator",
    "2021-715103 - Man-made Fibre Production Machine Operator",
    "2021-715201 - Weaving Machine Operator",
    "2021-715202 - Warping Machine Operator",
    "2021-715203 - Braiding Machine Operator",
    "2021-715204 - Knitting Machine Operator",
    "2021-715205 - Non-woven Machine Operator",
    "2021-715206 - Textile Dry Finishing Machine Operator",
    "2021-715301 - Sewing Machine Operator",
    "2021-715302 - Clothing, Textile and Leather Goods Production Operator",
    "2021-715401 - Textile Wet Process Production Operator",
    "2021-715501 - Leather Processing Machine Operator",
    "2021-715601 - Footwear Cutting Production Machine Operator",
    "2021-715602 - Footwear Closing Production Machine Operator",
    "2021-715603 - Footwear Bottom Stock Production Machine Operator",
    "2021-715604 - Footwear Lasting Production Machine Operator",
    "2021-715605 - Footwear Finishing Production Machine Operator",
    "2021-715701 - Laundry and Dry Cleaning Machine Operator",
    "2021-715901 - Textile and Footwear Manufacturing Machine Minder",
    "2021-716101 - Fruit and Vegetable Processing Machine Operator",
    "2021-716102 - Distillery Process Machine Operator",
    "2021-716103 - Juice Extraction and Blending Process Machine Operator",
    "2021-716104 - Dairy Products Machine Operator",
    "2021-716105 - Bakery and Confectionary Products Machine Operator",
    "2021-716106 - Sugar Processing Machine Operator",
    "2021-716107 - Coffee and Tea Processing Machine Operator",
    "2021-716108 - Seed Processing Machine Operator",
    "2021-716109 - Milling Process Machine Operator",
    "2021-716110 - Tobacco Product Processing Machine Operator",
    "2021-716111 - Meat Processing Machine Operator",
    "2021-716112 - Seafood Processing Machine Operator",
    "2021-716113 - Grain Handling Operator",
    "2021-716114 - Sparkling Soft drink process machine operator",
    "2021-716115 - Wine processing machine operator",
    "2021-716116 - Cereals, snacks, pasta and condiments machine process operator",
    "2021-716117 - Brew house Process Machine Operator",
    "2021-716118 - Food and Beverage Process Operator",
    "2021-717101 - Wood and Paper Manufacturing Machine Minder",
    "2021-717102 - Paper and Pulp Mill Operator",
    "2021-717201 - Wood Processing Machine Operator",
    "2021-718101 - Clay Production Machine Operator",
    "2021-718102 - Glass Production Machine Operator",
    "2021-718201 - Boiler or Engine Operator",
    "2021-718301 - Labelling Machine Operator",
    "2021-718302 - Packing Machine Operator",
    "2021-718303 - Filling Line Operator",
    "2021-718304 - Packaging Manufacturing Machine Minder",
    "2021-718901 - Silicon Chip Production Machine Operator",
    "2021-718902 - Cable and Rope Splicing Machine Operator",
    "2021-718903 - Cable Manufacturing Machine Minder",
    "2021-718904 - Integrated Manufacturing Line Machine Setter",
    "2021-718905 - Engineering Production Systems Worker",
    "2021-718906 - Bulk Materials Handling Plant Operator",
    "2021-718907 - Weighbridge Operator",
    "2021-718908 - Car Compactor Operator",
    "2021-718909 - Lighthouse Keeper",
    "2021-718910 - Lock Master (Water Transport)",
    "2021-718911 - Snow Maker",
    "2021-718912 - Wash Plant Operator",
    "2021-718913 - Motion Picture Projectionist",
    "2021-718914 - Sand Blaster",
    "2021-718915 - Venetian Blind Machine Operator",
    "2021-721101 - Machinery Assembler",
    "2021-721201 - Electrical and Electronic Equipment Assembler",
    "2021-721901 - Product Assembler",
    "2021-731101 - Train Driver",
    "2021-731102 - Tram Driver",
    "2021-731201 - Railway Signal Operator",
    "2021-731202 - Train Controller",
    "2021-732101 - Delivery Driver",
    "2021-732102 - Delivery Driver (Motorcycle)",
    "2021-732201 - Chauffeur",
    "2021-732202 - Taxi Driver",
    "2021-732203 - Emergency Vehicle Drivers",
    "2021-732204 - Oversize Load Pilot / Escort",
    "2021-733101 - Bus Driver",
    "2021-733102 - Charter and Tour Bus Driver",
    "2021-733103 - Passenger Coach Driver",
    "2021-733201 - Truck Driver (General)",
    "2021-733202 - Aircraft Refueller",
    "2021-733203 - Furniture Removalist",
    "2021-733204 - Tanker Driver",
    "2021-733205 - Tow Truck Driver",
    "2021-733206 - Armoured Personnel Carrier Operator",
    "2021-733207 - Snow Groomer",
    "2021-733208 - Mobile Mining Equipment Operator",
    "2021-733209 - Linemarker",
    "2021-733210 - Road Construction Plant Operator",
    "2021-733211 - Remotely Operated Vehicle (ROV) Pilot",
    "2021-734101 - Agricultural Mobile Plant (Equipment) Operator",
    "2021-734102 - Logging Plant Operator",
    "2021-734201 - Earthmoving Plant Operator (General)",
    "2021-734202 - Backhoe Operator",
    "2021-734203 - Bulldozer Operator",
    "2021-734204 - Excavator Operator",
    "2021-734205 - Grader Operator",
    "2021-734206 - Loader Operator",
    "2021-734207 - Mulcher Operator",
    "2021-734208 - Tunnelling Machine Operator",
    "2021-734209 - Mobile Explosives Manufacturing Unit (MEMU) Operator",
    "2021-734210 - Scraper Operator",
    "2021-734211 - Dragline Operator",
    "2021-734212 - Railway Track Master",
    "2021-734213 - Road Roller Operator",
    "2021-734214 - Dump Truck Operator",
    "2021-734301 - Crane or Hoist Operator",
    "2021-734302 - Cable Ferry Operator",
    "2021-734303 - Dredge Operator",
    "2021-734401 - Lift Operator",
    "2021-734402 - Forklift Driver",
    "2021-734403 - Straddle Carrier Operator",
    "2021-735101 - Deck Hand",
    "2021-735102 - Jetty Operator",
    "2021-811101 - Domestic Cleaner",
    "2021-811201 - Commercial Cleaner",
    "2021-811202 - Healthcare Cleaner",
    "2021-811203 - Tea Attendant",
    "2021-811204 - Caretaker / cleaner",
    "2021-812101 - Laundry Worker (General)",
    "2021-812103 - Ironer or Presser",
    "2021-812104 - Carpet Cleaner",
    "2021-812201 - Vehicle Detailer (Valet Servicer)",
    "2021-812301 - Window Cleaner",
    "2021-812901 - Septic Tank Cleaner",
    "2021-812902 - Swimming Pool Cleaner",
    "2021-812903 - Washroom Attendant",
    "2021-812904 - Sterilisation Technician",
    "2021-821101 - Crop Production Farm Worker / Assistant",
    "2021-821102 - Irrigationist",
    "2021-821103 - Scout",
    "2021-821104 - Harvester / Picker",
    "2021-821105 - Pruner",
    "2021-821201 - Livestock Farm Worker / Assistant",
    "2021-821202 - Insect Farm Worker / Assistant",
    "2021-821203 - Game Farm Worker / Assistant",
    "2021-821204 - Poultry, Ratites or Avian Farm Worker / Assistant",
    "2021-821205 - Wool Handler",
    "2021-821301 - Mixed Crop and Livestock Farm Worker / Assistant",
    "2021-821401 - Garden Workers",
    "2021-821402 - Ornamental Horticultural or Nursery Assistant",
    "2021-821403 - Indoor Plant Worker",
    "2021-821501 - Forestry Worker",
    "2021-821502 - Logging Assistant",
    "2021-821601 - Fishing Hand",
    "2021-821602 - Aquaculture Farm Worker / Assistant",
    "2021-831101 - Mining Worker",
    "2021-831102 - Driller's Assistant",
    "2021-831103 - Mineral Beneficiation Plant Worker",
    "2021-831104 - Mining Spotter Controller",
    "2021-831301 - Builder's Worker",
    "2021-831302 - Drainage, Sewerage and Storm Water Worker",
    "2021-831303 - Earthmoving Worker",
    "2021-831304 - Plumber's Assistant",
    "2021-831305 - Cement and Concrete Plant Worker",
    "2021-831306 - Paving and Surfacing Worker",
    "2021-831307 - Railway Track Worker",
    "2021-831308 - Crane Chaser",
    "2021-831309 - Lagger",
    "2021-831310 - Surveyor's Assistant",
    "2021-831311 - Fencer",
    "2021-831312 - Sign Erector",
    "2021-831313 - Water Process Worker",
    "2021-832101 - Packer (Non Perishable Products)",
    "2021-832102 - Meat Packer",
    "2021-832103 - Fish or Seafood Packer",
    "2021-832104 - Cheese Packer",
    "2021-832901 - Metal Engineering Process Worker",
    "2021-832902 - Plastics, Composites and Rubber Factory Worker",
    "2021-832903 - Timber and Wood Process Worker",
    "2021-832904 - Food and Beverage Factory Worker",
    "2021-832905 - Footwear and Leather Factory Worker",
    "2021-832906 - Glass Processing Worker",
    "2021-832907 - Chemical Plant Worker",
    "2021-832908 - Clay Processing Factory Worker",
    "2021-832909 - Textile, Clothing and Footwear Factory Worker",
    "2021-832910 - Component Fitter",
    "2021-833301 - Freight Handler (Rail or Road)",
    "2021-833302 - Truck Driver's Offsider",
    "2021-833303 - Waterside Worker",
    "2021-833304 - Airline Ground Crew",
    "2021-833401 - Shelf Filler",
    "2021-833402 - Store Person",
    "2021-841101 - Fast Food Cook",
    "2021-841201 - Kitchenhand",
    "2021-841202 - Food Trade Assistant",
    "2021-851101 - Car Park Attendant",
    "2021-851102 - Leaflet or Newspaper Deliverer",
    "2021-852101 - Street Vendor",
    "2021-861101 - Recycling or Rubbish Collector",
    "2021-861201 - Refuse Sorter",
    "2021-861202 - Waste Material Sorter and Classifier",
    "2021-861301 - Street Sweeper Operator",
    "2021-862101 - Busser",
    "2021-862102 - Luggage Porter",
    "2021-862103 - Cloak Room Attendant",
    "2021-862104 - Hotel Cellar Hand",
    "2021-862201 - Home Improvement Installer",
    "2021-862202 - Handyperson",
    "2021-862301 - Meter Reader",
    "2021-862302 - Vending Machine Attendant",
    "2021-862913 - Event Assistant",
    "2021-862914 - Sheltered Workshop Worker",
    "2021-862915 - Chemical Mixer",
    "2021-862916 - Farm Maintenance Worker",
    "2021-862917 - Crossing Supervisor",
    "2021-862918 - Electrical or Telecommunications Trades Assistant",
    "2021-862919 - Mechanic Trade Assistant",
    "2021-862920 - Railways Assistant",
    "2021-862922 - Electronics and Telecommunications Trades Assistant",
    "2021-862923 - Trolley Collector",
    "2021-862924 - Stage or Studio Hand",
    "2021-862925 - Caddie",
    "2021-862926 - Ticket Collector",
    "2021-862927 - Borehole Pump Operator",
    "-",
    "2019-862917 - Crossing Supervisor",
    "2019-862918 - Electrical or Telecommunications Trades Assistant",
    "2019-862919 - Mechanic Trade Assistant",
    "2019-862920 - Railways Assistant",
    "2019-862922 - Electronics and Telecommunications Trades Assistant",
    "2019-862923 - Trolley Collector",
    "2019-862924 - Stage or Studio Hand",
    "2019-862925 - Caddie",
    "2019-862926 - Ticket Collector",
    "2019-862927 - Borehole Pump Operator ",
];

export default OFOOccupations;
