


import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "../DropDown/Dropdown";
import React, { useState } from "react";
//import 'bootstrap-daterangepicker/daterangepicker.css';

const ProgrammeInterventionCostFilters = () => {

    return (
        <>
            <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
                <div className="m-0 my-1 d-flex">
                    <div className=" d-flex flex-column">

                        <div className="d-flex flex-wrap">
                        <Dropdown label={"Region"} myClass={"mx-2 my-1"} filterItems={[  { id: 1, label: "HEAD OFFICE" }]}/>

                            <div className="position-relative d-flex py-2">
                                <span className="mx-2 mt-1 text-nowrap">
                                    Period:
                                </span>
                                <select defaultValue={1} class="form-select me-2" id="inputGroupSelect01">
                                    <option>---Select---</option>
                                    <option value="1">2022</option>
                                </select>
                                <select defaultValue={1} class="form-select" id="inputGroupSelect01">
                                    <option>---Select---</option>
                                    <option className="px-3" value="1">Feb</option>
                                </select> 
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default ProgrammeInterventionCostFilters