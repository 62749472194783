import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import WSPATRFilters from '../../components/Filters/AuditTrailFilters';
import SkillsDevelopmentSpendFilters from '../../components/Filters/SkillsDevelopmentSpendFilters';


const SkillsDevelopmentSpend = () => {

  const chartProps1 = {
    type: 'column',
  
    title: '',
    categories: ["Apr ‘22", "May ‘22", "Jun ‘22", "Jul ‘22", "Aug ‘22", "Sep ‘22", "Oct ‘22", "Nov ‘22", "Dec ‘22", "Jan ‘22", "Feb ‘22", "Mar ‘22"],
    colorByPoint: false,
    colors: ['#5BBDCB', '#4D91B6'],
    creditsEnabled: false,
    credits: '',
    legendEnabled: true,
    series: [
      {
        type: 'line',
        name: 'Actuarial & Risk',
        data: [1000000, 2000000, 2500000, 2500000, 2500000, 1900000, 6000000, 1900000, 2000000, 1700000, 2000000, 2000000],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#FFD530',
        marker: {
          lineWidth: 2,
          lineColor: '#FFD530',
          fillColor: '#FFD530'
        }
      },

      {
        type: 'line',
        name: 'Client Services',
        data: [4000000, 4100000, 4200000, 4300000, 4500000, 4600000, 4700000, 4800000, 4800000, 4800000, 4900000, 4900000],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#5BBDCB',
        marker: {
          lineWidth: 2,
          lineColor: '#5BBDCB',
          fillColor: '#5BBDCB'
        }
      },

      {
        type: 'line',
        name: 'Sales & Marketing',
        data: [5000000, 5100000, 5200000, 4300000, 5500000, 5600000, 5700000, 5800000, 5800000, 3800000, 5900000, 5900000],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#7A0200',
        marker: {
          lineWidth: 2,
          lineColor: '#7A0200',
          fillColor: '#7A0200'
        }
      },


      {
        type: 'line',
        name: 'Asset Management',
        data: [6000000, 6100000, 6200000, 7300000, 6500000, 6600000, 6700000, 6800000, 5800000, 6800000, 6900000, 6900000],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#67904B',
        marker: {
          lineWidth: 2,
          lineColor: '#67904B',
          fillColor: '#67904B'
        }
      },

      {
        type: 'line',
        name: 'Shared Services',
        data: [8000000, 8100000, 8200000, 8300000, 8500000, 8600000, 8700000, 8800000, 4800000, 4800000, 4900000, 4900000],
        dataLabels: {
          enabled: false,
          format: '{y}',
          style: {
            color: 'black',
            textOutline: 'none'
          }
        },
        color: '#FBAB18',
        marker: {
          lineWidth: 2,
          lineColor: '#FBAB18',
          fillColor: '#FBAB18'
        }
      }
    ]
  };

  const getOptions = (props) => ({
    chart: {
      type: props.type,
      // width: props.width === undefined ? 500 : props.width,
      // height: props.height === undefined ? 400 : props.height,
      backgroundColor: 'transparent',
    },
    plotOptions: {
      column: {
        colorByPoint: props.colorByPoint
      },
      series: {
        borderRadius: '25%'
      }
    },
    colors: props.colors,
    title: {
      text: props.title
    },
    credits: {
      enabled: props.creditsEnabled
    },
    legend: {
      enabled: props.legendEnabled
    },
    yAxis: {
      title: {
        text: 'Percentages(%)',
      },
    },
    xAxis: {
      categories: props.categories,
      labels: {
        style: {
          fontSize: '12px', // Adjust font size if needed
          whiteSpace: 'normal', // Allow labels to wrap when long
          overflow: 'scroll',
        },
        tickWidth: 0,
      },
    },
    series: props.series,
  });


 

  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Dashboards / Skills Development Spend"} />
      <main id="main" className="main">
        <div className='d-flex flex-column'>
          <div className='d-flex mt-3'>
            <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
            <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
          </div>
        </div>
        <SkillsDevelopmentSpendFilters/>
        <section className="section mt-3">
          <div className='row'>

            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>

                <div className='card-body'>
                  <div className="">
                    <h5 className="header-title">Cumulative Spend per Business Unit</h5>
                  </div>
                  <br/>
                  <div className={"d-flex justify-content-center"}>
                  </div>
                  <HighchartsReact highcharts={Highcharts} options={getOptions(chartProps1)} />
                  <div>
                    <button className='btn btn-sm py-1 text-light bg-secondary'>
                      <i class="fa-solid fa-file-excel me-2"></i>
                      Export data Table
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
export default SkillsDevelopmentSpend;
