import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../Header';
import WSPATRFilters from '../../components/Filters/AuditTrailFilters';
import SkillsDevelopmentElementScoreFilters from '../../components/Filters/SkillsDevelopmentElementScoreFilters';

const SkillsDevelopmentElementScore = () => {



  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Reports / Skills Development Element Score"} />
      <main id="main" className="main">
        <div className='d-flex mt-3'>
          <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
          <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
        </div>
        <SkillsDevelopmentElementScoreFilters />
        <br/>

        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex flex-column '>
                    <div className='text-center bg-primary text-center text-light py-1 mx-0 border'>
                      Skills Development Spend on Black Employees
                    </div>
                    <table class="table  custom-table skills-dev">
                      <thead>
                        <tr>
                          <th scope="col" className=''>Expenditure</th>
                          <th scope="col" className=''>All</th>
                          <th scope="col" className=''>Black</th>
                          <th scope="col" className=''>Black Female</th>
                          <th scope="col" className=''>Black Disabled</th>
                          <th scope="col" className=''>Black Female Disabled</th>
                          <th scope="col" className=''>YTD Calendar Jan 22 - Dec 22</th>
                          <th scope="col" className=''>YTD Financial Year Jul 22 - Jun 23</th>
                          <th scope="col" className=''>YTD WSP Apr 22 - Mar 23</th>


                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td >A Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >B Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >C Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >D Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >E Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >F Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >Line Managers’ salaries: A - F</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr className='font-weight-600'>
                          <td >A - F Recognition</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >G Programmes</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >Line Managers’ salaries: G</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr className='font-weight-600'>
                          <td >Sub Total: Category G</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr className='font-weight-600'>
                          <td >G Recognition</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr className='font-weight-600'>
                          <td >Total</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex flex-column '>
                    <div className='text-center bg-primary text-center text-light py-1 mx-0 border'>
                      Number of Black Learners on B - D Programmes
                    </div>
                    <table class="table  custom-table skills-dev">
                      <thead>
                        <tr>
                          <th scope="col" className=''>Programmes</th>
                          <th scope="col" className=''>Black</th>
                          <th scope="col" className=''>Black YTD Calendar Jan 22 - Dec 22</th>
                          <th scope="col" className=''>Black YTD Financial Year Jul 22 - Jun 23</th>
                          <th scope="col" className=''>Black YTD WSP Apr 22 - Mar 23</th>
                          <th scope="col" className=''>Black Female</th>
                          <th scope="col" className=''>Black Female YTD Calendar Jan 22 - Dec 22</th>
                          <th scope="col" className=''>Black Female YTD Financial Year Jul 22 - Jun 23</th>
                          <th scope="col" className=''>Black Female YTD WSP Apr 22 - Mar 23</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td >B Programmes</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>

                        <tr>
                          <td>C Programmes</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                        </tr>

                        <tr>
                          <td >D Programmes</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>

                        <tr>
                          <td>Total</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>



            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='d-flex flex-column '>
                    <div className='text-center bg-primary text-center text-light py-1 mx-0 border'>
                      Unit Skills Development Element Score
                    </div>
                    <table class="table  custom-table skills-dev">
                      <thead>
                        <tr>
                          <th scope="col" className=''>Expenditure</th>
                          <th scope="col" className=''>Draft</th>
                          <th scope="col" className=''>Final</th>
                          <th scope="col" className=''>YTD Calendar Jan 22 - Dec 22</th>
                          <th scope="col" className=''>YTD Financial Year Jul 22 - Jun 23</th>
                          <th scope="col" className=''>YTD WSP Apr 22 - Mar 23</th>


                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td >Black Spend</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td >Disabled Black Spend</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>
                        <tr>
                          <td >Black Learners</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td>Total Score</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>

                        <tr>
                          <td>Unit Target</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>
                        <tr>
                          <td>Variance</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                          <td>R0</td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main >
    </>
  )
}
export default SkillsDevelopmentElementScore;
