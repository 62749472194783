import Dropdown from "../DropDown/Dropdown";
import React from "react";


const WSPATRFilters = () => {

    return (
        <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
            <div className="m-0 my-1 d-flex">
                <div className=" d-flex flex-wrap">
                    <div className="position-relative d-flex pt-3">
                        <span className="mx-2 mt-1 text-nowrap">
                            WSP Period:
                        </span>
                        <select defaultValue={1} class="form-select me-2" id="inputGroupSelect01">
                            <option value="1">1 Apr 2022 - 31 Mar 2023</option>
                        </select>
                    </div>
                    <span className="mx-3"></span>
                    <Dropdown label={"Business Area"} myClass={"mx-2 my-2"} filterItems={[
                        { id: 3, label: "Acturial & Risk" },
                        { id: 4, label: "Asset Management/Institutional" },
                        { id: 5, label: "Business Development" },
                        { id: 6, label: "Client & Advisor Experience" },
                        { id: 7, label: "Client & Advisor Service Centre" },
                        { id: 8, label: "Employee Benefits" }
                    ]} />
                </div>
            </div>
        </div>
    );
}
export default WSPATRFilters



