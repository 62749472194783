


import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "../DropDown/Dropdown";
import React, { useState } from "react";
//import 'bootstrap-daterangepicker/daterangepicker.css';

const DashboardLearnersFilters = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getRange = (start, end) => {
        var start_date = new Date(Date.parse(start));
        var end_date = new Date(Date.parse(end));
        start_date.setDate(start_date.getDate() + 1);
        start_date = start_date.toISOString().slice(0, 10);
        end_date = end_date.toISOString().slice(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);
    }
    return (
        <>
            <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
                <div className="m-0 my-1 d-flex">
                    <div className=" d-flex flex-column">
                        <div className="d-flex flex-wrap">
                            <Dropdown label={"Race"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "African" },
                                { id: 2, label: "Indian" },
                                { id: 3, label: "Coloured" },
                                { id: 4, label: "White" }
                            ]} />
                            <Dropdown label={"Age Group"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: '18-24' },
                                { id: 2, label: '25-29' },
                                { id: 3, label: '30-39' },
                                { id: 4, label: '40-49' },
                                { id: 5, label: '50-59' },
                                { id: 6, label: '60+' }
                            ]} />

                            <Dropdown label={"Yrs Of Services"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: '0-2' },
                                { id: 2, label: '3-5' },
                                { id: 3, label: '6-10' },
                                { id: 4, label: '11-20' },
                                { id: 5, label: '21-30' },
                                { id: 6, label: '31+' }
                            ]} />
                            <Dropdown label={"EE Category"} myClass={"mx-2 my-2"} filterItems={[{ id: 1, label: "Top Management" },
                            { id: 2, label: "Senior Management" },
                            { id: 3, label: "Professional/ Middle Management" },
                            { id: 4, label: "Technical/ Juniour Management" },
                            { id: 5, label: "Semi-Skilled" }]} />
                            <Dropdown label={"Disability"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "Yes" },
                                { id: 2, label: "No" }
                            ]} />

                            <Dropdown label={"Band"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "B1" },
                                { id: 2, label: "B2" },
                                { id: 3, label: "B3" },
                                { id: 4, label: "B4" },
                                { id: 5, label: "B5" }
                            ]} />
                            <Dropdown label={"Pat Grade"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "A1-A2" },
                                { id: 2, label: "B1-B2" },
                                { id: 3, label: "C2-C3" },
                                { id: 4, label: "D4-D5" },
                                { id: 5, label: "E2-E3" }
                            ]} />

                            <Dropdown label={"HC Exec"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "Sinazo Beyonce" },
                                { id: 2, label: "Arnold Brown" },
                                { id: 3, label: "Kayla Swift" },
                                { id: 4, label: "Tom Jerry" },
                                { id: 5, label: "Rihanna Kingwilliams" }
                            ]} />
                            <Dropdown label={"Senior HC"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "Adam Knowles" },
                                { id: 2, label: "Anga Ashleys" },
                                { id: 3, label: "Norman Boomny" },
                                { id: 4, label: "Agnes Chris" },
                                { id: 5, label: "Zoey Grootboom" }
                            ]} />
                        </div>

                        <hr />
                        <div className="d-flex flex-wrap">
                            <Dropdown label={"Business Area"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 3, label: "Acturial & Risk" },
                                { id: 4, label: "Asset Management/Institutional" },
                                { id: 5, label: "Business Development" },
                                { id: 6, label: "Client & Advisor Experience" },
                                { id: 7, label: "Client & Advisor Service Centre" },
                                { id: 8, label: "Employee Benefits" }
                            ]} />

                            <Dropdown label={"Business Unit"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "CLIENT & ADVISER SERVICE CENTRE" },
                                { id: 2, label: "CLIENT & ADVISER EXPERIENCE" },
                                { id: 3, label: "CLIENT & ADVISER SERVICE CENTRE" },
                                { id: 4, label: "RETAIL RISK SOLUTIONS" },
                                { id: 5, label: "INFORMATION TECHNOLOGY" }
                            ]} />
                            <Dropdown label={"Division"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: 'Risk Product' },
                                { id: 2, label: 'Operations' },
                                { id: 3, label: 'Chapters' },
                                { id: 4, label: 'Onboarding' },
                                { id: 5, label: 'Service Channel Management' }
                            ]} />
                            <Dropdown label={"Department"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: 'Product Pricing' },
                                { id: 2, label: 'Fund Governance & New Business' },
                                { id: 3, label: 'Analyst Chapter' },
                                { id: 4, label: 'Onboarding Admin & Medical Services' },
                                { id: 5, label: 'Business Preservation' }
                            ]} />
                            <Dropdown label={"Line Manager"} myClass={"mx-2 my-2"} filterItems={[
                                { id: 1, label: "Ayanda Grace" },
                                { id: 2, label: "Grace Bryston" },
                                { id: 3, label: "Maxwell Chrisbrown" },
                                { id: 4, label: "Nozuko Gym" },
                                { id: 5, label: "Emmanuel Johncena" }
                            ]} />
                            <div className='d-flex'>
                                <input type="checkbox" defaultChecked className='mx-2' name='hsdghgds' />
                                <p className='my-3'>Permanent</p>
                            </div>
                            <div className='d-flex'>
                                <input type="checkbox" className='mx-2' name='hsdghgds' />
                                <p className='my-3'>Temp / Contr.</p>
                            </div>
                            <div className="position-relative d-flex py-2">
                                <span className="mx-2 mt-1">
                                    Date:
                                </span>
                                <select class="form-select" id="inputGroupSelect01">
                                    <option value="1">1 Apr 2022 - 31 Mar 2023</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default DashboardLearnersFilters