
import SignIn from './pages/SignIn';

import {BrowserRouter, MemoryRouter, Route, Routes} from 'react-router-dom';
import Sidebar from './components/sidebar/Sidebar';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import { Redirect } from './components/Redirect';
import { baseUrl, chatbotData } from './components/constants';
import axios from 'axios';
import Dashboard from './pages/Dashboard';
import Chatbot from './components/cf-react-chatbot/Chatbot';
import { useState } from 'react';

import config from './config';
import HeadcountVSHeadcount from './pages/HeadcountVSHeadcount/HeadcountVSHeadcount';
import Learners from './pages/Learners/Learners';
import SkillsDevelopmentElementScore from './pages/SkillsDevelopmentElementScore/SkillsDevelopmentElementScore';
import AuditTrail from './pages/AuditTrail/AuditTrail';
import SkillsDevelopmentSpend from './pages/SkillsDevelopmentSpend/SkillsDevelopmentSpend';
import WSPATRReport from './pages/WSPATRReport/WSPATRReport';
import ProgrammeInterventionsCosts from './pages/ProgrammeInterventionsCosts/ProgrammeInterventionsCosts';
import HelpSupport from "./pages/HelpSupport/HelpSupport";
import IssueLogForm from './pages/IssueLog/IssueLogForm';
import IssueLogs from './pages/IssueLog/IssueLog';
import HCMore from 'highcharts/highcharts-more'; // If you need additional chart types
import HighchartsExporting from 'highcharts/modules/exporting'; // For exporting the chart as an image or PDF
import HighchartsExportData from 'highcharts/modules/export-data'; // For exporting data as CSV or XLSX
import Highcharts from 'highcharts';


// Initialize the additional modules
HCMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);


const App = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [conversation,setConversation] = useState([]);
  const [question,setQuestion]= useState();
  const token = localStorage.getItem("token");
  //axios.defaults.baseURL = "http://localhost:8000/api/";
  axios.defaults.baseURL = config.apiUrl;
  axios.interceptors.request.use(
    async (config) => {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.credentials = 'include'; // Set the "credentials" option to "include"
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(async (response) => {
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    // console.log(originalRequest)
    if (error.response.status === 401) { localStorage.removeItem('token'); window.location.reload(true); }
    if (error.response.status === 408) { localStorage.removeItem('token'); window.location.reload(true); }
    return Promise.reject(error);
  });

const getConversation = ()=>{
  axios.get("getConversations").then((res)=>{
    setConversation(res.data.data)
  }).catch(()=>{

  })
}
const createQuestion = ()=>{
  axios.post("createQuestion",{question}).then((res)=>{
    getConversation();
    setQuestion("");
  }).catch(()=>{

  })
}


  return (
    <>
     
      {
<button className="chatbot-toggler shadow ">
<span className="material-symbols-rounded">
  <div className="bot-icon-container" onClick={() => { setShowChatbot((val) => { return !val }) }}>
    {showChatbot && <i class="fa-solid fa-xmark text-light"></i>}
    {!showChatbot && <i class="fa-solid fa-message text-light"></i>}
  </div>
  {showChatbot && <div className='position-absolute chatbot-package shadow rounded-4'>
    <Chatbot
      data={chatbotData}
      getConversation={getConversation }
      conversation = {conversation}
      // onClose={()=>{ toggleBot(false)}}
      onChange = {(value)=>{ setQuestion(value)}}
      onClose={() => { }}
      onSubmit={() => { createQuestion() }}
    />
  </div>
  }
</span>
</button>
      }
      <MemoryRouter>
        <Sidebar />
        <Routes >
          <Route exact path="/" element={<ProtectedRoutes element={<Dashboard />} />} />
          <Route exact path="/wsp-atr-report" element={<ProtectedRoutes element={<WSPATRReport />} />} />
          <Route exact path="/programme-interventions-costs" element={<ProtectedRoutes element={<ProgrammeInterventionsCosts />} />} />
          <Route exact path="/learners" element={<ProtectedRoutes element={<Learners />} />} />
          <Route exact path="/headcount-vs-headcount" element={<ProtectedRoutes element={<HeadcountVSHeadcount />} />} />
          <Route exact path="/audit-trail" element={<ProtectedRoutes element={<AuditTrail />} />} />
          <Route exact path="/skills-development-spend" element={<ProtectedRoutes element={<SkillsDevelopmentSpend />} />} />
          <Route exact path="/skills-development-element-score" element={<ProtectedRoutes element={<SkillsDevelopmentElementScore />} />} />
          <Route path="/help-support" element={<ProtectedRoutes element={<HelpSupport/>} />} />
          <Route path="/issue-logs" element={<ProtectedRoutes element={<IssueLogs/>} />} />
          <Route path="/issue-logs/add" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
          <Route path="/issue-logs/:id" element={<ProtectedRoutes element={<IssueLogForm/>} />} /> 
        </Routes>
        <Routes>
          <Route path="/login" element={<Redirect element={< SignIn />} />} />          
        </Routes>
      </MemoryRouter>
    </>
  );
}

export default App;
