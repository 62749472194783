import React, { useState } from 'react';
import Header from '../Header';
import ProgrammeInterventionCostFilters from '../../components/Filters/ProgrammeInterventionCostFilters';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OFOOccupations from '../WSPATRReport/extra/ofoOccupations';
import OFOSpecializations from '../WSPATRReport/extra/OFOSpecialization';
import LearnerInformation from '../WSPATRReport/extra/learnersInformation';

const data_ = [
  {

    id: 1,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Joe Slovo",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []
  },
  {
    id: 2,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Anele Miriam Swanepoel",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []

  },
  {
    id: 3,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true

    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Sam Smith",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []
  },

  {
    id: 4,
    learningProgramme: "Data Visualisation Techniques",
    OFOOccupation: "2021-251102 - Data Scientist",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Internal LMS",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "22 900.40",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Nick Jack White",
    attachReciept: {
      name: "",
      url: ""
    },
    learnerIds: []

  },
  {
    id: 5,
    learningProgramme: "Data Visualisation Techniques",
    OFOOccupation: "2021-251102 - Data Scientist",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Internal LMS",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "22 900.40",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Wendy Ross",
    attachReciept: {
      name: "Payment.pdf",
      url: "Payment.pdf"
    },
    learnerIds: []

  },
  {
    id: 6,
    learningProgramme: "Risk Management",
    OFOOccupation: "2021-331504 - Insurance Risk Surveyor",
    OFOSpecialization: "2021-241301 - Insurance Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Connemara",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "",
      splitAccross: false
    },
    expenditureOther: {
      value: "43 700.34",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Mandy Norway",
    attachReciept: {
      name: "",
      url: "Payment.pdf"
    },
    learnerIds: []

  },
  {
    id: 7,
    learningProgramme: "Analytics 101",
    OFOOccupation: "2021-251202 - Programmer Analyst",
    OFOSpecialization: "2021-133104 - ICT Development Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "No",
    startDate: "2021-10-01",
    endDate: "2022-02-28",
    trainingProvider: "Careers IT",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "42 300.00",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Jack Jones",
    attachReciept: {
      name: "",
      url: "Payment.pdf"
    },
    learnerIds: []
  },
];
const ATRProgrammeInterventionsCosts = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [selectedKey, setSelectedKey] = useState('');
  const [title, setTitle] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [data, setData] = useState(data_);
  const [selectedIds, setSelectedIds] = useState([]); //for learner
  const [currentRecordId, setCurrentRecordId] = useState(-1);

  const OFOOccupationsList = OFOOccupations;
  const OFOSpecializationList = OFOSpecializations;
  const LearnerInformationList = LearnerInformation;

  const openModal = (key, id) => {
    // Set filtered items based on the key
    setSelectedKey(key);
    setSelectedId(id);
    if (key === 'OFOSpecialization') {
      setShowModal(true);
      setFilteredItems(OFOSpecializationList);
      setTitle('OFO Specialization')
    } else if (key === 'OFOOccupations') {
      setShowModal(true);
      setTitle('OFO Occupation')
      setFilteredItems(OFOOccupationsList);
    } else if (key === 'learnerInformation') {
      setShowModal2(true)
      setFilteredItems(LearnerInformationList);
      setTitle('Learner Information')
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal2(false);

  };

  const handleItemSelection = (selectedItem) => {
    // Handle the selected item here
    console.log(`Selected Item: ${selectedItem}`);
    setSearchQuery(''); // Clear the search query
    setShowModal(false); // Close the modal

    const updatedData = data.map((item) => {
      if (item.id === selectedId) {

        if (selectedKey === 'OFOSpecialization') {
          item.OFOSpecialization = selectedItem;
        } else if (selectedKey === 'OFOOccupations') {
          item.OFOOccupation = selectedItem;
        } else if (selectedKey === 'learnerInformation') {
          item.attachReciept.name = selectedItem;
        }

      }
      return item;
    });

    // Set the updated data in the state
    setData(updatedData);
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the items based on the search query
    const filteredResult = filteredItems.filter((item) =>
      item.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredItems(filteredResult);
  };
  const onCheckLearner = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        // If the ID is already selected, remove it from the selectedIds array
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        // If the ID is not selected, add it to the selectedIds array
        return [...prevSelectedIds, id];
      }
    });
    const updatedData = data.map((item) => {
      if (item.id === currentRecordId) {
        // Update the learnerIds property of the matching item
        return { ...item, learnerIds: selectedIds };
      }
      return item;
    });

    // Set the updated data back to your state variable
    setData(updatedData);
  };



  const splitAcross = (e, id, field) => {
    const value = e.target.value

    const updatedData = data.map((item) => {

      if (item.id === id) {
        let expenditureINSETA = item.expenditureINSETA.value.toString().replace(/\s/g, '');
        let expenditureCompany = item.expenditureCompany.value.toString().replace(/\s/g, '');
        let expenditureOther = item.expenditureOther.value.toString().replace(/\s/g, '');

        expenditureINSETA = parseFloat(expenditureINSETA);
        expenditureCompany = parseFloat(expenditureCompany);
        expenditureOther = parseFloat(expenditureOther);

        let total = 0;

        total = expenditureOther + expenditureCompany + expenditureINSETA;
        if (field === 'expenditureINSETA') {

          if (item.expenditureINSETA.splitAccross === true) {
            item.expenditureINSETA.value = formatNumberToAccounting(total, '');;

            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;

            item.expenditureCompany.value = 0.00;
            item.expenditureOther.value = 0.00;

          } else {
            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;

            let split = total / 3;
            split = formatNumberToAccounting(split, '')
            item.expenditureINSETA.value = split;
            item.expenditureCompany.value = split;
            item.expenditureOther.value = split;
          }

        }
        else if (field === 'expenditureCompany') {
          if (item.expenditureCompany.splitAccross === true) {
            item.expenditureCompany.value = formatNumberToAccounting(total, '');;

            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;

            item.expenditureINSETA.value = 0.00;
            item.expenditureOther.value = 0.00;

          } else {
            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;
            let split = total / 3;
            split = formatNumberToAccounting(split, '')
            item.expenditureINSETA.value = split;
            item.expenditureCompany.value = split;
            item.expenditureOther.value = split;
          }

        }
        else if (field === 'expenditureOther') {
          if (item.expenditureOther.splitAccross === true) {
            item.expenditureOther.value = formatNumberToAccounting(total, '');

            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;

            item.expenditureINSETA.value = 0.00;
            item.expenditureCompany.value = 0.00;

          } else {
            let split = total / 3;
            item.expenditureINSETA.splitAccross = !item.expenditureINSETA.splitAccross;
            item.expenditureCompany.splitAccross = !item.expenditureCompany.splitAccross;
            item.expenditureOther.splitAccross = !item.expenditureOther.splitAccross;

            /*split = split.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD', // Replace with your desired currency code
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });*/

            split = formatNumberToAccounting(split, '')
            item.expenditureINSETA.value = split
            item.expenditureCompany.value = split;
            item.expenditureOther.value = split;
          }
        }
      }
      return item;
    });
    setData(updatedData)
  }

  const removeRecord = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData)
  }

  const addRecord = () => {
    var template = {
      id: data.length + 3,
      learningProgramme: "",
      OFOOccupation: "",
      OFOSpecialization: "",
      programmeLevel: "",
      pivotalProgramme: "",
      startDate: "",
      endDate: "",
      trainingProvider: "",
      expenditureINSETA: {
        value: "",
        splitAccross: false
      },
      expenditureCompany: {
        value: "",
        splitAccross: false
      },
      expenditureOther: {
        value: "",
        splitAccross: true
      },
      creditBearing: "",
      learnerName: "",
      attachReciept: {
        name: "",
        url: ""
      },
      learnerIds: []
    };

    setData(data.concat([template]));
  }

  const onChangeDate = (event, id, type) => {
    const value = event.target.value;

    const updatedData = data.map((item) => {
      if (item.id === id) {
        if (type === 'start')
          item.startDate = value;
        else if (type === 'end')
          item.endDate = value;
      }
      return item;
    });

    // Set the updated data in the state
    setData(updatedData);
  }

  const uploadFile = (id) => {

    const fileInput = document.createElement('input');
    fileInput.type = 'file';

    // Add an event listener to handle file selection
    fileInput.addEventListener('change', (e) => {
      const file = e.target.files[0];
      if (file) {
        // Set the selected file in the state
        // Update the data array with the new attachReciept.name
        const updatedData = data.map((item) => {
          if (item.id === id) {
            item.attachReciept.name = file.name;
          }
          return item;
        });

        // Set the updated data in the state
        setData(updatedData);
      }
    });

    // Trigger a click event on the file input element
    fileInput.click();
  };

  const handleExpenseChange = (e, id, type) => {
    const value = e.target.value;

    const updatedData = data.map((item) => {
      if (item.id === id) {

        if (type === 'expenditureINSETA') {
          item.expenditureINSETA.value = value; //formatNumberToAccounting(value,   '');;

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureCompany.value = 0.00;
          item.expenditureOther.value = 0.00;
        } else if (type === 'expenditureCompany') {

          item.expenditureCompany.value = value; //formatNumberToAccounting(value,   '');

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureINSETA.value = 0.00;
          item.expenditureOther.value = 0.00;
        } else if (type === 'expenditureOther') {

          item.expenditureOther.value = value; //formatNumberToAccounting(value,   '');;

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureINSETA.value = 0.00;
          item.expenditureCompany.value = 0.00;
        }
      }
      return item;
    });

    // Set the updated data in the state
    setData(updatedData);
  }

  const handleExpenseOnBlur = (e, id, type) => {
    const value = e.target.value;

    const updatedData = data.map((item) => {
      if (item.id === id) {

        if (type === 'expenditureINSETA') {
          item.expenditureINSETA.value = formatNumberToAccounting(value, '');;

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureCompany.value = 0.00;
          item.expenditureOther.value = 0.00;
        } else if (type === 'expenditureCompany') {

          item.expenditureCompany.value = formatNumberToAccounting(value, '');;

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureINSETA.value = 0.00;
          item.expenditureOther.value = 0.00;
        } else if (type === 'expenditureOther') {

          item.expenditureOther.value = formatNumberToAccounting(value, '');;

          item.expenditureINSETA.splitAccross = false; //!item.expenditureINSETA.splitAccross;
          item.expenditureCompany.splitAccross = false; //!item.expenditureCompany.splitAccross;
          item.expenditureOther.splitAccross = false; // !item.expenditureOther.splitAccross;

          item.expenditureINSETA.value = 0.00;
          item.expenditureCompany.value = 0.00;
        }
      }
      return item;
    });

    // Set the updated data in the state
    setData(updatedData);
  }


  function formatNumberToAccounting(number, currencySymbol = '') {
    // Convert the number to a string with two decimal places
    const formattedNumber = parseFloat(number).toFixed(2);

    // Split the number into parts (integer and fractional)
    const [integerPart, fractionalPart] = formattedNumber.split('.');

    // Add spaces as thousands separators to the integer part
    const integerWithSpaces = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    // Combine the formatted parts with the currency symbol
    const result = `${currencySymbol}${integerWithSpaces}.${fractionalPart}`;

    return result;
  }

  const handleSearchInputChangeForLearner = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter the items based on the search query
    const filteredResult = filteredItems.filter((item) => {
      // Check if any of the values in the item object contain the search query
      for (const key in item) {
        const value = String(item[key]); // Convert the value to a string
        if (value.toLowerCase().includes(query.toLowerCase())) {
          return true; // Include the item if any value matches the query
        }
      }
      return false; // Exclude the item if no value matches the query
    });
    if (query == "") {
      setFilteredItems(LearnerInformationList);
    } else {
      setFilteredItems(filteredResult);
    }
  };

  const duplicateRecord = () => {
    var tempArray = [];


    for (let i = 0; i < selectedIds.length; i++) {
      for (let j = 0; j < LearnerInformation.length; j++) {
        if (LearnerInformation[j].id == selectedIds[i]) {
          // var learner = LearnerInformation[j];
          var learner = { ...LearnerInformation[j] }
          if (learner) {
            var recordToDuplicate = null; // Initialize it to null

            for (let i = 0; i < data.length; i++) {
              if (data[i].id == currentRecordId) {
                recordToDuplicate = { ...data[i] }; // Create a new object
              }
            }
            if (recordToDuplicate) {
              recordToDuplicate.id = data.length + 3;
              recordToDuplicate.learnerName = LearnerInformation[j].Name + " " + LearnerInformation[j].Last_Name;
              tempArray.push(recordToDuplicate);
            }

          }
        }
      }
    }
    setData(data.concat(tempArray))
    setShowModal2(false);
    setSelectedIds([])
  };



  const updateTextFieldsOnChange = (id, property, newValue) => {
    const itemIndex = data.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      const updatedData = [...data];
      updatedData[itemIndex][property] = newValue;
      setData(updatedData);
    }
  };

  return (
    <>
   
        <section className="section ">
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <div className='card'>
                <div className='card-body'>
                  <div className='scroll-x'>
                    <div className='table-container'>
                      <table className="table custom-table int-table">
                        <thead>
                          <tr>
                            <th scope="col" className='action-col'></th>
                            <th scope="col" className=''>Learning Programme</th>
                            <th colSpan="2" scope="col" className=''>OFO Occupation</th>
                            <th colSpan="2" scope="col" className=''>OFO Specialisation</th>
                            <th scope="col" className=''>Programme Level</th>
                            <th scope="col" className=''>Pivotal Programme</th>
                            <th scope="col" className=''>Start Date</th>
                            <th scope="col" className=''>End Date</th>
                            <th scope="col" className=''>Training Provider</th>
                            <th colSpan="2" scope="col" className=''>Expenditure: INSETA</th>
                            <th colSpan="2" scope="col" className=''>Expenditure: Company</th>
                            <th colSpan="2" scope="col" className=''>Expenditure: Other</th>
                            <th scope="col" className=''>Credit Bearing</th>
                            <th colSpan="2" scope="col" className=''>Learner Name</th>
                            <th colSpan="2" scope="col" className=''>Attach Receipt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data.map((item, key) => (
                              <>
                                <tr>
                                  <td className="action-col" onClick={() => { removeRecord(item.id) }}>
                                    <span className="mag-glass " onClick={() => { removeRecord(item.id) }}>
                                      <i className="fa-solid fa-circle-xmark cursor-pointer text-danger" onClick={() => { removeRecord(item.id) }}></i>
                                    </span>
                                  </td>
                                  <td><input onChange={(e) => { updateTextFieldsOnChange(item.id, "learningProgramme", e.target.value) }} type="text" id={item.id} value={item.learningProgramme} /></td>

                                  <td>{item.OFOOccupation}</td>
                                  <td className="mag-glass-container cursor-pointer" onClick={() => { openModal('OFOOccupations', item.id) }}>
                                    <span className="mag-glass " onClick={() => { openModal('OFOOccupations', item.id) }}><i className="fa-solid fa-magnifying-glass cursor-pointer"></i></span>
                                  </td>

                                  <td>
                                    {item.OFOSpecialization}
                                  </td>
                                  <td className="mag-glass-container cursor-pointer" onClick={() => { openModal('OFOSpecialization', item.id) }}>
                                    <span className="mag-glass " onClick={() => { openModal('OFOSpecialization', item.id) }}><i className="fa-solid fa-magnifying-glass cursor-pointer"></i></span>
                                  </td>

                                  <td>
                                    <select className="" value={item.programmeLevel}>
                                      <option >Open this select menu</option>
                                      <option value="Entry">Entry</option>
                                      <option value="Intermediate">Intermediate</option>
                                      <option value="Advanced">Advanced</option>
                                    </select>
                                  </td>

                                  <td>
                                    <select className="" value={item.pivotalProgramme}>
                                      <option >Open this select menu</option>
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </td>
                                  <td>
                                    <input type="date" value={item.startDate} onChange={(event) => { onChangeDate(event, item.id, 'start') }} />
                                  </td>
                                  <td>
                                    <input type="date" value={item.endDate} onChange={(event) => { onChangeDate(event, item.id, 'end') }} />
                                  </td>

                                  <td>
                                    <input
                                      className="expenditure"
                                      type="text"
                                      id={item.id}
                                      onChange={(e) => { updateTextFieldsOnChange(item.id, "trainingProvider", e.target.value) }}
                                      value={item.trainingProvider}
                                    />
                                  </td>

                                  <td>
                                    <input
                                      className="expenditure"
                                      type="text"
                                      id={item.id}
                                      value={item.expenditureINSETA.value}
                                      onChange={(e) => { handleExpenseChange(e, item.id, 'expenditureINSETA') }}
                                      onBlur={(e) => { handleExpenseOnBlur(e, item.id, 'expenditureINSETA') }}
                                    />
                                  </td>
                                  <td className="split-across-container">
                                    <input className="form-check-input" type="checkbox" checked={item.expenditureINSETA.splitAccross}
                                      name={item.id}
                                      id={`expenditureINSETA_ ${item.id}`}
                                      onChange={(e) => { splitAcross(e, item.id, 'expenditureINSETA') }} />
                                    <label className="form-check-label split-across" htmlFor={`expenditureINSETA_ ${item.id}`}>
                                      Split across
                                    </label>
                                  </td>

                                  <td>
                                    <input className="expenditure" type="text"
                                      id={item.id}
                                      value={item.expenditureCompany.value}
                                      onChange={(e) => { handleExpenseChange(e, item.id, 'expenditureCompany') }}
                                      onBlur={(e) => { handleExpenseOnBlur(e, item.id, 'expenditureCompany') }}
                                    />
                                  </td>
                                  <td className="split-across-container">
                                    <input className="form-check-input" type="checkbox"
                                      checked={item.expenditureCompany.splitAccross}
                                      name={item.id}
                                      id={`expenditureCompany_ ${item.id}`}
                                      onChange={(e) => { splitAcross(e, item.id, 'expenditureCompany') }}
                                    />
                                    <label className="form-check-label split-across" htmlFor={`expenditureCompany_ ${item.id}`}>
                                      Split across
                                    </label>
                                  </td>

                                  <td>
                                    <input
                                      className="expenditure"
                                      type="text" id={item.id}
                                      value={item.expenditureOther.value}
                                      onChange={(e) => { handleExpenseChange(e, item.id, 'expenditureOther') }}
                                      onBlur={(e) => { handleExpenseOnBlur(e, item.id, 'expenditureOther') }}
                                    />
                                  </td>
                                  <td className="split-across-container">
                                    <input className="form-check-input" type="checkbox" checked={item.expenditureOther.splitAccross}
                                      name={item.id}
                                      id={`expenditureOther_ ${item.id}`}
                                      onChange={(e) => { splitAcross(e, item.id, 'expenditureOther') }} />
                                    <label className="form-check-label split-across" htmlFor={`expenditureOther_ ${item.id}`}>
                                      Split across
                                    </label>
                                  </td>

                                  <td>
                                    <select className="" id={item.id} value={item.creditBearing}>
                                      <option >Open this select menu</option>
                                      <option value="Credit-Bearing">Credit-Bearing</option>
                                      <option value="Non Credit-Bearing">Non Credit-Bearing</option>
                                    </select>
                                  </td>

                                  <td>{item.learnerName}</td>
                                  <td className="mag-glass-container cursor-pointer" onClick={() => { openModal('learnerInformation', item.id) }}>
                                    <span className="mag-glass " onClick={() => { setCurrentRecordId(item.id); setSelectedIds([]); openModal('learnerInformation', item.id) }}><i className="fa-solid fa-magnifying-glass cursor-pointer"></i></span>
                                  </td>

                                  <td className={item.attachReciept.name === '' ? 'no-attachment' : ''}>
                                    {item.attachReciept.name !== '' ? item.attachReciept.name : 'No Attachment'}
                                  </td>
                                  <td className="mag-glass-container cursor-pointer" onClick={() => { uploadFile(item.id) }}>
                                    <span className="mag-glass cursor-pointer" onClick={() => { uploadFile(item.id) }}><i className="fa-solid fa-paperclip cursor-pointer"></i></span>
                                  </td>
                                </tr>
                              </>
                            ))
                          }
                          <tr>
                            <td className="action-col" onClick={() => { addRecord() }}>
                              <span className="mag-glass " onClick={() => { addRecord() }}>
                                <i className="fa-solid fa-circle-plus cursor-pointer text-success" onClick={() => { addRecord() }}></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Items"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          <ul className="list-group list-group-flush">
            {filteredItems.map((item, key) => (
              <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                {selectedKey === 'learnerInformation' ?
                  <>{item.Emp_Number} - {item.Name} {item.Last_Name} ({item.Line_Manager})</> : item
                }
                <Button
                  variant="primary"
                  onClick={() => {
                    handleItemSelection(item);
                    closeModal();
                  }}
                  className="ml-2"
                >
                  Select
                </Button>
              </li>
            ))}
          </ul>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          {/* Add additional buttons or actions here */}
        </Modal.Footer>
      </Modal>



      <Modal show={showModal2} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search Items"
              value={searchQuery}
              onChange={handleSearchInputChangeForLearner}
            />
          </div>

          <>
            <table class="table table-hover table-striped instance-table-header custom-table ">
              <thead>
                <tr>
                  <th width="" scope="col" className=" m-0" id="issue_sub_category">Action</th>

                  <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                  <th width="" scope="col" className="" id="subject">Name</th>
                  <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                  <th width="" scope="col" className="" id="date_opened">Position</th>
                  <th width="" scope="col" className="" id="date_closed">Line Manager</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, key) => (
                  <>
                    <tr>
                      <td><input onClick={() => { onCheckLearner(item.id) }} checked={selectedIds.includes(item.id)} type="checkbox" /></td>
                      <td >{item.Emp_Number}</td>
                      <td>{item.Name}</td>
                      <td>{item.Last_Name}</td>
                      <td >{item.Position}</td>
                      <td >{item.Line_Manager}</td>
                    </tr>
                  </>
                ))}


              </tbody>
            </table>
            <hr />
            <h5>Selected</h5>
            <table class="table table-hover table-striped instance-table-header custom-table">
              <thead>
                <tr>
                  <th width="" scope="col" className=" m-0" id="issue_sub_category">Action</th>

                  <th width="" scope="col" className="" id="issue_sub_category">Emp. No</th>
                  <th width="" scope="col" className="" id="subject">Name</th>
                  <th width="" scope="col" className="" id="logged_by_name">Last Name</th>
                  <th width="" scope="col" className="" id="date_opened">Position</th>
                  <th width="" scope="col" className="" id="date_closed">Line Manager</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item, key) => (
                  <>
                    {
                      selectedIds.includes(item.id) && (
                        <>
                          <tr>
                            <td><input onClick={() => { onCheckLearner(item.id) }} checked={selectedIds.includes(item.id)} type="checkbox" /></td>
                            <td >{item.Emp_Number}</td>
                            <td>{item.Name}</td>
                            <td>{item.Last_Name}</td>
                            <td >{item.Position}</td>
                            <td >{item.Line_Manager}</td>
                          </tr>
                        </>
                      )
                    }
                  </>
                ))}


              </tbody>
            </table>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => duplicateRecord()}>
            Submit
          </Button>
          {/* Add additional buttons or actions here */}
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ATRProgrammeInterventionsCosts;
