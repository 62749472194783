import DateRangePicker from "react-bootstrap-daterangepicker";
import Dropdown from "../DropDown/Dropdown";
import React, { useState } from "react";
//import 'bootstrap-daterangepicker/daterangepicker.css';

const AuditTrailFilters = () => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getRange = (start, end) => {
        var start_date = new Date(Date.parse(start));
        var end_date = new Date(Date.parse(end));
        start_date.setDate(start_date.getDate() + 1);
        start_date = start_date.toISOString().slice(0, 10);
        end_date = end_date.toISOString().slice(0, 10);
        setStartDate(start_date);
        setEndDate(end_date);
    }
    return (
        <>
            <div className="bg-secondary ps-1 mb-3 rounded-1 py-1  my-4 border-r  d-flex align-items-center justify-content-between dynamic-filters-container">
                <div className="m-0 my-1 d-flex">
                    <div className=" d-flex flex-wrap">
                        <div className="position-relative d-flex py-3">
                            <span className="mx-2 mt-1">
                                Date:
                            </span>
                            <DateRangePicker onCancel={() => { setStartDate(""); setEndDate(""); }} onApply={(e, picker) => { getRange(picker.startDate._d, picker.endDate._d) }}>
                                <button className="btn btn-sm text-dark border rounded-1 text-light me-2 text-nowrap" type="button" style={{ backgroundColor: "white", border: "3px solid black" }}>{startDate && endDate ? `${startDate + " - " + endDate}` : "Choose Date Range"}</button>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AuditTrailFilters