import React, { useState } from 'react';
import Header from '../Header';
import ProgrammeInterventionCostFilters from '../../components/Filters/ProgrammeInterventionCostFilters';
import ATRProgrammeInterventionsCosts from './ATRProgrammeInterventionsCosts';
import WSPProgrammeInterventionsCosts from './WSPProgrammeInterventionsCosts';


const data_ = [
  {

    id: 1,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Joe Slovo",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []
  },
  {
    id: 2,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Anele Miriam Swanepoel",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []

  },
  {
    id: 3,
    learningProgramme: "Machine Learning - AI",
    OFOOccupation: "2021-212101 - Actuary",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-05-01",
    endDate: "2022-02-28",
    trainingProvider: "Ceed Learning",
    expenditureINSETA: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureCompany: {
      value: "57 000.02",
      splitAccross: true
    },
    expenditureOther: {
      value: "57 000.02",
      splitAccross: true

    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Sam Smith",
    attachReciept: {
      name: "Invoicing Schedule_v2.pdf",
      url: "sdfdsf.pdf"
    },
    learnerIds: []
  },

  {
    id: 4,
    learningProgramme: "Data Visualisation Techniques",
    OFOOccupation: "2021-251102 - Data Scientist",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Internal LMS",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "22 900.40",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Nick Jack White",
    attachReciept: {
      name: "",
      url: ""
    },
    learnerIds: []

  },
  {
    id: 5,
    learningProgramme: "Data Visualisation Techniques",
    OFOOccupation: "2021-251102 - Data Scientist",
    OFOSpecialization: "2021-212101 - Actuary Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Internal LMS",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "22 900.40",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Wendy Ross",
    attachReciept: {
      name: "Payment.pdf",
      url: "Payment.pdf"
    },
    learnerIds: []

  },
  {
    id: 6,
    learningProgramme: "Risk Management",
    OFOOccupation: "2021-331504 - Insurance Risk Surveyor",
    OFOSpecialization: "2021-241301 - Insurance Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "Yes",
    startDate: "2021-08-01",
    endDate: "2022-02-28",
    trainingProvider: "Connemara",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "",
      splitAccross: false
    },
    expenditureOther: {
      value: "43 700.34",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Mandy Norway",
    attachReciept: {
      name: "",
      url: "Payment.pdf"
    },
    learnerIds: []

  },
  {
    id: 7,
    learningProgramme: "Analytics 101",
    OFOOccupation: "2021-251202 - Programmer Analyst",
    OFOSpecialization: "2021-133104 - ICT Development Manager",
    programmeLevel: "Advanced",
    pivotalProgramme: "No",
    startDate: "2021-10-01",
    endDate: "2022-02-28",
    trainingProvider: "Careers IT",
    expenditureINSETA: {
      value: "0.00",
      splitAccross: false
    },
    expenditureCompany: {
      value: "42 300.00",
      splitAccross: false
    },
    expenditureOther: {
      value: "0.00",
      splitAccross: false
    },
    creditBearing: "Non Credit-Bearing",
    learnerName: "Jack Jones",
    attachReciept: {
      name: "",
      url: "Payment.pdf"
    },
    learnerIds: []
  },
];
const ProgrammeInterventionsCosts = () => {
  const [value, setValue] = useState(1);


  return (
    <>
      <Header icon={"fa-solid fa-users fa-xl mx-2"} label={"Capture Data / Programme Interventions & Costs"} />
      <main id="main" className="main">
        <div className='d-flex mt-3'>
          <button type="button" class="btn btn-primary font-weight-600 me-2">APPLY</button>
          <button type="button" class="btn btn-primary font-weight-600">REAPPLY</button>
          <span>
            <select value={value} onChange={(e)=>setValue(e.target.value)} className="form-select ms-2 border py-2" aria-label="Default select example">
              <option value="1">ATR</option>
              <option value="2">WSP</option>
            </select>
          </span>
        </div>
        <ProgrammeInterventionCostFilters />
        <br />
        {value == 1 ? <ATRProgrammeInterventionsCosts /> : <WSPProgrammeInterventionsCosts />}
      </main>
    </>
  )
}
export default ProgrammeInterventionsCosts;
